import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `Common/CardPrintList.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import QmlWeb 1.0
import DGrid 1.0
import "../Common" as Common
import "../Common/Util.js" as Util

Common.CardLayout {
    property int patientID: -1
    property int encounterID: -1
    property int operatingOrderID: -1
    property bool preview: false
    property bool loadPreview: false
    property var confirm: false
    property var reason: ''

    property var task_id
    property var task_tracking
    property bool get_printed: false

    id: root
    toggleCard: false

    function clear(){
        // set blank iframe
        iframe.dom.src = 'about:blank'
        frmError.clear()
        // clear selected of dgrid
        grdRequest.clearSelection()
        rstDocumentType.filter({printable: 'true', division: 'current'})
        root.task_tracking = null
        root.task_id = null
        root.get_printed = false
    }

    function setPatientData(data) {
        root.patientID = data.patientID || -1
        root.encounterID = data.encounterID || -1
        root.operatingOrderID = data.operatingOrderID || -1
    }

    function printDocument(documentURL, jasperModule, jasperPath){
        var query = {}
        query.patient_id = patientID
        query.encounter_id = encounterID
        if (operatingOrderID && operatingOrderID > 0) {
            query.order_id = operatingOrderID
        }
        query.jasper_module = jasperModule
        query.jasper_path = jasperPath
        if(root.confirm){
            query.confirm = root.confirm
            query.reason = root.reason
        }
        if (preview == true)
            query.pdf = true
        rmdDocument.url = documentURL
        rmdDocument.query = query
        rmdDocument.fetch()
        rmdDocument.success = null
        rmdDocument.warning = null
        rmdDocument.error = null
        rmdDocument.confirm = null
        root.reason = ''
        loadPreview = true
    }

    function getPrintedDocument(documentURL, task_id){
        preview = true
        root.confirm = false
        rmdDocument.url = documentURL
        rmdDocument.query = {
            task_id: task_id
        }
        rmdDocument.fetch()
        rmdDocument.success = null
        rmdDocument.warning = null
        rmdDocument.error = null
        rmdDocument.confirm = null
        root.reason = ''
        loadPreview = true
    }

    onPatientIDChanged: {
        subPatientIdentification.clear()
        subPatientIdentification.reload(patientID)
    }
    onEncounterIDChanged: {
        if (encounterID && encounterID > 0) {
            subPatientIdentification.clear()
            sbxEncounter.getEncounter(encounterID)
            subPatientIdentification.reloadFromEncounter(encounterID)
        }
        else {
            sbxEncounter.clear()
        }
    }

    title: "พิมพ์เอกสาร"
    headerColor: "blue"

    Component.onCompleted: {
        setInterval(function() {
            if (root.task_tracking) {
                rmdTaskTracking.query = {
                    task_id: root.task_tracking
                }
                rmdTaskTracking.fetch()
            }
        }, 15000);

        vcMessenger.onMessage( function(msg) {
            try {
                if (msg.event === 'DPI_DOCTOR_ORDER_PRINT') {
                    if (msg.task_id === root.task_id) {
                        modInfoFixed.txtContent = "กรุณารอสักครู่<br/>Status: " + msg.status + " " + msg.progress + 
                                                "%<br/>For IT: task_id = " + msg.task_id
                        if (msg.status === 'COMPLETED' && !root.get_printed) {
                            getPrintedDocument("/apis/core/task-tracking/result/", msg.task_id)
                            root.get_printed = true
                            modInfoFixed.clear()
                        }
                        else if (msg.status === 'FAILED') {
                            modInfoFixed.clear()
                            modInfo.titleName = 'ผิดพลาด'
                            modInfo.titleColor = 'red'
                            modInfo.txtContent = msg.task_id
                            modInfo.show()
                        }
                    }
                }
            } catch(e) {
            }
        })

    }

    content: [
        PatientIdentification {
            id: subPatientIdentification
            style: 'margin: 0px; margin-top: -10px;'
            compact: true
        },
        Br {},
        Grid{
            Row{
                Column{
                    className: "sixteen wide"
                    Form{
                        id: frmError
                        inline: true
                        Message {
                            className: "error"
                        }
                        Fields {
                            Field {
                                className: 'seven wide'
                                label: 'Encounter Number: '
                                SearchBox {
                                    id: sbxEncounter
                                    baseURL: '/apis/core/encounter/search/'
                                    title: 'number_date'
                                    minCharacters: 2
                                    linkIcon: true
                                    customQuery: new Object({
                                        patient: patientID
                                    })
                                    property var rest: RestModel {
                                        property var _id
                                        property var number_date
                                        onFetched: sbxEncounter.setData({id: _id, number_date: number_date})
                                    }
                                    function getEncounter(encounterID) {
                                        sbxEncounter.rest.url = '/apis/core/encounter/search/' + encounterID + '/'
                                        sbxEncounter.rest.fetch()
                                    }
                                    onIconClicked: {
                                        modEncounterList.show()
                                    }
                                    onSelectedIdChanged: {
                                        root.encounterID = sbxEncounter.selectedId
                                    }
                                    onTextChanged: {
                                        if (text == '') {
                                            sbxEncounter.clear()
                                        }
                                    }
                                    Modal {
                                        id: modEncounterList
                                        onShowed: {
                                            rstEncounterList.filter({
                                                patient: root.patientID
                                            })
                                        }
                                        Segment {
                                            style: "background-color: #F3F3F3"
                                            className: "raised black"
                                            Grid {
                                                Row {
                                                    Column{
                                                        className: "sixteen wide"
                                                        Text{
                                                            className: "header"
                                                            text: "Encounter"
                                                        }
                                                    }
                                                    Column {
                                                        className: 'sixteen wide'
                                                        Br {}
                                                    }
                                                    Column {
                                                        className: 'sixteen wide'
                                                        DGrid {
                                                            id: grdEncounterList
                                                            height: 400
                                                            model: rstEncounterList
                                                            function chooseEncouner() {
                                                                if (selectedRow.id) {
                                                                    sbxEncounter.setData({
                                                                        id: selectedRow.id, 
                                                                        number_date: selectedRow.number_date
                                                                    })
                                                                    modEncounterList.hide()
                                                                }
                                                            }
                                                            onEntered: {
                                                                grdEncounterList.chooseEncouner()
                                                            }
                                                            columns: [
                                                                DColumn{
                                                                    field:'patient_name'
                                                                    label:'Patient'
                                                                    width: 30
                                                                },
                                                                DColumn{
                                                                    field:'number'
                                                                    label:'Number'
                                                                    width: 15
                                                                },
                                                                DColumn{
                                                                    field:'division_name'
                                                                    label:'Division'
                                                                    width: 30
                                                                },
                                                                DColumn{
                                                                    field:'doctor_name'
                                                                    label:'Doctor'
                                                                    width: 30
                                                                },
                                                                DColumn{
                                                                    field:'status_name'
                                                                    label:'Status'
                                                                    width: 30
                                                                },
                                                                DColumn{
                                                                    field:'created'
                                                                    label:'Date - Time'
                                                                    width: 30
                                                                },
                                                            ]
                                                            RestStore {
                                                                id: rstEncounterList
                                                                target: '/apis/core/encounter/search/'
                                                            }
                                                        }
                                                        Br {}
                                                    }
                                                    Column {
                                                        className: 'thirteen wide'
                                                    }
                                                    Column {
                                                        className: 'three wide'
                                                        Button {
                                                            className: 'green fluid'
                                                            text: 'เลือก'
                                                            onClicked: {
                                                                grdEncounterList.chooseEncouner()
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            Row{
                Column{
                    className: "four wide"

                    RestStore{
                        id: rstDocumentType
                        target: "/apis/core/document-type/"
                    }

                    DGrid{
                        id: grdRequest
                        doc_label: "รายการเอกสาร"
                        height: 500
                        columns:[
                            DColumn{
                                label: "ชื่อเอกสาร"
                                field: "name"
                                width: 30
                            },
                        ]

                        model: rstDocumentType

                        onSelectedCell:{
                            if (grdRequest.selectedRow){
                                preview = true
                                var printAPI = grdRequest.selectedRow.print_api
                                var jasperModule = grdRequest.selectedRow.jasper_module
                                var jasperPath = grdRequest.selectedRow.jasper_path
                                printDocument(printAPI, jasperModule, jasperPath)
                            }
                        }
                    }
                }

                Column{
                    className: "twelve wide"

                    RestModel{
                        id: rmdDocument
                        property bool success
                        property string warning
                        property string error
                        property string pdf_b64data
                        property string confirm
                        property string task_id

                        function handleFail() {
                            if(preview){
                                clear()
                            }
                            loadPreview = false
                            if(confirm){
                                if (error != null || error!=''){
                                    modConfirm.titleName = error.message
                                }
                                modConfirm.clear()
                                modConfirm.show()
                            }
                            else{
                                modInfo.titleName = 'ผิดพลาด'
                                modInfo.titleColor = 'red'
                                if (error != null && error!=''){
                                    modInfo.txtContent = error.message
                                }
                                modInfo.show()
                            }
                        }

                        onFetched:{
                            if (preview && success && pdf_b64data.length > 0) {
                                // preview pdf on iframe
                                iframe.dom.src = 'data:application/pdf;base64,' + rmdDocument.pdf_b64data
                            } else if (!preview && success) {
                                modInfo.titleName = 'สำเร็จ'
                                modInfo.titleColor = 'teal'
                                modInfo.txtContent = 'พิมพ์รายการสำเร็จ'
                                modInfo.show()
                            } else if (warning) {
                                iframe.dom.src = 'about:blank'
                                alert(warning)
                            } else if (task_id) {
                                modInfoFixed.txtContent = "กรุณารอสักครู่<br/>For IT: task_id = " + task_id
                                modInfoFixed.show()
                                root.get_printed = false
                                root.task_id = task_id
                                root.task_tracking = task_id
                                task_id = null
                            } else {
                                handleFail()
                            }
                            loadPreview = false
                        }

                        onFailed: handleFail()

                        Component.onCompleted: {
                            frmError.setupValidate($context, $properties, rmdDocument)
                        }
                    }

                    Common.ModInfo{
                        id:modInfo
                        property alias txtContent: _txtContent.text
                        titleName:'พิมพ์สำเร็จ'
                        titleColor:'green'

                        content:[
                            Text{
                                id:_txtContent
                            }
                        ]

                        onApproved:{
                            modInfo.hide()
                        }
                    }

                    Modal{
                        id:modInfoFixed
                        property alias txtContent: _txtContent.text
                        property alias content: _txtContent.data
                        property var titleName: 'กำลังเตรียมเอกสาร'
                        property string titleColor: 'teal'
                        className: 'mini'
                        autofocus: true
                        closable: false

                        RestModel{
                            id: rmdTaskTracking
                            url: "/apis/core/task-tracking/result/"

                            property var task_id
                            property var status
                            property var current_progress
                            property var current_progress_description
                            property var action: null

                            onFetched: {
                                if (status === 'COMPLETED' && !root.get_printed) {
                                    getPrintedDocument("/apis/core/task-tracking/result/", root.task_id)
                                    root.get_printed = true
                                    modInfoFixed.clear()
                                }
                                else if (status === 'FAILED') {
                                    modInfoFixed.clear()
                                    modInfo.titleName = 'ผิดพลาด'
                                    modInfo.titleColor = 'red'
                                    modInfo.txtContent = root.task_id
                                    modInfo.show()
                                }
                                else if (status === 'CANCELED') {
                                    modInfoFixed.clear()
                                    modInfo.titleName = 'ยกเลิก'
                                    modInfo.titleColor = 'red'
                                    modInfo.txtContent = current_progress_description
                                    modInfo.show()
                                }
                            }
                        }
                        
                        function clear(){
                            modInfoFixed.hide()
                            root.task_tracking = null
                        }

                        Segments{
                            Segment{
                                className:'inverted ' + modInfoFixed.titleColor 
                                Text{
                                    text: modInfoFixed.titleName
                                    style: 'color:white'
                                }
                            }
                            Segment{
                                className: 'raised'
                                Dom{
                                    id: _txtContent
                                    style: 'white-space: pre-wrap; line-height: 1.5;'
                                }
                                Br{}
                                Grid{
                                    Column{
                                        className:'centered five wide'
                                        Button{
                                            doc_label: 'ปุ่มยกเลิก'
                                            className: 'red' + ' basic fluid'        				
                                            text: "ยกเลิก"
                                            onClicked:{
                                                rmdTaskTracking.query = {
                                                    task_id: root.task_tracking,
                                                    action: "CANCEL"
                                                }
                                                rmdTaskTracking.fetch()
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    Common.ModConfirm{
                        id: modConfirm
                        property alias form: frmConfirm
                        function clear(){
                            frmConfirm.clear()
                            cboReason.clear()
                        }
                        onApprove: {
                            preview = false
                            confirm = true
                            if(!cboReason.text){
                                frmConfirm.showError(({
                                    'เหตุผล': ['กรุณาระบุเหตุผล']
                                }))
                                return
                            }
                            else{
                                frmConfirm.clear()
                            }
                            reason = cboReason.text
                            var printAPI = grdRequest.selectedRow.print_api
                            var jasperModule = grdRequest.selectedRow.jasper_module
                            var jasperPath = grdRequest.selectedRow.jasper_path
                            printDocument(printAPI, jasperModule, jasperPath)
                            modConfirm.hide()
                        }
                        onDeny: {
                            modConfirm.hide()
                        }
                        content: [
                            Form {
                                id: frmConfirm
                                Message{
                                    className: 'error'
                                }
                                Field {
                                    className: 'required'
                                    label: _('ระบุเหตุผล')
                                    ComboBox {
                                        id: cboReason
                                        doc_label: 'เหตุผล'
                                        search: true
                                        fullTextSearch: true
                                        allowAdditions: true
                                        Component.onCompleted: {
                                            rmdReasonPrintCard.fetch()
                                        }
                                        RestModel{
                                            id: rmdReasonPrintCard
                                            property alias items: cboReason.items
                                            url:"/apis/core/clinicalterm/"
                                            queryMimeType: "application/json"
                                            query: {
                                                return {
                                                    type: "REASON_PATIENT_CARD"
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        ]
                    }

                    Dom{
                        id: prtPreview
                        Dom {
                            id: iframe
                            tagName: 'iframe'
                            htmlAttr: { return {
                                width: '100%',
                                height: '500px',
                            }}
                        }

                        Dimmer {
                            doc_skip: 'me'
                            active: loadPreview
                            className: 'inverted'
                            Loading {
                                className: 'large text'
                                text: 'Loading'
                            }
                        }
                    }

                    Form{
                        Fields{
                            className: 'inline'
                            Field{
                                className: 'thirteen wide'
                            }
                            Field{
                                className: 'three wide'
                                Button{
                                    text: "พิมพ์"
                                    backgroundColor: 'blue'
                                    fluid: true
                                    onClicked:{
                                        preview = false
                                        root.confirm = false
                                        var printAPI = grdRequest.selectedRow.print_api
                                        var jasperModule = grdRequest.selectedRow.jasper_module
                                        var jasperPath = grdRequest.selectedRow.jasper_path
                                        printDocument(printAPI, jasperModule, jasperPath)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    ]
}

        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/Common/CardPrintList.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}