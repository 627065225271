import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `REG/MainREG.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import QmlWeb 1.0
import "../Common" as Common
import '../Common/Util.js' as Util
import '../Common/CAgent.js' as CAgent
import '../LR' as LR

Common.MainLayout {
    id: main
    property var showMenu: false
    property var encounterID: null
    property bool isNewPatient: false
    property int PATIENT_TYPE_NORMAL: 1
    property int PATIENT_TYPE_TEMP: 2

    hideWest: true
    hideEast: true

    RestModel{
        id: rmdMSWordPrint
        url:'/apis/REG/msword/print/'
    }

        function capitalize(txt) {
            if (txt.length < 2){
                return txt.toUpperCase()
            }
            return txt[0].toUpperCase() + txt.slice(1).toLowerCase()
        }

    Modal {
        id: modSmartCardInfo
        property alias jsonData: crdSmartCardInfo.jsonData
        onShowed: {
            crdSmartCardInfo.displayNone = false
            crdSmartCardInfo.clear()
        }

        Segment{
            CardSmartCardInfo{
                id: crdSmartCardInfo
                username: DJANGO.user.fullname

                onConfirm: {
                    var data = modSmartCardInfo.jsonData
                    var keys = [
                        "dateOfBirth", "personalID", "titleDesc", "firstName", "lastName", "middleName", 
                        "englishTitleDesc", "englishFirstName", "englishLastName", "englishMiddleName", "fatherName", "motherName",
                        "houseNo", "alleyDesc", "roadDesc", "subdistrictDesc", "provinceDesc", "houseTypeDesc", "villageDesc"
                    ]
                    keys.forEach(function(prop){
                        if (!data[prop]) {
                            data[prop] = '';
                        }
                    })

                    if (data.dateOfBirth) {
                        // var birth_date = data.dateOfBirth.toString().match(/(?<year>[0-9]{4})(?<month>[0-9]{2})(?<day>[0-9]{2})/).groups;
                        var birth_date = data.dateOfBirth.toString()
                        var dateOfBirth  = birth_date.slice(6) + "/" + birth_date.slice(4, 6) + "/" + birth_date.slice(0, 4)
                    } else {
                        var dateOfBirth = ""
                    }
                    
                    var sex = data.genderCode == 1 ? 'Male' : 'Female'
                    var english_title_map = {
                        "MR.": "Mr.",
                        "MS.": "Ms.",
                        "MRS.": "Mrs.",
                    }

                    var englishFirstName = capitalize(data.englishFirstName)
                    var englishLastName = capitalize(data.englishLastName)

                    patientHistory.setPatientData(data.personalID, dateOfBirth, sex, data.titleDesc, 
                        data.firstName, data.lastName, english_title_map[data.englishTitleDesc], englishFirstName, englishLastName, 
                        "Thailand - ไทย", "หน้าเคาเตอร์")
                    patientHistory.setExtraPatientData(data.fatherName, data.motherName, data.middleName, data.englishMiddleName)
                    
                    patientAddress.setPatientHomeAddress(
                        data.houseNo, "", data.alleyDesc, data.roadDesc, data.subdistrictDesc, data.districtDesc, data.provinceDesc
                    )
                    patientAddress.setExtraPatientHomeAddress(
                        data.houseTypeDesc, data.villageDesc
                    )

                    profile.source = jsonData.mineType + "," + jsonData.image || ""
                    webcam.base64Img = jsonData.mineType + "," + jsonData.image || ""
                    if (!mainRest.profile_image) {
                        mainRest.profile_image = {
                            image: jsonData.mineType + "," + jsonData.image || "",
                            image_type: 'P'
                        }
                    } else {
                        mainRest.profile_image.image = jsonData.mineType + "," + jsonData.image || ""
                    }

                    crdSmartCardInfo.clear()
                    modSmartCardInfo.hide()
                }

                hideCallback: function(){
                    modSmartCardInfo.hide()
                }
            }
        }
    }

    Modal {
        id: modSmartCardAuthen

        onShowed: {
            crdSmartCardAuthen.clear()
            crdSmartCardAuthen.displayNone = false
            crdSmartCardAuthen.getAuthen()
        }
 
        Segment {
            CardSmartCardAuthen{
                id: crdSmartCardAuthen

                onAuthorized: {
                    modSmartCardAuthen.hide()
                    modSmartCardInfo.show()
                }
                
                hideCallback: function(){
                    modSmartCardAuthen.hide()
                }
            }
        }
    }

    ModSmartCardMethod {
        id: modSmartCardMethod

        onOnline: {
            modSmartCardMethod.hide()
            modSmartCardAuthen.show()
        }

        onCard: {
            modSmartCardMethod.hide()
            main.readSmartCard()
        }
    }
    
    Common.UserPermission {
        id: config
        property bool config_REG_ENABLE_ONLINE_SMART_CARD: false
    }

    function readSmartCard() {
        // Show loading and start smart card service
        loadingText = "กำลังอ่าน Smart Card"
        loading = true
        // Clear current Hn
        clearAll()
        CAgent.smartcard(
            function(result){ // Success function
                // // For debugging
                // console.log("citizen_id : " + result.citizen_id)
                // console.log("birth_date : " + result.birth_date)
                // console.log("sex : " + result.sex)
                // console.log("th_prefix : " + result.th_prefix)
                // console.log("th_firstname : " + result.th_firstname)
                // console.log("th_lastname : " + result.th_lastname)
                // console.log("en_prefix : " + result.en_prefix)
                // console.log("en_firstname : " + result.en_firstname)
                // console.log("en_lastname : " + result.en_lastname)
                // console.log("address : " + result.address)
                // console.log("addr_house_no : " + result.addr_house_no)
                // console.log("addr_village_no : " + result.addr_village_no)
                // console.log("addr_lane : " + result.addr_lane)
                // console.log("addr_road : " + result.addr_road)
                // console.log("addr_tambol : " + result.addr_tambol)
                // console.log("addr_amphur : " + result.addr_amphur)
                // console.log("addr_province : " + result.addr_province)
                // console.log("issuer : " + result.issuer)
                // console.log("issue_date : " + result.issue_date)
                // console.log("expire_date : " + result.expire_date)

                loading = false

                // Set profile image
                profile.source = result.picture_base64
                webcam.base64Img = result.picture_base64
                if (!mainRest.profile_image) {
                    mainRest.profile_image = {
                        image: result.picture_base64,
                        image_type: 'P'
                    }
                } else {
                    mainRest.profile_image.image = result.picture_base64
                }

                // Prepare patient info variables
                var sex = result.sex == 1 ? 'Male' : 'Female'
                // Set patient data and auto Set nationality to Thai
                patientHistory.setPatientData(result.citizen_id,
                    result.birth_date, sex, result.th_prefix, result.th_firstname,
                    result.th_lastname, result.en_prefix, result.en_firstname,
                    result.en_lastname, "Thailand - ไทย", "หน้าเคาเตอร์")

                // Prepare patient address variables
                var moo = result.addr_village_no.split("หมู่ที่").pop().trim()
                var tambol = result.addr_tambol.split("ตำบล").pop().trim()
                tambol = tambol.split("แขวง").pop().trim()
                var amphoe = result.addr_amphur.split("อำเภอ").pop().trim()
                var province = result.addr_province.split("จังหวัด").pop().trim()
                // Set patient address
                patientAddress.setPatientHomeAddress(result.addr_house_no,
                    moo, result.addr_lane, result.addr_road, tambol, amphoe, province)
            },
            function(error){
                // Failed function
                loading = false
                Util.danger(error)
            }
        )
    }

    north: [
        MenuItem {
            text: "ค้นหาประวัติ"
            onClicked:{
                psbHN.searchPatient()
            }
        },

        // TODO: ใช้ปุ่มเดียวกับ ค้นหาประวัติ
        // MenuItem {
        //     text: "ค้นหาประวัติผู้ป่วยใน"
        // },

        MenuItem {
            text: "Encounter"
            onClicked:{
                if(mainRest._id === "")
                {
                    alert("กรุณาระบุ HN")
                    return
                }
                if (mainRest.type === PATIENT_TYPE_TEMP) {
                    Util.danger('ไม่สามารถเปิด Encounter ให้ผู้ป่วยชั่วคราวได้')
                    return
                }
                loader1.loadAndShowModal()
            }
            Loader {
                id: loader1
                is_emergency: false
                onLoaded: {
                    modOpenVN.patientDisplayName = 'HN : ' + mainRest.hn + ' ' + mainRest.name
                    modOpenVN.patient_id = mainRest._id
                    if(loader1.is_emergency) {
                        // Auto open encounter for this patient
                        modQuickHN.hide()
                        modOpenVN.openEmergencyEncounter()
                        loader1.is_emergency = false  // set flag back to false
                        mainRest.is_emergency = false  // set flag back to false
                    }
                }
                ModOpenEncounter{
                    id: modOpenVN
                    // newEncounterOnly: true
                    hideChiefComplaint:true
                    onHidden:{
                        getPatientInfo(mainRest._id)
                        loader1.active = false
                    }
                }
            }
        },

        MenuItem {
            text: "รับรองสิทธิ"
            onClicked:{
                if(mainRest._id === "")
                {
                    alert("กรุณาระบุ HN")
                    return
                }
                if(!(txtDeathDate.text == null || txtDeathDate.text == '')){
                    confirmCoverage.readOnly = true
                }
                else{
                    confirmCoverage.readOnly = false
                }
                confirmCoverage.show()
            }
            ModConfirmCoverage {
                id: confirmCoverage
                patient_id : mainRest._id
            }
        },


        MenuItem {
            text: "Smart Card"
            onClicked: {
                if (config.config_REG_ENABLE_ONLINE_SMART_CARD) {
                    modSmartCardMethod.show()
                } else {
                    main.readSmartCard()
                }
            }
        },


        MenuItem {
            text: "เปิดประวัติฉุกเฉิน"
            onClicked:{
                crdQuickHN.displayNone = false
                crdQuickHN.clear()
                modQuickHN.show()
            }
            Modal{
                id: modQuickHN
                CardQuickHN {
                    id: crdQuickHN
                    hideCallback: function(){
                        modQuickHN.hide()
                    }

                    onQuickHNCreated: {
                        //Auto assign created HN to this page
                        mainRest.need_new_image = false
                        mainRest.is_emergency = true
                        psbHN.manualSearch(quickHN)
                    }
                }
            }
        },

        MenuItem {
            text: "สแกนเอกสาร"
            onClicked: {
                if (mainRest._id !== "") {
                    ldrScanner.loadAndShowModal()
                }
                else {
                    alert("กรุณาระบุ HN")
                }
            }
            Loader {
                id: ldrScanner
                onLoaded: {
                    modScanner.patientId = mainRest._id
                }
                Common.ModScanner {
                    id: modScanner
                    onHidden: {
                        ldrScanner.active = false
                    }
                }
            }
        },

        MenuItem {
            text: "ดูรูปสแกน"
            onClicked: {
                if (mainRest._id !== "") {
                    ldrScannedDoc.loadAndShowModal()
                }
                else {
                    alert("กรุณาระบุ HN")
                }
            }
            Loader {
                id: ldrScannedDoc
                Modal {
                    className: 'large'
                    onShowed: {
                        crdScannedDoc.patient_id = mainRest._id
                        crdScannedDoc.refresh()
                    }

                    Common.CardScannedDocument {
                        id: crdScannedDoc
                        toggleCard: false
                    }
                }
            }
        },

        InnerMenu {
            style: "margin-left: 0px"
            className: "right"
            // Item{
            //     displayNone: true
            //     style: "padding: 0.5 em"
            //     Button {
            //         className: "green fluid"
            //         text:"ยืนยันประวัติเด็กแรกเกิด"
            //         displayNone: !patientHistory.restModel.is_newborn_unconfirmed
            //         onClicked:{
            //             confirmNewborn()
            //         }
            //     }
            // }
            // Item{
            //     style: "padding: 0.5 em"
            //     Button {
            //         id: btnNewBornIncomplete
            //         className: "green fluid"
            //         displayNone: false
            //         onClicked:{
            //             modNewBornIncomplete.show()
            //         }
            //     }
            // }
            Item{
                style: "padding: 0.5 em"
                Button {
                    className: "green fluid"
                    text:"ปกปิดประวัติผู้ป่วย"
                    onClicked:{
                        saveSecretClick()
                    }
                }
            }
            Item{
                displayNone: true
                // style: "padding: 0.5 em"
                Button {
                    className: "pink fluid"
                    text:"MSWord"
                    onClicked:{
                        rmdMSWordPrint.fetch()
                    }
                }
            }
            Item{
                style: "padding: 0.5 em"
                Button {
                    className: "green fluid"
                    text:"บันทึกข้อมูลผู้ป่วย"
                    onClicked:{
                        patientHistory.leftForm.clear()
                        saveClick()
                    }
                }
            }
            Item{
                style: "padding: 0.5 em"
                Button {
                    className: "basic teal fluid"
                    text: "ล้างหน้าจอ"
                    onClicked:{
                        clearAll()
                    }
                }
            }
            Item{
                style: "padding: 0.5 em"
                Button {
                    className: "basic blue fluid"
                    text:"พิมพ์เอกสาร"
                    onClicked:{
                        crdPrintList.clear()
                        crdPrintList.setPatientData({
                            'patientID': mainRest._id,
                            'encounterID': main.encounterID
                        })
                        crdPrintList.displayNone = false
                        modPrintList.show()
                    }
                    Modal{
                        id: modPrintList
                        Common.CardPrintList {
                            id: crdPrintList
                            patientID: mainRest._id
                            encounterID: main.encounterID
                            hideCallback: function(){
                                modPrintList.hide()
                            }
                        }
                    }
                }
            }

            // TODO:
            // Item{
            //     style: "padding: 0.5 em"
            //     Button {
            //         className: "basic red fluid"
            //         text:"ยกเลิกข้อมูลผู้ป่วย"
            //     }
            // }

        },
    ]

    middle: [
        Segment {
            id: segHasDebt
            displayNone: true
            className: 'yellow inverted'
            Text {
                className: 'header'
                text: _('ผู้ป่วยมียอดค้างชำระ')
            }
        },
        Dom{
            id: formPatientInfo

            property string citizen_passport_old : ""

            property string pre_name_th_old : ""
            property string first_name_th_old : ""
            property string mid_name_th_old : ""
            property string last_name_th_old : ""

            property string pre_name_en_old : ""
            property string first_name_en_old : ""
            property string mid_name_en_old : ""
            property string last_name_en_old : ""

            Modal{
                id: webcamModal
                className: "mini"
                wrapContent: true
                WebCam{
                    id: webcam
                    doc_label: "หน้าจอถ่ายรูปผู้ป่วย"
                    width: 360
                    height: 480
                    cropWidth: 360
                    cropHeight: 480
                    enabled:false
                    onCaptured:{
                        profile.source = base64Img
                        if (!mainRest.profile_image) {
                            mainRest.profile_image = {
                                image: base64Img,
                                image_type: 'P'
                            }
                        } else {
                            mainRest.profile_image.image = base64Img
                        }
                        webcamModal.hide()
                    }
                }
                onHidden: {
                    webcam.enabled = false
                }
            }

            Common.ModInfo{
                id:modInfo
                property alias txtContent: _txtContent.text

                content:[
                    Text{
                        id: _txtContent
                    }
                ]

                onApproved:{
                    modInfo.hide()
                }
            }

            Common.ModConfirm{
                property alias alert : txtAlert.text

                id: modConfirm

                content: [
                    Text{
                        id: txtAlert
                    }
                ]
                onApprove:{
                    var query = {}
                    query.patient_id = mainRest._id
                    rmdConsentFormPrint.query = query
                    rmdConsentFormPrint.fetch()

                    hide()
                }
                onDeny: {
                    hide()
                }
            }

            ModAliasName{
                id: modAliasName
                onConfirmSave:{
                    mainRest.confirmSave = true
                    if (isNewPatient){
                        callCreatePatientAPI()
                    }
                    else{
                        callUpdatePatientAPI()
                    }

                }
            }

            RestModel{
                id: rmdConsentFormPrint
                url: '/apis/REG/patient/print/register-form/'
                property string error

                onFetched:{
                    modInfo.titleName = 'สำเร็จ'
                    modInfo.titleColor = 'teal'
                    modInfo.txtContent = 'พิมพ์รายการสำเร็จ'
                    modInfo.show()
                }

                onFailed:{
                    modInfo.titleName = 'ผิดพลาด'
                    modInfo.titleColor = 'red'
                    if (error!=null || error!=''){
                        modInfo.txtContent = error.detail
                    }
                    modInfo.show()
                }
            }

            Grid{
                Column{
                    className: "eight wide"
                    Grid{
                        Row{
                            Column{
                                className :"three wide"
                                Common.ImageButton{
                                    id: profile
                                    property alias lbtADR: _lbtADR
                                    className:"fluid"
                                    source: "../../static/images/person.png"
                                    doc_label: "รูปผู้ป่วย"
                                    onClicked:{
                                        webcam.enabled = true
                                        webcamModal.show()
                                    }
                                    contents: [
                                        LabelTag {
                                            className: 'ribbon blue floating'
                                            style: 'left: 0.5em'
                                            displayNone: !mainRest.welfare_category
                                            text: mainRest.welfare_category || ''
                                            Popup {
                                                id: popChangeName
                                                activeEvent: 'hover'
                                                text: 'สิทธิสวัสดิการ'
                                                position: 'bottom left'
                                            }
                                        },
                                        Common.SubADRLabelTag {
                                            id: _lbtADR
                                            className: 'floating'
                                            style: 'margin-top: 1em !important; display: none'
                                        }
                                    ]
                                }
                            }
                            Column{
                                // className :"fourteen wide"
                                className :"thirteen wide"
                                Segment{
                                    className: "darkblue inverted"
                                    Form{
                                        id: patientForm
                                        Fields{
                                            className:"inline"
                                            Field {
                                                className: "two wide"
                                                label: "HN:"
                                                labelAlign: "right"
                                                labelColor: "white"
                                            }
                                            Field{
                                                className: "six wide"
                                                Common.PatientSearchBox{
                                                    id: psbHN
                                                    onSearched: {
                                                        clearAll()
                                                        getPatientInfo(patientID)
                                                    }
                                                }
                                                // TextBox{
                                                //     placeholder:"XXXXXXXX-XX"
                                                //     readOnly : true
                                                // }
                                            }
                                            Field {
                                                className: "three wide"
                                                label: "Encounter:"
                                                labelAlign: "right"
                                                labelColor: "white"
                                            }
                                            Field {
                                                className: "five wide"
                                                TextBox{
                                                    id: txtEncounter
                                                    readOnly : true
                                                    // placeholder:"XXXXXXXX-XX"
                                                }
                                            }
                                            // Field{
                                            //     label:"HN แม่: "
                                            //     TextBox{
                                            //         placeholder:"XXXXXXXX-XX"
                                            //         style: "width: 150px"
                                            //     }
                                            // }
                                        }
                                        Fields{
                                            className: "inline"
                                            Field {
                                                className: "two wide"
                                                labelAlign: "right"
                                                label: "ชื่อ:"
                                                labelColor: "white"
                                            }
                                            Field {
                                                className: "six wide"
                                                TextBox {
                                                    id: txtName
                                                    readOnly : true
                                                    doc_label: "ชื่อ:"
                                                }
                                            }
                                            Field {
                                                className: "three wide"
                                                label: "ชื่อเดิม:"
                                                labelAlign: "right"
                                                labelColor: "white"
                                            }
                                            Field {
                                                className: "five wide"
                                                ActionTextBox{
                                                    id: txtOldName
                                                    doc_label:"ชื่อเดิม"
                                                    readOnly : true
                                                    onButtonClicked:{
                                                        if(psbHN.keyword)
                                                            patientOldNameLoad.loadAndShowModal()
                                                        else
                                                            alert("กรุณาเลือกผู้ป่วยก่อนทำรายการ")
                                                    }
                                                    Loader {
                                                        doc_skip: "me"
                                                        id: patientOldNameLoad
                                                        onLoaded: {
                                                            modPatientOldName.show()
                                                        }
                                                        Modal{
                                                            id: modPatientOldName
                                                            Common.CardPatientOldName{
                                                                id: patientOldName
                                                                patient_id: mainRest._id
                                                                citizen_passport: patientHistory.restModel.citizen_passport
                                                                hn: psbHN.keyword
                                                                name: txtName.text
                                                                allowClose: function() {
                                                                    modPatientOldName.hide()
                                                                }
                                                            }
                                                            onHidden:{
                                                                patientOldNameLoad.active = false
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            // Field{
                                            //     label:"AN: "
                                            //     labelColor: "white"
                                            //     className:"four wide"
                                            //     TextBox{
                                            //         placeholder:"XXXXXXXX-XX"
                                            //     }
                                            // }
                                        }
                                        Fields {
                                            className: "inline"
                                            Field {
                                                className: "two wide"
                                                label: "Location:"
                                                labelAlign: "right"
                                                labelColor: "white"
                                            }
                                            Field {
                                                className: "six wide"
                                                TextBox {
                                                    id: txtLocation
                                                    readOnly : true
                                                }
                                            }
                                            Field {
                                                className: "three wide"
                                                label: "ห้อง:"
                                                labelAlign: "right"
                                                labelColor: "white"
                                            }
                                            Field {
                                                className: "five wide"
                                                TextBox{
                                                    id: txtRoomNo
                                                    readOnly : true
                                                }
                                            }
                                        }
                                        Fields{
                                            className: "inline"
                                            Field {
                                                className: "three wide"
                                                label: "เวลาเข้ารักษา:"
                                                labelAlign: "right"
                                                labelColor: "white"
                                            }
                                            Field {
                                                className: "five wide"
                                                TextBox{
                                                    id: txtTime
                                                    readOnly : true
                                                }
                                            }
                                            Field {
                                                visible: !(txtDeathDate.text == null || txtDeathDate.text == '')
                                                className: "three wide"
                                                label: "เวลาเสียชีวิต:"
                                                labelAlign: "right"
                                                labelColor: "white"
                                            }
                                            Field{
                                                visible: !(txtDeathDate.text == null || txtDeathDate.text == '')
                                                className: "five wide"
                                                TextBox{
                                                    id: txtDeathDate
                                                    doc_label:'เวลาเสียชีวิต'
                                                    readOnly : true
                                                }
                                            }

                                        }

                                        // Fields{
                                        //     className:"inline"

                                        // }
                                        // Fields{
                                        //     className:"inline"
                                        //     Field{
                                        //         label:"Cashier: "
                                        //         className:"eight wide"
                                        //         labelColor: "white"
                                        //         TextBox{
                                        //             readOnly : true
                                        //         }
                                        //     }
                                        //     Field{
                                        //         label:"Discharge: "
                                        //         className:"eight wide"
                                        //         labelColor: "white"
                                        //         TextBox{
                                        //             readOnly : true
                                        //         }
                                        //     }
                                        // }
                                    }
                                }
                            }
                        }
                        Row{
                            Column{
                                className: "sixteen wide"
                                // Segment{
                                //     style: "background-color: #f6f6f7 "
                                //     Form{
                                //         Fields{
                                //             className:"inline"
                                //
                                CardPatientInfo{
                                    id:patientHistory
                                }
                            }
                        }
                    }
                }
                Column{
                    className: "eight wide"
                    Grid{
                        Column{
                            CardPatientAddress{
                                id:patientAddress
                            }
                        }
                    }
                }
            }

            RestModel{
                id: mainRest
                queryMimeType: "application/json"
                ignore:['deathdate']
                property var confirmSave
                property alias hn: psbHN.keyword
                property alias name: txtName.text
                property alias deathdate: txtDeathDate.text
                property var profile_image
                property bool is_emergency: false
                property bool need_new_image
                property string _id
                property string location
                property string welfare_category
                property int type
                merge: [
                    patientHistory.restModel,
                    patientAddress.restModel
                ]
                onFetched:{
                    loading = false
                    rmdInvoice.fetch()

                    if(patientHistory.restModel.birthdate){
                        var birthdate = patientHistory.restModel.birthdate
                        if(birthdate.startsWith('00/00/')) {
                            // backend send 00/00/year to tell user that this person has only year in his/her birthdate
                            //  we still need to provide proper date format because momentjs use it for calculation
                            birthdate = '01/01/' + birthdate.slice(6)
                        }
                        patientHistory.txtBirthDate_.text = birthdate
                    }
                    if(patientHistory.restModel.birthdate_year_only){
                        patientHistory.cboBirthDate_.value = patientHistory.BIRTHDATE_YEAR_ONLY
                    }else{
                        patientHistory.cboBirthDate_.value = patientHistory.BIRTHDATE_FULL
                    }
                    main.encounterID = patientHistory.restModel.encounter_id
                    updatePatientHeader()
                    showNickname()
                    getCurrentName()

                    if (need_new_image) {
                        modInfo.titleName = 'ไม่พบรูปผู้ป่วยหรือ รูปผู้ป่วยมีอายุเกินกำหนด'
                        modInfo.titleColor = 'orange'
                        modInfo.txtContent = 'กรุณาถ่ายรูปผู้ป่วยอีกรอบ'
                        modInfo.show()
                    }

                    if(is_emergency) {
                        //Auto start Encounter loader
                        loader1.is_emergency = true
                        loader1.loadAndShowModal()
                    }
                }
                onSaved:{
                    loading = false
                    if(patientHistory.restModel.birthdate)
                        patientHistory.txtBirthDate_.text = patientHistory.restModel.birthdate
                    updatePatientHeader()
                    showNickname()
                    getCurrentName()
                    if (isNewPatient){
                        modConfirm.titleName = 'บันทึกสำเร็จ'
                        modConfirm.alert = 'ต้องการพิมพ์ Consent form หรือไม่'
                        modConfirm.show()
                        isNewPatient =false
                    }
                    else{
                        modInfo.titleName = 'สำเร็จ'
                        modInfo.titleColor = 'teal'
                        modInfo.txtContent = 'บันทึกสำเร็จ'
                        modInfo.show()
                    }

                    //clear image property after save
                    image = ""
                }
                onFailed:{
                    loading = false
                    if (JSON.parse(xhr.response)['alias_patients']){
                        patientHistory.clearErrorMessage()
                        modAliasName.isNewPatient = isNewPatient
                        modAliasName.data = JSON.parse(xhr.response)['items']
                        modAliasName.show()
                    }

                    // alert("ไม่สำเร็จ : Error " + xhr.status + " " + xhr.statusText)
                    // console.log(xhr.response)
                }
            }

            Modal{
                id: modChangeName
                doc_label: "หน้าจอเปลี่ยนชื่อ"
                className: "tiny"
                CardChangeName {
                    id: crdChangeName
                    hideCallback: function(){
                        modChangeName.hide()
                    }
                    onConfirmChange:{
                        if(isConfirm){
                            patientHistory.restModel.change_name_reason = reason
                            patientHistory.restModel.change_name_ref_no = ref_no
                            crdChangeName.displayNone = false
                            modChangeName.hide()
                            callUpdatePatientAPI()
                        }
                        else{
                            crdChangeName.displayNone = false
                            modChangeName.hide()
                            alert("ไม่บันทึกการเปลี่ยนแปลงข้อมูล")
                        }
                    }
                }
            }
        }
    ]

    property var confirm_newborn_url: '/apis/LRM/newborn/reg-confirm/'
    RestModel{
        id: rmdConfirmNewborn
        property bool confirmed: false

        onSaved: {
            patientHistory.leftForm.clear()
            saveClick()
            checkHasNewBornIncomplete()
        }

        onFailed: {
            Util.alert(_('เกิดข้อผิดพลาด'), 'red', null, null, null, error)
            loading = false
        }
    }

    RestModel{
        id: rmdNewBorn
        url: "/apis/LRM/newborn/"
        property alias items: modNewBornIncomplete.model
    }

    Modal{
        id: modNewBornIncomplete
        className: "large"

        property alias model: crdNewBornIncomplete.model
        property var newbornPatientID
        onShowed: {
            rmdNewBorn.fetch()
        }
        LR.CardNewbornIncomplete {
            id: crdNewBornIncomplete

            onSelectedNewBorn:{
                modNewBornIncomplete.newbornPatientID = newbornPatientID
                modNewBornIncomplete.hide()
            }
        }
        onHidden:{
            getPatientInfo(newbornPatientID)
        }
    }

    function checkHasNewBornIncomplete(){
        main.loading = true
        rmdNewBorn.fetch().then(
            function(){
                if (rmdNewBorn.items.length > 0){
                    btnNewBornIncomplete.text = "ประวัติเด็กไม่สมบูรณ์ ★"
                } else {
                    btnNewBornIncomplete.text = "ประวัติเด็กไม่สมบูรณ์"
                }
                main.loading = false
            }
        )
    }

    RestModel{
        id: rmdSecretPatient
        url: "/apis/core/secret-patient/"
        property bool active
        property var patient

        onSaved: {
            Util.alert(_('บันทึกสำเร็จ'), 'green')
            loading = false
        }

        onFailed: {
            Util.alert(_('เกิดข้อผิดพลาด'), 'red', null, null, null, error)
            loading = false
        }
    }


    function updatePatientHeader(){
        if(mainRest.profile_image){
            profile.source = mainRest.profile_image.image
        }
        else{
            resetPatientImage()
        }
        txtName.text = patientHistory.restModel.full_name
        txtOldName.text = patientHistory.restModel.old_name
        txtLocation.text = mainRest.location
        txtTime.text = patientHistory.restModel.date_time
        txtRoomNo.text = patientHistory.restModel.room_no

        if (patientHistory.restModel.encounter_type) {
            txtEncounter.text = '{no} ({type})'.format({
                no: patientHistory.restModel.encounter_no,
                type: patientHistory.restModel.encounter_type,
            })
        } else {
            txtEncounter.text = patientHistory.restModel.encounter_no
        }
    }

    function getPatientInfo(id){
        if (id){
            mainRest.url = "/apis/REG/patient/" + id + "/"
            mainRest.fetch()
            profile.lbtADR.refreshApproved(id)
        }
    }

    function getCurrentName(){
        citizen_passport_old = patientHistory.restModel.citizen_passport

        pre_name_th_old = patientHistory.restModel.pre_name_th
        first_name_th_old = patientHistory.restModel.first_name_th
        mid_name_th_old = patientHistory.restModel.middle_name_th
        last_name_th_old = patientHistory.restModel.last_name_th

        pre_name_en_old = patientHistory.restModel.pre_name_en
        first_name_en_old = patientHistory.restModel.first_name_en
        mid_name_en_old = patientHistory.restModel.middle_name_en
        last_name_en_old = patientHistory.restModel.last_name_en

        patientHistory.nickname_using = patientHistory.restModel.nickname_active

        patientHistory.restModel.change_name_reason = ""
        patientHistory.restModel.change_name_ref_no = ""
    }

    function resetPatientImage(){
        profile.source = "../../static/images/person.png"
        mainRest.profile_image = null
        webcam.base64Img = null
    }

    function clearAll(){
        resetPatientImage()
        patientHistory.clear()
        patientAddress.clear()
        clearHeader()
    }

    function clearHeader(){
        encounterID = null
        mainRest._id = ""
        mainRest.type = PATIENT_TYPE_NORMAL
        mainRest.welfare_category = ""
        psbHN.keyword = ""
        segHasDebt.displayNone = true
        profile.lbtADR.displayNone = true
        txtName.text = ""
        txtOldName.text = ""
        txtEncounter.text = ""
        txtLocation.text = ""
        txtTime.text = ""
        txtRoomNo.text = ""
        txtDeathDate.text = null
    }

    function saveClick(){
        // TODO: Check Tel.
        // console.log(patientAddress)
        // debugger;
        // if( patientAddress.presentAddressRest.restModel.tel_home == ""
        //     && patientAddress.presentAddressRest.restModel.tel_office == ""
        //     && patientAddress.presentAddressRest.restModel.tel_mobile == ""
        //     && patientAddress.relativeAddressRest.restModel.tel_home == ""
        //     && patientAddress.relativeAddressRest.restModel.tel_office == ""
        //     && patientAddress.relativeAddressRest.restModel.tel_mobile == ""
        // ){
        //     alert("ไม่มีเบอร์ติดต่อ")
        //     return
        // }

        mainRest.confirmSave = false

        // To create new patient.
        if(mainRest._id === ""){
            callCreatePatientAPI()
        }

        // To update patient info.
        else{
            // Patient name change checking
            var CITIZEN_NO_IDENTITY = 'N'
            var BLANK_IDENTITY_NO = 'Non citizen identity'
            if( (
                    (patientHistory.restModel.citizen_type == CITIZEN_NO_IDENTITY && citizen_passport_old != BLANK_IDENTITY_NO)
                    || (patientHistory.restModel.citizen_type != CITIZEN_NO_IDENTITY && citizen_passport_old == BLANK_IDENTITY_NO)
                    || (citizen_passport_old.split('-').join('') != BLANK_IDENTITY_NO
                        && citizen_passport_old.split('-').join('') != patientHistory.restModel.citizen_no.split('-').join('')
                        && citizen_passport_old.split('-').join('') != patientHistory.restModel.passport_no.split('-').join('')
                        && citizen_passport_old.split('-').join('') != patientHistory.restModel.alien_no.split('-').join('')
                    )
                )
                || pre_name_th_old      != patientHistory.restModel.pre_name_th
                || first_name_th_old    != patientHistory.restModel.first_name_th
                || mid_name_th_old      != patientHistory.restModel.middle_name_th
                || last_name_th_old     != patientHistory.restModel.last_name_th
                || pre_name_en_old      != patientHistory.restModel.pre_name_en
                || first_name_en_old    != patientHistory.restModel.first_name_en
                || mid_name_en_old      != patientHistory.restModel.middle_name_en
                || last_name_en_old     != patientHistory.restModel.last_name_en
            ){
                crdChangeName.clear()
                crdChangeName.displayNone = false
                modChangeName.show()
            }
            else{
                callUpdatePatientAPI()
            }
        }
    }

    function confirmNewborn() {
        loading = true
        rmdConfirmNewborn.url = confirm_newborn_url + mainRest._id + '/'
        rmdConfirmNewborn.confirmed = true
        rmdConfirmNewborn.save()
    }

    function beforeCallSecretPatientAPI(){
        patientHistory.restModel.birthdate = patientHistory.txtBirthDate_.toDashFormat()
        if (webcam.base64Img) {
            mainRest.profile_image = getImageDict(webcam.base64Img)
        } else {
            mainRest.profile_image = null
        }
        loadingText = 'กำลังบันทึกข้อมูลผู้ป่วย'
        loading = true
    }

    function saveSecretClick() {
        if (!mainRest._id) {
            alert("กรุณาระบุ HN")
        } 
        else if (!patientHistory.cboSecretField_.displayNone){
            beforeCallSecretPatientAPI()
            if (patientHistory.cboSecret_.value == "S"){
                rmdSecretPatient.active = true
            }
            else{
                rmdSecretPatient.active = false
            }
            rmdSecretPatient.patient = mainRest._id
            rmdSecretPatient.create()
        } 
        else {
            alert("ไม่มีสิทธิ์ในการดำเนินการ")
        }
    }

    function getImageDict(base64) {
        return {
            image: base64,
            image_type: 'P'
        }
    }

    function beforeCallPatientAPI(){
        patientHistory.restModel.birthdate = patientHistory.txtBirthDate_.toDashFormat()
        if (webcam.base64Img) {
            mainRest.profile_image = getImageDict(webcam.base64Img)
        } else {
            mainRest.profile_image = null
        }
        loadingText = 'กำลังบันทึกข้อมูลผู้ป่วย'
        loading = true
    }

    function callCreatePatientAPI(){
        beforeCallPatientAPI()
        mainRest.url = "/apis/REG/patient/"
        isNewPatient = true
        mainRest.create()
    }

    function callUpdatePatientAPI(){
        beforeCallPatientAPI()
        mainRest.url = "/apis/REG/patient/" + mainRest._id + "/"
        isNewPatient = false
        mainRest.save()
    }

    function showNickname(){
        if (patientHistory.restModel.nickname_active){
            patientHistory.getCurrentNickname(patientHistory.restModel.nickname)
            patientHistory.lockNicknameFields(true)
        }
    }

    RestModel {
        id: rmdInvoice
        url: '/apis/BIL/patient/' + mainRest._id + '/has-debt/'
        property bool has_debt
        onFetched: {
            segHasDebt.displayNone = !has_debt
        }
    }

    Component.onCompleted: {
        main.clearAll()
        // checkHasNewBornIncomplete()
    }
}

        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/REG/MainREG.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}