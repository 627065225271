import React from "react";
import PropTypes from "prop-types";
import { Modal, Segment, Button } from "semantic-ui-react";
import "../../css/ModInfo.css";

const ModInfo = props => {
  let color = "orange";
  let header = "แจ้งเตือน";

  if (props.type === TYPE.ERROR) {
    color = "red";
    header = "เกิดข้อผิดพลาด";
  } else if (props.type === TYPE.SUCCESS) {
    color = "green";
    header = "บันทึกสำเร็จ";
  } else if (props.type === TYPE.CANCEL){
    color = "red";
    header = "ยกเลิก";
  }
  
  if(props.color) {
    color = props.color
  }

  if (props.titleColor) {
    color = props.titleColor;
  }
  if (props.titleName) {
    header = props.titleName;
  }

  React.useEffect(() => {
    if (props.duration && props.open) {
      setTimeout(() => props.onClose(), props.duration);
    }
  }, [props.open, props.duration]);

  return (
    <Modal
      open={props.open}
      size={props.size}
      className={props.className}
      closeOnDimmerClick={props.closeOnDimmerClick}
      onClose={props.onClose}
    >
      <Segment inverted className={"modHeader " + color} style={styles.header}>
        {header}
      </Segment>
      <Segment padded align="center" className="modContent">
        <div>{props.alertText}</div>
        {props.children}
        <Button
          basic
          color={props.buttonColor ? props.buttonColor : color}
          className="approveButton"
          style={styles.button}
          onClick={props.onApprove}
          loading={props.btnLoading}
        >
          {props.btnText}
        </Button>
      </Segment>
    </Modal>
  );
};

const styles = {
  header: {
    lineHeight: "1.3em"
  },
  button: {
    marginTop: "10px"
  }
};

const TYPE = {
  ERROR: "error",
  SUCCESS: "success",
  CANCEL: "cancel"
};

ModInfo.propTypes = {
  color: PropTypes.string,
  type: PropTypes.string,
  open: PropTypes.bool,
  onApprove: PropTypes.func,
  alertText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  btnText: PropTypes.string,
  titleName: PropTypes.string,
  titleColor: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  closeOnDimmerClick: PropTypes.bool,
  onClose: PropTypes.func,
  duration: PropTypes.number,
  buttonColor: PropTypes.string,
  btnLoading: PropTypes.bool,
};

ModInfo.defaultProps = {
  open: false,
  onApprove: () => {},
  alertText: "",
  btnText: "ตกลง",
  size: "mini",
  className: "",
  closeOnDimmerClick: true,
  onClose: () => {},
  btnLoading: false,
};

export default ModInfo;
