import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `Common/CardScannedDocument.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import DGrid 1.0
import QmlWeb 1.0

CardLayout{
    id: __root

    signal selected(var items)
    // if readOnly = false, user can choose document
    property bool readOnly: true
    property int patient_id: null
    property int encounter_id: null

    property bool secret_all_overwrite: false

    title: "Scanned Documents"
    headerColor: "blue"
    toggleCard: false

    function refresh() {
        __root.clear()

        if (typeof PATIENT_DATA !== 'undefined') {
            __root.patient_id = _.get(PATIENT_DATA, 'patient_id')
            __root.encounter_id = _.get(PATIENT_DATA, 'ENCOUNTER.encounter_id')
        }
        // Wait for fill patient field then fetch
        Promise.all([
            (__root.patient_id && psbHN.manualSearch(__root.patient_id, 'id')),
            (__root.encounter_id && rmdEncounterSearch.fetchEncounter())
        ]).then(function() {
            __root.fetchDocument()
        })
    }

    function clearScannedView() {
        imgScanned.clear()
    }

    function clear() {
        imgScanned.clear()
        grdScanned.model = []
        subPatientIdentification.clear()
    }

    function fetchDocument() {
        var query = {}
        if (psbHN.keyword) {
            query.patient__hn = psbHN.keyword
        }
        if (sbxEncounter.selectedId > 0) {
            query.encounter = sbxEncounter.selectedId
        }
        if (cboDocumentCategory.value) {
            query.document_category = cboDocumentCategory.value
        }
        if (cboDocumentType.value) {
            query.document_type = cboDocumentType.value
        }
        if (txtDocumentNo.text) {
            query.document_no = txtDocumentNo.text
        }
        if (cboScanDivision.value) {
            query.scan_division = cboScanDivision.value
        }
        if (sbxUser.selectedId > 0) {
            query.scan_user = sbxUser.selectedId
        }
        if (cboOwnerDivision.value) {
            query.owner_division = cboOwnerDivision.value
        }
        if (sbxDoctor.selectedId > 0) {
            query.encounter__doctor = sbxDoctor.selectedId
        }
        if (dtbDocDateFrom.text) {
            query.doc_date_from = dtbDocDateFrom.text
        }
        if (dtbDocDateTo.text) {
            query.doc_date_to = dtbDocDateTo.text
        }
        if (dtbScanDateFrom.text) {
            query.scan_date_from = dtbScanDateFrom.text
        }
        if (dtbScanDateTo.text) {
            query.scan_date_to = dtbScanDateTo.text
        }
        if (dtbExpiryDateFrom.text) {
            query.expiry_date_from = dtbExpiryDateFrom.text
        }
        if (dtbExpiryDateTo.value) {
            query.expiry_date_to = dtbExpiryDateTo.value
        }
        query.offset = rptPage.getOffset()
        query.limit = cboPageSize.value

        rmdScanned.query = query
        __root.loading = true
        rmdScanned.fetch()
    }

    Component.onCompleted:{
        rmdDocumentCategory.fetch()
        var query = {limit: 99999}
        rmdDocumentType.query = query        
        rmdDocumentType.fetch()
        rmdDivision.fetch()
    }

    RestModel {
        id: rmdEncounterSearch
        url: '/apis/core/encounter/search/'
        property var items

        function fetchEncounter() {
            rmdEncounterSearch.query = {
                id: __root.encounter_id
            }
            return rmdEncounterSearch.fetch()
        }

        onFetched: {
            if (rmdEncounterSearch.items.length == 1) {
                sbxEncounter.setData(rmdEncounterSearch.items[0])
            }
        }
    }

    RestModel {
        id: rmdDocumentCategory
        url: '/apis/core/document-category/?limit=99999'
        property alias items: cboDocumentCategory.items
    }

    RestModel {
        id: rmdDocumentType
        url: '/apis/core/document-type/'
        property alias items: cboDocumentType.items
    }

    RestModel {
        id: rmdDivision
        url: '/apis/core/division/?limit=9999'
        property var items
    }

    RestModel {
        id: rmdScanned
        url: '/apis/core/scanned-document/'
        property alias items: grdScanned.model
        property var total
        onFetched: {
            __root.loading = false
            if(rptPage.page) {
                rptPage.genNumber(total)
            }
            else{
                rptPage.page = 1
                rptPage.genNumber(total)
            }
        }
    }

    Zoom {
        id: zoom
    }

    ChromePrint {
        id: chromePrint
    }

    Modal {
        id: modEditDocument
        className: 'tiny'
        onShowed: {
            subScannerForm.fillFormEdit(grdScanned.selectedRow)
        }
        onHidden: {
            subScannerForm.clearForm()
        }
        Segments {
            Segment {
                className: 'inverted yellow'
                text: 'แก้ไข'
            }
            SubScannerForm {
                id: subScannerForm
                division: 'all'

                onEditCompleted: {
                    modEditDocument.hide()
                    fetchDocument()
                }

                onCancelCompleted: {
                    modEditDocument.hide()
                    subScannerForm.clearForm()
                    fetchDocument()
                }
            }
        }
    }


    content:[
        PatientIdentification {
            id: subPatientIdentification
            style: 'margin-top: -10px;'
            compact: true
        },
        Container{
            className: 'fluid'
            Grid{
                Column{
                    className: 'eight wide column'
                    Segment {
                        id: domPreview
                        className: 'grey inverted'
                        style: new Object({
                            height: '100%',
                            overflowY: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                        })
                        Dom {
                            id: imgScanned
                            tagName: 'img'
                            style: 'cursor: zoom-in'
                            htmlAttr: new Object({
                                height: '500px'
                            })
                            function clear() {
                                imgScanned.dom.src = ''
                            }
                            function setURL(url) {
                                imgScanned.dom.src = url
                            }
                            Component.onCompleted: {
                                imgScanned.dom.addEventListener('click', function(event) {
                                    if (imgScanned.dom.src) {
                                        zoom.show(imgScanned.dom.src, event)
                                    }
                                })
                            }
                        }
                    }
                }
                Column{
                    className: 'eight wide column'
                    Form{
                        Fields{
                            Field {
                                className: 'six wide'
                                label: 'HN'
                                Common.PatientSearchBox {
                                    id: psbHN
                                    onSearched: {
                                        if (found) {
                                            subPatientIdentification.reload(patientID)
                                        }
                                    }
                                }
                            }
                            Field {
                                className: 'six wide'
                                label: 'Encounter'
                                SearchBox {
                                    id: sbxEncounter
                                    baseURL: '/apis/core/encounter/search/'
                                    title: 'number_date'
                                    description: 'patient_name'
                                    minCharacters: 2
                                    customQuery: new Object({
                                        patient__hn: psbHN.keyword
                                    })
                                }
                            }
                            Field {
                                className: 'four wide'
                                Button{
                                    text: 'ค้นหา'
                                    className: 'fluid'
                                    backgroundColor: 'blue'
                                    onClicked:{
                                        fetchDocument()
                                    }
                                }
                            }
                        }
                        Fields{
                            Field{
                                className: 'ten wide'
                                label: _('ประเภทเอกสาร')
                                ComboBox {
                                    id: cboDocumentCategory
                                    search: true
                                    forceSelection: true
                                    fullTextSearch: true
                                    emptyItem: _('แสดงทุกประเภท')
                                    onChanged:{
                                        var query = {limit: 99999}
                                        query.category = cboDocumentCategory.value
                                        rmdDocumentType.query = query
                                        rmdDocumentType.fetch()
                                    }
                                }
                            }
                        }
                        Fields{
                            Field{
                                className: 'ten wide'
                                label: _('ชื่อเอกสาร')
                                ComboBox {
                                    id: cboDocumentType
                                    search: true
                                    forceSelection: true
                                    fullTextSearch: true
                                    emptyItem: _('แสดงทุกประเภท')
                                }
                            }
                            Field{
                                className: 'six wide'
                                label: _('เลขที่เอกสาร')
                                TextBox {
                                    id: txtDocumentNo
                                }
                            }
                        }
                        Fields{
                            Field{
                                className: 'eight wide'
                                label: _('แผนกที่สแกนเอกสาร')
                                ComboBox {
                                    id: cboScanDivision
                                    search: true
                                    forceSelection: true
                                    fullTextSearch: true
                                    optionTextField: 'name_code'
                                    emptyItem: _('แสดงทุกแผนกที่สแกน')
                                    items: rmdDivision.items
                                }
                            }
                            Field {
                                className: 'eight wide'
                                label: _('ผู้สแกนเอกสาร')
                                SearchBox {
                                    id: sbxUser
                                    baseURL: '/users/apis/users/'
                                    title: 'full_name'
                                    targetField: 'full_name'
                                    minCharacters: 2
                                }
                            }
                        }
                        Fields{
                            Field{
                                className: 'eight wide'
                                label: _('แผนกเจ้าของเอกสาร')
                                ComboBox {
                                    id: cboOwnerDivision
                                    search: true
                                    forceSelection: true
                                    fullTextSearch: true
                                    optionTextField: 'name_code'
                                    emptyItem: _('แสดงทุกแผนกเจ้าของเอกสาร')
                                    items: rmdDivision.items
                                }
                            }
                            Field {
                                className: 'eight wide'
                                label: 'แพทย์'
                                SearchBox {
                                    id: sbxDoctor
                                    doc_label: 'แพทย์'
                                    baseURL: '/apis/core/doctor/'
                                    title: 'name_code'
                                    minCharacters: 2
                                }
                            }
                        }
                        Fields{
                            className: 'inline'
                            Field{
                                className: 'five wide'
                                label: _('วันที่ของเอกสารสแกน')
                            }
                            Field{
                                className: 'five wide'
                                DateTextBox{
                                    id: dtbDocDateFrom
                                }
                            }
                            Field {
                                className: 'one wide'
                                label: _('ถึง')
                            }
                            Field{
                                className: 'five wide'
                                DateTextBox{
                                    id: dtbDocDateTo
                                }
                            }
                        }
                        Fields{
                            className: 'inline'
                            Field{
                                className: 'five wide'
                                label: _('วันที่สแกนเอกสาร')
                            }
                            Field{
                                className: 'five wide'
                                DateTextBox{
                                    id: dtbScanDateFrom
                                }
                            }
                            Field {
                                className: 'one wide'
                                label: _('ถึง')
                            }
                            Field{
                                className: 'five wide'
                                DateTextBox{
                                    id: dtbScanDateTo
                                }
                            }
                        }
                        Fields{
                            className: 'inline'
                            Field{
                                className: 'five wide'
                                label: _('วันที่หมดอายุของเอกสาร')
                                // labelAlign: 'right'
                            }
                            Field{
                                className: 'five wide'
                                DateTextBox{
                                    id: dtbExpiryDateFrom
                                }
                            }
                            Field {
                                className: 'one wide'
                                label: _('ถึง')
                            }
                            Field{
                                className: 'five wide'
                                DateTextBox{
                                    id: dtbExpiryDateTo
                                }
                            }
                        }
                    }
                    Br{}
                    DGrid{
                        id: grdScanned
                        doc_label: 'เอกสารที่สแกนแล้ว'
                        height: 250
                        onModelChanged:{
                            imgScanned.clear()
                            var select_all = true
                            for(var i=0; i<grdScanned.model.length; i++){
                                if(!grdScanned.model[i].is_secret){
                                    select_all = false
                                }
                            }
                            if(!select_all || grdScanned.model.length==0){
                                // to prevent secret_all.onChanged
                                secret_all_overwrite = true
                                // unchecked all
                                secret_all.checked = false
                                secret_all_overwrite = false
                            }
                            else{
                                secret_all.checked = true
                            }

                        }
                        columns:[
                            DColumn{
                                field: 'no'
                                label: 'no'
                                width: 5
                                hidden: !__root.readOnly
                            },
                            DCheckBoxColumn{
                                field: 'checked'
                                label: ''
                                width: 5
                                hidden: __root.readOnly

                                onChanged:{
                                    grdScanned.selectedRow.checked = checked
                                }
                            },
                            DColumn{
                                field:'scan_date'
                                label: 'วันที่'
                                width: 20
                            },
                            DColumn{
                                field:'document_no'
                                label: 'หมายเลขเอกสาร'
                                width: 30
                                hidden: __root.readOnly
                            },
                            DColumn{
                                field:'document_type_name'
                                label: 'ประเภทเอกสาร'
                                width: 40
                            },
                            DCheckBoxColumn{
                                field:'is_secret'
                                label: 'การปกปิด'
                                width: 20
                                onChanged:{
                                    var i = grdScanned.selectedRow.no - 1
                                    grdScanned.model[i].is_secret = checked
                                    grdScanned.refreshRow(i)

                                    var select_all = true
                                    for(var i=0; i<rmdScanned.items.length; i++){
                                        if(!grdScanned.model[i].is_secret){
                                            select_all = false
                                        }
                                    }
                                    if(!select_all){
                                        // to prevent secret_all.onChanged
                                        secret_all_overwrite = true
                                        // unchecked all
                                        secret_all.checked = false
                                        secret_all_overwrite = false
                                    }
                                    else{
                                        secret_all.checked = true
                                    }
                                }
                            },
                            DCustomColumn{
                                field:'_print'
                                label: 'พิมพ์'
                                width: 8
                                Button {
                                    doc_label: 'พิมพ์'
                                    backgroundColor: 'teal'
                                    icon: 'print'
                                    onClicked: {
                                        chromePrint.print(grdScanned.selectedRow.document_image)
                                    }
                                }
                            },
                            DCustomColumn{
                                field:'_edit'
                                label: 'แก้ไข'
                                width: 8
                                Button {
                                    doc_label: 'edit'
                                    backgroundColor: 'yellow'
                                    icon: 'pencil'
                                    onClicked: {
                                        modEditDocument.show()
                                    }
                                }
                            },
                        ]
                        onSelected:{
                            imgScanned.setURL(grdScanned.selectedRow.document_image)
                        }
                    }

                    Form {
                        Fields {
                            Field {
                                className: 'two wide'
                                ComboBox {
                                    id: cboPageSize
                                    value: 10
                                    items: [
                                        {name:10, id:10},
                                        {name:20, id:20},
                                        {name:30, id:30},
                                        {name:40, id:40},
                                        {name:50, id:50},
                                        {name:100, id:100},
                                    ]
                                    onChanged: {
                                        __root.fetchDocument()
                                    }
                                }
                            }
                            Field {
                                className: 'fifteen wide'
                                Repeater {
                                    id: rptPage
                                    property int page: 1
                                    onRenderCompleted: {
                                        rptPage.page = rptPage.page || 1
                                        if(rptPage.$items.length){
                                            rptPage.$items[rptPage.page-1].setSelected()
                                        }
                                    }
                                    model: []
                                    function clear() {
                                        rptPage.model = []
                                    }
                                    function genNumber(n) {
                                        n = Math.ceil(n/cboPageSize.value)
                                        var pageList = Array.apply(null, { length: n }).map(function(element, index) { return index+1; })
                                        rptPage.model = pageList
                                    }
                                    function deselectedAll() {
                                        rptPage.$items.forEach(function(model){
                                            model.setDeSelected()
                                        })
                                    }
                                    function getOffset() {
                                        return (rptPage.page - 1) * cboPageSize.value
                                    }
                                    Button {
                                        id: btnPage
                                        text: index+1
                                        className: 'large'
                                        function setSelected() {
                                            btnPage.backgroundColor = 'blue'
                                        }

                                        function setDeSelected() {
                                            btnPage.backgroundColor = ''
                                        }
                                        onClicked: {
                                            rptPage.deselectedAll()
                                            setSelected()
                                            rptPage.page = index + 1
                                            __root.fetchDocument()
                                        }
                                    }
                                }
                            }
                        }
                    }

                    Button{
                        displayNone: __root.readOnly
                        text: 'เลือก'
                        backgroundColor: 'green'
                        onClicked:{
                            var items = grdScanned.model.filter(function(item) {
                                return !!item.checked
                            })
                            selected(items)
                        }
                    }
                    Form{
                        Fields{
                            className: 'inline'
                            Field{
                                className: 'ten wide'
                            }
                            Field{
                                className: 'one wide'
                                CheckBox{
                                    id: secret_all
                                    onChanged:{
                                        if(!secret_all_overwrite){
                                            for(var i=0; i<rmdScanned.items.length; i++){
                                                grdScanned.model[i].is_secret = checked
                                                grdScanned.refreshRow(i)
                                            }
                                        }
                                    }
                                }
                            }
                            Field{
                                className: 'three wide'
                                label: _('ปกปิดทั้งหมด')
                                labelAlign: 'right'
                            }
                            Button{
                                className: 'three wide'
                                text: 'ปกปิดเอกสาร'
                                backgroundColor: 'yellow'
                                onClicked:{
                                    modConfirm.showModalWith(modConfirm.STATE.CONFIRM)
                                }
                            }
                        }
                    }
                }
            }
        }
    ]

    ModConfirm {
        id: modConfirm
        property var STATE: ({
            CONFIRM: 'CONFIRM'
        })

        approveButtonText: _('ตกลง')
        denyButtonText: _('ปิด')

        RestModel {
            id: rmdEditDocs
            property alias items: rmdScanned.items

            property alias username: txtUsername.text
            property alias password: txtPassword.text

            onSaved: {
                subScannerForm.editCompleted()
                Util.alert("บันทึกสำเร็จ", "green", "ตกลง", 2000)
                clear()
                modConfirm.hide()
            }

            onFailed: {
                Util.danger(_('ไม่สามารถแก้ไขเอกสารได้'))
                clear()
                frmUser.showErrorNew(error, $context, $properties);
            }

            function editDocuments() {
                rmdEditDocs.url = '/apis/core/scanned-document/update-secret/'
                rmdEditDocs.save()
                fetchDocument()
            }
        }

        function showModalWith(state) {
            if (state == modConfirm.STATE.CONFIRM) {
                modConfirm.titleName = _('ปกปิดเอกสาร')
                modConfirm.titleColor = 'blue'

                modConfirm.approve = function () {
                    rmdEditDocs.editDocuments()
                }
            }
            else {
                return
            }

            frmUser.clear()
            modConfirm.show()
        }

        function clear() {
            txtUsername.text = ''
            txtPassword.text = ''
        }

        onDeny: {
            modConfirm.hide()
        }

        content: [
            Form {
                id: frmUser

                Message {
                    className: 'error'
                }
                Field {
                    label: _('Username')
                    TextBox {
                        id: txtUsername
                        inputName: 'username'
                        doc_label: 'username'
                        placeholder: 'Username'
                    }
                }
                Field {
                    label: _('Password')
                    TextBox {
                        id: txtPassword
                        inputName: 'password'
                        doc_label: 'password'
                        inputType: 'password'
                        placeholder: 'Password'
                    }
                }
            },
        ]
    }
}

        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/Common/CardScannedDocument.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}