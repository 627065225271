import React from "react";
import PropTypes from "prop-types";
import * as Common from "../common/";
import PureReactTable from "./PureReactTable";
import { Form, Button } from "semantic-ui-react";

const limit = 50;

const CardEncounterList = React.forwardRef((props, ref) => {
  const [encounterList, setEncounterList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [totalPage, setTotalPage] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (props.patientId) {
      getEncounterSearch();
    }
  }, [props.patientId]);

  React.useEffect(() => {
    getEncounterSearch();
  }, [currentPage]);

  const getEncounterSearch = async () => {
    setIsLoading(true);
    const [data, error] = await props.controller.getEncounterSearch({
      patientId: props.patientId,
      limit,
      offset: currentPage * limit
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        setTotalPage(parseInt(data.total / limit) + 1);
        setEncounterList(data.items);
      }
    }
  };

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  return (
    <Common.CardLayout titleText="Encounter" hideHeaderIcon loading={isLoading}>
      <PureReactTable
        style={styles.encounterTable}
        testid="encounterTable"
        data={encounterList}
        showPageSizeOptions={false}
        pages={totalPage}
        onPageChange={handlePageChange}
        page={currentPage}
        onSelect={originalRow => setSelectedRow(originalRow)}
        onEnter={originalRow => {
          props.onSelect(originalRow)
        }}
        selectedRow={selectedRow}
        selectedClassName="blueSelectedRow"
        minRows={7}
        columns={[
          {
            Header: "Patient",
            accessor: "patient_name"
          },
          {
            Header: "Number",
            accessor: "number"
          },
          {
            Header: "Division",
            accessor: "division_name"
          },
          {
            Header: "Doctor",
            accessor: "doctor_name"
          },
          {
            Header: "Status",
            accessor: "status_name"
          },
          {
            Header: "Date - Time",
            accessor: "created"
          }
        ]}
      />
      <br />
      <Form>
        <Form.Group inline className="rightAlign">
          <Form.Field width={3}>
            <Button
              fluid
              color="green"
              content="เลือก"
              onClick={() => {
                if(!selectedRow){
                  return
                }
                props.onSelect(selectedRow);
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </Common.CardLayout>
  );
});

const styles = {
  encounterTable: {
    height: "400px"
  }
};

CardEncounterList.propTypes = {
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  controller: PropTypes.object,
  onSelect: PropTypes.func
};

CardEncounterList.defaultProps = {
  patientId: null,
  controller: {},
  onSelect: () => {}
};

export default React.memo(CardEncounterList);
