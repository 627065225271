import lodash from 'lodash';

var dictionary = {
  // ============================================================== COMMON
  'history': 'ประวัติ',
  'accept': 'ตกลง',
  'completed': 'สำเร็จ',
  'close': 'ปิด',
  'paid': 'ชำระเงินแล้ว',
  'unpaid': 'ยังไม่ชำระเงิน',
  'title': 'หัวข้อ',
  'status': 'สถานะ',
  'payment': 'การชำระเงิน',
  'confirm': 'ยืนยัน',
  'cause': 'สาเหตุ',
  'save': 'บันทึก',
  'time': 'เวลา',
  'division': 'แผนก',
  'total': 'ราคารวม',
  'claimable': 'เบิกได้',
  'non_claimable': 'เบิกไม่ได้',
  'cashonly': 'เบิกไม่ได้',
  'reimburse': 'เบิกได้',
  'discount': 'ส่วนลด',
  'copay': 'ผู้ป่วยชำระ',
  'please_choose': 'กรุณาเลือก',
  'price': 'ราคา',
  'reimbursable': 'เบิกได้',
  'payable': 'ผู้ป่วยชำระ',

  // ============================================================== SPECIFIC
  'drug_order_queue': 'คิวใบสั่งยา',
  'transfer_request': 'ขอยา',
  'transfer_request_queue': 'คิวใบขอยา',
  'transfer_request_history': 'ประวัติการขอยา',
  'dispenser_workload': 'อัตรากำลัง',
  'paper_drugorder_all': 'ใบสรุปรายการสั่งยาของเภสัชกรและของผู้ป่วย',
  'paper_drugorder_for_pharmacist': 'ใบสรุปรายการสั่งยาของเภสัชกร',
  'paper_drugorder_for_patient': 'ใบสรุปรายการสั่งยาของผู้ป่วย',
  'drugorder_requester': 'ผู้สั่ง',
  'drugorder_verifier': 'ผู้ตรวจ',
  'drugorder_checker': 'ผู้จัด',
  'drugorder_deliverer': 'ผู้จ่าย',
  'drugorder_transporter': 'ผู้ขนส่ง',
  'drugorder_receiver': 'ผู้รับ',
  'drugorder_rejecter': 'ผู้ปฏิเสธ',
  'drugorder_canceler': 'ผู้ยกเลิก',
  'drugorder_approver': 'ผู้อนุมัติ',
  'title_drugtransferrequest': 'ขอยาจากห้องยาอื่นๆ',
  'diagnosis': 'การวินิจฉัย',
  'order_supply': 'สั่งเวชภัณฑ์',
  'refill_supply': 'เติมเวชภัณฑ์',
  'set_receipt_code': 'ตั้งเลขที่ใบเสร็จ',
  'patient_queue': 'คิวผู้ป่วย',
  'specify_patient': 'ระบุผู้ป่วย',
  'drug_order_is_not_paid': 'ใบยายังไม่ได้รับชำระเงิน',
  'drug_order_contains_risky_drug': 'มียาที่อาจเป็นอันตรายต่อผู้ป่วย',
  'deliverer': 'ผู้จัดส่ง',
  'order_history': 'ประวัติการสั่ง',
  'dispense_history': 'ประวัติการจ่าย',
  'order_selling': 'สั่งยาจำหน่าย',
  'no_active_encounter': 'ไม่พบ encounter ของผู้ป่วย',
  'med_error_list': 'ความคลาดเคลื่อน',
  'new_adverse_reaction': 'มีบันทึก ADR ใหม่',
  'drug_external_usage': 'บันทึกใช้ยานอกโรงพยาบาล',
};

// disable global lodash
if (typeof window !== 'undefined') {
  window.lodash = lodash.noConflict();

  // replace with i18n stub
  window._ = function (message) {
    // Stub for Internationalization
    if (dictionary[message]) {
      return dictionary[message];
    } else {
      return message;
    }
  };
  
  // provide all lodash method to i18n object
  for (var i in window.lodash) {
    window._[i] = window.lodash[i];
  }
  
  
  if (!String.prototype.format){
    String.prototype.format = function () {
      var args = arguments;
      return this.replace(/{(\w+)}/g, function (match, key) {
        var value;
        if (isNaN(parseInt(key))) {
          if (typeof args[0] === 'object') {
            value = args[0][key];
          } else {
            value = args[key];
          }
        } else {
          value = args[key];
        }
        return typeof value != 'undefined' ? value : match;
      });
    };
  }
} else {
  global.window = { _ : lodash }
}
  
let _ = window._;  

export default _;




