import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `Common/ModPlanning.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import QmlWeb 1.0
import DGrid 1.0
import 'Util.js' as Util

Modal {
    id: modPlanning
    signal actionDone(string action, bool onModal)
    signal actionFailed(string action, var error, bool onModal)
    property alias restModel: crdPlanning._rmdPlanning
    property bool showTimeSelection: true
    property bool timeRequired: true
    property bool _isOnModal: true
    property var ACTION: new Object({
        EDIT: 'EDIT',
        HOLD: 'HOLD',
        RESUME: 'RESUME',
        OFF: 'OFF',
        RENEW: 'RENEW',
    })

    /**
     * HOLD, id : to HOLD a planning without modal
     * RESUME, id : to RESUME a planning without modal
     * OFF, id : to OFF a planning without modal
     * RENEW, id : to RENEW a planning without modal
     * @param {string} action
     * @param {int} id
     */
    function doAction(action, id) {
        crdPlanning.doAction(action, id)
    }

    /**
     * objectOrId == null, to fill in planning data without saving (parent component should handle saving by itself)
     * objectOrId == object, - to set and show planning data with information from object (if object.id is not defined),
     *                       - to load planning data from object.id (if object.id is defined)
     * objectOrId == int, to edit/resume/off/hold planning data in modal
     * @param {int|null} objectOrId
     */
    function showPlanning(objectOrId) {
        crdPlanning.showPlanning(objectOrId)
    }

    function getDefaultPlanning(planSummary) {
        return crdPlanning.getDefaultPlanning(planSummary)
    }

    CardLayout {
        id: crdPlanning
        inModal: true
        title: _('Planning')
        headerColor: 'brown'
        property string PLANNING_URL: '/apis/core/plan-item/'
        property bool _preventSummarize
        property alias _rmdPlanning: rmdPlanning
        property var weekDayMap: new Object({
            'Mon': _('จันทร์'),
            'Tue': _('อังคาร'),
            'Wed': _('พุธ'),
            'Thu': _('พฤหัสบดี'),
            'Fri': _('ศุกร์'),
            'Sat': _('เสาร์'),
            'Sun': _('อาทิตย์'),
        })
        function getDefaultPlanning(planSummary) {
            return {
                id: null,
                action: 'REQUEST',
                allowed_actions: null,
                date_time_summary: 'เริ่ม {start_date}'.format({start_date: Util.dateNow()}),
                dates: [],
                day_skip: '',
                day_take: '',
                end_date: '',
                note: '',
                occurrence: '',
                start_date: Util.dateNow(),
                times: [],
                week_days: [],
                plan_summary: planSummary || '',
            }
        }
        function showPlanning(objectOrId) {
            function fetchPlanning(id) {
                rmdPlanning.url = PLANNING_URL + id + '/'
                rmdAction.url = PLANNING_URL + id + '/'
                crdPlanning.loading = true
                rmdPlanning.fetch()
                    .then(null)
                    .catch(function() {}) // <-- rmdPlanning handles this error
                    .then(function() {
                        crdPlanning.loading = false
                    })
            }

            if (objectOrId) {
                _isOnModal = true
                if (typeof objectOrId === 'number') {
                    fetchPlanning(objectOrId)
                }
                if (typeof objectOrId === 'object') {
                    if (objectOrId.id) {
                        fetchPlanning(objectOrId.id)
                    } else {
                        clear()
                        generateButtons([])
                        rmdPlanning.setDataFromObject(objectOrId)
                        modPlanning.show()
                    }
                }
            } else {
                Util.danger('object or id is not defined!!')
            }
        }
        function doAction(action, id) {
            if (!id) {
                Util.alert('ยังไม่ได้ระบุ id', 'red')
                return
            }
            if (!ACTION.hasOwnProperty(action)) {
                Util.alert('ไม่มี action:' + action, 'red')
                return
            }
            if (action === ACTION.EDIT) {
                Util.alert('action EDIT ให้ใช้ function showPlanning(id)', 'red')
                return
            }
            _isOnModal = false
            _preventSummarize = true
            rmdAction.action = action
            rmdAction.url = PLANNING_URL + id + '/'
            return rmdAction.save()
        }
        function generateButtons(allowed_actions) {
            function generateButton(action) {
                return $( '<button data-action="' + action + '"class="ui button '
                + buttonColors[action] + '" style="width: 7em">' + _(action) + '</button>')[0]
            }
            var buttonColors = {
                EDIT: 'yellow',
                HOLD: 'brown',
                RESUME: 'blue',
                OFF: 'black',
                RENEW: 'teal',
                OK: 'green',
                CLOSE: 'grey',
            }
            if (allowed_actions instanceof Array) {
                buttonContainer.dom.innerHTML = ''
                allowed_actions = allowed_actions.filter(function(item) { return ACTION.hasOwnProperty(item) })
                allowed_actions.forEach(function(item) {
                    var button = generateButton(item)
                    buttonContainer.dom.appendChild(button)
                })
                if (!allowed_actions.length) {
                    var buttonClose = generateButton('CLOSE')
                    var buttonOK = generateButton('OK')
                    buttonContainer.dom.appendChild(buttonOK)
                    buttonContainer.dom.appendChild(buttonClose)
                } else {
                    var button = generateButton('CLOSE')
                    buttonContainer.dom.appendChild(button)
                }
            }
        }
        function buttonAction(action) {
            frmError.clear()

            if (action === 'CLOSE') {
                modPlanning.hide()
                return
            }
            if (!frmError.validateDate()) {
                frmError.showError({'วันที่': [_('กรุณาระบุ เริ่มต้น หรือ เลือกวันที่ แบบเลือกเอง')]})
                return
            }
            if (!frmError.validateTime()) {
                frmError.showError({'เวลา': [_('กรุณาระบุเวลา')]})
                return
            }
            if (action === 'OK') {
                modPlanning.actionDone('REQUEST', _isOnModal)
                modPlanning.hide()
                return
            }
            if (action === ACTION.EDIT) {
                rmdPlanning.action = action
                rmdPlanning.save()
            } else {
                rmdAction.action = action
                rmdAction.save()
            }
        }
        function clear() {
            rmdPlanning._id = null
            rmdAction._id = null
            frmNormal.displayNone = false
            frmCustom.displayNone = true
            txtDateTimeSummary.text = _('ข้อมูลสรุป')
            txtPlanSummary.text = ''
            cboType.value = 'NORMAL'
            clearNormal()
            clearCustom()
        }
        function clearNormal() {
            txtDaySkip.text = ''
            txtDayTake.text = ''
            txtOccurrence.text = ''
            dtbStartDate.text = ''
            dtbEndDate.text = ''
            cboWeekDays.clear()
            cboWeekDays.enabled = true
            txtDayTake.enabled = true
            txtDaySkip.enabled = false
            rdgEndDate.value = 'NONE'
        }
        function clearCustom() {
            calDates.clear()
            // dtbStartDate.date = moment()
            // summarize()
        }
        function summarize() {
            var summary = ''
            if (_preventSummarize) {
                return
            }
            if (!rmdPlanning.dates) {
                rmdPlanning.dates = []
            }
            if (!rmdPlanning.week_days) {
                rmdPlanning.week_days = []
            }
            if (!rmdPlanning.times) {
                rmdPlanning.times = []
            }
            if (!rmdPlanning.start_date && rmdPlanning.dates.length === 0) {
                txtDateTimeSummary.text = _('กรุณาระบุ เริ่มต้น หรือ เลือกวันที่ แบบเลือกเอง')
                return
            }
            if (rmdPlanning.start_date) {
                summary += _('เริ่ม {start_date}, ').format(rmdPlanning)
            }
            if (rmdPlanning.week_days.length > 0) {
                summary += _('ทำทุกวัน ')
                var sorter = {
                    'Mon': 1,
                    'Tue': 2,
                    'Wed': 3,
                    'Thu': 4,
                    'Fri': 5,
                    'Sat': 6,
                    'Sun': 7
                }
                var week_days = rmdPlanning.week_days.sort(function(a,b) {
                    return sorter[a] > sorter[b]
                })
                week_days.forEach(function(item) {
                    summary += '%s, '.replace('%s', weekDayMap[item])
                })
            }
            if (rmdPlanning.day_take) {
                if (rmdPlanning.day_skip) {
                    summary += _('ทำซ้ำ {day_take} วัน เว้น {day_skip} วัน, ').format(rmdPlanning)
                } else {
                    summary += _('ทำซ้ำ {day_take} วัน, ').format(rmdPlanning)
                }
            }
            if (rmdPlanning.occurrence) {
                summary += _('{occurrence} วัน, ').format(rmdPlanning)
            }
            if (rmdPlanning.end_date) {
                summary += _('สิ้นสุด {end_date}, ').format(rmdPlanning)
            }
            if (rmdPlanning.dates.length > 0) {
                summary += _('ทำในวันที่ ')
                sortDates(rmdPlanning.dates).forEach(function(item) {
                    summary += '%s, '.replace('%s', item)
                })
            }
            if (rmdPlanning.times.length > 0) {
                summary += _('เวลา ')
                var times = rmdPlanning.times.sort(function (a, b) {
                    return Date.parse('2011-10-10T' + a) - Date.parse('2011-10-10T' + b)
                })
                times.forEach(function(item) {
                    summary += '%s น., '.replace('%s', item)
                })
            }
            txtDateTimeSummary.text = summary.substring(0, summary.length - 2)
        }
        function sortDates(dates) {
            return dates.sort(function (date1, date2) {
                date1 = date1.split('/')
                date2 = date2.split('/')
                var year1 = parseInt(date1[2]) - 543
                var year2 = parseInt(date2[2]) - 543
                var date1 = year1 + '-' + date1[1] + '-' + date1[0]
                var date2 = year2 + '-' + date2[1] + '-' + date2[0]
                return Date.parse(date1 + 'T00:00:00') - Date.parse(date2 + 'T00:00:00')
            });
        }
        function switchMode(value) {
            if (value === 'NORMAL') {
                clearCustom()
                frmNormal.displayNone = false
                frmCustom.displayNone = true
            } else {
                clearNormal()
                frmNormal.displayNone = true
                frmCustom.displayNone = false
            }
            summarize()
        }

        Modal {
            id: modNote
            property var restModel
            function showErrorModal(source, error){
                modNote.restModel = source
                frmErrorModal.showError(error)
                modNote.show()
            }
            Segment {
                Form {
                    id: frmErrorModal
                    Message {
                        className: 'error'
                    }
                    Fields {
                        id: flsChange
                        Field {
                            id: fldNote
                            className: 'fourteen wide'
                            label: _('เหตุผล')
                            TextArea {
                                id: txtNote
                                className: 'fluid'
                                rows: 2
                            }
                        }
                        Field {
                            className: 'two wide'
                            Button {
                                text: 'SAVE'
                                onClicked: {
                                    modNote.restModel.note = txtNote.text
                                    modNote.restModel.save()
                                    modNote.hide()
                                }
                            }
                        }
                    }
                }
            }
            onShowed: {
                txtNote.text = ''
            }
            onHidden: {
                txtNote.text = ''
                modNote.restModel = null
            }
        }

        RestModel {
            id: rmdAction
            property int _id: null
            property string action: null
            property string note: ''
            property var allowed_actions: null
            property string status_name: 'NEW'

            onSaved: {
                if (_isOnModal) {
                    Util.alert(_('บันทึกสำเร็จ'), 'green')
                    generateButtons(allowed_actions)
                }
                rmdAction.note = ''
                rmdPlanning.status_name = rmdAction.status_name
                modPlanning.actionDone(action, _isOnModal)
            }
            onFailed: {
                if (_isOnModal) {
                    if (error.hasOwnProperty('note')) {
                        modNote.showErrorModal(rmdAction, error)
                        return
                    }
                    Util.alert(_('บันทึกไม่สำเร็จ'), 'red')
                }
                frmError.showError(error)
                modPlanning.actionFailed(action, error, _isOnModal)
            }
        }

        RestModel {
            id: rmdPlanning
            property int _id: null
            property string action: null
            property var allowed_actions: null
            property string note: ''
            property string status_name: 'NEW'
            property alias start_date: dtbStartDate.text
            property alias end_date: dtbEndDate.text
            property alias week_days: cboWeekDays.valueList
            property alias day_take: txtDayTake.text
            property alias day_skip: txtDaySkip.text
            property alias occurrence: txtOccurrence.text
            property alias dates: calDates.selectedDate
            property alias times: rptTimes.model
            property alias date_time_summary: txtDateTimeSummary.text
            property alias plan_summary: txtPlanSummary.text

            function setUpForm() {
                if (occurrence) {
                    rdgEndDate.value = 'OCCURRENCE'
                }
                else if (end_date) {
                    rdgEndDate.value = 'END_DATE'
                }
                else {
                    rdgEndDate.value = 'NONE'
                }
                if (day_take) {
                    txtDayTake.changed()
                }
                if (week_days.length > 0) {
                    cboWeekDays.changed(week_days)
                }
                if (dates.length > 0) {
                    _txtDates.text = sortDates(dates).join('\n')
                    cboType.value = 'CUSTOM'
                }
                rmdPlanning.note = ''
                frmError.clear()
            }

            function setDataFromObject(data) {
                if (!data) return
                _preventSummarize = true
                rmdPlanning.$updatePropertiesFromResponseObject(data)
                setUpForm()
                _preventSummarize = false
            }

            onFetched: {
                setUpForm()
                modPlanning.show()
                generateButtons(allowed_actions)
                _preventSummarize = false
            }
            onSaved: {
                setUpForm()
                if (_isOnModal) {
                    Util.alert(_('บันทึกสำเร็จ'), 'green')
                }
                modPlanning.actionDone(action, _isOnModal)
                generateButtons(allowed_actions)
                _preventSummarize = false
            }
            onFailed: {
                if (!action || action === 'REQUEST') {
                    return
                }
                if (_isOnModal) {
                    if (error.hasOwnProperty('note')) {
                        modNote.showErrorModal(rmdPlanning, error)
                        return
                    }
                    Util.alert(_('บันทึกไม่สำเร็จ'), 'red')
                }
                frmError.showError(error)
                modPlanning.actionFailed(action, error, _isOnModal)
                _preventSummarize = false
            }
        }

        RadioGroup {
            id: rdgEndDate
            group: 'endDate'
            value: 'NONE'
            onChanged: {
                if (value === 'NONE') {
                    txtOccurrence.enabled = false
                    txtOccurrence.text = ''
                    dtbEndDate.enabled = false
                    dtbEndDate.text = ''
                }
                if (value === 'OCCURRENCE') {
                    txtOccurrence.enabled = true
                    dtbEndDate.enabled = false
                    dtbEndDate.text = ''
                }
                if (value === 'END_DATE') {
                    txtOccurrence.enabled = false
                    txtOccurrence.text = ''
                    dtbEndDate.enabled = true
                }
                summarize()
            }
        }

        content: [
            Container {
                Form {
                    id: frmError
                    Message {
                        style: 'margin-bottom: 1.5em'
                        className: 'error'
                    }
                    function validateDate() {
                        if (!rmdPlanning.start_date && rmdPlanning.dates.length === 0) {
                            return false
                        }
                        return true
                    }
                    function validateTime() {
                        if (timeRequired) {
                            if (rptTimes.model.length == 0) {
                                return false
                            }
                        }
                        return true
                    }
                }
                Dom {
                    style: 'display: flex'
                    Dom {
                        style: {
                            if (showTimeSelection) {
                                return 'flex-basis: 49%'
                            } else {
                                return 'flex-basis: 100%'
                            }
                        }
                        Form {
                            Dom {
                                style: 'margin-bottom: 1em'
                                Text {
                                    id: txtPlanSummary
                                }
                            }
                            Fields {
                                className: 'inline'
                                Field {
                                    className: 'three wide'
                                    label: 'ประเภท'
                                }
                                Field {
                                    className: 'six wide'
                                    ComboBox {
                                        id: cboType
                                        items: [
                                            { id:'NORMAL', name: _('ปกติ') },
                                            { id:'CUSTOM', name: _('เลือกเอง') },
                                        ]
                                        value: 'NORMAL'
                                        onChanged: {
                                            switchMode(value)
                                        }
                                    }
                                }
                            }
                        }
                        Form {
                            id: frmNormal
                            Fields {
                                className: 'inline'
                                Field {
                                    className: 'three wide'
                                    label: _('เริ่มต้น')
                                }
                                Field {
                                    className: 'six wide'
                                    DateTextBox {
                                        id: dtbStartDate
                                        date: moment()
                                        past: false
                                        onChanged: {
                                            summarize()
                                        }
                                    }
                                }
                            }
                            Fields {
                                className: 'inline'
                                Field {
                                    className: 'three wide'
                                    label: _('ทำซ้ำ')
                                }
                                Field {
                                    className: 'six wide'
                                    style: 'position: relative'
                                    LabelTag {
                                        style: new Object({
                                            position: 'absolute',
                                            right: '10px',
                                            zIndex: '1',
                                        })
                                        text: _('วัน')
                                        backgroundColor: 'grey'
                                    }
                                    TextBox {
                                        id: txtDayTake
                                        inputType: 'number'
                                        inputAttr: new Object({min:'1'})
                                        placeholder: _('ระบุตัวเลข')
                                        onChanged: {
                                            if (text) {
                                                if (!txtDaySkip.enabled) {
                                                    txtDaySkip.text = 1
                                                }
                                                txtDaySkip.enabled = parseInt(text) > 0
                                                cboWeekDays.enabled = false
                                                cboWeekDays.clear()
                                            } else {
                                                txtDaySkip.text = ''
                                                cboWeekDays.enabled = true
                                                txtDaySkip.enabled = false
                                            }
                                            if (txtDayTake.enabled) {
                                                summarize()
                                            }
                                        }
                                        onBlured: {
                                            if (parseInt(txtDayTake.text) < 0) {
                                                txtDayTake.text = ''
                                                txtDaySkip.text = ''
                                                summarize()
                                            }
                                        }
                                    }
                                }
                                Field {
                                    className: 'seven wide'
                                    label: _('เว้น')
                                    style: 'position: relative'
                                    LabelTag {
                                        style: new Object({
                                            position: 'absolute',
                                            right: '20px',
                                            zIndex: '1',
                                        })
                                        text: _('วัน')
                                        backgroundColor: 'grey'
                                    }
                                    TextBox {
                                        id: txtDaySkip
                                        inputType: 'number'
                                        inputAttr: new Object({min:'1'})
                                        placeholder: _('ระบุตัวเลข')
                                        enabled: false
                                        onChanged: {
                                            if (txtDaySkip.enabled) {
                                                summarize()
                                            }
                                        }
                                        onBlured: {
                                            if (!txtDaySkip.text || parseInt(txtDaySkip.text) < 0) {
                                                txtDaySkip.text = 1
                                                summarize()
                                            }
                                        }
                                    }
                                }
                            }
                            Fields {
                                className: 'inline'
                                Field {
                                    className: 'three wide'
                                    label: _('ในวัน')
                                }
                                Field {
                                    className: 'thirteen wide'
                                    style: 'position: relative'
                                    ComboBox {
                                        id: cboWeekDays
                                        multipleSelection: true
                                        items: [
                                            { id: 'Mon', name: _('จันทร์')},
                                            { id: 'Tue', name: _('อังคาร')},
                                            { id: 'Wed', name: _('พุธ')},
                                            { id: 'Thu', name: _('พฤหัสบดี')},
                                            { id: 'Fri', name: _('ศุกร์')},
                                            { id: 'Sat', name: _('เสาร์')},
                                            { id: 'Sun', name: _('อาทิตย์')},
                                        ]
                                        onChanged: {
                                            if (valueList.length > 0) {
                                                txtDayTake.enabled = false
                                                txtDaySkip.enabled = false
                                                txtDayTake = ''
                                                txtDaySkip = ''
                                            } else {
                                                txtDayTake.enabled = true
                                            }
                                            if (cboWeekDays.enabled) {
                                                summarize()
                                            }
                                        }
                                    }
                                }
                            }
                            Fields {
                                className: 'inline'
                                Field {
                                    className: 'three wide'
                                    label: _('สิ้นสุด')
                                }
                                Field {
                                    className: 'five wide'
                                    RadioButton {
                                        group: 'endDate'
                                        text: _('ไม่มี')
                                        value: 'NONE'
                                    }
                                }
                            }
                            Fields {
                                className: 'inline'
                                Field {
                                    className: 'three wide'
                                    label: ''
                                }
                                Field {
                                    className: 'six wide'
                                    RadioButton {
                                        group: 'endDate'
                                        text: _('หลังจากเกิดขึ้น')
                                        value: 'OCCURRENCE'
                                    }
                                }
                                Field {
                                    className: 'seven wide'
                                    style: 'position: relative'
                                    LabelTag {
                                        style: new Object({
                                            position: 'absolute',
                                            right: '10px',
                                            zIndex: '1',
                                        })
                                        text: _('วัน')
                                        backgroundColor: 'grey'
                                    }
                                    TextBox {
                                        id: txtOccurrence
                                        inputType: 'number'
                                        inputAttr: new Object({min:'1'})
                                        placeholder: _('ระบุตัวเลข')
                                        onChanged: {
                                            if (txtOccurrence.enabled) {
                                                summarize()
                                            }
                                        }
                                        onBlured: {
                                            if (parseInt(txtOccurrence.text) < 0) {
                                                txtOccurrence.text = ''
                                                summarize()
                                            }
                                        }
                                    }
                                }
                            }
                            Fields {
                                className: 'inline'
                                Field {
                                    className: 'three wide'
                                    label: ''
                                }
                                Field {
                                    className: 'six wide'
                                    RadioButton {
                                        group: 'endDate'
                                        text: _('ในวันที่')
                                        value: 'END_DATE'
                                    }
                                }
                                Field {
                                    className: 'seven wide'
                                    DateTextBox {
                                        id: dtbEndDate
                                        past: false
                                        onChanged: {
                                            if (dtbEndDate.enabled) {
                                                summarize()
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        Form {
                            id: frmCustom
                            style: {
                                if (showTimeSelection) {
                                    return 'flex-basis: 49%'
                                } else {
                                    return 'flex-basis: 100%'
                                }
                            }
                            Calendar {
                                id: calDates
                                style: new Object({
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    marginTop: '1.5em',
                                    marginBottom: '1.5em',
                                })
                                multipleSelection: true
                                function clear() {
                                    calDates.selectedDate = []
                                    _txtDates.text = ''
                                }
                                onSelected: {
                                    _txtDates.text = sortDates(value).join('\n')
                                    summarize()
                                }
                            }
                            TextArea {
                                id: _txtDates
                                readOnly: true
                                style: 'text-align: center'
                                rows: 5
                            }
                        }
                    }
                    Dom {
                        style: {
                            if (showTimeSelection) {
                                return {
                                    flexBasis: '1%',
                                    borderRight: 'solid grey',
                                }
                            } else {
                                return 'display: none'
                            }
                        }
                    }
                    Dom {
                        id: timeSection
                        style: {
                            if (showTimeSelection) {
                                return 'flex-basis: 49%'
                            } else {
                                return 'display: none'
                            }
                        }
                        TooltipDialog {
                            id: tltWarning
                            activeEvent: ''
                            target: btnAddTime
                            function showMissingTime() {
                                _txtMessage.text = _('กรุณาเลือกเวลา')
                                tltWarning.show()
                            }
                            function showDuplicatedTime() {
                                _txtMessage.text = _('เวลานี้ได้เลือกไปแล้ว')
                                tltWarning.show()
                            }
                            Text {
                                id: _txtMessage
                            }
                        }
                        Form {
                            Fields {
                                className: 'inline'
                                Field {
                                    className: 'three wide'
                                    label: 'เวลา'
                                    labelAlign: 'right'
                                }
                                Field {
                                    className: 'ten wide'
                                    TimeTextBox {
                                        id: ttbTime
                                    }
                                }
                                Field {
                                    className: 'one wide'
                                    Button {
                                        id: btnAddTime
                                        icon: 'plus'
                                        backgroundColor: 'green'
                                        onClicked: {
                                            if (!ttbTime.text) {
                                                tltWarning.showMissingTime()
                                                return
                                            }
                                            if (rptTimes.model.indexOf(ttbTime.text) !== -1) {
                                                tltWarning.showDuplicatedTime()
                                                return
                                            }
                                            rptTimes.model.push(ttbTime.text)
                                            rptTimes.model = rptTimes.model
                                        }
                                    }
                                }
                            }
                            Segment {
                                style: new Object({
                                    marginLeft: '1em',
                                    marginRight: '1em',
                                    paddingTop: '1em',
                                    paddingBottom: '1em',
                                    textAlign: 'center',
                                })
                                Text {
                                    id: txtNoTime
                                    text: _('ไม่ได้เลือกเวลา')
                                    style: 'color: #555'
                                }
                                Repeater {
                                    id: rptTimes
                                    Component.onCompleted: {
                                        txtNoTime.displayNone = rptTimes.model.length > 0
                                    }
                                    onModelChanged: {
                                        txtNoTime.displayNone = rptTimes.model.length > 0
                                        summarize()
                                    }
                                    Fields {
                                        className: 'inline'
                                        Field {
                                            className: 'three wide'
                                        }
                                        Field {
                                            className: 'ten wide'
                                            LabelTag {
                                                className: 'large fluid'
                                                text: modelData
                                            }
                                        }
                                        Field {
                                            className: 'one wide'
                                            Button {
                                                id: btnAddTime
                                                icon: 'trash'
                                                backgroundColor: 'red'
                                                onClicked: {
                                                    rptTimes.model.splice(index, 1)
                                                    rptTimes.model = rptTimes.model
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                Segment {
                    style: 'text-align: center'
                    Text {
                        id: txtDateTimeSummary
                        style: 'color: #555'
                    }
                }

                Dom {
                    id: buttonContainer
                    style: new Object({
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                    })
                    Component.onCompleted: {
                        $(buttonContainer.dom).on('click', 'button', function(event) {
                            buttonAction($(event.target).data('action'))
                        })
                    }
                }
            }
        ]
    }
}

        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/Common/ModPlanning.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}