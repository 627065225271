import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `Common/AutoRestModel.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import QmlWeb 1.0

RestModel {
    id: rmd
    property var form                           // id of form to auto create property from
                                                //TODO(natt): Does not support changing form
    property bool include_restmodel: false      // whether to include 
    ignore: ["form", "include_restmodel"]
 
    function traverseNode(node, result) {
        const RestModel = QmlWeb.getConstructor("QmlWeb", "2.0", "RestModel")
        var _childrenAttrs = ["children", "columns", "$children", "north", "east", "west", "middle"]
        var _componentWithText = {"TextBox":true, "DateTextBox":true, "TimeTextBox": true, "TextArea": true}
        result = result || {}
        var isRepeaterGeneratedNode = node.hasOwnProperty('modelData') && node.hasOwnProperty('index');
        // skip Repeater generated Components, just document one instance of delegate
        if(node.id && node.id in rmd.$context) {
            if(node.hasOwnProperty('model')) {
                if(rmd.include_restmodel && node.model instanceof RestModel && node.model !== rmd) {
                    result[node.id] = {
                        type: 'hierarchy',
                        objRef: node.model
                    }
                    return result
                }
            } else {
                if(node.$class == 'ComboBox') {
                    if(node.allowAdditions) {
                        result[node.id] = new QmlWeb.QMLAliasDefinition(node.id, 'text')
                    } else {
                        result[node.id] = new QmlWeb.QMLAliasDefinition(node.id, 'value')
                    }
                } else if(node.$class in _componentWithText) {
                    result[node.id] = new QmlWeb.QMLAliasDefinition(node.id, 'text')
                } else if(node.$class == 'Common.SubUserToken') {
                    result[node.id] = new QmlWeb.QMLAliasDefinition(node.id, 'token')
                } else if(node.$class == 'CheckBox') {
                    result[node.id] = new QmlWeb.QMLAliasDefinition(node.id, 'checked')
                } else if(node.$class == 'RadioGroup') {
                    result[node.id] = new QmlWeb.QMLAliasDefinition(node.id, 'value')
                } else if(node.$class == 'Menu') {
                    result[node.id] = new QmlWeb.QMLAliasDefinition(node.id, 'selectedTab')
                    //console.log("kkkkkkkkkkkkk")
                } 
                // else if(node.$class == 'MenuItem') {
                //     result[node.id] = new QmlWeb.QMLAliasDefinition(node.id, 'dataTab')
                //     //console.log("kkkkkkkkkkkkk")
                // }
                 else {
                    // console.log(node.id, 'unknown', node.$class)
                }
            }
        }
        _childrenAttrs.forEach(function(attr) {
            if(node.hasOwnProperty(attr)) {
                var children = node[attr];
                if(children instanceof Array) {
                    // ok
                } else {
                    if(children instanceof Object) {
                        children = [children];
                    } else {
                        children = [];
                    }
                }
                children.forEach(function(child) {
                    Object.assign(result, traverseNode(child))
                });
            }
        })
        return result;
    }

    function transformField(field) {
        // remove field prefix, e.g., txt, chk, then convert PascalCase to underscore_case
        // Ref: https://stackoverflow.com/questions/30521224/javascript-convert-pascalcase-to-underscore-case
        if(field.length > 3) {
            field = field.slice(3)
        }
        return field.replace(/\.?([A-Z]+)/g, function (x,y){return "_" + y.toLowerCase()}).replace(/^_/, "")
    }

    function autoAliasProperties(node) {
        var result = traverseNode(node)
        var transformed_key = {}
        var hierarchy = {}
        for(var key in result) {
            if(result[key].hasOwnProperty('type')) {
                if(result[key].type == 'hierarchy') {
                    hierarchy[transformField(key)] = result[key].objRef
                    continue
                }
            }
            var objectName = result[key].objectName
            if(objectName in rmd.$context) {
                var field_name = transformField(key)
                transformed_key[field_name] = result[key]
                if(field_name in rmd.$properties) {
                    console.warn('AutoRestModel: property "' + rmd.id + '.' + field_name + '" was overwritten by field "' + objectName + '"')
                }
            } else {
                // console.log("skip non accessible ", objectName)
            }
        }
        QmlWeb.applyProperties(transformed_key, rmd, rmd, rmd.$context)
        var existingAttr = new Set(rmd.$attributes)
        var excludeProp = new Set(["url", "isLoading", "mimeType", "queryMimeType", "query", "merge", "hierarchy", "ignore"])
        // populate $attributes from updated properties, traversed nodes
        //    $attributes = [property manually defined in RestModel] + [transformed properties] - [internal properties]
        for(var key in rmd.$properties) {
            if(existingAttr.has(key) || excludeProp.has(key)) {
                continue
            }
            existingAttr.add(key)
            rmd.$attributes.push(key)
        }
        if(rmd.include_restmodel) {
            if(rmd.hierarchy === undefined) {
                rmd.hierarchy = {}
            }
            for(var key in hierarchy) {
                rmd.hierarchy[key] = hierarchy[key]
            }
        }
    }

    onFormChanged: {
        autoAliasProperties(form)
    }
    /**
     * Clear all alias form elements
     */
    function clear() {
        //TODO: 
    }
}
        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/Common/AutoRestModel.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}