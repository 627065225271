import BaseService from "./BaseService";
import { MIXIN } from '../../configs/apis';
class MixInService extends BaseService {
  /**
   * get operating order
   * @param {object} data
   */
  getPrintAPI({ printAPI, params } = {}) {
    return this.client
      .get(printAPI, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getRegisterByTokenFacebook({ params } = {}){
    return this.client.get(MIXIN.REGISTER_BY_TOKEN_FACEBOOK, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

}
export default MixInService;
