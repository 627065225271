import moment from 'moment';

const thaiYear = 'BE';
const format = moment.fn.format;

moment.fn.format = function (str) {
  if (str && /BE/.test(str)) {
    str = str.replace(thaiYear, parseInt(this.get('year'), 10) + 543);
  }
  return format.call(this, str);
};
