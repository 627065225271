import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `Common/LoaderManager.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import QtQml 1.0

QtObject {
    id: manager
    signal defaultCardActived()
    property var _executionQueue: new Object()
    property var _delayedNavigate: null

    /**
     *       README
     *       please don't use id of card directly (ex. crdVital.someProperty = 5)
     *       it may cause an error because card is not loaded
     *       every card should use values from PATIENT_DATA
     *       if you really want to set value to each card directly, use getCard (ex. getCard('crdVital').xx = 5))

     ************ Usage Example ************
     * MainWRD.qml, MainDPI.qml

    /**
     *   allCards:
     *       - use full card name as a key (ex. CardVital)
     *
     *       (required)
     *       - loaderId (string)
     *       - cardId (string)
     *
     *       (optional)
     *       - autoRefresh (bool) if true after calling setVisible(), function clear and refresh will be called
     *       - default = true
     *
     *       - refCheckBoxId (string) is an id of CheckBox which activates a card (can be empty)
     *         default = not defined
     *
     *       - refButtonId (string) is an id of Button which activates a card (can be empty)
     *         default = not defined
     *
     *       - defaultCard (bool|function) if true a card will be shown up when calling activeDefaultCard(),
     *         default = false
     *
     *       - immediatelyShowWithDelay (int) (milisecond) if true a card will be shown (with delay time) after loading
     *         default = not defined, not show up
     *
     */
    property var allCards: new Object({})

    /**
     * cards which are specified in this property will be pinned at the top
     */
    property var pinned: []

    property var mainLayout: null

    property bool noAutoMargin: false

    Component.onCompleted: {
        window.addEventListener('hideModal', function() {
            if (manager._delayedNavigate) {
                setTimeout(function() {
                    if (_.isFunction(manager._delayedNavigate.expand)) {
                        manager._delayedNavigate.expand()
                    }
                    if (_.isFunction(manager._delayedNavigate.navigate)) {
                        manager._delayedNavigate.navigate()
                    }
                    manager._delayedNavigate = null
                }, 200)
            }
        })
        Object.keys(allCards).forEach(function(cardName) {
            var delay = allCards[cardName].immediatelyShowWithDelay
            if (delay) {
                setTimeout(function () {
                    setVisible(cardName, true)
                }, delay)
            }
        })
    }

    /**
     * return promise
     *  - qml object if card is loaded
     *  - undefined if card is not loaded
     * @param {string} cardName
     */
    function getCard(cardName) {
        return QmlWeb.executionContext[allCards[cardName].cardId]
    }

    /**
     * return
     *  - false, if card is not active
     *  - true, if card active and function can be called
     *
     *  callFunc({
     *      cardName: 'CardDrugOrderSummary',
     *      funcName:'test',
     *      params: [55, 66],
     *      callback: function(res) {},
     *      enqueue: true
     *  })
     * @param {string} cardName
     * @param {string} funcName
     * @param {var|array} params
     * @param {function} callback, if calling is success this callback will be executed
     * @param {bool} enqueue, to specify this function call should be enqueued if card is not actived (and execute when card is actived)
     */
    function callFunc(cardName, funcName, params, callback, enqueue) {
        if (typeof cardName === 'object') {
            funcName = cardName.funcName
            params = cardName.params
            enqueue = cardName.enqueue
            callback = cardName.callback
            cardName = cardName.cardName
        }

        var card = getCard(cardName)
        if (!(params instanceof Array)) {
            params = [params]
        }
        if (!card) {
            if (enqueue) {
                _executionQueue[cardName] = _executionQueue[cardName] || []
                _executionQueue[cardName].push({funcName: funcName, params: params, callback: callback})
            }
            return false
        }
        if (typeof card[funcName] === 'function') {
            var ret = card[funcName].apply(card, params)
            if (typeof callback === 'function') {
                callback(ret)
            }
            return true
        }
        return false
    }

    /**
     *  clearCallFunc({
     *      cardName: 'CardDrugOrderSummary',
     *      funcName: 'test',
     *  })
     *
     *  To clear all function call on CardDrugOrderSummary
     *  clearCallFunc({
     *      cardName: 'CardDrugOrderSummary',
     *      funcName: '__all__',
     *  })
     * @param {string} cardName
     * @param {string | '__all__'} funcName
     */
    function clearCallFunc(cardName, funcName) {
        if (typeof cardName === 'object') {
            funcName = cardName.funcName
            cardName = cardName.cardName
        }
        if (funcName === '__all__') {
            _executionQueue[cardName] = []
        }
        if (_executionQueue[cardName] instanceof Array) {
            _executionQueue[cardName] = _executionQueue[cardName].filter(function(item) {
                return item.funcName !== funcName
            })
        }
    }

    /**
     * call this function to show card that has property defaultCard in allCards
     */
    function activeDefaultCard() {
        var promise = Promise.resolve()
        Object.keys(allCards).forEach(function(cardName) {
            var visible = false
            if (typeof allCards[cardName].defaultCard === 'function') {
                visible = allCards[cardName].defaultCard()
            } else {
                visible = allCards[cardName].defaultCard
            }
            if (visible) {
                // for parallel promises execution
                promise = promise.then(setVisible.bind(null, cardName, visible))
            }
        })
        return promise.then(function() {
            defaultCardActived()
        }).catch(function(err) {
            console.error(err)
            console.warn('error in activeDefaultCard()')
        })
    }

    /**
     *
     * return promise
     *  - will resolve promise after card is shown/hidden
     * @param {string} cardName
     * @param {bool} visible
     */
    function setVisible(cardName, visible) {
        var refButtonId = allCards[cardName].refButtonId
        var refCheckBoxId = allCards[cardName].refCheckBoxId
        var refButton = QmlWeb.executionContext[refButtonId] || { loading: false }
        var refCheckBox = QmlWeb.executionContext[refCheckBoxId] || { loading: false }

        return new Promise(function(resolve, reject) {
            // prevent blocking UI thread
            refButton.loading = true
            refCheckBox.loading = true
            setTimeout(function() {
                var loaderId = allCards[cardName].loaderId
                var loader = QmlWeb.executionContext[loaderId]

                if (!loader) {
                    Util.alert('Loader is not found: ' + loaderId, 'red')
                    return
                }

                if (!getCard(cardName) && !visible) {
                    return
                }

                // We will not deactivate loader (loader memory leaks problem)
                if (!loader.active) {
                    loader.loaded.connect(loader, function() {
                        _registerSignal(cardName)
                        _showOrHide(cardName, visible)
                        resolve()
                        refButton.loading = false
                        refCheckBox.loading = false
                        _executeFuncInQueue(cardName)
                    })
                    loader.active = true
                    return
                }

                _showOrHide(cardName, visible)
                resolve()
                refButton.loading = false
                refCheckBox.loading = false
            }, 50)
        })
    }

    /**
     * navigate to the specified card, also prevent false scrolling position
     * when modal is showing
     * if collapseAll is true, all cards (except this one) will be collapsed
     * @param {string} cardName
     * @param {bool} collapseAll
     */
    function focus(cardName, collapseAll) {
        function _collpaseOthers() {
            Object.keys(allCards).forEach(function(_cardName) {
                var other = getCard(_cardName)
                if (cardName !== _cardName && other) {
                    other.collapse()
                }
            })
        }

        var card = getCard(cardName)
        var promise = Promise.resolve()
        if (card && !card.displayNone) {
            _tryToNavigateOrWait(card)
        } else {
            promise = setVisible(cardName, true)
        }
        if (collapseAll) {
            promise.then(_collpaseOthers)
        }
    }

    /**
     * hide all cards
     */
    function hideAll() {
        Object.keys(allCards).forEach(function(_cardName) {
            setVisible(_cardName, false)
        })
    }

    /***************************************************** PRIVATE SECTION ****************************************/

    // This function shouldn't be called directly, use setVisible instead
    function _showOrHide(cardName, visible) {
        var cardId = allCards[cardName].cardId
        var refCheckBoxId = (allCards[cardName].refCheckBoxId || '').trim()
        var refButtonId = (allCards[cardName].refButtonId || '').trim()
        var autoRefresh = allCards[cardName].hasOwnProperty('autoRefresh') ? allCards[cardName].autoRefresh : true
        var card = QmlWeb.executionContext[cardId]
        var refCheckBox, refButton

        if (!card) {
            Util.alert('Card is not found: ' + cardId, 'red')
            return
        }

        refCheckBox = QmlWeb.executionContext[refCheckBoxId]
        refButton = QmlWeb.executionContext[refButtonId]

        if (refCheckBox) {
            // use bySystem to prevent firing onChanged on CheckBox
            refCheckBox.bySystem = true
            refCheckBox.checked = visible
            refCheckBox.checkbox.checked = visible
            refCheckBox.bySystem = false
        }

        if (refButton) {
            refButton.active = visible
        }

        if (visible) {
            autoRefresh && _commonRefreshCard(card)
            card.displayNone = false
            _moveAndNavigateCard(card)

            if (!manager.noAutoMargin && card.dom.style.marginBottom !== '15px') {
                card.dom.style.marginBottom = '15px'
            }
        } else {
            card.displayNone = true
        }
    }

    function _moveAndNavigateCard(card) {
        if (manager.mainLayout) {
            var container = manager.mainLayout.getMiddleContainer().dom.children[0]
            // set pinned position to loader node
            var pinnedPosition = manager.pinned.indexOf(card._qmlClass.trim())
            card.dom.parentNode.dataset.pinnedPosition = pinnedPosition
            // find the first node of unpinned cards
            var firstUnpinnedNode = [].find.call(container.children, function(node) {
                return parseInt(node.dataset.pinnedPosition) === -1
            })
            // find the last node of pinned cards
            var lastPinnedNode = [].filter.call(container.children, function(node) {
                return  typeof node.dataset.pinnedPosition !== 'undefined' &&
                        parseInt(node.dataset.pinnedPosition) !== -1
            })
            lastPinnedNode = lastPinnedNode.slice() // copy array
            lastPinnedNode = lastPinnedNode.sort(function(a, b) { // sort by ascending order
                return parseInt(a.dataset.pinnedPosition) - parseInt(b.dataset.pinnedPosition)
            })
            lastPinnedNode = lastPinnedNode.pop()

            // if this card should be pinned
            if (pinnedPosition !== -1) {
                if (lastPinnedNode) {
                    // if there's the last pinned node, put this node according to its position
                    var found = [].some.call(container.children, function(node) {
                        var currentPinnedPosition = parseInt(node.dataset.pinnedPosition)
                        if (currentPinnedPosition > pinnedPosition) {
                            container.insertBefore(card.dom.parentNode, node)
                            return true
                        }
                    })
                    if (!found && firstUnpinnedNode) {
                        container.insertBefore(card.dom.parentNode, firstUnpinnedNode)
                    } else {
                        // case: pinnedCard is the first card
                    }
                } else if (firstUnpinnedNode) { // if there's the first unpinned node, put this node above
                    container.insertBefore(card.dom.parentNode, firstUnpinnedNode)
                } else {
                    container.insertBefore(card.dom.parentNode, container.firstChild)
                }
            } else {
                if (lastPinnedNode) { // if there's the last pinned node, put this node below
                    container.insertBefore(card.dom.parentNode, lastPinnedNode.nextSibling)
                } else if (firstUnpinnedNode) { // if there's the first unpinned node, put this node above
                    container.insertBefore(card.dom.parentNode, firstUnpinnedNode)
                } else {
                    container.insertBefore(card.dom.parentNode, container.firstChild)
                }
            }
        }
        _tryToNavigateOrWait(card)
    }

    function _tryToNavigateOrWait(card) {
        var modals = document.querySelectorAll('body > .dimmable');
        var someModalShowing = [].some.call(modals, function(node) {
            return node.style.display !== 'none'
        })
        if (someModalShowing) {
            manager._delayedNavigate = card
        } else {
            setTimeout(function() {
                if (_.isFunction(card.expand)) {
                    card.expand()
                }
                if (_.isFunction(card.navigate)) {
                    card.navigate()
                }
            }, 50)
        }
    }

    function _commonRefreshCard(card) {
        if (typeof card.clear === 'function') {
            card.clear()
        }
        if (typeof card.refresh === 'function') {
            card.refresh()
        }
    }

    function _executeFuncInQueue(cardName) {
        if (_executionQueue[cardName] instanceof Array) {
            var queue = _executionQueue[cardName]
            while(queue.length > 0) {
                var info = queue.shift()
                var card = getCard(cardName)
                var func = card[info.funcName]
                if (typeof func !== 'function') {
                    continue
                }
                var ret = func.apply(card, info.params)
                if (typeof info.callback === 'function') {
                    info.callback(ret)
                }
            }
        } else {
            _executionQueue[cardName] = []
        }
    }

    function _registerSignal(cardName) {
        var cardId = allCards[cardName].cardId
        var refCheckBoxId = allCards[cardName].refCheckBoxId
        var refButtonId = allCards[cardName].refButtonId
        var card = QmlWeb.executionContext[cardId]
        var refCheckBox, refButton

        if (!card) {
            Util.alert('Card is not found: ' + cardId, 'red')
            return
        }

        // register hideCallback
        card.hideCallback = function() {
            refButton = QmlWeb.executionContext[refButtonId]
            refCheckBox = QmlWeb.executionContext[refCheckBoxId]

            if (refButton) {
                refButton.active = false
            }

            if (refCheckBox) {
                refCheckBox.checked = false
            }
        }
    }
}

        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/Common/LoaderManager.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}