import React from 'react';
import PropTypes from 'prop-types';


export default class GoogleMap extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    options: PropTypes.object,
    onMapLoad: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {

    };
  }

  onScriptLoad = () => {
    const map = new window.google.maps.Map(
      document.getElementById(this.props.id),
      this.props.options);
    this.props.onMapLoad(map);
  }

  componentDidMount() {
    if (!window.google) {
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = 'https://maps.google.com/maps/api/js?key=AIzaSyDnZHCNVuYH8lZSMZtuHzJ4677eUi6AE8w';
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);

      s.addEventListener('load', (e) => {
        this.onScriptLoad();
      });
    } else {
      this.onScriptLoad();
    }
  }

  render() {
    return (
      <div>
        <div style={{ width: 300, height: 300 }} id={this.props.id}></div>
      </div>
    );
  }
}