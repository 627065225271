import BaseService from "./BaseService";
import { USER } from "../../configs/apis";
class UserService extends BaseService {
  getSearchUser(nameUsername) {
    const url = USER.SEARCH_USER({ name_username: nameUsername });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getUser(params) {
    return this.client
      .get(USER.USER, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getUserPermission(params) {
    return this.client
      .post(USER.USER_PERMISSION, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getUserTokenize(params) {
    return this.client
      .post(USER.USER_TOKENIZE, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getUserTokenizeLicense(params) {
    return this.client
      .post(USER.USER_TOKENIZE_LICENSE, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getSessionInfo(params) {
    return this.client
      .get(USER.SESSION_INFO)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  
  getUserProfile({headers, params } = {}) {
    return this.client.get(USER.USER_PROFILE, { headers, params } )
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
}

  patchUserProfile({headers, data} = {}) {
    return this.client.patch(USER.USER_PROFILE, data, {headers})
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
}
  
}
export default UserService;
