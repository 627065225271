import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `Common/SubScannerForm.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import QmlWeb 1.0
import '../Common/Util.js' as Util

Segment {
    id: subScannerForm

    property bool isEditing: false
    property var division: 'current'

    property int scanDocId: null

    signal scanClicked()
    signal editClicked()
    signal cancelEditClicked()

    signal editCompleted()
    signal cancelCompleted()

    function clearForm() {
        cboDocType.clear()
        txtDocumentNo.text = ''
        txtVersion.text = ''
        cboOwnerDivision.clear()
        dtbDocDate.date = moment()
        dtbExpiryDate.text = ''
        txtRemark.text = ''
        modConfirm.clear()

        subScannerForm.isEditing = false
        subScannerForm.scanDocId = null
    }

    function fillRow(row) {
        row.document_type_name = cboDocType.getText()
        row.document_type = cboDocType.value
        row.document_no = txtDocumentNo.text
        row.version = txtVersion.text
        row.owner_division = cboOwnerDivision.value
        row.document_date = dtbDocDate.text
        row.expiry_date = dtbExpiryDate.text
        row.remark = txtRemark.text
    }

    function fillFormEdit(row) {
        if (row.id) {
            subScannerForm.scanDocId = row.id
        }

        cboDocType.value = row.document_type
        txtDocumentNo.text = row.document_no
        txtVersion.text = row.version
        cboOwnerDivision.value = row.owner_division
        dtbDocDate.text = row.document_date
        dtbExpiryDate.text = row.expiry_date
        txtRemark.text = row.remark
        subScannerForm.isEditing = true
    }

    function setData(data) {
        if(data.docType){
            cboDocType.text = data.docType
        }
        if(data.division){
            cboOwnerDivision.value = data.division
        }
        if(data.division_code) {
            cboOwnerDivision.items.forEach(function(object){
                if(object.code == data.division_code) {
                    cboOwnerDivision.value = object.id
                }
            })
        }
    }

    ModConfirm {
        id: modConfirm
        property var STATE: ({
            CANCEL: 'CANCEL',
            CONFIRM: 'CONFIRM'
        })
        property alias cancelNote: txtCancelRemark.text
        approveButtonText: _('ตกลง')
        denyButtonText: _('ปิด')

        RestModel {
            id: rmdEditDoc
            property alias document_type: cboDocType.value
            property alias document_no: txtDocumentNo.text
            property alias version: txtVersion.text
            property alias owner_division: cboOwnerDivision.value
            property alias document_date: dtbDocDate.text
            property alias expiry_date: dtbExpiryDate.text
            property alias remark: txtRemark.text

            property alias username: txtUsername.text
            property alias password: txtPassword.text

            onSaved: {
                clear()
                subScannerForm.editCompleted()
                modConfirm.hide()
            }

            onFailed: {
                clear()
                Util.danger(_('ไม่สามารถแก้ไขเอกสารได้'))
                frmUser.showErrorNew(error, $context, $properties);
            }

            function editDocument() {
                rmdEditDoc.url = '/apis/core/scanned-document/' + subScannerForm.scanDocId + '/'
                rmdEditDoc.patch()
            }
        }

        RestModel {
            id: rmdUpdateActiveFlag
            property bool active: false
            property alias cancel_note: modConfirm.cancelNote

            property alias username: txtUsername.text
            property alias password: txtPassword.text

            onSaved: {
                clear()
                modConfirm.hide()
                subScannerForm.cancelCompleted()
            }

            onFailed: {
                clear()
                Util.danger(_('ไม่สามารถยกเลิกเอกสารได้'))
                frmUser.showErrorNew(error, $context, $properties);
            }

            function deactivateDocument() {
                rmdUpdateActiveFlag.url = '/apis/core/scanned-document/' + subScannerForm.scanDocId + '/'
                rmdUpdateActiveFlag.active = false
                return rmdUpdateActiveFlag.patch()
            }
        }

        function showModalWith(state) {
            if (state == modConfirm.STATE.CANCEL) {
                modConfirm.titleName = _('ยกเลิกเอกสาร')
                modConfirm.titleColor = 'red'

                frmCancelDocument.displayNone = false

                modConfirm.approve = function () {
                    rmdUpdateActiveFlag.deactivateDocument()
                }

            }
            else if (state == modConfirm.STATE.CONFIRM) {
                modConfirm.titleName = _('แก้ไขเอกสาร')
                modConfirm.titleColor = 'blue'

                frmCancelDocument.displayNone = true

                modConfirm.approve = function () {
                    rmdEditDoc.editDocument()
                }
            }
            else {
                return
            }

            frmUser.clear()
            modConfirm.show()
        }

        function clear() {
            txtCancelRemark.text = ''
            txtUsername.text = ''
            txtPassword.text = ''
        }

        onDeny: {
            modConfirm.hide()
        }

        content: [
            Form {
                id: frmUser

                Message {
                    className: 'error'
                }
                Field {
                    label: _('Username')
                    TextBox {
                        id: txtUsername
                        inputName: 'username'
                        doc_label: 'username'
                        placeholder: 'Username'
                    }
                }
                Field {
                    label: _('Password')
                    TextBox {
                        id: txtPassword
                        inputName: 'password'
                        doc_label: 'password'
                        inputType: 'password'
                        placeholder: 'Password'
                    }
                }
            },
            Form {
                id: frmCancelDocument
                Field {
                    label: _('เหตุผลในการยกเลิก')
                    TextArea {
                        id: txtCancelRemark
                        rows: 2
                    }
                }
            }
        ]
    }

    Form {
        id: frmController
// ---- Validation --------------------------------------------------------------------------------
        validateSetting: new Object({
            fields: {
                cboDocType: ['empty']
            },
            prompt: {
                empty: _('กรุณาเลือกค่าในช่อง ประเภทเอกสาร')
            }
        })
        Message{
            className: "error"
        }
// ------------------------------------------------------------------------------------------------
        Fields {
            Field {
                className: 'nine wide'
                label: 'ชื่อเอกสาร'
                ComboBox {
                    id: cboDocType
                    dataValidate: 'cboDocType'
                    search: true
                    forceSelection: true
                    fullTextSearch: true
                    RestModel {
                        id: rmdDocType
                        url: '/apis/core/document-type/'
                        property alias items: cboDocType.items
                    }
                    Component.onCompleted: {
                        rmdDocType.query = {
                            division: subScannerForm.division,
                            limit: 99999
                        }
                        rmdDocType.fetch()
                    }
                }
            }
            Field {
                className: 'four wide'
                label: _('เลขที่เอกสาร')
                TextBox {
                    id: txtDocumentNo
                }
            }
            Field {
                className: 'three wide'
                label: _('Version')
                TextBox {
                    id: txtVersion
                    inputType: 'number'
                    inputAttr: ({ min: 0 })
                }
            }
        }
        Fields {
            Field {
                className: 'sixteen wide'
                label: _('แผนกเจ้าของเอกสาร')
                ComboBox {
                    id: cboOwnerDivision
                    search: true
                    forceSelection: true
                    fullTextSearch: true
                    optionTextField: 'name_code'
                    emptyItem: _('ไม่ระบุ')

                    RestModel {
                        id: rmdDivision
                        url: '/apis/core/division/?limit=9999'
                        property alias items: cboOwnerDivision.items
                    }

                    Component.onCompleted: {
                        rmdDivision.fetch()
                    }
                }
            }
        }
        Fields {
            Field {
                className: 'eight wide'
                label: 'วันที่ของเอกสาร'
                DateTextBox {
                    id: dtbDocDate
                    date: moment()
                }
            }
            Field {
                className: 'eight wide'
                label: 'วันที่หมดอายุของเอกสาร'
                DateTextBox {
                    id: dtbExpiryDate
                }
            }
        }
        Fields {
            style: 'margin-bottom: 0'
            Field {
                className: 'eight wide'
                label: _('หมายเหตุ')

                TextArea {
                    id: txtRemark
                    style: ({
                        resize: 'vertical',
                        minHeight: '2em',
                        maxHeight: '10em',
                    })
                    rows: 1
                }
            }
            Field {
                className: 'four wide'
                Button {
                    className: 'fluid'
                    text: subScannerForm.scanDocId? _('ยกเลิก'): _('ล้างข้อมูล')
                    backgroundColor: 'red'
                    displayNone: !subScannerForm.isEditing
                    onClicked: {
                        if (subScannerForm.scanDocId) {
                            // Deactivate ScannedDocument
                            modConfirm.showModalWith(modConfirm.STATE.CANCEL)
                        }
                        else {
                            subScannerForm.cancelEditClicked()
                        }
                    }
                }
            }
            Field {
                className: 'four wide'
                Button {
                    id: btnScan
                    className: 'fluid'
                    text: subScannerForm.isEditing? 'EDIT': 'SCAN'
                    backgroundColor: subScannerForm.isEditing? 'orange': 'green'
                    onClicked: {
                        if (!frmController.validateForm()) {
                            return
                        }

                        // Edit mode
                        if (subScannerForm.isEditing) {
                            if (subScannerForm.scanDocId) {
                                // Edit existed
                                modConfirm.showModalWith(modConfirm.STATE.CONFIRM)
                            }
                            else {
                                subScannerForm.editClicked()
                            }
                        }
                        // Scan mode
                        else {
                            subScannerForm.scanClicked()
                        }
                    }
                }
            }
        }
    }
}

        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/Common/SubScannerForm.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}