import React from "react";
import PropTypes from 'prop-types';
import * as CONSTANT from '../../utils/constant';
import '../../css/AutoCompleteBox.css'

const AutoCompleteBox = React.forwardRef((props, ref) => {

  const [stateOptions, setStateOptions] = React.useState([])
  const [visible, setVisible] = React.useState(false)
  const [text, setText] = React.useState('')
  const [activeIndex, setActiveIndex] = React.useState(0)

  const inputRef = React.useRef()
  const itemRef = React.useRef()

  React.useImperativeHandle(ref, () => ({
    getValue: () => {
      return text
    },
  }));

  React.useEffect(() => {
    setStateOptions(props.options)
  }, [props.options])  

  React.useEffect(() => {
    setText(props.value)
  }, [props.value])

  React.useEffect(() => {
    setActiveIndex(0)
  }, [visible])  

  function handleClickOutside(event) {
    if (inputRef.current && !inputRef.current.contains(event.target) &&
    (itemRef.current && !itemRef.current.contains(event.target))
    ) {
      setVisible(false)
    }
  }

  React.useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const generateDropdown = () => {
    let data = []
    for (let items in stateOptions) {
      let itemText = stateOptions[items].text
      data.push(<div 
        onMouseOver={() => setActiveIndex(parseInt(items))}
        className={'item ' + (activeIndex === parseInt(items) ? 'active' : '' )}
        key={stateOptions[items].key}
        onClick={(e) => handleSelectChoice(itemText)}
         >
         <b>{itemText}</b>
         </div>)
    }
    return data
  }

  const handleInputChange = (e) => {
    const { value } = e.target;
    setText(e.target.value)
    let valueArr = value.split(' ')
    let newOptions = props.options.filter((item) => {
      return item.text.includes(valueArr[valueArr.length - 1])
    })
    if(newOptions.length === 0){
      return setVisible(false)
    } 
    if(!visible){
      setVisible(true)
    }
    setStateOptions(newOptions)
    setActiveIndex(0)
  }

  const handleSelectChoice = (itemText) => {
    let stateText = text
    let textArr = stateText.split(' ')
    let newText = ''
    if((textArr[textArr.length - 1].includes(itemText) ||
    itemText.includes(textArr[textArr.length - 1])
    ) && textArr[textArr.length - 1].length < itemText.length){
      textArr[textArr.length - 1] = itemText
      newText = textArr.join(' ')
    } else {
      textArr[textArr.length - 1] = itemText
      newText = stateText + itemText
    }
    setText(newText)
    inputRef.current.focus()
    setVisible(false)
  }
  
  const handleKeyDown = (e) => {
    if(e.keyCode === CONSTANT.KEY_CODE.ARROW_DOWN && (activeIndex !== stateOptions.length - 1)){
      setActiveIndex(activeIndex + 1)
    } else if(e.keyCode === CONSTANT.KEY_CODE.ARROW_UP && activeIndex !== 0){
      setActiveIndex(activeIndex - 1)
    }
    else if (e.keyCode === CONSTANT.KEY_CODE.SPACE) {
      setVisible(true)
    } else if(e.keyCode === CONSTANT.KEY_CODE.ENTER){
      if(!visible){
        return
      }
      let itemText = stateOptions[activeIndex].text
      handleSelectChoice(itemText)
    } else if(e.keyCode === CONSTANT.KEY_CODE.ESCAPE){
      setVisible(false)
    }
  }

  const handleFocus = () => {
    if(props.options.length > 0){
      setVisible(true)
    } else {
      setVisible(false)
    }
  }

  return (
    <div className='ui autoComplete'>
    <div className='autoCompleteMenu'>
        <input 
          disabled={props.disabled}
          onClick={handleFocus}
          onKeyDown={handleKeyDown}
          ref={inputRef}
          value={text}
          onChange={handleInputChange}
          spellCheck={false} 
          className={'reduceHeight autoCompleteInput greyDisabled ' + (props.disabled ? 'disabled' : '') }
        />
        <div className={'ui menu autoCompleteItem ' + (visible ? 'visible' : 'hidden')} ref={itemRef}
        >
        {generateDropdown()}
        </div>
      </div>
    </div>
  );
});

AutoCompleteBox.defaultProps = {
  options: [],
  value: '',
};

AutoCompleteBox.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  })),
  value: PropTypes.string,
};

export default React.memo(AutoCompleteBox);
