import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `Common/Login.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import QmlWeb 1.0
import 'Util.js' as Util

Modal {
    id: login
    property bool showRelogin: false
    property bool relogin: false
    className: 'mini'
    closable: false

    onHidden: {
        txtUsername.text = ''
        txtPassword.text = ''
    }

    onReloginChanged: {
        login.updateUI()
    }

    onShowed: {
        login.updateUI()
    }

    function updateUI() {
        mnuMode.selectedTab = login.relogin ? 'continue' : 'otherUser'
        manageUsernameField(login.relogin)
    }

    function manageUsernameField(condition) {
        if (condition) {
            txtUsername.text = Util.getCookie('user')
            txtUsername.readOnly = true
            txtUsername.style = {
                opacity: '0.6'
            }
            txtPassword.setFocus()
        } else {
            txtUsername.text = ''
            txtUsername.readOnly = false
            txtUsername.style = ''
            txtUsername.setFocus()
        }
    }

    RestModel {
        id: rmdRelogin
        url: '/users/apis/relogin/'
        property alias username: txtUsername.text
        property alias password: txtPassword.text
        property bool relogin_success
        onSaved: {
            rmdRelogin.password = ''
            if(relogin_success) {
                login.hide()
            }
        }
        onFailed: {
            rmdRelogin.password = ''
            if(xhr.status == 403) {
                // relogin not allowed
                alert('ไม่สามารถ Relogin เนื่องจากคุณออกจากระบบแล้ว กรุณาเข้าสู่ระบบใหม่')
                relogin = false
            }
            var err = error.errors
            frmLogin.showError(error.errors)
        }
    }

    Segment {
        Menu {
            id: mnuMode
            className: showRelogin? 'two item': 'item'
            onChanged: {
                relogin = (selected == 'continue')
            }
            MenuItem {
                className: 'active blue'
                text: 'ใช้งานต่อ'
                dataTab: 'continue'
                displayNone: !showRelogin
            }
            MenuItem {
                className: 'red'
                text: 'ล็อกอินใหม่'
                dataTab: 'otherUser'
            }
        }
        Form {
            id: frmLogin
            tagName: 'form'
            htmlAttr: {
                var action = ''
                if(!location.pathname.startsWith('/users/login/')) {
                    action = '/users/login/?next=' + encodeURIComponent(location.pathname)
                }
                return {
                    'method': 'POST',
                    'onsubmit': relogin ? 'return false' : 'return true',
                    'action': action,
                }
            }
            Dom {
                tagName: 'input'
                htmlAttr: {
                    return {
                        'type': 'hidden',
                        'name': 'csrfmiddlewaretoken',
                        'value': DJANGO.CSRF_TOKEN
                    }
                }
            }
            Dom {
                tagName: 'input'
                htmlAttr: {
                    return {
                        'type': 'hidden',
                        'name': 'next',
                        'value': DJANGO.next
                    }
                }
            }
            Segment {
                className: 'raised defaultBackground'
                Form {
                    displayNone: true
                    Field {
                        TextBox {
                            inputName: 'username'
                        }
                    }
                    Field {
                        TextBox {
                            inputName: 'password'
                        }
                    }
                }
                Form {
                    Field {
                        label: 'Username'
                        TextBox {
                            id: txtUsername
                            inputName: 'username'
                            placeholder: 'Username'
                            inputAttr: new Object({autocomplete: 'off', spellcheck: 'false', dir: 'ltr'})
                        }
                    }
                    Field {
                        label: 'Password'
                        TextBox {
                            id: txtPassword
                            inputName: 'password'
                            inputType: 'password'
                            placeholder: 'Password'
                            inputAttr: new Object({autocomplete: 'off', spellcheck: 'false', dir: 'ltr'})
                        }
                    }
                    Button {
                        className: 'positive'
                        text: 'Login'
                        onClicked: {
                            frmLogin.clear()
                            Util.setCookie('user', txtUsername.text, 1)
                            localStorage.setItem('csrf_safari', DJANGO.CSRF_TOKEN)
                            localStorage.setItem('logging_in', moment().unix())
                            if(relogin) {
                                rmdRelogin.create()
                            } else {
                                 console.log('normal Submit')
                                // normal submit
                            }
                        }
                    }
                    Link {
                        style: 'float: right; padding-top: 1em;'
                        href: '/users/password_reset/'
                        text: 'ลืมรหัสผ่าน?'
                    }
                    Message {
                        className: 'error'
                    }
                    Component.onCompleted: {
                        errors = DJANGO.context.errors
                        if (errors) {
                            if(Object.keys(errors).length > 0) {
                                var result = []
                                if ('username' in errors) {
                                    result.push({'username': errors['username']})
                                    delete errors['username']
                                }
                                if ('password' in errors) {
                                    result.push({'password': errors['password']})
                                    delete errors['password']
                                }
                                if (Object.keys(errors).length > 0) {
                                    result.push(errors)
                                }

                                frmLogin.useOriginalName = true
                                frmLogin.showErrorNew(result)
                            }
                        }
                    }
                }
            }
        }

        // Quick login for dev -----------------------------------------------------------------------------------------
        Segment {
            className: 'raised defaultBackground'
            displayNone: !DJANGO.debug_login
            Form {
                className: 'tiny'
                Fields {
                    className: 'inline'
                    Field {
                        className: 'four wide'
                        label: 'Username'
                        labelAlign: 'right'
                    }
                    Field {
                        className: 'nine wide'
                        ComboBox {
                            id: cboUser
                            search: true
                            forceSelection: true
                            useTextValue: true
                            optionTextField: 'username'
                            RestModel {
                                id: rmdUser
                                url: '/users/apis/users/'
                                property alias items: cboUser.items
                            }
                            Component.onCompleted: {
                                rmdUser.fetch()
                            }
                        }
                    }
                }
                Fields {
                    className: 'inline'
                    Field {
                        className: 'four wide'
                        label: 'Device'
                        labelAlign: 'right'
                    }
                    Field {
                        className: 'nine wide'
                        ComboBox {
                            id: cboDevice
                            search: true
                            forceSelection: true
                            fullTextSearch: true
                            useTextValue: true
                            optionTextField: 'computer_name'
                            RestModel {
                                id: rmdDevice
                                url: '/apis/core/device/?disable_pagination=true'
                                property alias items: cboDevice.items
                            }
                            Component.onCompleted: {
                                rmdDevice.fetch()
                            }
                        }
                    }
                    Field {
                        className: 'three wide'
                        Button {
                            id: btnDevLogin
                            text: 'Login'
                            className: 'tiny'
                            onClicked: {
                                devLogin()
                            }
                            function devLogin() {
                                txtUsername.text = cboUser.value
                                txtPassword.text = 'cnmi2559'
                                grdDebug.submitDebug(cboDevice.value).toUpperCase()
                            }
                        }
                    }
                }
            }
            Divider {}
            Grid {
                id: grdDebug
                className: 'two column'
                function submitDebug(comname) {
                    console.log('submitDebug', comname)
                    var comnameIndex = frmLogin.dom.action.indexOf('&comname')
                    if (comnameIndex > 0) {
                        var path = frmLogin.dom.action.slice(0, comnameIndex)
                    }
                    else {
                        var path = frmLogin.dom.action
                    }
                    frmLogin.dom.action = path + '&comname=' + comname
                    frmLogin.dom.submit()
                }
                Repeater {
                    id: rptDebug
                    model: DJANGO.debug_login
                    property int index: 0
                    property var COLORS: [
                        'red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue',
                        'violet', 'purple', 'pink', 'brown', 'grey', 'black'
                    ]
                    Column {
                        Button {
                            id: btnUser
                            className: 'small fluid'
                            text: modelData.label
                            Component.onCompleted: {
                                rptDebug.index++
                                rptDebug.index %= rptDebug.COLORS.length
                                btnUser.backgroundColor = rptDebug.COLORS[rptDebug.index]
                            }
                            onClicked: {
                                console.log('onClicked, small fluid')
                                txtUsername.text = modelData.username
                                txtPassword.text = modelData.password
                                grdDebug.submitDebug(modelData.comname)
                                localStorage.setItem('csrf_safari', DJANGO.CSRF_TOKEN)
                                localStorage.setItem('logging_in', moment().unix())
                            }
                        }
                    }
                }
            }
        }
    }
}

        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/Common/Login.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}