import React from "react";
import PropTypes from "prop-types";
import { Form, Input, TextArea, Button } from "semantic-ui-react";
import {
  ComboBox,
  DateTextBox,
  SearchBox,
  ModAuthen as ModCancelAuthen,
  ModAuthen as ModEditAuthen,
  ErrorMessage
} from "../common";
import * as Util from "../../utils";
import moment from "moment";

const SubScannerForm = React.forwardRef((props, ref) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [scanDocId, setScanDocId] = React.useState(null);
  const [documentType, setDocumentType] = React.useState(null);
  const [documentTypeName, setDocumentTypeName] = React.useState("");
  const [documentNo, setDocumentNo] = React.useState("");
  const [version, setVersion] = React.useState("");
  const [ownerDivision, setOwnerDivision] = React.useState(null);
  const [documentCategory, setDocumentCategory] = React.useState(null);
  const [documentTypeOptions, setDocumentTypeOptions] = React.useState(null);
  const [documentDate, setDocumentDate] = React.useState(
    Util.formatDate(moment())
  );
  const [expiryDate, setExpiryDate] = React.useState("");
  const [remark, setRemark] = React.useState("");
  const [openModEditAuthen, setOpenModEditAuthen] = React.useState(false);
  const [openModCancelAuthen, setOpenModCancelAuthen] = React.useState(false);
  const [cancelNote, setCancelNote] = React.useState("");
  const [noDocTypeError, setNoDocTypeError] = React.useState(null);

  const docTypeRef = React.useRef();
  const doctorRef = React.useRef<any>();

  React.useImperativeHandle(ref, () => ({
    clearForm: () => {
      clearForm();
    },
    fillRow: () => {
      return fillRow();
    }
  }));

  React.useEffect(() => {
    setIsEditing(props.isEditing);
  }, [props.isEditing]);

  React.useEffect(() => {
    setScanDocId(props.scanDocId);
  }, [props.scanDocId]);

  React.useEffect(() => {
    if (props.data && Object.keys(props.data).length > 0) {
      fillFormEdit(props.data);
    }
  }, [props.data]);

  const clearForm = () => {
    setDocumentType(null);
    setDocumentTypeName("");
    setDocumentNo("");
    setVersion("");
    setOwnerDivision(null);
    setDocumentDate(Util.formatDate(moment()));
    setExpiryDate("");
    setRemark("");
    setIsEditing(false);
  };

  const fillFormEdit = row => {
    if (row.id && !row.id.toString().includes("fakeId")) {
      setScanDocId(row.id);
    }
    setDocumentType(row.document_type);
    setDocumentTypeName(row.document_type_name || row.document_type_name_en);
    setDocumentNo(row.document_no);
    setVersion(row.version);
    setOwnerDivision(row.owner_division);
    setDocumentDate(row.document_date);
    setExpiryDate(row.expiry_date);
    setRemark(row.remark);
    setIsEditing(true);
    if (props.showDocumentCategory) {
      handleChangeCategory(row.document_category_id)
    }
  };

  const fillRow = row => {
    return {
      document_type_name: docTypeRef.current.getText(),
      document_type: docTypeRef.current.getId(),
      document_no: documentNo,
      version: version,
      owner_division: ownerDivision,
      document_date: documentDate,
      expiry_date: expiryDate,
      remark: remark
    };
  };

  React.useEffect(() => {
    if (props.defaultDocName) {
      setDocumentType(props.defaultDocName.id ? props.defaultDocName.id : "")
      setDocumentTypeName(props.defaultDocName.name ? props.defaultDocName.name : "")
    }
  }, [props.defaultDocName])

  const handleChangeCategory = async (id) => {
    setDocumentCategory(id)

    const [optionsData, optionsError] = await props.onGetDocumentTypeOptions({
      searchText: "",
      documentCategory: id
    });
    const options = optionsData.map((item, index) => ({ key: index, text: item.name || item.name_en, value: item.id }))
    setDocumentTypeOptions(options)
  }

  return (
    <Form>
      <ModCancelAuthen
        testid="modCancelAuthen"
        open={openModCancelAuthen}
        approveButtonText="ตกลง"
        denyButtonText="ปิด"
        titleColor="red"
        titleName="ยกเลิกเอกสาร"
        onDeny={() => setOpenModCancelAuthen(false)}
        error={props.cancelDocError}
        loading={props.loading}
        onCloseWithDimmerClick={() => setOpenModCancelAuthen(false)}
        onApprove={({ username, password } = {}) =>
          props.onCancelApprove({ username, password, cancelNote, scanDocId })
        }
      >
        <Form.Field
          label="เหตุผลในการยกเลิก"
          error={props.cancelDocError ? true : false}
        />
        <Form.Field error={props.cancelDocError ? true : false}>
          <TextArea
            value={cancelNote}
            onChange={(e) => setCancelNote(e.target.value)}
          />
        </Form.Field>
      </ModCancelAuthen>
      <ModEditAuthen
        testid="modEditAuthen"
        open={openModEditAuthen}
        approveButtonText="ตกลง"
        denyButtonText="ปิด"
        titleName="แก้ไขเอกสาร"
        onDeny={() => setOpenModEditAuthen(false)}
        error={props.editDocError}
        onCloseWithDimmerClick={() => setOpenModEditAuthen(false)}
        loading={props.loading}
        onApprove={({ username, password } = {}) =>
          props.onEditApprove({
            username,
            password,
            scanDocId,
            documentType: props.showDocumentCategory ? documentType : docTypeRef.current.getId(),
            documentNo,
            version,
            ownerDivision,
            documentDate,
            expiryDate,
            remark,
            documentCategory: documentCategory,
            encounterDoctor: doctorRef.current?.getId()
          })
        }
      />
      <ErrorMessage error={noDocTypeError} />
      <Form.Group>
        {
          !props.showDocumentCategory ?
            <Form.Field width={9} error={noDocTypeError ? true : false}>
              <label>ชื่อเอกสาร</label>
              <SearchBox
                ref={docTypeRef}
                defaultId={documentType}
                defaultText={documentTypeName}
                textField="name"
                onGetSearchOptions={props.onGetDocumentTypeOptions}
                fetchOnFocus={true}
              />
            </Form.Field> :
            <Form.Field width={9}>
              <label>แผนกเจ้าของเอกสาร</label>
              <ComboBox
                value={ownerDivision}
                options={props.divisionOptions}
                onChange={(e, { value }) => setOwnerDivision(value)}
                fluid
                search
                placeholder="ไม่ระบุ"
              />
            </Form.Field>
        }
        <Form.Field width={4}>
          <label>เลขที่เอกสาร</label>
          <Input
            value={documentNo}
            onChange={(e) => setDocumentNo(e.target.value)}
          />
        </Form.Field>
        <Form.Field width={3}>
          <label>Version</label>
          <Input
            type="number"
            value={version}
            onChange={(e) => setVersion(e.target.value)}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        {
          !props.showDocumentCategory ?
            <Form.Field width={16}>
              <label>แผนกเจ้าของเอกสาร</label>
              <ComboBox
                value={ownerDivision}
                options={props.divisionOptions}
                onChange={(e, { value }) => setOwnerDivision(value)}
                clearable
                fluid
                placeholder="ไม่ระบุ"
              />
            </Form.Field> :
            <>
              <Form.Field width={8}>
                <label>ประเภทเอกสาร</label>
                <ComboBox
                  value={documentCategory}
                  options={props.categoryOptions}
                  search
                  onChange={(e, { value }) => {
                    setDocumentType('')
                    handleChangeCategory(value)
                  }}
                  fluid
                  placeholder="ไม่ระบุ"
                />
              </Form.Field>
              <Form.Field width={8} error={noDocTypeError ? true : false}>
                <label>ชื่อเอกสาร</label>
                <ComboBox
                  value={documentType}
                  search
                  options={documentTypeOptions}
                  onChange={(e, { value }) => setDocumentType(value)}
                  fluid
                  placeholder="ไม่ระบุ"
                />
              </Form.Field>
            </>
        }

      </Form.Group>
      <Form.Group>
        <Form.Field width={8}>
          <label>วันที่ของเอกสาร</label>
          <DateTextBox
            value={documentDate}
            onChange={(date) => setDocumentDate(date)}
          />
        </Form.Field>
        <Form.Field width={8}>
          <label>วันที่หมดอายุของเอกสาร</label>
          <DateTextBox
            value={expiryDate}
            onChange={(date) => setExpiryDate(date)}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group inline>
        {
          props.showDocumentCategory &&
          <Form.Field width={8}>
            <label>ทันตแพทย์</label>
            <SearchBox
              ref={doctorRef}
              defaultId={doctorRef.current?.getId()}
              defaultText={doctorRef.current?.getText()}
              textField="name_code"
              onGetSearchOptions={props.onGetSearchOptions}
              fetchOnFocus={true}
            />
          </Form.Field>
        }
        <Form.Field width={10}>
          <label>หมายเหตุ</label>
          <TextArea
            rows={1}
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
        </Form.Field>
        {!props.hideButtonCancel &&
          <Form.Field width={3}>

            <label>&ensp;</label>
            <Button
              fluid
              color="red"
              content={scanDocId ? "ยกเลิก" : "ล้างข้อมูล"}
              style={{ display: !isEditing ? "none" : null }}
              onClick={() => {
                if (scanDocId) {
                  setOpenModCancelAuthen(true);
                } else {
                  props.cancelEditClicked();
                }
              }}
            />
          </Form.Field>
        }

        <Form.Field width={3}>
          <label>&ensp;</label>
          <Button
            testid="btnScan"
            fluid
            color={isEditing ? "orange" : "green"}
            content={isEditing ? (!props.buttonEditTextTh ? "EDIT" : "แก้ไข") : (!props.buttonEditTextTh ? "SCAN" : "สแกน")}
            onClick={() => {
              if (docTypeRef.current && !docTypeRef.current.getId()) {
                return setNoDocTypeError("กรุณาเลือกค่าในช่อง ประเภทเอกสาร");
              }
              if (props.showDocumentCategory && !documentType) {
                return setNoDocTypeError("กรุณาเลือกค่าในช่อง ชื่อเอกสาร");
              }
              if (isEditing) {
                if (scanDocId) {
                  setOpenModEditAuthen(true);
                } else {
                  props.onEditClicked();
                }
              } else {
                props.onScanClicked();
              }
            }}
          />
        </Form.Field>
      </Form.Group>
    </Form >
  );
});

SubScannerForm.propTypes = {
  scanDocId: PropTypes.number,
  isEditing: PropTypes.bool,
  data: PropTypes.object,
  onGetDocumentTypeOptions: PropTypes.func,
  divisionOptions: PropTypes.array,
  onCancelApprove: PropTypes.func,
  cancelDocError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  editDocError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  loading: PropTypes.bool,
  onEditApprove: PropTypes.func,
  onEditClicked: PropTypes.func,
  onScanClicked: PropTypes.func,
  cancelEditClicked: PropTypes.func,
  hideButtonCancel: PropTypes.bool,
  buttonEditTextTh: PropTypes.bool,
  showDocumentCategory: PropTypes.bool,
  categoryOptions: PropTypes.array,
  onGetSearchOptions: PropTypes.func
};

SubScannerForm.defaultProps = {
  scanDocId: null,
  isEditing: false,
  data: {},
  onGetDocumentTypeOptions: () => [null, null],
  divisionOptions: [],
  onCancelApprove: () => { },
  cancelDocError: null,
  editDocError: null,
  loading: false,
  onEditApprove: () => { },
  onEditClicked: () => { },
  onScanClicked: () => { },
  cancelEditClicked: () => { },
  hideButtonCancel: false,
  buttonEditTextTh: false,
  showDocumentCategory: false,
  categoryOptions: [],
  onGetSearchOptions: () => { }
};

export default React.memo(SubScannerForm);
