import axios from "axios"
import querystring from "querystring";

// const SERVER_URL = "http://127.0.0.1:8000/"
const SERVER_URL = "https://accounts.thevcgroup.com/"
const CALLBACK_URL = `${window.location.origin}/email-callback/`
const APP = "ishealth"

export default class EmailLoginManager {
    register = async (email, recaptcha, first_name, last_name) => {
        const bodyFormData = new FormData()
        bodyFormData.set("email", email)
        bodyFormData.set("recaptcha", recaptcha)
        bodyFormData.set("first_name", first_name)
        bodyFormData.set("last_name", last_name)
        try {
            let ret = await axios.post(`${SERVER_URL}email_auth/register/`, bodyFormData,
                {headers: {"Content-Type": "multipart/x-www-form-urlencoded" }});
            console.log(ret)
            return ret.data
        } catch (e) {
            console.error(e.response)
            throw e
        }
    }

    requestLogin = async (email) => {
        const bodyFormData = new FormData()
        bodyFormData.set("email", email)
        bodyFormData.set("callback", CALLBACK_URL)
        try {
            let ret = await axios.post(`${SERVER_URL}email_auth/send_login/`, bodyFormData,
                {headers: {"Content-Type": "multipart/form-data" }})
            return ret.data
        } catch (e) {
            console.error(e.response)
            throw e
        }
    }

    getTokenFromAccount = async (email, access_token) => {
        const query = querystring.stringify({
            access_token,
            email,
            app: APP,
        })
        try {
            let ret = await axios.get(`${SERVER_URL}apis/register_by_token/email_auth/?${query}`)
            return ret.data
        } catch (e) {
            console.error(e.response)
            throw e
        }
    }
}