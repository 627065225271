import React, { useState, useEffect, useMemo } from "react";
import {
  Form,
  Dropdown,
  Button,
} from "semantic-ui-react";
import CardLayout from "./CardLayout";
import ReactTable from "react-table";
import PropTypes from "prop-types";

const CardPatientSearch = ({
  hnPreData,
  getPatientList,
  getNationality,
  hideCallBack,
  onSelectPatient,
  nationalitiesList,
  patientList,
  onOpenModPatientOldName,
  numberOfPage,
  currentPage,
  onPaginationChange,
  patientListLoading,
  isAPILoading,
  hideColumnsByAccessor,
  hideNationality,
  hideCitizenID
}) => {
  const [hn, setHn] = useState("");
  const [fullName, setFullName] = useState("");
  const [citizenId, setCitizenId] = useState("");
  const [nationality, setNationality] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [patientResult, setPatientResult] = useState([]);

  const [clickTimeout, setClickTimeout] = useState(null); // to distinguish between single click and double click
  const [patientSelected, setPatientSelected] = useState(null);

  const styles = {
    mainTable: { height: 420, backgroundColor: "#FFFFFF", overflowX: "auto" }
  };

  const handleOnClear = () => {
    setHn("");
    setFullName("");
    setCitizenId("");
    setNationality(null);
    setPatientResult([]);
  };

  const handleOnSearch = async () => {
    console.log("handleOnSearch True")
    // setIsLoading(true);
    await getPatientList({
      hn: hn,
      fullName: fullName,
      citizenId: citizenId,
      nationality: nationality,
      patientCurrentPage: 1,
    });
    console.log("handleOnSearch false")
    // setIsLoading(false);
  };

  useEffect(() => {
    getNationality();
  }, []);

  useEffect(() => {
    setPatientResult(patientList);
  }, [patientList]);

  useEffect(() => {
    setHn(hnPreData)
  }, [hnPreData])

  const handleOnChangeHN = e => {
    setHn(e.target.value);
  };

  const handleOnChangeFullName = (e, data) => {
    setFullName(data.value);
  };

  const handleOnChangeCitizenID = e => {
    setCitizenId(e.target.value);
  };

  const handleOnChangeNation = (e, { value }) => {
    setNationality(value);
  };

  const handleOnSelectPatient = () => {
    if (patientSelected) {
      onSelectPatient(patientSelected.original.id, patientSelected.original.hn, patientSelected.original.full_name);
      // hideCallBack();
    }
  };

  const handlePageChange = async (page) => {
    setIsLoading(true)
    await onPaginationChange({
      hn: hn,
      fullName: fullName,
      citizenId: citizenId,
      nationality: nationality,
      patientCurrentPage: page + 1
    });
    console.log("handlePageChange False")
    setIsLoading(false)
  }

  const allGridColumns = useMemo(() =>
    [
      {
        Header: "HN",
        accessor: "hn",
        minWidth: 70
      },
      {
        Header: "Citizen ID / Passport No.",
        accessor: "citizen_passport",
        style: { whiteSpace: "unset" },
        minWidth: 130
      },
      {
        Header: "ชื่อ นามสกุล(ไทย)",
        accessor: "full_name_th",
        style: { whiteSpace: "unset" },
        minWidth: 180
      },
      {
        Header: "ชื่อ นามสกุล(อังกฤษ)",
        accessor: "full_name_en",
        style: { whiteSpace: "unset" },
        minWidth: 180
      },
      {
        Header: "วัน/เดือน/ปีเกิด",
        accessor: "birthdate",
        minWidth: 100
      },
      {
        Header: "ชื่อมารดา",
        accessor: "mother",
        style: { whiteSpace: "unset" },
        minWidth: 180
      },
      {
        Header: "ที่อยู่",
        accessor: "address",
        style: { whiteSpace: "unset" },
        minWidth: 220
      },
      {
        Header: "เบอร์โทรศัพท์",
        accessor: "tel_num",
        minWidth: 120
      },
      {
        Header: "ชื่อเดิม",
        accessor: "old_name",
        style: { whiteSpace: "unset", textAlign: 'center' },
        minWidth: 50,
        Cell: (data) => {
          if (data.original.old_name) {
            return (
              <Button
                color='blue'
                icon='search'
                onClick={() => onOpenModPatientOldName(data.original.id, data.original.hn, data.original.full_name)}
              />
            )
          }
          return ""
        }
      }
    ]
    , [])

  const gridColumns = useMemo(() => {
    const columns = [...allGridColumns]
    return columns.filter(col => !(hideColumnsByAccessor || [])?.includes(col.accessor))
  }, [])

  console.log(gridColumns)

  return (
    <CardLayout
      titleText="ค้นหาผู้ป่วย"
      onClose={hideCallBack}
      toggleable={false}
      loading={patientListLoading}
    >
      <Form>
        <Form.Group inline>
          <Form.Field width={2}>
            <b>HN</b>
          </Form.Field>
          <Form.Input width={4} onChange={handleOnChangeHN} value={hn} />
          <Form.Field width={4}>
            <b>ชื่อ นามสกุล (ห้ามใส่คำนำหน้า)</b>
          </Form.Field>
          <Form.Input
            width={6}
            onChange={handleOnChangeFullName}
            value={fullName}
          />
        </Form.Group>
        <Form.Group inline>
          {
            hideCitizenID ? <Form.Field width={6}></Form.Field> :
              <>
                <Form.Field width={2}>
                  <b>Citizen ID</b>
                </Form.Field>
                <Form.Input
                  width={4}
                  onChange={handleOnChangeCitizenID}
                  value={citizenId}
                />
              </>
          }
          {
            hideNationality ? <Form.Field width={4}></Form.Field> :
              <>
                <Form.Field>
                  <b>สัญชาติ</b>
                </Form.Field>
                <Form.Field width={3}>
                  <Dropdown
                    className='block'
                    search
                    selection
                    clearable
                    placeholder="ทุกสัญชาติ"
                    options={nationalitiesList}
                    value={nationality}
                    onChange={handleOnChangeNation}
                  />
                </Form.Field>
              </>
          }

          <Form.Button color="blue" onClick={handleOnSearch}>
            ค้นหา
          </Form.Button>
          <Form.Button color="teal" onClick={handleOnClear}>
            Clear
          </Form.Button>
        </Form.Group>
      </Form>

      <ReactTable
        style={styles.mainTable}
        manual
        data={patientResult}
        pageSize={
          patientList ? (patientList.length < 10 ? 10 : patientList.length) : 10
        }
        showPageSizeOptions={false}
        page={currentPage - 1}
        pages={numberOfPage}
        onPageChange={handlePageChange}
        loading={isLoading || isAPILoading}
        getNoDataProps={() => {
          return { style: { display: "none" } };
        }}
        getTrProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            let background =
              rowInfo.index === (patientSelected ? patientSelected.index : null)
                ? "#CCE2FF"
                : "white";
            return {
              onClick: e => {
                setPatientSelected(rowInfo);
                if (clickTimeout !== null) {
                  if (
                    (patientSelected ? patientSelected.index : null) ===
                    rowInfo.index
                  ) {
                    clearTimeout(clickTimeout);
                    setClickTimeout(null);
                    onSelectPatient(rowInfo.original.id, rowInfo.original.hn, rowInfo.original.full_name);
                    // hideCallBack();
                  }
                } else {
                  setClickTimeout(
                    setTimeout(() => {
                      clearTimeout(clickTimeout);
                      setClickTimeout(null);
                    }, 500)
                  );
                }
              },
              style: {
                background: background
              }
            };
          } else {
            return {};
          }
        }}
        columns={gridColumns}
      ></ReactTable>
      <br />
      <Button color="blue" onClick={handleOnSelectPatient}>
        เลือก
      </Button>
    </CardLayout>
  );
};

CardPatientSearch.defaultProps = {
  hnPreData: '',
  patientList: [],
  nationalitiesList: [],
  getPatientList: () => { },
  getNationality: () => { },
  hideCallBack: () => { },
  onSelectPatient: () => { },
  onOpenModPatientOldName: () => { },
  onPaginationChange: () => { },
  currentPage: 1,
  hideColumnsByAccessor: [],
  hideNationality: false,
  hideCitizenID: false
};

CardPatientSearch.propTypes = {
  hnPreData: PropTypes.string,
  patientList: PropTypes.array,
  nationalitiesList: PropTypes.array,
  getPatientList: PropTypes.func,
  getNationality: PropTypes.func,
  hideCallBack: PropTypes.func,
  onSelectPatient: PropTypes.func,
  onOpenModPatientOldName: PropTypes.func,
  onPaginationChange: PropTypes.func,
  currentPage: PropTypes.number,
  hideColumnsByAccessor: PropTypes.array,
  hideNationality: PropTypes.bool,
  hideCitizenID: PropTypes.bool
};

export default React.memo(CardPatientSearch);
