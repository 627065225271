import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `REG/CardQuickHN.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import QmlWeb 1.0
import DGrid 1.0
import "../Common" as Common
import "../Common/Util.js" as Util


Common.CardLayout {
    id: _crdQuickHN
    property var NON_IDENTITY : 'N'
    property var NULL_ADDRESS : new Object({
        "owner_name": "",
        "relative": "",
        "no": "",
        "name": "",
        "town": "",
        "street": "",
        "road": "",
        "type":null,
        "city": null,
        "district": null,
        "province": null,
        "country": null,
        "tel_home": "",
        "tel_home_suffix": "",
        "tel_office": "",
        "tel_office_suffix": "",
        "tel_mobile": "",
        "email": "",
        "channel": "",
        "note": ""
    })
    signal quickHNCreated(var quickHN)

    title: "ลงทะเบียนฉุกเฉิน"
    hideCloseButton: false
    toggleCard: false
    headerColor: "blue"

    function clear(){
        txtHN.text = ''
        txtFirstName.text = ''
        txtLastName.text = ''
        rdgGender.clear()
        rdgNation.clear()
        form.clear()
    }

    function resetValue(){
        rmdQuickHN.hn                 = ''
        rmdQuickHN.citizen_passport   = ''
        rmdQuickHN.middle_name_th     = ''
        rmdQuickHN.first_name_en      = ''
        rmdQuickHN.middle_name_en     = ''
        rmdQuickHN.last_name_en       = ''
        rmdQuickHN.citizen_type       = NON_IDENTITY
        rmdQuickHN.home_address       = NULL_ADDRESS
        rmdQuickHN.present_address    = NULL_ADDRESS
        rmdQuickHN.relative_address   = NULL_ADDRESS
        rmdQuickHN.nickname_active    = false
    }
    
    content: [
        RestModel{
            id: rmdQuickHN

            property var hn
            property var citizen_passport   : ''
            property var middle_name_th     : ''
            property var first_name_en      : ''
            property var middle_name_en     : ''
            property var last_name_en       : ''
            property var citizen_type       : NON_IDENTITY
            property var home_address       : NULL_ADDRESS
            property var present_address    : NULL_ADDRESS
            property var relative_address   : NULL_ADDRESS
            property var nickname_active    : false

            property alias first_name_th    : txtFirstName.text
            property alias last_name_th     : txtLastName.text
            property alias gender           : rdgGender.value
            property alias nationality      : rdgNation.value

            url: "/apis/REG/quick-hn/"
            onSaved:{
                txtHN.text = hn
                this.createdHN = hn
                _crdQuickHN.quickHNCreated(hn)
                Util.alert('บันทึกเรียบร้อย', 'green', null, 3000, null)
            }

            onFailed: {
                form.showError(error, $context, $properties);
            }   
        },

        Grid{
            Row{
                Column{
                    className: "sixteen wide"
                    Form{
                        id: form
                        Message{
                            className: "error"
                        }
                        Fields{
                            className: "inline"
                            Field{
                                className: 'two wide'
                                Text{
                                    text: "HN :"
                                }
                            }
                            Field{
                                className: 'five wide'
                                LabelTag{
                                    id: txtHN
                                    className: 'fluid'
                                    text: ''
                                }
                            }
                        }
                        Fields{
                            className: "inline"
                            RadioGroup{
                                id: rdgGender
                                group: 'gender'
                            }
                            Field{
                                className: 'two required wide'
                                label: "เพศ"
                                RadioButton{
                                    displayNone: true
                                    group: 'gender'
                                }
                            }
                            Field{
                                RadioButton{
                                    group: 'gender'
                                    text: "ชาย"
                                    value: 'M'
                                }
                            }
                            Field{
                                RadioButton{
                                    group: 'gender'
                                    text: "หญิง"
                                    value: 'F'
                                }
                            }
                            Field{
                                RadioButton{
                                    group: 'gender'
                                    text: "เด็กชาย"
                                    value: 'CM'
                                }
                            }
                            Field{
                                RadioButton{
                                    group: 'gender'
                                    text: "เด็กหญิง"
                                    value: 'CF'
                                }
                            }
                        }
                        Fields{
                            className: "inline"
                            RadioGroup{
                                id: rdgNation
                                group: 'nationality'
                            }
                            Field{
                                className: 'two required wide'
                                label: "สัญชาติ"
                                RadioButton{
                                    displayNone: true
                                    group: 'nationality'
                                }
                            }
                            Field{
                                RadioButton{
                                    group: 'nationality'
                                    text: "ไทย"
                                    value: 'T'
                                }
                            }
                            Field{
                                RadioButton{
                                    group: 'nationality'
                                    text: "ต่างชาติ"
                                    value: 'F'
                                }
                            }
                        }
                        Fields{
                            className: "inline"
                            Field{
                                className: 'two wide'
                                Text{
                                    text: "ชื่อ :"
                                }
                            }
                            Field{
                                className: 'five wide'
                                TextBox{
                                    id: txtFirstName
                                    doc_label: "ชื่อผู้ป่วยฉุกเฉิน"
                                }
                            }
                            Field{
                                className: 'one wide'
                            }
                            Field{
                                className: 'two wide'
                                Text{
                                    text: "นามสกุล :"
                                }
                            }
                            Field{
                                className: 'five wide'
                                TextBox{
                                    id: txtLastName
                                    doc_label: "นามสกุลผู้ป่วยฉุกเฉิน"
                                }
                            }
                        }
                    }
                }
            }

            Row{
                Column{
                    className: "three wide"
                    Button{
                        className: "green fluid"
                        text: "Save"
                        doc_label: 'บันทึกผู้ป่วยฉุกเฉิน'
                        onClicked:{
                            if (txtHN.text==''){
                                form.clear()
                                resetValue()
                                rmdQuickHN.create()
                            }
                            else{
                                Util.alert('ผู้ป่วยมี HN แล้ว', 'red', null, 3000, null)
                            }
                        }
                    }
                }
                Column{
                    className: "three wide"
                    Button{
                        className: "teal fluid"
                        text: "Clear"
                        doc_label: 'ล้างผู้ป่วยฉุกเฉิน'
                        onClicked:{
                            clear()
                        }
                    }
                }
            }
        }
    ]
}


        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/REG/CardQuickHN.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}