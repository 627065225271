import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Grid, Button, TextArea, Form, Dimmer, Loader, Segment } from 'semantic-ui-react';

/**
 * A modal display the settings of shift on the given division.
 */
export default class ModConfirmCNMI extends React.Component {
  static propTypes = {
    textConTent: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number, PropTypes.element]),
    titleName:  PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
    size: PropTypes.string,
    approveButtonText: PropTypes.string,
    denyButtonText: PropTypes.string,
    approveButtonColor: PropTypes.string,
    denyButtonColor: PropTypes.string,
    buttonClassName: PropTypes.string,
    loading: PropTypes.bool,
    openModal: PropTypes.bool,
    onApprove: PropTypes.func,
    onDeny: PropTypes.func, 
    basic: PropTypes.bool,
    // loading: PropTypes.bool,
    // Handle closeOnDimmerClick event
    onCloseWithDimmerClick: PropTypes.func,
  };

  static defaultProps = {
    textConTent: null,
    content: null,
    backgroundColor: null,
    titleName: 'แจ้งเตือน!!!',
    size: 'mini',
    approveButtonText: 'ใช่',
    denyButtonText: 'ไม่',
    approveButtonColor: 'green',
    denyButtonColor: 'red',
    buttonClassName: 'centered five wide',
    loading: false,
    openModal: false,
    basic: true,
    // loading: false,
    testId: '',
    // Handle closeOnDimmerClick event
    onCloseWithDimmerClick: () => {},
  };

  constructor(props) {
    super(props);

  }

  render() {
    const {
      textConTent,
      content,
      titleName,
      size,
      basic,
      titleColor,
      approveButtonText,
      denyButtonText,
      approveButtonColor,
      denyButtonColor,
      buttonClassName,
      loading,
      openModal,
      backgroundColor,
      testId,
      className
    } = this.props;

    let headerColor = titleColor

    if(!titleColor && !backgroundColor){
      headerColor = 'blue'
    }

    return (
      <Modal
        open={openModal}
        size={size}
        testid={'modConfirm' + testId}
        closeOnDimmerClick={true}
        onClose={this.props.onCloseWithDimmerClick}
        className={className}
      >
        <Segment className={'inverted ' + headerColor} style={{ ...styles.header, backgroundColor: backgroundColor }}>{titleName}</Segment>
        <Segment basic loading={this.props.loading} style={styles.content}>
          {textConTent}
          {content}
          <br />
          <Grid>
            <Grid.Column
              style={{ display: !approveButtonText ? 'none' : null }}
              className={buttonClassName}
            >
              <Button
                basic={basic}
                fluid
                color={approveButtonColor}
                onClick={this.props.onApprove}
              >
                {approveButtonText}
              </Button>
            </Grid.Column>
            <Grid.Column
              style={{ display: !denyButtonText ? 'none' : null }}
              className={buttonClassName}
            >
              <Button
                basic={basic}
                fluid
                color={denyButtonColor}
                onClick={this.props.onDeny}
              >
                {denyButtonText}
              </Button>
            </Grid.Column>
          </Grid>
        </Segment>
      </Modal>
    );
  }
}

const styles = {
  header: {
    borderRadius: 0,
    fontWeight: 'bold'
  },
  content: {
    lineHeight: '1.5em'
  }
}
