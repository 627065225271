import React from "react";
import PropTypes from "prop-types";
import { Modal, Segment, Header, Button, Icon } from "semantic-ui-react";
import { SubScannerForm } from "../common";

const ModEditDocument = React.forwardRef((props, ref) => {
  return (
    <Modal
      testid={"modEditDocument" + props.testId}
      open={props.open}
      size={props.size}
      closeOnDimmerClick={true}
      onClose={props.onClose}
    >
      <Segment
        className={"inverted " + props.headerColor}
        style={styles.header}
      >
        {props.headerText}
        {props.closeIcon &&
          <Icon name="close" style={{ float: "right", cursor: "pointer" }} onClick={props.onIconClose} />
        }
      </Segment>
      <Segment basic loading={props.isLoading}>
        <SubScannerForm
          data={props.data}
          onGetDocumentTypeOptions={props.onGetDocumentTypeOptions}
          divisionOptions={props.divisionOptions}
          onCancelApprove={props.onCancelApprove}
          onEditApprove={props.onEditApprove}
          cancelDocError={props.cancelDocError}
          editDocError={props.editDocError}
          loading={props.loading}
          hideButtonCancel={props.hideButtonCancel}
          buttonEditTextTh={props.buttonEditTextTh}
          showDocumentCategory={props.showDocumentCategory}
          categoryOptions={props.categoryOptions}
          onGetSearchOptions={props.onGetSearchOptions}
        />
      </Segment>
    </Modal>
  );
});

const styles = {
  header: {
    borderRadius: 0,
    fontWeight: "bold"
  }
};

ModEditDocument.propTypes = {
  testId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.string,
  headerColor: PropTypes.string,
  headerText: PropTypes.string,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object,
  onGetDocumentTypeOptions: PropTypes.func,
  divisionOptions: PropTypes.array,
  categoryOptions: PropTypes.array,
  onCancelApprove: PropTypes.func,
  cancelDocError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  editDocError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  loading: PropTypes.bool,
  onEditApprove: PropTypes.func,
  closeIcon: PropTypes.bool,
  hideButtonCancel: PropTypes.bool,
  buttonEditTextTh: PropTypes.bool,
  showDocumentCategory: PropTypes.bool,
  onGetSearchOptions: PropTypes.func,
  onIconClose: PropTypes.func
};

ModEditDocument.defaultProps = {
  testId: "",
  size: "tiny",
  headerColor: "yellow",
  headerText: "แก้ไข",
  isLoading: false,
  onClose: () => { },
  data: {},
  onGetDocumentTypeOptions: () => [null, null],
  divisionOptions: [],
  categoryOptions: [],
  onCancelApprove: () => { },
  cancelDocError: null,
  editDocError: null,
  loading: false,
  onEditApprove: () => { },
  onGetSearchOptions: () => { },
  closeIcon: false,
  hideButtonCancel: false,
  buttonEditTextTh: false,
  showDocumentCategory: false,
  onIconClose: () => { }
};

export default React.memo(ModEditDocument);
