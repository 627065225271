import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `REG/CardOpenEncounter.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import QmlWeb 1.0
import DGrid 1.0
import '../TPD' as TPD
import '../Common/Util.js' as Util
import '../Common' as Common
import '../BIL' as BIL
import '../REG' as REG

/*
 * There is two way to use this card

 1. List/Edit/New encounter for patientId
     - call fetchPatientEncounter(patient_id)
 2. Edit existing encounter for encounterId
     - call setEncounterEdit(patient_id, encounter_id)
 */

Common.CardLayout {
    id: crdOpenEncounter

    property var ER_DIVISION : 12
    property var AUTO_DOCTOR : 'AUTO'

    property var patientId
    property var encounterId
    property int targetEncounter
    property int gridHeight: 150
    property var hideChiefComplaint_
    property bool viewOnly : false // show only grid
    property bool readOnly : false // cannot edit division and doctor
    property bool editTargetOnly : false
    signal encounterFetched()
    signal encounterCreated(int encounterId)
    signal encounterSelect(int encounterId)

    title: 'Encounter'

    property var ENCOUNTER_ACTION: new Object({
        OPD_NEW: 'OPD_NEW',
        OPD_EDIT: 'OPD_EDIT',
    })

    function clear(){
        clearEditor()
        patientId = 0
        targetEncounter = 0
        grdEncounter.model = []
    }

    function clearEditor() {
        form.clear()
        encounterId = ''
        txtEncounter.text = '-'
        txtLocation.text = '-'
        rmdEncounter.action = ENCOUNTER_ACTION.OPD_NEW
        // cboType.clear()
        cboDivision.clear()
        cboDoctor.clear()
        cboChiefComplaints.text = ''
        cboRemark.clear()
        txtNote.text = ''
        cboUnderlyingDisease.clear()
        grdEncounter.clearSelection()
    }

    function refresh(){
        if (patientId) {
            fetchPatientEncounter(patientId)
        }
    }

    function fetchPatientEncounter(_id){
        rmdEncounterList.items = []
        clearEditor()

        subADRNote.patient_id = _id
        subADRNote.refresh()

        if (patientId !== _id) {
            patientId = _id
        }

        var query = {}
        query.unexpired_only = true
        query.patient = _id
        rmdEncounterList.query = query
        rmdEncounterList.fetch()
    }

    function setEncounterEdit(patient_id, encounter_id) {
        targetEncounter = encounter_id
        fetchPatientEncounter(patient_id)
    }

    function isAllEncounterEdited() {
        // check is all encounter in grid is filled with division and doctor
        var isAllEdited = true
        if (grdEncounter.model.length === 0) {
            return false
        }
        grdEncounter.model.forEach(function(row){
            if (!row.division || !row.doctor) {
                isAllEdited = false
            }
        })
        return isAllEdited
    }

    /**
    * For open Encounter with ER case. Usually called after CardQuickHN
    * save successfully (onQuickHNCreated)
    * This method will fetch for ER_DIVISION configure before actually open a VN
    **/
    function openEmergencyEncounter() {
        crdOpenEncounter.loading = true
        rmdConstanceERDivision.fetch()
    }

    RestModel{
        // Rest model for fetch only. It fetches constance config
        id: rmdConstanceERDivision
        url:'/apis/core/constance/?list=REG_ER_DIVISION_ID'
        property var result
        onFetched: {
            ER_DIVISION = result.REG_ER_DIVISION_ID
            rmdEncounter.patient = patientId
            rmdEncounter.division = ER_DIVISION
            rmdEncounter.doctor = AUTO_DOCTOR
            rmdEncounter.action = ENCOUNTER_ACTION.OPD_NEW
            rmdEncounter.url = '/apis/core/encounter/'
            rmdEncounter.create()
        }

        onFailed: {
            crdOpenEncounter.loading = false
        }
    }

    RestModel{
        id: rmdPrintVisitSlip
        url:'/apis/REG/encounter/print/'
        property bool success
        property string pdf_b64data
        property var error

        onFetched:{
            if (success) {
                if(pdf_b64data.length > 0){
                    Util.displayPDF(pdf_b64data)
                }
                else{
                    Util.alert('พิมพ์สำเร็จ', 'green')
                }
            }
            else{
                Util.alert(error.message, 'red')
            }
        }
    }

    RestModel {
        id: rmdEncounter
        url:'/apis/core/encounter/'
        property var pk
        property var type: 'OPD'
        property var action: ENCOUNTER_ACTION.OPD_NEW
        property var drug_allergy
        property var ended
        property var patient
        property alias division: cboDivision.value
        property alias doctor: cboDoctor.value
        property alias chief_complaints: cboChiefComplaints.text
        property alias remark: cboRemark.value
        property alias note: txtNote.text
        property alias underlying_disease: cboUnderlyingDisease.value
        property alias number: txtEncounter.text
        property alias location: txtLocation.text
        property alias zone: cboZone.value
        property alias patient_case: cboPatientCase.value

        onSaved:{
            crdOpenEncounter.loading = false
            form.clear()
            Util.alert('บันทึกเรียบร้อย', 'blue', null, null, function() {
                encounterCreated(rmdEncounter.pk)
                setEncounterEdit(patient, rmdEncounter.pk)
                crdOpenEncounter.loading = false
            })
        }
        onFailed: {
            crdOpenEncounter.loading = false
            form.showError(error, $context, $properties)
        }
        Component.onCompleted: {
            form.setupValidate($context, $properties)
        }
    }

    RestModel{
        id: rmdEncounterList
        url: '/apis/core/encounter/'
        property alias items: grdEncounter.model

        onFetched: {
            encounterFetched()
            if (targetEncounter !== 0) {
                grdEncounter.model.forEach(function(row) {
                    if (targetEncounter === row.pk) {
                        grdEncounter.selectRow(row.storekey)
                    }
                })
                targetEncounter = 0
            }
        }
    }

    RestModel{
        id: rmdPatientCase
        url: '/apis/core/clinicalterm/?type=PATIENT_CASE'
        property alias items: cboPatientCase.items
    }

    content: [
        Dom{
            DGrid {
                id: grdEncounter
                doc_label: 'รายการ Encounter'
                height: gridHeight
                columns:[
                    DColumn {label: 'Encounter'; field: 'number'; width:10},
                    DColumn {label: 'Division'; field: 'division_name'; width:15},
                    DColumn {label: 'Doctor'; field: 'doctor_name'; width:25},
                    DColumn {label: 'Status'; field: 'status_name'; width:15},
                    DColumn {doc_label: 'เวลาเปิด Encounter'; label: 'Create'; field: 'created'; width:20},
                    DColumn {doc_label: 'เวลาแก้ไข Encounter ล่าสุด'; label: 'Update'; field: 'edited'; width:20}
                ]
                onSelected:{
                    if (Object.keys(grdEncounter.selectedRow).length !== 0){
                        encounterId = grdEncounter.selectedRow.id
                        txtEncounter.text = grdEncounter.selectedRow.number
                        txtLocation.text = grdEncounter.selectedRow.location
                        cboDivision.value = grdEncounter.selectedRow.division
                        cboPatientCase.value = grdEncounter.selectedRow.patient_case
                        cboZone.value = grdEncounter.selectedRow.zone
                        cboDoctor.value = grdEncounter.selectedRow.doctor
                        cboRemark.value = grdEncounter.selectedRow.remark
                        cboChiefComplaints.text = grdEncounter.selectedRow.chief_complaints
                        txtNote.text = grdEncounter.selectedRow.note
                        cboUnderlyingDisease.value = grdEncounter.selectedRow.underlying_disease
                        rmdEncounter.action = ENCOUNTER_ACTION.OPD_EDIT
                        encounterSelect(grdEncounter.selectedRow.id)
                    }
                }

            }

            Br{}

            Form{
                displayNone: viewOnly
                inline: true
                id:form
                Message{
                    className: 'error'
                }

                Fields{
                    Field{
                        className: 'four wide'
                        style: 'padding-right: 0px;'
                        LabelTag{
                            style: new Object({
                                border: 'solid #063e82',
                                zIndex: 10,
                                background: '#063e82',
                                color: 'white',
                                fontWeight: 'bold',
                                textAlign: 'center'
                            })
                            className: 'large fluid'
                            text: 'Encounter: '
                        }
                    }
                    Field{
                        className: 'five wide'
                        style: 'margin-left: -10px;'
                        LabelTag{
                            id: txtEncounter
                            style: new Object({
                                border: 'solid #063e82',
                                background: 'white',
                                color: '#063e82',
                                fontWeight: 'bold',
                                textAlign: 'center'
                            })
                            className: 'large fluid'
                            text: '-'
                        }
                    }
                    Field{
                        className: 'two wide'
                    }
                    Field{
                        className: 'six wide'
                        style: 'margin-left: -10px;'
                        LabelTag{
                            id: txtLocation
                            style: new Object({
                                border: 'solid #063e82',
                                background: 'white',
                                color: '#063e82',
                                fontWeight: 'bold',
                                textAlign: 'center'
                            })
                            className: 'large fluid'
                            text: '-'
                            doc_label: 'สถานที่'
                            doc_skip: false
                        }
                    }
                }
                // Fields{
                //     Field{
                //         className:'four wide'
                //         Text{
                //             text:'ประเภทการรักษา'
                //         }
                //     }
                //     Field{
                //         className:'twelve wide'
                //         ComboBox{
                //             id: cboType
                //         }
                //     }
                // }

                Fields{
                    displayNone: readOnly

                    Field{
                        className:'four wide'
                        Text{
                            text:'แผนก'
                        }
                    }
                    Field{
                        className:'twelve wide'
                        ComboBox{
                            id: cboDivision
                            doc_label: 'แผนก'
                            search: true
                            emptyItem: 'ไม่ระบุ'
                            fullTextSearch: true
                            optionTextField: 'name_code'
                            RestModel{
                                id: divisionRest
                                url:'/apis/core/division/'
                                query: new Object({
                                    for_opd_encounter: true
                                })
                                property alias items: cboDivision.items
                            }
                            Component.onCompleted:{
                                divisionRest.fetch()
                            }
                            onChanged: {
                                rmdZone.query = {
                                    type: 'ZONE',
                                    parent: cboDivision.value
                                }
                                rmdZone.fetch()
                            }
                        }
                    }
                }

                Fields {
                    id: flsZone
                    displayNone: true
                    Field {
                        className: 'four wide'
                        Text {
                            text: 'Zone'
                        }
                    }
                    Field {
                        className: 'twelve wide'
                        ComboBox {
                            id: cboZone
                            doc_label: 'โซน'
                            search: true
                            fullTextSearch: true
                            optionTextField: 'name_code'
                            emptyItem: 'ไม่เลือก'

                            RestModel {
                                id: rmdZone
                                url: '/apis/core/division/'
                                property alias items: cboZone.items

                                onFetched: {
                                    flsZone.displayNone = _.isEmpty(rmdZone.items)
                                }
                            }
                        }
                    }
                }

                Fields{
                    displayNone: readOnly
                    Field{
                        className:'four wide'
                        Text{
                            text:'แพทย์'
                        }
                    }
                    Field{
                        className:'twelve wide'
                        ComboBox{
                            id: cboDoctor
                            doc_label: 'แพทย์'
                            search: true
                            fullTextSearch: true
                            emptyItem: 'ไม่ระบุ'
                            optionTextField: 'name_code'
                            RestModel{
                                id: doctorRest
                                url:'/apis/core/doctor/?type=normal&limit=5000'
                                property alias items: cboDoctor.items
                                onFetched:{
                                    cboDoctor.items.splice(0, 0, {
                                        'id': 'AUTO',
                                        'name_code': 'Auto Assigned'
                                    })
                                    cboDoctor.items = cboDoctor.items
                                }
                            }
                            Component.onCompleted:{
                                doctorRest.fetch()
                            }
                        }

                    }
                }

                Fields {
                    Field {
                        className: 'four wide'
                        Text{
                            text: 'ประเภทผู้ป่วย'
                        }
                    }
                    Field {
                        className: 'twelve wide'
                        ComboBox {
                            id: cboPatientCase
                            style: 'width: 100%'
                            doc_label: 'ประเภทผู้ป่วย'
                            search: true
                            fullTextSearch: true
                            Component.onCompleted:{
                                rmdPatientCase.fetch()
                            }
                        }
                    }
                }

                Fields{
                    displayNone: hideChiefComplaint_ || editTargetOnly
                    Field{
                        className:'four wide'
                        Text{
                            text:'อาการเบื้องต้น'
                        }
                    }
                    Field{
                        className:'twelve wide'
                        AutoCompleteBox {
                            id: cboChiefComplaints
                            RestModel {
                                id: rmdChiefComplaint
                                url: '/apis/core/clinicalterm/'
                                query: {
                                    type: 'A'
                                }
                                property alias items: cboChiefComplaints.options
                            }
                            Component.onCompleted: {
                                rmdChiefComplaint.fetch()
                            }
                        }
                    }
                }
                Fields{
                    Field{
                        className:'four wide'
                        Text{
                            text:'จุดสังเกต'
                        }
                    }
                    Field{
                        className:'twelve wide'
                        ComboBox{
                            id: cboRemark
                            doc_label: 'จุดสังเกต'
                            search: true
                            fullTextSearch: true
                            allowAdditions: true
                            multipleSelection:true
                            useTextValue:true
                            forceSelection: true
                            RestModel{
                                id: remarkRest
                                url:'/apis/core/clinicalterm/'
                                property alias items: cboRemark.items
                                query: {
                                    return {
                                        type: 'B'
                                    }
                                }
                            }
                            Component.onCompleted:{
                                remarkRest.fetch()
                            }
                        }
                    }
                }
                Fields{
                    Field{
                        className:'four wide'
                        Text{
                            text:'หมายเหตุ'
                        }
                    }
                    Field{
                        className:'twelve wide'
                        TextBox{
                            id: txtNote
                            doc_label: 'หมายเหตุ'
                        }
                    }
                }

                Fields{
                    displayNone: editTargetOnly
                    Field{
                        className:'four wide'
                        Text{
                            text:'โรคประจำตัว'
                        }
                    }
                    Field{
                        className:'twelve wide'
                        ComboBox{
                            id: cboUnderlyingDisease
                            doc_label: 'โรคประจำตัว'
                            search: true
                            fullTextSearch: true
                            allowAdditions: true
                            multipleSelection: true
                            useTextValue: true
                            forceSelection: true
                            RestModel{
                                id: underlyingRest
                                url:'/apis/core/clinicalterm/'
                                property alias items: cboUnderlyingDisease.items
                                query: {
                                    return {
                                        type: 'D'
                                    }
                                }
                            }
                            Component.onCompleted:{
                                underlyingRest.fetch()
                            }
                        }
                    }
                }
            }

            TPD.SubADRNote{
                displayNone: viewOnly
                id: subADRNote

                onSaveAllergy:{
                    if (success){
                        rmdEncounter.patient = patientId
                        if (!encounterId) {
                            rmdEncounter.url = '/apis/core/encounter/'
                            rmdEncounter.create()
                        }
                        else {
                            rmdEncounter.url = '/apis/core/encounter/' + encounterId + '/'
                            rmdEncounter.save()
                        }
                    }
                    else {
                        crdOpenEncounter.loading = false
                    }
                }
            }

            Br{
                displayNone: viewOnly
            }

            Form{
                displayNone: viewOnly
                inline: true
                Fields{
                    className: 'inline'
                    Field{
                        className: 'four wide'
                        Button {
                            text: encounterId? 'แก้ไข Encounter' : 'บันทึก Encounter ใหม่'
                            className: 'fluid'
                            backgroundColor: encounterId? 'orange' : 'green'
                            onClicked: {
                                if (readOnly && encounterId) {
                                    alert('ไม่มีสิทธิในการแก้ไข')
                                    return
                                }

                                if(subADRNote.validate()==true){
                                    crdOpenEncounter.loading = true
                                    subADRNote.save()
                                }
                            }
                        }
                    }

                    Field{
                        className:'four wide'
                        Button{
                            text:'ล้างหน้าจอ-เพิ่มEncounter'
                            className:'teal fluid'
                            onClicked:{
                                clearEditor()
                            }
                        }
                    }

                    Field{
                        className:'two wide'
                        Button{
                            text:'ค่าใช้จ่าย'
                            className:'blue fluid'
                            enabled: encounterId ? true : false
                            onClicked: {
                               crdOtherBill.refresh()
                               modOtherBill.show()
                            }
                        }
                    }

                    Field{
                        className:'four wide'
                        Buttons {
                            className: 'blue'
                            Button {
                                text:'พิมพ์ Visit Slip'
                                onClicked:{
                                    var query = {}
                                    query.patient_id = crdOpenEncounter.patientId
                                    rmdPrintVisitSlip.pdf_b64data = ''
                                    rmdPrintVisitSlip.query = query
                                    rmdPrintVisitSlip.fetch()
                                }
                            }
                            Dom {
                                id: domDropDown
                                className: 'floating dropdown icon button'
                                Dom { className: 'dropdown icon' }
                                Dom {
                                    className: 'menu'
                                    Dom {
                                        className: 'item'
                                        htmlAttr: new Object({dataValue: 'PREVIEW'})
                                        text: 'Preview Visit Slip'
                                    }
                                }
                                Component.onCompleted: {
                                    $(domDropDown.dom).dropdown({
                                        onChange: function() {
                                            var query = {}
                                            query.patient_id = crdOpenEncounter.patientId
                                            query.pdf = true
                                            rmdPrintVisitSlip.query = query
                                            rmdPrintVisitSlip.fetch()
                                        }
                                    })
                                }
                            }
                        }
                    }

                    Field{
                        className:'two wide'
                        Button{
                            text: _('ยกเลิก')
                            className:'red fluid'
                            enabled: encounterId ? true : false
                            onClicked: {
                                modCancel.show()
                            }
                            Common.ModConfirm {
                                id: modCancel
                                titleName: _('ระบุเหตุผลยกเลิก Encounter')
                                content:[
                                    Form{
                                        id: frmNote
                                        Field {
                                            label: 'Username'
                                            TextBox {
                                                id: txtUsername
                                                inputName: 'username'
                                                doc_label: 'username'
                                                placeholder: 'Username'
                                            }
                                        }
                                        Field {
                                            label: 'Password'
                                            TextBox {
                                                id: txtPassword
                                                inputName: 'password'
                                                doc_label: 'password'
                                                inputType: 'password'
                                                placeholder: 'Password'
                                            }
                                        }
                                        Field{
                                            label: _('เหตุผลการยกเลิก')
                                            TextBox {
                                                id: txtNote
                                                doc_label: 'note'
                                            }
                                        }
                                        Message {
                                            className: 'error'
                                        }
                                    }
                                ]
                                onApprove:{
                                    rmdCancelEncounter.url = '/apis/core/encounter/' + encounterId + '/'
                                    rmdCancelEncounter.remove()
                                }
                                onDeny:{
                                    modCancel.hide()
                                }
                                onHidden:{
                                    frmNote.clear()
                                    txtUsername.text = ''
                                    txtPassword.text = ''
                                    txtNote.text = ''
                                }
                                RestModel {
                                    id: rmdCancelEncounter
                                    property alias note: txtNote.text
                                    property alias username: txtUsername.text
                                    property alias password: txtPassword.text
                                    property var csrfmiddlewaretoken: DJANGO.CSRF_TOKEN
                                    onDestroy: {
                                        modCancel.hide()
                                        Util.alert(_('ยกเลิกสำเร็จ'), 'green')
                                        crdOpenEncounter.refresh()
                                    }
                                    onFailed: {
                                        Util.danger(_('ไม่สามารถยกเลิกได้'))
                                        frmNote.showError(error, $context, $properties);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            Modal {
                id: modOtherBill
                BIL.CardOtherBill {
                    id: crdOtherBill
                    encounter_id: crdOpenEncounter.encounterId
                    product_group: 'MISC'
                    inModal: true
                }
            }
        }
    ]
}

        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/REG/CardOpenEncounter.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}