import React, { useRef, useEffect, useState } from "react";
import { Menu, Label, Segment, Radio, Checkbox, List, Loader, Dimmer } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Remarkable } from "remarkable";
import Parser from "html-react-parser";
import { Button, Form } from "semantic-ui-react";
import * as Common from "../../common"

const fontSize = "12px";
var md = new Remarkable();
const parse = mdtext => Parser(md.render(mdtext));

//  Form --> Thread --> Block (in QuesctionSection/Diag) --> executeAnswer --> Form

export const prepareForm = diagRule => {
  var obj = JSON.parse(diagRule.content);
  for (var id in obj) {
    obj[id].selected = id == 0 ? true : false
    obj[id].extra_finished = Object.keys(obj[id]).includes("extra_items") && 
                              Array.isArray(obj[id].extra_items) && 
                              obj[id].extra_items.length > 0 ? false : true
    if (!obj[id].extra_finished) {
      for (var item of obj[id].extra_items) {
        if (["Text", "Integer", "Decimal", "Select"].includes(item.type)) {
          if (Object.keys(item).includes("default")) {
            if (item.default) {
              item.value = item.default
            }
            else {
              item.value = ""
            }
          } else {
            item.value = ""  // put empty string if there is no default
          }
        } else if (item.type === "MultiSelect") {
          item.value = []
        }
      }
    }
  }
  return obj
}

export const updateThread = (output, formlist, node) => {
  // First display the extra items
  // if (Object.keys(node).includes("extra_items")){
  if (Object.keys(node).includes("extra_items") && Array.isArray(node["extra_items"]) && node["extra_items"].length > 0) {
    output.push({ id: node.id, display: "extra_intro", node: node })
    output.push({ id: node.id, display: "extra", node: node })
    if (!node.extra_finished) {
      return output
    }
  }
  
  // Display text of the node itself
  output.push(Object.assign({ display: "text" }, node));

  // Display children
  var selected = formlist.filter(
    item => item.parent_id === node.id && item.selected === true
  );
  if (selected.length === 0) { // no child is selected
    var children = formlist.filter(item => item.parent_id === node.id);
    for (var c of children) { 
      output.push(Object.assign({display: "choices" }, c));
    }    
  } else if (selected.length === 1) { // one child is selected
    // Recursively follow the node until no child is selected
    output.push(Object.assign({ display: "choices" }, selected[0]))
    output = updateThread(output, formlist, selected[0]);
  } else {}    // This case should not exists

  return output;
};

const Block = ({item, index, selectedAnswer}) => {
  return (
    item.display === "text" ?
      <div key={index} width="100%" className="block-display-text" style={{ fontSize: fontSize }} >
        {parse(item.text)}
      </div> :
    item.display === "choices" && item.selected == true ?
      <div key={index} width="100%" className="block-display-choices-true" style={{ fontSize: fontSize }} >
        {item.condition}
      </div> :
    item.display === "choices" && item.selected == false ?
      <div key={index} width="100%" className="block-display-choices-false" style={{ fontSize: fontSize }}
        onClick={selectedAnswer({ id: item.id, selected: true })}>
        {item.condition}
      </div> :
    item.display === "extra_intro" ?
      <div key={index} width="100%" className="block-display-extra-intro" style={{ fontSize: fontSize }} >
        {item.node.extra_intro ? item.node.extra_intro : "ข้อมูลเพิ่มเติม"}
      </div> :
    item.display === "extra" ?
      <Extra item={item} selectedAnswer={selectedAnswer} /> :
      <div key={index}> </div>
  )
}

const checkValid = (items) => {

  try {
    items.forEach((ex, index) => {
      if (ex.type === "Text") {
        if (!(ex.nullOk || ex.value.length > 0)) {
          throw false
        }
      } else if (ex.type === "Integer") {
        if (!(ex.nullOk || ex.value.match(/^-{0,1}\d+$/))) {
          throw false
        }
      } else if (ex.type === "Decimal") {
        if (!( ex.nullOk || ex.value.match(/^-{0,1}\d+$/) || ex.value.match(/^\d+\.\d+$/))) {
          throw false
        }
      } else if (ex.type === "Select") {
        if (!(ex.nullOk || ex.value)) {
          throw false
        }
      } else if (ex.type === "MultiSelect") {
        if (!(ex.nullOk || ex.value.length > 0)) {
          throw false
        }
      }
    })
  } catch (e) {
    return false
  }
  return true
}

const appendValid = (items) => {
  let valid = true
  let invalidField = []
  items.forEach((ex, index) => {
    if(!ex.value) {
      ex.value = ""
    }
    if (ex.type === "Text") {
      ex.validated = ex.nullOk || ex.value.length > 0
      ex.validated_text = ex.validated ? "" : "กรุณากรอกข้อมูล ข้อมูลนี้จำเป็น"
    } else if (ex.type === "Integer") {
      ex.validated = ex.nullOk || ex.value.match(/^-{0,1}\d+$/) ? true : false
      ex.validated_text = ex.validated ? "" : "กรุณากรอกเลขจำนวนเต็มไม่มีทศนิยม"
    } else if (ex.type === "Decimal") {
      ex.validated = ex.nullOk || ex.value.match(/^-{0,1}\d+$/) || ex.value.match(/^\d+\.\d+$/) ? true : false
      ex.validated_text = ex.validated ? "" : "กรุณากรอกตัวเลข (มีจุดทศนิยมได้)"
    } else if (ex.type === "Select") {
      ex.validated = ex.nullOk || ex.value ? true : false
      ex.validated_text = ex.validated ? "" : "กรุณาเลือกข้อมูล"
    } else if (ex.type === "MultiSelect") {
      ex.validated = ex.nullOk || ex.value.length > 0 ? true : false
      ex.validated_text = ex.validated ? "" : "กรุณาเลือกข้อมูล"
    }else {
      ex.validated = true
      ex.validated_text = ""
    }
    if(!ex.validated){
      invalidField.push(ex.label)
    }
    valid = valid && ex.validated
  })
  return [valid, invalidField]

}


const Extra = ({item, selectedAnswer}) => {
  let [validated, invalidField] = appendValid(item.node.extra_items)
  const [showValidatePopup, setShowValidatePopup] = React.useState(false)


  const AnswerMutipleSelect = props => { 
    return ( 
      <>
        { props.value.map((multi, index) => (
          <div key={index} className="extra-choices"> &nbsp;&nbsp; &#10003;&nbsp; {multi ? multi : "ไม่ระบุ"} </div>
        )) 
        }
      </>
    )
  }

  return ( 
    <div>
      <Common.ModInfo 
      type="error"
      open={showValidatePopup} 
      titleName="กรุณากรอกข้อมูลต่อไปนี้ให้ครบถ้วน"
      alertText={(
        <div>
          <List as='ul' style={{ textAlign: "left" }}>
            {invalidField.map((item) => {
              return (
                <List.Item as='li'>
                  {item}
                </List.Item>
              )
            })}
          </List>
        </div>
      )}
      onApprove={() => setShowValidatePopup(false)}
      onClose={() => setShowValidatePopup(false)}
      />
      {item.node.extra_items.map((ex, index, extra_array) => {
        if(ex.type === "Modal"  ){
          return <></>
        }
        return (
          <div key={index}>
            <div  style={{ marginTop: (index === 0 ? '3px' : '15px'), marginBottom: '10px',}}><div dangerouslySetInnerHTML={{ __html: ex.label }} /></div>
            {!item.node.extra_finished ? 
                
                (["Text", "Integer", "Decimal"].includes(ex.type) ?
                <div>
                  <Form.Input style={{width: "100%"}}
                    onChange={selectedAnswer({ id: item.id, extra: { index: index }})} 
                    value={ex.value ?  ex.value : ""} 
                  />
                  {!ex.validated ? <div style={{color: "red",marginTop: '5px', fontSize:'12px' }}>{ex.validated_text}</div> : <></>}
                </div> :
                
                ex.type === "Select" ?
                <div>
                  {!ex.validated ? <div style={{color: "red",marginTop: '5px', marginBottom:'10px', fontSize:'12px' }}>{ex.validated_text}</div> : <></>}
                  {Array.isArray(ex.options) && ex.options.length > 0 && ex.options.map((op, op_index) => (
                    <div key={op_index}>
                      <Radio style={{marginTop: '3px', marginBottom: '3px'}} label={op} value={op} checked={ex.value === op}
                        onChange={selectedAnswer({ id: item.id, extra: { index: index }})} 
                      />
                    </div>
                  ))}
                </div> :
      
                  ex.type === "MultiSelect" ?
                  <div>
                    {!ex.validated ? <div style={{color: "red",marginTop: '5px', marginBottom:'10px', fontSize:'12px' }}>{ex.validated_text}</div> : <></>}
            
                    {Array.isArray(ex.options) && ex.options.length > 0 && ex.options.map((op, op_index) => (
                      <div key={op_index}>
                        <Checkbox style={{marginTop: '3px', marginBottom: '3px'}}label={op} value={op} checked={ex.value.includes(op)}
                          onChange={selectedAnswer({ id: item.id, extra: { index: index }})} 
                        />
                      </div>
                    ))}
                  </div> : 

                  <>
                  </>
                ) :
                  ( 
                    ex.type !== "MultiSelect" ?
                      (ex.type !== "Description" ? <div style={{fontWeight:'bold'}}> &nbsp;&nbsp; &nbsp;&nbsp;{ex.value ? ex.value : "ไม่ระบุ"} </div> : <></>)
                      : Array.isArray(ex.value) && ex.value.length > 0 ? 
                        <AnswerMutipleSelect value={ex.value} /> 
                     : <div style={{fontWeight:'bold'}}> &nbsp;&nbsp; &nbsp;&nbsp;{"ไม่ระบุ"} </div>
                  ) 
            }
        </div>
        )
      })
      }
      {item.node.extra_finished === false || item.extra_finished === false ?
        <Button style={{marginTop:'15px'}} color="green"
        // disabled={!validated} 
        onClick={() => {
          if(!validated){
            setShowValidatePopup(true)
          }else {
            selectedAnswer({ id: item.id, extra_finished: true })()
          }}
          }>
          {item.node.extra_submit}
        </Button> : <></>
      }
    </div>
  )
}

export const executeAnswer = async (action, event, data, form, setForm, trigger, setTrigger, rule, updateDiagForm, checkTargetLavel) => {
  // Update state
  // console.log(' executeAnswer action', action)
  // console.log(' executeAnswer', event)
  // console.log(' executeAnswer', form)

  for (var [key, value] of Object.entries(action) ) {
    if (key === "selected") {
      form[action.id].selected = value
      form[form[action.id].parent_id].answer = form[action.id].condition
    } else if (key === "extra_finished") {
      form[action.id].extra_finished = value
    } else if (key === "extra") {
      let e = form[action.id].extra_items[action.extra.index]
      if (["Text", "Integer", "Decimal"].includes(e.type)) {
        e.value = event.target.value
      } else if (e.type === "Select") {
        e.value = data.value
      } else if (e.type === "MultiSelect") {
        if (!e.value.includes(data.value)) {
          e.value.push(data.value)
        } else {
          e.value = e.value.filter(j => j !== data.value)
        }
      }
    }
  }
  setForm(form)
  setTrigger(!trigger)
  
  // Check if emulator or real diag
  if (updateDiagForm) {
    // Save if finished
    let target = form[action.id];
    let isFinished = (target.type === "end" && target.extra_finished); // add another condition that extra has also been filled
    if (isFinished) {
      var answers = {}
      for (var [id, item] of Object.entries(form)) {
        answers[id] = {}
        answers[id].selected = Object.keys(item).includes("selected") ? 
          item.selected : null
        answers[id].answer = Object.keys(item).includes("answer") ? 
          item.answer : null
        answers[id].extra_finished = Object.keys(item).includes("extra_finished") ? 
          item.extra_finished : null
        answers[id].extra_items = Object.keys(item).includes("extra_items") ? 
          item.extra_items.map((ex) => ( { label: ex.label, value: ex.value } )) : null
        answers[id].type = Object.keys(item).includes("type") ?
          item.type : null
      }
      // Check triage level
      let division = rule.division.id;
      let triageLavel = target.triage_level;
      let msg = target.text;
      await updateDiagForm(JSON.stringify(answers), isFinished, {triageLevel: triageLavel, text: msg});
      console.log("finish update DiagForm")
      checkTargetLavel(division, triageLavel, msg);
    } else {
      updateDiagForm(JSON.stringify(answers), isFinished, {});
    }
  }
}

// Container code -------------------------------------------------------------------------
const SectionRow = ({item, index, selectedAnswer, threadLength}) => {
  if((item.display === "extra_intro" && !item.node.extra_intro) || 
  (item.display === "text" && !item.text)){
    return <></>
  }
  return (
    <Segment className="section-row"
        style={{
          alignSelf: ["text", "extra_intro"].includes(item.display)  ? "flex-start" : "flex-end",
          marginBottom: ((threadLength - 1) == index ) ? "6%" : "1%",
        }}>
      <Block item={item} index={index} selectedAnswer={selectedAnswer} />
    </Segment>
  )
}

export const QuestionSection = props => {
  const threadLength = props.threadQuestion.length
  // console.log('props.threadQuestion ' , props.threadQuestion)
  return (
      <div className="question-section">

        {props.threadQuestion.map((item, index) => (
          <SectionRow key={index} item={item} index={index} selectedAnswer={props.selectedAnswer} 
            threadLength={threadLength} />
        ))}
      </div>
  )
}

/********************************
 * 
 * Diag
 * 
***********************************/

const Diag = props => {
  const divRef = useRef(null);
  const [thread, setThread] = useState([]);

  useEffect( ()=>{
    return ( ()=>{ 
      setThread([])
    })
  },[])

  const handleAnswer = action => (event, data) => {
    props.onAnswerSelected(action, event, data);
  };

  useEffect(() => {
    if (props.form) {
      let formlist = Object.values(props.form);
      var targetThread = [];
      if (props.form !== undefined && formlist.length > 0) {
        targetThread = updateThread([], formlist, formlist[0]);
      }
      setThread(targetThread);
    }
  }, [props.form, props.trigger]);

  useEffect(() => {
    // TODO : Scan in thread all question completely field-in then scroll

    let isHasExtra = false
    thread.forEach( (thre)=> {
      if (thre.display === 'extra') {
        isHasExtra = true
        if (thre.node.extra_items && thre.node.extra_items.length > 0) {
          if (checkValid(thre.node.extra_items)) {
            // scrollToBottom()
            return
          }
        }
      }
    })

    if (!isHasExtra) {
      // scrollToBottom()
    }

  },[thread]);

  return (
    <>
    <Dimmer.Dimmable>
      <Dimmer active={props.isLoading} inverted>
          <Loader inverted>Loading...</Loader>
        </Dimmer>
      <div>
        <Label
        >
          {" "}
          {props.diagRuleName}{" "}
        </Label>
        <QuestionSection threadQuestion={thread} selectedAnswer={handleAnswer} />
        <div className="empty-div" ref={divRef}>
        </div>
      </div>
      </Dimmer.Dimmable>
    </>
  );
};

Diag.defaultProps = {
  onAnswerSelected: id => {},
  form: null,
  trigger: false,
  diagRuleName: "แบบประเมินทั่วไป",
  isEmulator: false
};

Diag.propTypes = {
  onAnswerSelected: PropTypes.func,
  form: PropTypes.object,
  trigger: PropTypes.bool,
  diagRuleName: PropTypes.string,
  isEmulator: PropTypes.bool
};

export default Diag;

//================================================
// deprecated?
export const createForm = rule => {
  var form = rule !== null && "content" in rule ? rule.content : {};
  for (var id in form) {
    if (id === 0) {
      form[0].selected = true
    } else {
      form[id].selected = false;
    }
  }
  return form;
};

// deprecated?
export const createFormDisplay = (rule, formdata) => {
  var form = Object.assign({}, rule.content);
  for (var id of Object.keys(form)) {
    form[id].selected = formdata[id];
  }
  form[0].selected = true
  return form;
};



// Feature no comma
const AnswerMultiSelect = ({index, extra_array}) => {

  let temp_array = []
  extra_array.forEach( (item, index) => {
    if (item.label === "") {
      temp_array.push(Object.assign({}, item))
      temp_array[index].label = temp_array[index-1].label
    } else {
      temp_array.push(Object.assign({}, item))
    }
  })

  // console.log('extra_array', extra_array, index)

  if (!Array.isArray(extra_array[index].value) && extra_array[index].value) {
    return <div style={{fontWeight:'bold'}}> &nbsp;&nbsp; &nbsp;&nbsp;{extra_array[index].value} </div>
  } else if (Array.isArray(extra_array[index].value) && extra_array[index].value[0]) {
    return <div style={{fontWeight:'bold'}}> &nbsp;&nbsp; &#10004; &nbsp;&nbsp;  {extra_array[index].value[0]} </div>
  } else {
    let label = temp_array[index].label
    let group_array = temp_array.filter( item => {
      return item.label == label
    })

    let found_answer = group_array.find( item => {

      // console.log('find ansser item', item )
      if (Array.isArray(item.value)) {
        return item.value.length !== 0 
      } else {
        return item.value !== "" 
      }
     
    })

    // console.log('found_answer', found_answer, index)
    if (found_answer) {
      return null 
    } else if (extra_array.length == index+1 || temp_array[index+1].label !== temp_array[index].label){
      return <div style={{fontWeight:'bold'}}> &nbsp;&nbsp; &nbsp;&nbsp;{"ไม่ระบุ"} </div>
    } else {
      return null
    }
  }
}
