import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `REG/CardAppointmentList.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import DGrid 1.0
import QmlWeb 1.0
import '../Common' as Common
import '../Common/Util.js' as Util

Segment{
    id: sgmAppointmentList
    signal hasAppointment(array appointment_list)
    signal encounterCreated(array encounter_list)
    property int patient_id: null
    className: "defaultBackground"

    function clear() {
        bbb = dteStart
        dteStart.setToday()
        dteEnd.setToday()
        chkDate.checked = true
        grdAppointment.model = []
        frmFilter.clear()
    }

    function refresh() {
        var query = {}
        
        if (!patient_id) {
            return
        }

        if (chkDate.checked) {
            query['date__gte'] = dteStart.text
            query['date__lte'] = dteEnd.text
        }

        query['patient'] = patient_id
        query['to_real_doctor_order'] = true
        rmdAppointment.query = query
        rmdAppointment.fetch()
    }

    RestModel{
        id: rmdAppointment
        url: '/apis/APP/appointment/'
        property alias items: grdAppointment.model
        property int total
        onFetched:{
            if (total > 0) {
                sgmAppointmentList.hasAppointment(items)
            }
        }
    }

    RestModel{
        id: rmdAppointmentForUpdate
        url: '/apis/APP/appointment/finish/'
        property alias items: grdAppointment.model
        property var encounters

        onSaved:{
            Util.alert('บันทึกสำเร็จ', 'green')
            frmFilter.clear()
            refresh()
            sgmAppointmentList.encounterCreated(encounters)
            btnSave.loading = false
        }

        onFailed:{
            Util.alert('ไม่สามารถบันทึกได้', 'red')
            frmFilter.showError(error)
            btnSave.loading = false
        }
    }

    Form{
        id: frmFilter
        Message{
            className: 'error'
        }
        Fields{
            className: 'inline'
            Field{
                className:"three wide"
                CheckBox{
                    id: chkDate
                    text:"ค้นหาวันที่"

                    onChanged: {
                        dteStart.enabled = checked
                        dteEnd.enabled = checked
                        dteStart.text = checked ? dteStart.text : ''
                        dteEnd.text = checked ? dteEnd.text : ''
                    }
                }
            }
            Field{
                className:"three wide"
                DateTextBox{
                    id: dteStart
                    enabled: false
                }
            }
            Field{
                className:"three wide"
                DateTextBox{
                    id: dteEnd
                    enabled: false
                }
            }
            Field{
                className:"three wide"
                Button{
                    className:"blue fluid"
                    text:"ค้นหาข้อมูล"

                    onClicked:{
                        refresh()
                    }
                }
            }
        }
    }

    function createDomItemInvestigation(color, acronym, type_name, tooltipTxt) {
        var domItem = document.createElement('div') 
        domItem.classList.add('ui', 'label', 'circular')
        domItem.classList.add(color)
        domItem.style['font-size'] = '15px'
        domItem.style['cursor'] = 'pointer'
        domItem.append(acronym)
        domItem.onmouseover = setInvestigationToolTip.bind(this, {dom:domItem}, type_name, tooltipTxt)
        return domItem
    }

    function setInvestigationToolTip(dom, type_name, tooltipTxt){
        popTooltip.target = dom
        popTooltip.disabled = false
        popTooltip.text = 'นัดหมาย {type_name}\n{summary_detail}'.format({ 
            type_name: type_name, 
            summary_detail: tooltipTxt 
        })
    }

    DGrid{
        id: grdAppointment
        doc_label: 'รายการนัดหมาย'
        height: 200
        columns:[
            DCheckBoxColumn{
                doc_label: "เลือก"
                field: "checked"
                label: ""
                width: 5

                onChanged:{
                    grdAppointment.selectedRow.checked = checked
                }
            },
            DColumn{ 
                field: "date"
                label: "วันที่"
                width: 30
            },
            DColumn{ 
                field: "time"
                label: "เวลา"
                width: 30
                formatter: function (value, object) {
                    return object.start_time + ' - ' + object.end_time
                }
            },
            DColumn{ 
                field: "doctor_name" 
                label: "ชื่อแพทย์" 
                width: 50
            },
            DColumn{ 
                field: "patient_name"
                label: "ชื่อผู้ป่วย"
                width: 50
            },
            DColumn{ 
                field: "division_name"
                label: "แผนก"
                width: 50
            },
            DColumn {
                field: 'order'
                label: "Order"
                width: 15
                function renderCell(object, value, node, options){
                    var domContainer = document.createElement('div')
                    domContainer.style['display'] = 'inline-block'
                    domContainer.style['float'] = 'left'
                    node.appendChild(domContainer)
                    for(var i=0; i<object.children.length; i++) {
                        var doctorOrder = object.children[i]
                        var domItem = createDomItemInvestigation(
                            Util.DOCTOR_ORDER_BG_COLOR['APPOINTMENT'], 
                            Util.DOCTOR_ORDER_ACRONYM[doctorOrder.type],
                            doctorOrder.type,
                            doctorOrder.summary_detail
                        )
                        domContainer.appendChild(domItem)
                    }
                }
            }
            // DCustomColumn {
            //     label: 'Order'
            //     width: 15
            //     Container {
            //         className: 'fluid'
            //         LabelTag {
            //             id: lbtLabStatus
            //             className: 'circular'
            //             backgroundColor: 'grey'
            //             link: true
            //             text: Util.DOCTOR_ORDER_ACRONYM[modelData.type_name]
            //             onHovered: {
            //                 console.log('xxxxxxxxxxx')
            //                 console.log(modelData)
            //                 console.log(Util.DOCTOR_ORDER_ACRONYM[modelData.type_name])
            //                 if (! popTooltip.hasTargetRegistered(lbtLabStatus)) {
            //                     popTooltip.target = lbtLabStatus
            //                     popTooltip.text = 'นัดหมาย {type_name}\n{summary_detail}'.format({ 
            //                         type_name: modelData.type_name, 
            //                         summary_detail: modelData.summary_detail 
            //                     })
            //                     popTooltip.show()
            //                 }
            //             }
            //         }
            //     }
            // }
        ]
    }

    Field{
        className:"three wide"
        Button{
            id: btnSave
            className:"green fluid"
            text:"บันทึก Encounter"

            onClicked:{
                btnSave.loading = true
                rmdAppointmentForUpdate.create()
            }
        }
    }

    TooltipDialog {
        id: popTooltip
        property string text
        activeEvent: 'hover'
        target: {}
        Text {
            text: popTooltip.text
            style: 'white-space: pre-wrap'
        }
    }
}
        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/REG/CardAppointmentList.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}