import BaseService from "./BaseService";
import { REG } from "../../configs/apis";
class REGService extends BaseService {

  /**
   * get patient data by hn
   * @param {object} data
   */
  getPatientByHN(hn) {
    const url = REG.PATIENT_HN({ hn: hn });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * get patient data by patient id
   * @param {object} data
   */
  getPatientById(id, apiToken) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const url = REG.PATIENT({ patient_id: id });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getSearchPatient(nameCode) {
    const url = REG.SEARCH_PATIENT_HN({ name_code: nameCode });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getSearchAllPatient(params, cancelToken = null) {
    const url = REG.SEARCH_PATIENT;
      return this.client
      .get(url, { params, ...( cancelToken && {cancelToken}) } )
      .then(this.handleResponse)
      .catch(this.throwErrorMessage)
  } 
  
  getPatientName(patientId) {
    const url = REG.SEARCH_PATIENT_NAME({ patient_id: patientId });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
}
export default REGService;
