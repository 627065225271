import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `Common/PatientPanel.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import QmlWeb 1.0
import '../REG' as REG

Segment {
    id: patientPanel

    signal hnChanged(string hn)
    signal hnTextChanged(string hn)
    signal patientIdChanged(string patientId, string encounterId)
    signal encounterChanged(string encounterId, string encounterStatus, string divisionId, string doctorId, string emrId, var data)

    className: 'raised defaultBackground'

    property bool fetchRecentEncounterByHN: false
    property bool allowEditHN: true
    property bool showNewPatient: false
    property bool showEncounter: false
    property bool showTriageLevel: false
    property bool showAge: true
    property bool showDateOfBirth: true
    property bool showCoverage: true
    property bool showCheckout: true
    property bool showRemark: false
    property bool showNote: false
    property bool showAppoint: false
    property bool showAdmitDate: false
    property bool showDivider: true
    property string patient_id: ''
    property string encounter_id: ''
    property string encounter_no: ''
    property string triage_level: ''

    property alias customContent: patientPanel.data

//-- RestModels --------------------------------------------------------------------------------------------------------
    property RestModel rmdEncounter: RestModel {
        property string _id
        property string status
        property var patient
        property string checkout_cause
        property string division
        property string doctor
        property string medical_record
        property string underlying_disease
        property string coverage
        property string payer_name
        property string coverage_code
        property string remark
        property string note
        property string created
        property string general_type
        property var examination_type
        property var allowed_actions
        property alias number: patientPanel.encounter_no

        function clear_text() {
            rmdEncounter.status = ''
            rmdEncounter.patient = null
            rmdEncounter.checkout_cause = ''
            rmdEncounter.division = ''
            rmdEncounter.doctor = ''
            rmdEncounter.medical_record = ''
            rmdEncounter.underlying_disease = ''
            rmdEncounter.coverage = ''
            rmdEncounter.coverage_code = ''
            rmdEncounter.payer_name = ''
            rmdEncounter.remark = ''
            rmdEncounter.note = ''
            rmdEncounter.created = ''
            rmdEncounter.examination_type = null
            rmdEncounter.allowed_actions = null
        }

        function emitEncounterChanged() {
            var data = {}
            data.underlying_disease = rmdEncounter.underlying_disease
            data.allowed_actions = rmdEncounter.allowed_actions
            data.examination_type = rmdEncounter.examination_type
            data.general_type = rmdEncounter.general_type
            patientPanel.encounterChanged(
                rmdEncounter._id,
                rmdEncounter.status,
                rmdEncounter.division,
                rmdEncounter.doctor,
                rmdEncounter.medical_record,
                data
            )
        }
    }

    property RestModel patientRest: RestModel {
        property var _id
        property alias hn: hnInput.text
        property string encounter_id
        property string encounter_no
        property string full_name
        property string birthdate
        property string full_age
        property string citizen_no  // TODO: check on usage
        property string gender_name
        property string coverage
        property string payer_name
        property string coverage_code
        property string welfare_category
        property var profile_image
        property string old_name
        property string deathdate
        property string religion
        property string belief

        function clear_text() {
            patientRest.encounter_id = null
            patientRest.encounter_no = ''
            patientRest.full_name = ''
            patientRest.birthdate = ''
            patientRest.full_age = ''
            patientRest.citizen_no = ''
            patientRest.gender_name = ''
            patientRest.coverage = ''
            patientRest.coverage_code = ''
            patientRest.payer_name = ''
            patientRest.welfare_category = ''
            patientRest.old_name = ''
            patientRest.deathdate = ''
            patientRest.religion = ''
            patientRest.belief = ''
        }

        onFetched: {
            patientPanel.patient_id = patientRest._id
            patientPanel.hnChanged(patientRest.hn);
            patientPanel.patientIdChanged(patientRest._id, patientRest.encounter_id);
            subADRlbt.refreshApproved(patientRest._id)
            if (fetchRecentEncounterByHN) {
                patientPanel.fetchLastPatientEncounter(patientRest._id).then(function () {
                    patientPanel.encounter_id = rmdEncounter._id
                    rmdEncounter.emitEncounterChanged()
                })
            }
            if (profile_image) {
                imgProfile.source = profile_image.image
            }
            else {
                imgProfile.source = '../../static/images/person.png'
            }

            if (showAppoint){
                rmdPatientAppoint.url = '/apis/APP/appointment/all/?patient=' + _id
                rmdPatientAppoint.fetch()
            }
        }
        onFailed: {
            hnInput.text = ''
            alert('ไม่พบข้อมูลผู้ป่วย')
        }
    }

    RestModel{
        property var items

        id: rmdPatientAppoint
        url: '/apis/APP/appointment/all/'

        onFetched:{
            if (items.length > 0 && items[0].next_appoint){
                lblAppoint.text = items[0].next_appoint
            }
            else{
                lblAppoint.text = '-'
            }
        }
    }

//-- Functions ---------------------------------------------------------------------------------------------------------
    function getPatientName() {
        return lbtPatientName.text
    }

    function getFullPatientName() {
        if(patientRest) {
            return 'HN : ' + patientRest.hn + ' ' + lbtPatientName.text + ' [ DOB : ' + patientRest.birthdate + ' ]'
        }
        else {
            return ''
        }
    }
    function fetchHN(hn) {
        clear()
        if (!hn) return;
        patientRest.url = '/apis/REG/patient/hn/' + hn + '/';
        patientRest.fetch();
    }
    function fetchByPatient(id, preventClear) {
        if (!preventClear) {
            clear()
        }
        if (!id) return;
        patientRest.url = '/apis/REG/patient/' + id + '/';
        return patientRest.fetch();
    }
    function fetchByEncounter(id) {
        clear()
        if (!id) return;
        rmdEncounter.url = '/apis/core/encounter/' + id + '/';
        var promise = rmdEncounter.fetch()
            .then(function() {
                return fetchByPatient(rmdEncounter.patient, true)
            })
            .then(function() {
                rmdEncounter.emitEncounterChanged()
            })
        return promise
    }
    function fetchByEncounterWithoutClear(id) {
        if (!id) return;
        rmdEncounter.url = '/apis/core/encounter/' + id + '/';
        var promise = rmdEncounter.fetch()
            .then(function() {
                rmdEncounter.emitEncounterChanged()
            })
        return promise
    }
    function fetchLastPatientEncounter(id) {
        rmdEncounter.url = '/apis/core/encounter/last-patient-encounter/' + id + '/';
        return rmdEncounter.fetch()
    }
    function clear(){
        subADRlbt.displayNone = true
        if (fetchRecentEncounterByHN){
            patientPanel.encounter_id = null
            patientPanel.encounter_no = ''
        }
        patientPanel.patient_id = null
        patientRest.clear_text()
        rmdEncounter.clear_text()
        lblAppoint.text = '-'

        imgProfile.source = '../../static/images/person.png'
    }
    function clearAll(){
        hnInput.text = ''
        clear()
        patient_id = ''
        encounter_id = ''
        encounter_no = ''
        triage_level = ''
    }

//-- Contents ----------------------------------------------------------------------------------------------------------
    Grid {
        className: 'center aligned padded'
        Row {
            Form{
                className: 'inline'
                Field{
                    Image {
                        id: imgProfile
                        className: 'small rounded'
                        source: '../../static/images/person.png'
                        LabelTag {
                            className: 'ribbon blue'
                            displayNone: !patientRest.welfare_category
                            text: patientRest.welfare_category || ''
                            Popup {
                                id: popChangeName
                                activeEvent: 'hover'
                                text: 'สิทธิสวัสดิการ'
                                position: 'bottom left'
                            }
                        }
                        SubADRLabelTag {
                            id: subADRlbt
                            className: 'floating'
                        }
                    }
                }
                Field{
                    displayNone: !showNewPatient
                    className: 'sixteen wide'
                    LabelTag {
                        className: 'fluid'
                        text: 'New Patient '
                        icon: 'star'
                        backgroundColor: 'teal'
                    }
                }
            }
        }
    }

    Form {
        inline: true
        Fields {
            Field {
                className: 'five wide'
                label: 'HN'
                labelAlign: 'right'
            }
            Field {
                className: 'eleven wide'
                ActionTextBox {
                    id: hnInput
                    doc_label: 'HN'
                    placeholder: 'XXXX-XX'
                    displayNone: !allowEditHN
                    style: 'margin: 0px'

                    onEntered: {
                        hnInput.text = hnInput.text.toUpperCase().trim();
                        patientPanel.fetchHN(hnInput.text);
                        patientPanel.hnTextChanged(hnInput.text);
                    }

                    onButtonClicked: {
                        openSearch()
                    }
                }
                LabelTag {
                    id: lbtHN
                    className: 'large fluid'
                    displayNone: allowEditHN
                    style: 'margin: 0px'
                    text: patientRest.hn || '-'
                }
            }
        }

        Fields {
            displayNone: !showEncounter
            Field {
                className: 'five wide'
                label: 'Encounter'
                labelAlign: 'right'
            }
            Field {
                className: 'eleven wide'
                LabelTag {
                    doc_label: 'encounter'
                    doc_auto_input: true
                    doc_read_only: true
                    className: 'large fluid'
                    text: patientPanel.encounter_no || '-'
                }
            }
        }

        Fields {
            displayNone: !showTriageLevel
            Field {
                className: 'five wide'
                label: 'Level'
                labelAlign: 'right'
            }
            Field {
                className: 'eleven wide'
                LabelTag {
                    doc_label: 'triage level'
                    doc_auto_input: true
                    doc_read_only: true
                    className: 'large fluid'
                    text: triage_level || '-'
                }
            }
        }

        Fields {
            Field {
                className: 'five wide'
                label: 'ชื่อ'
                labelAlign: 'right'
            }
            Field {
                className: 'eleven wide'
                LabelTag {
                    id: lbtPatientName
                    doc_label: 'ชื่อ'
                    doc_auto_input: true
                    doc_read_only: true
                    className: 'large fluid'
                    text: patientRest.full_name || '-'
                    style: 'margin: 0px'
                }
                Icon {
                    displayNone: !patientRest.old_name
                    icon: 'history'
                    style: 'margin-left: 5px; cursor: pointer'
                    onClicked: {
                        ldrPatientOldName.loadAndShowModal()
                    }
                }
                Popup {
                    id: popChangeName
                    activeEvent: patientRest.old_name? 'hover' : 'manual'
                    text: 'มีการเปลี่ยนชื่อ'
                }
            }
        }

        Fields {
            displayNone: !showAge
            Field {
                className: 'five wide'
                label: 'เพศ'
                labelAlign: 'right'
            }
            Field {
                className: 'eleven wide'
                LabelTag {
                    doc_auto_input: true
                    doc_read_only: true
                    doc_label: 'เพศ'
                    className: 'fluid'
                    text: patientRest.gender_name || '-'
                }
            }
        }

        Fields {
            displayNone: !showAge
            Field {
                className: 'five wide'
                label: 'อายุ'
                labelAlign: 'right'
            }
            Field {
                className: 'eleven wide'
                LabelTag {
                    doc_auto_input: true
                    doc_read_only: true
                    doc_label: 'อายุ'
                    className: 'fluid'
                    text: patientRest.full_age || '-'
                }
            }
        }

        Fields {
            displayNone: !showDateOfBirth
            Field {
                className: 'five wide'
                label: 'วันเกิด'
                labelAlign: 'right'
            }
            Field {
                className: 'eleven wide'
                LabelTag {
                    doc_auto_input: true
                    doc_read_only: true
                    doc_label: 'วันเกิด'
                    className: 'fluid'
                    text: patientRest.birthdate || '-'
                }
            }
        }

        Fields {
            displayNone: !showCoverage
            Field {
                className: 'five wide'
                label: 'สิทธิ์'
                labelAlign: 'right'
            }
            Field {
                className: 'eleven wide'
                LabelTag {
                    doc_auto_input: true
                    doc_read_only: true
                    doc_label: 'สิทธิ์'
                    className: 'fluid'
                    link: true
                    text: rmdEncounter.coverage || patientRest.coverage || '-'
                    Popup {
                        text: 'กดเพื่อดูหน้าสิทธิ์'
                    }
                    onClicked: {
                        ldrConfirmCoverage.loadAndShowModal()
                    }
                }
            }
        }

        Fields {
            displayNone: !showCoverage
            Field {
                className: 'five wide'
                label: 'Payer'
                labelAlign: 'right'
            }
            Field {
                className: 'eleven wide'
                LabelTag {
                    doc_auto_input: true
                    doc_read_only: true
                    doc_label: 'payer'
                    className: 'fluid'
                    link: true
                    text: rmdEncounter.payer_name || patientRest.payer_name || '-'
                }
            }
        }

        Fields {
            Field {
                className: 'five wide'
                label: 'ศาสนา/ <br/>ความเชื่อ'
                labelAlign: 'right'
            }
            Field {
                className: 'eleven wide'
                LabelTag {
                    doc_auto_input: true
                    doc_read_only: true
                    doc_label: 'ศาสนา/ความเชื่อ'
                    className: 'fluid'
                    text: (patientRest.religion || '-') + ' ' + (patientRest.belief || '')
                    Popup {
                        text: (patientRest.religion || '-') + ' ' + (patientRest.belief || '')
                    }
                }
            }
        }

        Fields {
            displayNone: !showAdmitDate
            Field {
                className: 'five wide'
                label: 'วันที่ Admit'
                labelAlign: 'right'
            }
            Field {
                className: 'eleven wide'
                LabelTag {
                    doc_auto_input: true
                    doc_read_only: true
                    doc_label: 'วันที่ Admit'
                    className: 'fluid'
                    text: rmdEncounter.created
                }
            }
        }

        Fields {
            displayNone: !showCheckout
            Field {
                className: 'five wide'
                label: 'Checkout'
                labelAlign: 'right'
            }
            Field {
                className: 'eleven wide'
                LabelTag {
                    doc_auto_input: true
                    doc_read_only: true
                    doc_label: 'Checkout'
                    className: 'fluid'
                    text: (rmdEncounter.checkout_cause != null ? rmdEncounter.checkout_cause : '-') + ' ' + (patientRest.deathdate ? patientRest.deathdate : '')
                }
            }
        }

        Fields {
            displayNone: !showRemark
            Field {
                className: 'five wide'
                label: 'จุดสังเกต'
                labelAlign: 'right'
            }
            Field {
                className: 'eleven wide'
                LabelTag {
                    doc_auto_input: true
                    doc_read_only: true
                    doc_label: 'จุดสังเกต'
                    className: 'fluid'
                    style: 'overflow-wrap: break-word'
                    text: rmdEncounter.remark != null ? rmdEncounter.remark.replace(",", ", ") : '-'
                }
            }
        }

        Fields {
            displayNone: !showNote
            Field {
                className: 'five wide'
                label: 'หมายเหตุ'
                labelAlign: 'right'
            }
            Field {
                className: 'eleven wide'
                LabelTag {
                    id: lblNote
                    doc_auto_input: true
                    doc_read_only: true
                    doc_label: 'หมายเหตุ'
                    className: 'fluid'
                    style: 'overflow-wrap: break-word'
                    text: rmdEncounter.note != null ? rmdEncounter.note : '-'
                }
            }
        }

        Fields {
            displayNone: !showAppoint
            Field {
                className: 'five wide'
                label: 'นัดหมาย'
                labelAlign: 'right'
            }
            Field {
                className: 'eleven wide'
                LabelTag {
                    id: lblAppoint
                    doc_auto_input: true
                    doc_read_only: true
                    doc_label: 'นัดหมาย'
                    className: 'fluid'
                    // text: patientRest.next_appoint
                }
            }
        }
    }

    Divider {
        displayNone: !showDivider
    }

    Loader {
        id: ldrConfirmCoverage
        REG.ModConfirmCoverage {
            id: modConfirmCoverage
            patient_id: patientPanel.patient_id
            readOnly: true
        }
    }

    Loader {
        id: ldrPatientOldName
        Modal {
            id: modPatientOldName
            CardPatientOldName {
                id: crdPatientOldName
                property alias patient_id: patientRest._id
                property alias hn: patientRest.hn
                property alias name: patientRest.full_name
            }
        }
    }

    Modal{
        id: modPatientSearch
        CardPatientSearch {
            id: crdPatientSearch
            hideCallback: function(){
                modPatientSearch.hide()
            }
            onSelected:{
                hnInput.text = patientData.hn
                patientPanel.fetchHN(hnInput.text);
                patientPanel.hnTextChanged(hnInput.text);
                modPatientSearch.hide()
                // TODO: reduce search frequency [E]
                // // -- Work like fetchHN -----------------------------------------------------------
                // // for reduce search frequency
                // clear()
                // patientPanel.patient_id = patientData.id
                // patientPanel.encounter_id = patientData.encounter_id
                // patientPanel.encounter_no = patientData.encounter_no
                // modADR.refresh(patientData.id)
                // if (fetchRecentEncounterByHN){
                //     patientPanel.encounterChanged(patientPanel.encounter_id, null, null, null)
                // }
                // if (patientData.profile_image) {
                //     imgProfile.source = patientData.profile_image.image
                // }
                // else {
                //     imgProfile.source = '../../static/images/person.png'
                // }

                // if (showAppoint){
                //     rmdPatientAppoint.url = '/apis/APP/appointment/all/?patient=' + patientData.id
                //     rmdPatientAppoint.fetch()
                // }
                // patientPanel.hnChanged(patientData.hn);
                // patientPanel.patientIdChanged(patientData.id);
                // // --------------------------------------------------------------------------------
            }
            allowClose: function() {
                modPatientSearch.hide()
            }
        }
    }

    function openSearch(){
        crdPatientSearch.clearAll()
        crdPatientSearch.displayNone = false
        modPatientSearch.show()
    }
}

        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/Common/PatientPanel.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}