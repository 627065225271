import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `REG/CardChangeName.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import QmlWeb 1.0
import DGrid 1.0
import "../Common" as Common


Common.CardLayout {
    id: _crdChangeName
    title: "เปลี่ยนชื่อผู้ป่วย"
    hideCloseButton: false
    toggleCard: false
    headerColor: "blue"

    property var change_name_reason
    property var require_ref

    signal confirmChange(bool isConfirm, var reason, string ref_no)

    RestModel{
        id: reasonRest
        property alias items : cboReason.items
        url: "/apis/core/clinicalterm/?type=E"
        queryMimeType: "application/json"
    }

    content: [
        Grid{
            Row{
                Column{
                    className: "fifteen wide"
                    Form{
                        Fields{
                            Field{
                                label: "เหตุผล"
                                className: "twelve wide"
                                ComboBox{
                                    id: cboReason
                                    doc_label: "เหตุผลเปลี่ยนชื่อ"
                                    search: true
                                    fullTextSearch: true
                                    allowAdditions: true
                                    useTextValue: true
                                    Component.onCompleted:{
                                        reasonRest.fetch()
                                    }
                                    onChanged:{
                                        change_name_reason = cboReason.value

                                        if (cboReason.getSelected()){
                                            require_ref = cboReason.getSelected().require_flag     
                                        }
                                        else{
                                            require_ref = false
                                        }
                                        
                                        if (require_ref){
                                            txtRef.enabled = true
                                        }                                       
                                        else{
                                            txtRef.enabled = false
                                            txtRef.text = ""
                                        }
                                    }
                                }
                            }
                        }

                        Fields{}

                        Fields{
                            Field{
                                className: "ten wide"
                                label: "หมายเลขหนังสือเปลี่ยนชื่อ"
                                TextBox{
                                    id: txtRef
                                    doc_label: "หมายเลขหนังสือเปลี่ยนชื่อ"
                                    enabled: false
                                }
                            }
                        }

                        Fields{}
                    }
                }
            }
            Row{
                Column{
                    className: "three wide"
                    Button{
                        className: "green fluid"
                        text: "บันทึก"
                        doc_label: "บันทึกเปลี่ยนชื่อ"
                        onClicked:{
                            if (!cboReason.value){
                                alert("ระบุเหตุผลเปลี่ยนชื่อ")
                            }
                            else if (require_ref && txtRef.text == ""){
                                alert("ระบุหมายเลขหนังสือเปลี่ยนชื่อ")
                            }
                            else{
                                _crdChangeName.confirmChange(true, change_name_reason, txtRef.text)
                            }
                        }
                    }
                }
                Column{
                    className: "three wide"
                    Button{
                        className: "red fluid"
                        text: "ยกเลิก"
                        doc_label: "ยกเลิกเปลี่ยนชื่อ"
                        onClicked:{
                            _crdChangeName.confirmChange(false, "", "")
                        }
                    }
                }
            }
        }
    ]

    function clear(){
        cboReason.clear()
        txtRef.text = ''
    }
}


        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/REG/CardChangeName.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}