import { useState, useEffect, useRef, useCallback } from "react";

export function useDebouncedCallback(callback, wait) {
  // track args & timeout handle between calls
  const argsRef = useRef();
  const timeout = useRef();

  function cleanup() {
    if(timeout.current) {
      clearTimeout(timeout.current);
    }
  }

  // make sure our timeout gets cleared if
  // our consuming component gets unmounted
  useEffect(() => cleanup, []);

  return function debouncedCallback(...args) {
    // capture latest args
    argsRef.current = args;

    // clear debounce timer
    cleanup();

    // start waiting again
    timeout.current = setTimeout(() => {
      if(argsRef.current) {
        callback(...argsRef.current);
      }
    }, wait);
  };
}

export function useStateCallback(initialState) {
  const [state, _setState] = useState(initialState);

  const callbackRef = useRef();
  const isFirstCallbackCall = useRef(true);

  const setState = useCallback((setStateAction, callback) => {
    callbackRef.current = callback;
    _setState(setStateAction);
  }, []);

  useEffect(() => {
    if (isFirstCallbackCall.current) {
      isFirstCallbackCall.current = false;
      return;
    }
    if (callbackRef.current)  {
      callbackRef.current(state);
    }
  }, [state]);

  return [state, setState];
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function useCheckBox(initialValue) {
  const [value, setValue] = useState(initialValue);

  function handleChange(e, { checked }) {
    setValue(checked);
  }

  return {
    checked: value,
    onChange: handleChange
  };
}

// TODO: Check Callback 
export function useFilledButton(initialValue = false, onButtonClick) {
  const [active, setActiveButton] = useState(initialValue)
  function onClick() {
    setActiveButton(!active)
    onButtonClick && onButtonClick()
  }

  return {
    active: active,
    onClick: onClick
  }
}

export function useFormatDropdown(optionsList, textField, keyField) {
  if (optionsList && Array.isArray(optionsList)) {
    optionsList.forEach(items => {
      items.key = items[keyField];
      items.text = items[textField];
      items.value = items[keyField];
      delete items['is_active']
      delete items['require_flag']
      delete items['is_default']
      delete items['has_description']
      return items;
    });
    return optionsList;
  } else {
    return [];
  }
}

export const useSize = (myRef) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    if(myRef.current){
      setWidth(myRef.current.offsetWidth)
    }
  }, [myRef.current && myRef.current.offsetWidth])

  useEffect(() => {
    if(myRef.current){
      setHeight(myRef.current.offsetHeight)
    }
  }, [myRef.current && myRef.current.offsetHeight])

  return { width, height }
}