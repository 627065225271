import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Form,
  Icon,
  Input,
  Label,
  Segment,
  TextArea,
} from "semantic-ui-react";
import { CardLayout, ErrorMessage, ModConfirm } from "../common";
import {
  ProblemListList,
  ProblemListDetail,
} from "../../apis/master/apps/DPO/ProblemList";
import { toast } from "react-toastify";

const CardPatientUD = (props) => {
  const isMounted = useRef(true);

  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedUndelying, setSelectedUndelying] = useState({});
  const [openModConfirm, setOpenModConfirm] = useState(false);

  // create
  const handleCreate = async () => {
    setIsLoading(true);
    const [result, error, network] = await ProblemListList.create({
      data: {
        patient: props.patientId,
        message: message,
      },
    });
    if (isMounted.current) {
      if (error) {
        setErrors(error);
      } else {
        setErrors("");
        setMessage("");
        props.refreshCallback();
        toast.success("Success");
      }
    }
    setIsLoading(false);
  };

  // update
  const updateUnderlyingDisease = async (data) => {
    setIsLoading(true);
    const [result, error, network] = await ProblemListDetail.update(data);
    if (isMounted.current) {
      if (error) {
        setErrors(error);
      } else {
        setErrors("");
        toast.success("Success");
      }
    }
    props.refreshCallback();
    handleCloseConfirm();
    setIsLoading(false);
  };

  const handleSetActive = (ud) => {
    updateUnderlyingDisease({
      pk: ud.id,
      data: {
        patient: props.patientId,
        message: ud.message,
        is_active: true,
      },
    });
  };

  const handleSetInactive = (ud) => {
    updateUnderlyingDisease({
      pk: ud.id,
      data: {
        patient: props.patientId,
        message: ud.message,
        is_active: false,
      },
    });
  };

  const handleEdit = () => {
    updateUnderlyingDisease({
      pk: selectedUndelying.id,
      data: {
        patient: props.patientId,
        message: selectedUndelying.message,
        is_active: selectedUndelying.is_active,
      },
    });
  };

  // change
  const handleChangeMessage = (event, { value }) => {
    setMessage(value);
  };

  const handleModalChangeMessage = (event, { value }) => {
    setSelectedUndelying({...selectedUndelying, message: value});
  };

  // modal
  const handleOpenConfirm = (ud) => {
    setSelectedUndelying(ud);
    setOpenModConfirm(true);
  };

  const handleCloseConfirm = () => {
    setSelectedUndelying({});
    setOpenModConfirm(false);
  };

  return (
    <CardLayout
      titleText={props.titleCard}
      headerColor="pink"
      loading={isLoading}
      closeable={props.closeable}
      toggleable={props.toggleable}
      onClose={props.hideCallback}
    >
      <Form error={errors ? true : false}>
        <ErrorMessage error={errors} />

        <Form.Group>
          <Form.Field width={5}>
            <label>ข้อมูลโรคประจำตัวจาก Encounter:</label>
          </Form.Field>
          <Form.Field width={11}>
            <Label className="fluid">{props.underlyingDiseaseText || ""}</Label>
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field width={16}>
            <label>การซักประวัติ</label>
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field width={14}>
            <Input onChange={handleChangeMessage} value={message} />
          </Form.Field>
          <Form.Field width={2}>
            <Button fluid color="green" type="button" onClick={handleCreate}>
              เพิ่ม
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>

      {props.underlyingDisease.map((ud, index) => {
        return (
          <Segment key={index} clearing>
            {`${ud.no}. ${ud.message}`}

            {props.canEdit && ud.is_active && (
              <Button
                icon
                floated="right"
                color="red"
                size="tiny"
                onClick={(e) => {
                  handleSetInactive(ud);
                }}
              >
                <Icon name="trash" />
              </Button>
            )}

            {props.canEdit && ud.is_active && (
              <Button
                icon
                floated="right"
                color="yellow"
                size="tiny"
                onClick={(e) => {
                  handleOpenConfirm(ud);
                }}
              >
                <Icon name="edit" />
              </Button>
            )}

            {props.canEdit && !ud.is_active && (
              <Button
                icon
                floated="right"
                color="green"
                size="tiny"
                onClick={(e) => {
                  handleSetActive(ud);
                }}
              >
                <Icon name="checkmark" />
              </Button>
            )}
          </Segment>
        );
      })}

      <ModConfirm
        openModal={openModConfirm}
        titleName="แก้ไขโรคประจำตัว"
        content={
          <Form>
            <Form.Field width={16}>โรคประจำตัว</Form.Field>
            <Form.Field width={16}>
              <TextArea
                value={selectedUndelying.message}
                onChange={handleModalChangeMessage}
              />
            </Form.Field>
          </Form>
        }
        onApprove={handleEdit}
        onDeny={handleCloseConfirm}
      />
    </CardLayout>
  );
};

CardPatientUD.defaultProps = {
  titleCard: "ประวัติโรคประจำตัวและภาวะเฝ้าระวังของผู้ป่วย",
  closeable: true,
  toggleable: false,

  refreshCallback: () => {},
  hideCallback: () => {},

  patientId: null,
  canEdit: false,
  underlyingDisease: [],
  underlyingDiseaseText: "",
};

CardPatientUD.propTypes = {
  titleCard: PropTypes.string,
  closeable: PropTypes.bool,
  toggleable: PropTypes.bool,

  refreshCallback: PropTypes.func,
  hideCallback: PropTypes.func,

  patientId: PropTypes.number,
  canEdit: PropTypes.bool,
  underlyingDisease: PropTypes.array,
  underlyingDiseaseText: PropTypes.string,
};

export default React.memo(CardPatientUD);
