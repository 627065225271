import React from "react";
import PropTypes from "prop-types";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils from "react-day-picker/moment";
import moment from "moment";
import { validateTime } from "../../utils";

import "react-day-picker/lib/style.css";
import "../../css/DateTextBox.css";

import { Input, Icon, Dropdown, Select } from "semantic-ui-react";

class TimeTextBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: "",
      open: false,
      localOptions: [],
      minHour: 0,
      maxHour: 23,
      typingTime: "",
    };
    this.validateTime = validateTime.bind(this);
  }

  componentDidMount() {
    if (this.props.minHour) {
      this.setState({ minHour: this.props.minHour });
    }
    if (this.props.maxHour) {
      this.setState({ maxHour: this.props.maxHour });
    }

    if (this.props.useDefaultTime) {
      if (this.validateTime(this.props.defaultTime)) {
        this.setState({ time: this.props.defaultTime });
        this.generateTimeShift(this.props.defaultTime);
      }
    } else if (this.props.value) {
      if (this.validateTime(this.props.value)) {
        this.timerHandle = setTimeout(() => {
          this.generateTimeShift(this.props.value);
          this.setState({ time: this.props.value });
          this.timerHandle = 0
        }, 1);
      }
    }
  }


  componentWillUnmount() {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle) 
      this.timerHandle = 0
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (!Number.isNaN(this.props.minHour)) {
      if (prevProps.minHour !== this.props.minHour) {
        this.setState({ minHour: this.props.minHour });
        if (this.validateTime(this.state.time)) {
          this.generateTimeShift(this.state.time);
        } else {
          this.generateTimeShift();
        }
      }
    }
    if (!Number.isNaN(this.props.maxHour)) {
      if (prevProps.maxHour !== this.props.maxHour) {
        this.setState({ maxHour: this.props.maxHour });
        if (this.validateTime(this.state.time)) {
          this.generateTimeShift(this.state.time);
        } else {
          this.generateTimeShift();
        }
      }
    }

    if (prevProps.value !== this.props.value) {
      if (this.validateTime(this.props.value)) {
        this.timerHandle = setTimeout(() => {
          this.setState({ time: this.props.value });
          this.generateTimeShift(this.props.value);
        }, 1);
      }
    }
  }

  generateTimeShift = (hourmin) => {
    var min = ":00";
    if (hourmin) {
      min = hourmin.slice(-3);
    }
    const date = moment().format("MM/DD/YYYY");

    let timeList = [];
    let start = moment(date + " " + ("0" + this.props.minHour).slice(-2) + min, "MM/DD/YYYY HH:mm");

    let end = moment(date + " " + ("0" + this.props.maxHour).slice(-2) + min, "MM/DD/YYYY HH:mm");
    if (this.props.maxHour == 23) {
      end = moment(date + " " + ("0" + this.props.maxHour).slice(-2) + ":59", "MM/DD/YYYY HH:mm");
    }

    while (start <= end) {
      const time = moment(start).format("HH:mm");
      timeList.push({ text: time, value: time, key: time });
      start = moment(start).add(this.props.interval, "minutes");
    }

    this.setState({ localOptions: timeList });
    return timeList;
  };

  signalTimeChange = (e, value) => {
    const { onTimeChange } = this.props;

    this.setState({ time: value }, () => {
      if (onTimeChange) {
        this.props.onTimeChange(e, value);
      }
    });
  };

  openDropdown = () => {
    this.setState({ open: true });
  };

  handleTypingOnInputField = (e, data) => {
    // Store and close Modal

    this.setState({ typingTime: data.searchQuery });
    this.setState({ open: false });
  };

  handleOnBlur = (e) => {
    // After User Typing If valid then generate options list
    // Click dropdown looking on list and not selected any one

    if (this.state.typingTime) {
      this.setState({ open: false });
      if (!this.validateTime(this.state.typingTime)) {
        return this.signalTimeChange(e, "");
      }

      setTimeout(() => {
        this.generateTimeShift(this.state.typingTime);
        this.setState({ time: "" });
        setTimeout(() => {
          let time = this.state.typingTime;
          this.setState({ time: time });
          this.signalTimeChange(e, time);
          this.setState({ typingTime: "" });
        }, 1);
      }, 1);
    } else {
      this.setState({ open: false });
    }
  };

  handleOnSelected = (e, data) => {
    this.setState({ typingTime: "" });
    this.setState({ open: false });
    this.signalTimeChange(e, data.value);
  };

  handleOnClose = (e, data) => {
    if (this.state.open) {
      this.setState({ open: false });
    }
  };

  handleOnOpen = () => {};

  render() {
    const { interval, style } = this.props;

    return (
      <Dropdown
        selection
        lazyLoad
        className={`icon ${this.props.className}`}
        open={this.state.open}
        style={{ ...style }}
        onClick={this.openDropdown}
        onSearchChange={this.handleTypingOnInputField}
        onBlur={this.handleOnBlur}
        options={this.state.localOptions}
        value={this.state.time}
        onChange={this.handleOnSelected}
        onClose={this.handleOnClose}
        onOpen={this.handleOnOpen}
        disabled={this.props.disabled}
        search={this.props.search}
        clearable={this.props.clearable}
      ></Dropdown>
    );
  }
}

TimeTextBox.propTypes = {
  interval: PropTypes.number,
  style: PropTypes.object,
  value: PropTypes.string,
  minHour: PropTypes.number,
  maxHour: PropTypes.number,
  onTimeChange: PropTypes.func,
  defaultTime: PropTypes.string,
  useDefaultTime: PropTypes.bool,
  clearable: PropTypes.bool,
  search: PropTypes.bool,
};

TimeTextBox.defaultProps = {
  interval: 30,
  disabled: false,
  defaultTime: "00:00",
  minHour: 0,
  maxHour: 23,
  useDefaultTime: false,
  clearable: false,
  search: false,
  value: moment().format("HH:mm"),
  className: "",
};

export default TimeTextBox;
