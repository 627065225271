import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `Common/CardLayout.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0

Accordion {
    id:card
    property alias titleRibbon: _ribbonText.text
    property alias title: _headerText.text
    property alias titleContent: gclTitleContent.data
    property alias headerTitleContent: _headerTitleContent.data
    property alias hideHeaderTitleContent: _headerTitleContent.displayNone
    property alias hideTitleRibbon: gclTitleRibbon.displayNone
    property alias hideTitle: gidTitle.displayNone
    property alias hideTitleText: gclTitle.displayNone
    property alias hideTitleContent: gclTitleContent.displayNone
    property alias hideContent: _content.displayNone
    property alias content: _content.data
    property bool hideCloseButton  // hide close button and use dropdown icon instead
    property bool hideHeaderIcon  // hide all icon in header
    property string ribbonColor
    property string headerColor
    property bool inModal: false
    property bool toggleCard: true  // allow card to toggle
    property bool hideDivider
    property bool smoothFade: true  // Enable animation when fade
    property var allowClose: true // can be a function
    property var ignoreToggle: []
    property var hideCallback //callback when hide or deleted
    property var showCallback //callback when closed
    property var ribbonTextClassName : "eight wide"
    property var headerTextClassName : "eight wide"
    property var titleContentClassName : "eight wide right aligned"

    property alias readOnly: dimReadOnly.active
    property bool loading
    property string loadingText: 'Loading'

    expanded:true
    eventName:false
    style: "padding: 0px;"
    className: headerColor + " defaultBackground styled fluid raised segment"
    htmlAttr: new Object({
        type: 'card'
    })

    onInModalChanged: {
        if (inModal) {
            toggleCard = false
            allowClose = false
            closeButton.displayNone = true
            arrowIcon.displayNone = true
        } else {
            toggleCard = true
            allowClose = true
            closeButton.displayNone = false
            arrowIcon.displayNone = false
        }
    }

    onReadOnlyChanged: {
        if (readOnly) {
            _content.dom.classList.add('dimmable', 'dimmed')
        }
        else {
            _content.dom.classList.remove('dimmable', 'dimmed')
        }
    }

    onHideCloseButtonChanged:{
        if(hideCloseButton) {
            closeButton.displayNone = true
            if (toggleCard) {
                arrowIcon.displayNone = false
            } else {
                arrowIcon.displayNone = true
            }
        }
        else {
            closeButton.displayNone = false
            if (toggleCard) {
                arrowIcon.displayNone = true
            } else {
                arrowIcon.displayNone = true
            }
        }
    }

    onHideHeaderIconChanged:{
        if(hideHeaderIcon){
            closeButton.displayNone = true
            arrowIcon.displayNone = true
        }
        else{
            closeButton.displayNone = false
            arrowIcon.displayNone = false
        }
    }

    onOpening:{
        openAnim()
    }

    onClosing:{
        closeAnim()
    }

    //workaround for fixing modal position is not center
    onOpen:{
        $.each($('.ui.modal'), function(index,ele){
            if($.contains(ele, card.dom)) {
                setTimeout(function(){
                    $(ele).modal('refresh')
                })
            }
        })
        //workaround for fixing agenda is not show
        $.each($('div[data-main=fullcalendar]'), function(index,ele){
            if($.contains(card.dom, ele)) {
                setTimeout(function(){
                    $(ele).fullCalendar('render');
                })
            }
        })

    }

    Component.onCompleted: {
        card.completed = true
        if (card.displayNone) {
            card.dom.style.display = 'none'
            transitionComplete()
        } else {
            displayNoneCallBack()
        }
    }

    function displayNoneCallBack() {
        if (!card.completed) {
            return
        }
        if (!card.smoothFade) {
            if (card.displayNone) {
                card.dom.style.display = 'none'
            }else {
                card.dom.style.display = ''
            }
            transitionComplete()
        } else {
            var duration = 150
            if (card.displayNone) {
                card.transition({
                    animation: 'fade out',
                    duration: duration,
                    silent: true,
                    onComplete: card.transitionComplete
                })
            } else {
                card.transition({
                    animation: 'fade in',
                    duration: duration,
                    onComplete: card.transitionComplete
                })
            }
        }
    }

    function transitionComplete() {
        if(card.displayNone) {
            card.hideCallback && card.hideCallback()
        }
        else {
            card.showCallback && card.showCallback()
            document.body.dispatchEvent(
                new CustomEvent("resizeDgrid", {
                    detail: { dom: card.dom }
                })
            );
        }
    }

    function openAnim() {
        divider.displayNone = card.hideDivider ? true : false
        arrowIcon.dom.style.transform = "rotate(-90deg)"
        arrowIcon.dom.style.transition = "transform 0.15s linear"
    }

    function closeAnim() {
        divider.displayNone = true
        arrowIcon.dom.style.transform = "rotate(0deg)"
        arrowIcon.dom.style.transition = "transform 0.15s linear"
    }

    Link{
        id:closeButton
        doc_label: 'ปุ่มปิด'
        href: "javascript:void(0)"
        text: "&#10006"

        style: {
            return{
                position:"absolute",
                right:"5px",
                top:"5px",
                color:"red",
                fontSize:"x-large",
                zIndex:"2"
            };
        }

        onClicked:{
            if (typeof card.allowClose == 'function' && card.allowClose()) {
                 card.displayNone = true
            }
            else if (typeof card.allowClose == 'boolean' && card.allowClose){
                card.displayNone = true
            }
        }
    }

    AccordionTitle {
        doc_label: title
        //filter click event before expand of collapse accordion
        onClicked:{
            var target = event.target
            var expandAcc = true

            ignoreToggle.forEach(function(ele) {
                if(target.isSameNode(ele.dom)) {
                    expandAcc = false
                }
                if($.contains(ele.dom, target)) {
                    expandAcc = false
                }
            })

            toggleCard && expandAcc && card.toggle()
            target = null
        }

        style: {
            return{
                borderTop: "0px !important",
                zIndex:"1"
            }
        }

        Icon{
            doc_skip: "all"
            id:arrowIcon
            doc_label: 'ปุ่มย่อ'
            displayNone:true
            icon:"caret left"
            style: {
                return{
                    position:"absolute",
                    right:"5px",
                    color:"black",
                    fontSize:"x-large"
                }
            }
        }

        Dom {
            id: _headerTitleContent
            displayNone: true
        }

        Grid {
            id: gidTitle
            Row {
                style: {
                    return {
                        marginRight: "20px"
                    }
                }
                Column {
                    id: gclTitleRibbon
                    className: ribbonTextClassName
                    LabelTag {
                        id: _ribbonText
                        doc_skip: "me"
                        className: ribbonColor + " ribbon label"
                    }
                    displayNone: true
                }
                Column {
                    id: gclTitle
                    className: headerTextClassName
                    Text{
                        id: _headerText
                        className: "header"
                    }
                }
                Column{
                    id: gclTitleContent
                    className: titleContentClassName
                }
            }
            Row {
                style: {
                    return {
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        marginTop: "-10px",
                    }
                }
                Column {
                    id:divider
                    displayNone:true
                    className: "sixteen wide"
                    Dom { tagName: "hr" }
                }
            }
        }
    }
    AccordionContent {
        id: _content
        doc_skip: "me"
        Dimmer {
            id: dimReadOnly
            doc_skip: "me"
            className: "light inverted"
        }
        Dimmer {
            doc_skip: 'me'
            active: loading
            className: 'inverted'
            Loading {
                className: 'large text'
                text: card.loadingText
            }
        }
        style: new Object({
            paddingBottom: '1em'
        })
    }
}

        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/Common/CardLayout.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}