import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `REG/CardCoverageDetails.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import QmlWeb 1.0
import "../Common/Util.js" as Util

Dom{
    id: crdCoverage
    property var confirmEnabled: false
    property alias cboCoverage_ : cboCoverage
    property var patient_hn
    property var action_type
    property var readOnly: false
    property var COVERAGE_TYPE: Object({
        'EMERGENCY': 'EMERGENCY',
        'ACCIDENT': 'ACCIDENT',
        'REFER': 'REFER',
        'PROJECT': 'PROJECT',
        'HANDICAP': 'HANDICAP',
        'UNDEFINED': 'UNDEFINED'
    })

    property var TYPE: Object({
        'COVERAGE': 1,
        'DISCOUNT_POLICY': 2,
        'DISCOUNT_CARD': 3
    })
    property RestModel rest : RestModel{
        id: rmdPatientCoverage
        url:"/apis/core/patientcoverage/"
        property var _id
        property var patient
        property alias coverage : cboCoverage.value
        property var payer
        property alias service_type : serviceType.text
        property alias assure_type : cboAssureType.value
        property var project
        property alias claim_code : claimCode.text
        property alias claim_code_request_date: dtbCodeRequest.text
        property alias claim_code_request_time: ttbCodeRequest.text
        property alias claim_code_expiry_date: dtbCodeExpire.text
        property alias claim_code_expiry_time: ttbCodeExpire.text
        property alias start_date: startDate.text
        property alias stop_date: expDate.text
        property var active
        property alias referer: cboReferer.value
        property alias refer_date: dtbReferDate.text
        property alias refer_no: txtReferNo.text
        property alias service_point: cboServicePoint.valueList
        property alias priority: cboPriority.value
        property alias main_hospital_code: txtMainHospitalCode.text

        onService_typeChanged:{
            if(service_type === "I")
                ipd.checked = true
            if(service_type === "O")
                opd.checked = true
        }
        onSaved:{
            setRestToData()
            refreshGrid()
            clear()
            sgmConfirmCoverage.loading = false
            Util.alert("บันทึกสำเร็จ", "green", "ตกลง", 2000)
        }
        onFetched:{
            setRestToData()
        }
        onFailed: {
            sgmConfirmCoverage.loading = false
            coverageDetailForm.showError(error)
        }
    }
    RestModel{
        id: rmdCheckGroupNumber
        url: '/apis/core/check-coverage-group-number/'
        property var is_dup
        property var first_cov
        property var second_cov
        onFetched:{
            if(is_dup){
                confirmDialog.message = "ผู้ป่วยมีสิทธิ์หลัก ("+first_cov+") ต้องการยืนยันบันทึกสิทธิ์หลัก ("+second_cov+") เพิ่มเติมใช่หรือไม่"
                confirmDialog.show()
            }
            else{
                saveOrEdit()
            }
        }
    }

    RestModel {
        id: rmdCoveragePayerSetting
        url: '/apis/core/coverage-payer-settings/rule/'
        property var approval_code_required: false
        property var referer_required: false
        property var main_hospital_code_required: false
        property var default_main_hospital_code: ''

    }

    ConfirmDialog{
        id: confirmDialog
        title : "แจ้งเตือน!"
        confirmFunction : saveOrEdit
    }

    function fetchCoveragePayereSetting() {
        if (cboCoverage.value == '' || cboCoverage.value == null || sbxPayerCode.selectedRow == null) {
            // we will waiting until coverage and payer select both.
            return
        }
        if (rmdPatientCoverage._id != null && rmdPatientCoverage._id != '') {
            // ignore if PatientCoverage had already saved.
            return
        }
        var coverage_id = cboCoverage.value 
        var payer_id = sbxPayerCode.selectedRow.id
        query = {}
        if (coverage_id != null) {
            query['coverage'] = coverage_id
        }
        if (payer_id != null) {
            query['payer'] = payer_id
        }
        crdCoverage.loading = true

        rmdCoveragePayerSetting.query = query
        rmdCoveragePayerSetting.fetch().then(function(){
            crdCoverage.loading = false
            txtMainHospitalCode.text = rmdCoveragePayerSetting.default_main_hospital_code
        }).catch(function(){
            crdCoverage.loading = false
            Util.alert('ไม่สามารถ load ข้อมูลสิทธิและ Payer ได้ กรุณาตรวจสอบ permission ')
        })
    }

    function saveOrEdit(){
        sgmConfirmCoverage.loading = true
        if(action_type === "save"){
            savePatientCoverage()
        }
        if(action_type === "edit"){
            editPatientCoverage()
        }
        if(action_type === "confirm"){
            activePatientCoverage()
        }
    }

    function checkGroupNumber(){
        setDataToRest()
        rmdCheckGroupNumber.query = {}
        rmdCheckGroupNumber.query.patient_id = patient_hn
        rmdCheckGroupNumber.query.coverage = cboCoverage.value
        if(rmdPatientCoverage._id){
            rmdCheckGroupNumber.query.patient_coverage_id = rmdPatientCoverage._id
        }
        rmdCheckGroupNumber.fetch()
    }
    function setRestToData(){
        if(rmdPatientCoverage.payer){
            sbxPayerCode.setData(rmdPatientCoverage.payer)
        }

        if(rmdPatientCoverage.project){
            projectCode.text = rmdPatientCoverage.project.code
            projectName.text = rmdPatientCoverage.project.name
            projectCode.valueID = rmdPatientCoverage.project.id
        }
    }
    function setDataToRest(){
        if(cboCoverage.text === "ข้าราชการ" && ipd.checked)
            claimCode.dataValidate = "e-ClaimCode"
        else
            claimCode.dataValidate = ""

        if(!checkValidate()){
            // detailTab.select()
            throw new Error("input is not valid");
        }
        rmdPatientCoverage.payer = {
            'id': sbxPayerCode.selectedId == -1 ? null : sbxPayerCode.selectedId
        }
        rmdPatientCoverage.patient = patient_hn
        rmdPatientCoverage.project = projectCode.valueID
        if(!projectCode.valueID)
            rmdPatientCoverage.project = null
    }
    function savePatientCoverage(){
        rmdPatientCoverage.active = true
        rmdPatientCoverage.url = "/apis/core/patientcoverage/"
        rmdPatientCoverage.create()
    }
    function editPatientCoverage(){
        rmdPatientCoverage.active = true
        rmdPatientCoverage.url = "/apis/core/patientcoverage/" + patientCoverageID.text + "/"
        rmdPatientCoverage.save()
    }
    function cancelPatientCoverage(){
        setDataToRest()
        rmdPatientCoverage.active = false
        rmdPatientCoverage.url = "/apis/core/patientcoverage/" + patientCoverageID.text + "/"
        rmdPatientCoverage.save()
    }
    function activePatientCoverage(){
        rmdPatientCoverage.active = true
        rmdPatientCoverage.url = "/apis/core/patientcoverage/" + patientCoverageID.text + "/"
        rmdPatientCoverage.save()
    }
    function deselectedGrid(){
        grdPatientCoverage.deselectCurrentRow()
    }
    function clear(){
        ipd.checked = false
        opd.checked = false
        cboAssureType.enabled = true
        cboCoverage.clear()
        sbxPayerCode.clear()
        projectCode.text = ""
        projectCode.valueID = 0
        projectName.text = ""
        claimCode.text = ""
        dtbCodeRequest.text = ""
        ttbCodeRequest.text = ""
        dtbCodeExpire.text = ""
        ttbCodeExpire.text = ""
        startDate.date = moment()
        expDate.date = moment()
        startDate.refreshCalendar()
        expDate.refreshCalendar()
        cboAssureType.setUndefined()
        serviceType.text = ""
        patientCoverageID.text = ""
        coverageDetailForm.clear()
        eClaimCodeField.displayNone = true
        flsEClaimCodeInfo.displayNone = true
        cboReferer.clear()
        dtbReferDate.text = ""
        txtReferNo.text = ""
        cboServicePoint.clear()
        cboPriority.value = 99
        rmdPatientCoverage._id = null
        txtMainHospitalCode.text = ''
    }
    function checkValidate(){
        coverageDetailForm.validateForm();
        return coverageDetailForm.isValid()
    }
    Segment {
        style: "background-color: #F3F3F3"
        className: "raised"
        id:segment
        Text{
            text:"ข้อมูลสิทธิ์"
            style:"font-weight:bold;"
        }
        Br{}
        Br{}
        Form {
            id: coverageDetailForm
            className: "small"
            Message{
                className: "error"
            }
            validateSetting:{
                return {
                    fields:{
                        "ประเภทบริการ" : 'empty',
                        "สิทธิ์" : 'empty',
                        // "ประเภทสิทธิ์" : 'empty',
                        "ผู้จ่ายเงิน" : 'empty',
                        "วันที่เริ่ม" : 'empty',
                        "วันหมดอายุ" : 'empty',
                        "รหัสโครงการ" : 'empty',
                        "e-ClaimCode" : 'empty',
                    },
                    prompt: {
                        // see customize promt
                        // http://semantic-ui.com/behaviors/form.html#customizing-prompts
                        empty: 'กรุณาเลือก {identifier}'
                    },
                    keyboardShortcuts: false
                };
            }
            Fields{
                Field{
                    className:"required"
                    label: "ประเภทบริการ"
                    Field {
                        className: "inline"
                        label:""
                        RadioButton {
                            id:opd
                            text: "OPD"
                            group: "type"
                            onChanged:{
                                if(checked){
                                    serviceType.text = "O"
                                }
                            }
                            value:"O"
                        }
                        Text{
                            text:"      "
                        }
                        RadioButton {
                            id:ipd
                            text: "IPD"
                            group: "type"
                            onChanged:{
                                if(checked){
                                    serviceType.text = "I"
                                }
                            }
                            value:"I"
                        }
                    }
                    RadioGroup{
                        group:"type"
                        readOnly: crdCoverage.readOnly
                        TextBox{
                            id: serviceType
                            text: ""
                            displayNone: true
                            dataValidate: "ประเภทบริการ"
                            readOnly: crdCoverage.readOnly
                            onTextChanged:{
                                if(text === "I") {
                                    eClaimCodeField.displayNone = false
                                    flsEClaimCodeInfo.displayNone = false
                                }
                                else {
                                    eClaimCodeField.displayNone = true
                                    flsEClaimCodeInfo.displayNone = true
                                }
                            }
                        }
                    }
                }
                Field{
                    className:'one wide'
                }
                Field{
                    className:'inline two wide'
                    label:'ลำดับการเลือกใช้สิทธิ์'
                    ComboBox{
                        id: cboPriority
                        className:'fluid'
                        value:99
                        readOnly: crdCoverage.readOnly
                        search: true
                        items:[
                            {id:99, text:'ไม่เลือก'},
                            {id:1, text:1},
                            {id:2, text:2},
                            {id:3, text:3},
                            {id:4, text:4},
                            {id:5, text:5},
                            {id:6, text:6},
                            {id:7, text:7},
                            {id:8, text:8},
                            {id:9, text:9},
                            {id:10, text:10},
                        ]
                    }
                }
            }
            Dom{tagName:"br"}
            Fields {
                Field {
                    label: "สิทธิ์"
                    className: "seven wide required"
                    ComboBox {
                        id: cboCoverage
                        dataValidate:"สิทธิ์"
                        optionTextField: 'code_name'
                        search: true
                        fullTextSearch: true
                        readOnly: crdCoverage.readOnly
                        Component.onCompleted:{
                            coverageRest.fetch()
                        }
                        RestModel {
                            id: coverageRest
                            url:"/apis/core/coverage/?limit=1000"
                            property alias items: cboCoverage.items
                        }
                        
                        onChanged:{
                            items.forEach(function(object){
                                if(object.id == value){
                                    if(object.type == TYPE.COVERAGE){
                                        cboAssureType.enabled = true
                                        fldPayer.setRequired(true)
                                    }
                                    else{
                                        cboAssureType.enabled = false
                                        cboAssureType.setUndefined()
                                        fldPayer.setRequired(false)
                                    }
                                    
                                    // from issue #39597 #39578 fetch coverage setting to apply txtMainHospitalCode
                                    fetchCoveragePayereSetting();
                                }
                            })
                        }
                    }
                }
                Field {
                    label: "ประเภทสิทธิ์"
                    className: "seven wide required"
                    ComboBox{
                        id: cboAssureType
                        readOnly: crdCoverage.readOnly
                        dataValidate:"ประเภทสิทธิ์"
                        function setUndefined(){
                            for(var i=0; i<cboAssureType.items.length; i++)
                            {
                                if(cboAssureType.items[i].code == COVERAGE_TYPE.UNDEFINED){
                                    cboAssureType.value = cboAssureType.items[i].id
                                    return
                                }
                            }
                        }
                        function isType(code){
                            for(var i=0; i<cboAssureType.items.length; i++)
                            {
                                if(cboAssureType.items[i].id == cboAssureType.value && cboAssureType.items[i].code == code){
                                    return true
                                }
                            }
                            return false
                        }
                        onChanged:{
                            if(isType(COVERAGE_TYPE.PROJECT)){
                                projectCode.dataValidate = "รหัสโครงการ"
                                projectField.displayNone = false
                            }
                            else{
                                projectCode.dataValidate = ""
                                projectField.displayNone = true
                            }
                        }
                        Component.onCompleted: {
                            rmdAssureType.fetch()
                        }
                        RestModel {
                            id: rmdAssureType
                            url: '/apis/core/assure-type/'
                            property alias items: cboAssureType.items
                        }
                    }
                }
            }
            Fields {
                Field {
                    id: fldPayer
                    function setRequired(value){
                        if(value){
                            fldPayer.className = "seven wide required"
                            sbxPayerCode.dataValidate = "ผู้จ่ายเงิน"
                        }
                        else{
                            fldPayer.className = "seven wide"
                            sbxPayerCode.dataValidate = ""
                        }
                    }
                    label: "ผู้จ่ายเงิน"
                    className: "seven wide required"
                    SearchBox {
                        id: sbxPayerCode
                        baseURL: "/apis/core/payer/"
                        title: "name_with_id"
                        customQuery: new Object({
                            coverage: sbxPayerCode.coverage
                        })

                        dataValidate: "ผู้จ่ายเงิน"
                        linkIcon: !crdCoverage.readOnly
                        readOnly: crdCoverage.readOnly ? true : !sbxPayerCode.coverage

                        property alias coverage: cboCoverage.value

                        onCoverageChanged: {
                            if(!sbxPayerCode.bySystem){
                                sbxPayerCode.clear()
                            }
                            sbxPayerCode.bySystem = false
                        }

                        onIconClicked: {
                            searchPayer.show()
                        }

                        onSelected: {
                            var query = {}
                            if(cboCoverage.value && sbxPayerCode.selectedRow.payer_id){
                                query.payer_id = sbxPayerCode.selectedRow.payer_id
                                query.coverage = cboCoverage.value
                                rmdCoveragePayerMap.query = query
                                rmdCoveragePayerMap.fetch().then(function() {
                                    if(rmdCoveragePayerMap.items.length > 0){
                                        sbxPayerCode.bySystem = true
                                        cboCoverage.value = rmdCoveragePayerMap.items[0].map_to_coverage 
                                    }
                                    else{
                                        sbxPayerCode.bySystem = false
                                    }

                                    // from issue #39597 #39578 fetch coverage setting to apply txtMainHospitalCode
                                    fetchCoveragePayereSetting();
                                })
                            }
                        }

                        RestModel {
                            id: rmdCoveragePayerMap
                            url: '/apis/core/coverage-payer-map/'
                            property var items
                        }

                        ModSearchDialog{
                            id: searchPayer
                            targetUrl : "/apis/core/payer/"
                            codeField: "payer_id"
                            customQuery: new Object({
                                coverage: sbxPayerCode.coverage
                            })
                            onSelected: {
                                sbxPayerCode.setData(selectedRow)
                            }
                        }
                    }
                }
                Field {
                    id: fldHospitalMainCode
                    label: 'รหัสสถานพยาบาลหลัก'
                    className: 'seven wide'
                    TextBox {
                        id: txtMainHospitalCode
                    }
                }
            }
            Fields {
                id: projectField
                displayNone: true
                Field {
                    label: "รหัสโครงการ"
                    className: "seven wide required"
                    ActionTextBox {
                        id: projectCode
                        property int valueID: 0
                        readOnly: crdCoverage.readOnly
                        enableButton: !crdCoverage.readOnly
                        function clear(){
                            projectCode.text = ""
                            projectName.text = ""
                            projectCode.valueID = 0
                        }
                        onButtonClicked:{
                            searchProject.show()
                        }
                        onEntered:{
                            projectRest.fetch()
                        }
                        onBlured:{
                            projectRest.fetch()
                        }
                        TextBox{
                            id:projectID
                            text: projectCode.valueID
                            dataValidate: ""
                            readOnly: crdCoverage.readOnly
                            displayNone: true
                        }
                        RestModel{
                            id: projectRest
                            url: "/apis/core/project/"
                            property var items
                            query:{
                                return{
                                    code : projectCode.text
                                }
                            }
                            onFetched:{
                                if(items.toString()){
                                    projectCode.text = items[0].code
                                    projectName.text = items[0].name
                                    projectCode.valueID = items[0].id
                                }
                                else{
                                    projectCode.text = ""
                                    projectName.text = ""
                                    projectCode.valueID = 0
                                }
                                items = []
                            }
                        }
                    }
                }
                Field{
                    className: "nine wide"
                    Text {
                        id: projectName
                        style: "font-size: 14px; vertical-align: -webkit-baseline-middle"
                    }
                    ModSearchDialog{
                        id: searchProject
                        targetUrl : "/apis/core/project/"
                        onSelected: {
                            projectCode.text = code
                            projectName.text = name
                            projectCode.valueID = _id
                        }
                    }
                }
            }
            Fields {
                id: eClaimCodeField
                displayNone: true
                Field {
                    label: "e-ClaimCode"
                    className: "seven wide"
                    TextBox {
                        id:claimCode
                        dataValidate: ""
                        readOnly: crdCoverage.readOnly
                    }
                }
            }
            Fields {
                id: flsEClaimCodeInfo
                displayNone: true
                Field {
                    label: 'วันที่ขอเลข e-claim'
                    className: 'four wide'
                    DateTextBox {
                        id: dtbCodeRequest
                        dataValidate: 'claim_code_request_date'
                        readOnly: crdCoverage.readOnly
                    }
                }
                Field {
                    label: 'เวลาที่ขอเลข e-claim'
                    className: 'four wide'
                    TimeTextBox {
                        id: ttbCodeRequest
                        dataValidate: 'claim_code_request_time'
                        readOnly: crdCoverage.readOnly
                    }
                }
                Field {
                    label: 'วันที่หมดอายุเลข e-claim'
                    className: 'four wide'
                    DateTextBox {
                        id: dtbCodeExpire
                        dataValidate: 'claim_code_expiry_date'
                        readOnly: crdCoverage.readOnly
                    }
                }
                Field {
                    label: 'เวลาที่หมดอายุเลข e-claim'
                    className: 'four wide'
                    TimeTextBox {
                        id: ttbCodeExpire
                        dataValidate: 'claim_code_expiry_time'
                        readOnly: crdCoverage.readOnly
                    }
                }
            }
            Fields {
                Field {
                    label: "วันที่เริ่ม"
                    className: "three wide required"
                    DateTextBox {
                        id: startDate
                        boundary:segment
                        readOnly: crdCoverage.readOnly
                        dataValidate: "วันที่เริ่ม"
                    }
                }
                Field {
                    label: "วันหมดอายุ"
                    className: "three wide"
                    // className: "three wide required"
                    DateTextBox {
                        id: expDate
                        boundary:segment
                        readOnly: crdCoverage.readOnly
                        // dataValidate: "วันหมดอายุ"
                    }
                }
            }
        }
    }
    Segment{
        style: "background-color: #F3F3F3"
        className: "raised"
        id:segment
        Text{
            text:"Refer-in"
            style:"font-weight:bold;font-size: larger;"
        }
        Br{}
        Br{}
        Form{
            className: "small"
            Fields{
                Field{
                    className: "four wide"
                    label: "Referer:"
                    ComboBox {
                        id: cboReferer
                        search: true
                        emptyItem: "ไม่เลือก"
                        readOnly: crdCoverage.readOnly
                        fullTextSearch: true
                        Component.onCompleted: {
                            rmdReferer.fetch()
                        }
                        RestModel{
                            id: rmdReferer
                            property alias items: cboReferer.items
                            url: "/apis/core/referer/"
                        }
                    }
                }

                Field{
                    className: "four wide"
                    label: "Refer date:"
                    DateTextBox{
                        id: dtbReferDate
                        readOnly: crdCoverage.readOnly
                        boundary:segment
                    }
                }
            }

            Field{
                label: "Refer No. :"
                TextBox{
                    id: txtReferNo
                    readOnly: crdCoverage.readOnly
                }
            }

            Fields{
                Field{
                    className:"eight wide"
                    label: "จุดบริการ:"
                    ComboBox{
                        id: cboServicePoint
                        search: true
                        multipleSelection: true
                        fullTextSearch: true
                        optionTextField: 'name_code'
                        readOnly: crdCoverage.readOnly
                        Component.onCompleted: {
                            rmdDivision.fetch()
                        }
                        RestModel{
                            id: rmdDivision
                            property alias items: cboServicePoint.items
                            url: "/apis/core/division/"
                            query: new Object({
                                for_opd_encounter: true
                            })
                        }
                    }
                }
            }
        }
    }
}

        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/REG/CardCoverageDetails.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}