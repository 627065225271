import React, { Component } from "react";
import * as SCREEN from "../Screen/";
import { withRouter, Switch, Route } from "react-router-dom";
import { Button, Menu, Icon, Input } from "semantic-ui-react";
import DiagComposer from "../react-lib/apps/IsHealth/Studio/DiagComposer";
import * as Controller from "../Controller";
import MixInManager from "../react-lib/apis/manager/MixInManager";
import PRXManager from "../react-lib/apis/manager/PRXManager";
import UserManager from "../react-lib/apis/manager/UserManager";
import * as IsHealthCommon from "../react-lib/apps/IsHealth/Common";
import * as Studio from "../react-lib/apps/IsHealth/Studio";
import { CookiesProvider, withCookies } from "react-cookie";
import styles from "../react-lib/apps/IsHealth/css/styles";
import { version } from "../../package.json";
import FacebookLogin from "react-facebook-login";
import "../css/DiagComposer.css";
import "../css/RuleList.css";
import "../css/Login.css";
import "../css/LandingPage.css";
import EmailLogin from "../react-lib/apps/IsHealth/Common/EmailLogin/EmailLoginHome";

const LogoutMenu = props => {

  let logoutStyle = {...styles.basicButtonLarge, backgroundColor: "#5dbcd2", color: "white" }
  let anonymousStyle = { backgroundColor: "#5dbcd2", color: "white" }
  return (
    <>
      <Menu.Menu position="right">
        {props.apiToken == "anonymous" && (
          <Menu.Item>
            <Icon name="user secret" inverted size="large" />
            <div style={{ color: "white" }}> Playground </div>
          </Menu.Item>
        )}
        {props.fbName && (
          <Menu.Item>
            <Button icon style={{backgroundColor:'transparent'}} onClick={props.onAvatarClick}> 
            <Icon name="user md" inverted size="large" />
            </Button>
            <div style={{ color: "white" }}> {props.fbName} {" "} { props.isVerified ? "" : "(ยังไม่ได้ยืนยันตัวตน)"} </div>
          </Menu.Item>
        )}
        <Menu.Item>
          <Button
            basic
            inverted
            style={ props.isAnonymous ? anonymousStyle : logoutStyle }
            onClick={props.handleLogout}
          >
            { props.isAnonymous ? "Go to Homepage" : "Logout"} 
           
          </Button>
        </Menu.Item>
      </Menu.Menu>
    </>
  );
};

const LoginMenu = props => {
  return (
    <Menu.Item position="right">
      <Button
        style={{ backgroundColor: "#5dbcd2", color: "white" }}
        onClick={props.onClick}
      >
        Login
      </Button>
    </Menu.Item>
  );
};

class MainDiag extends Component {
  constructor(props) {
    super(props);

    const { cookies } = props;

    // console.log('cookies', cookies)
    // console.log('props cookies', props)

    this.state = {
      divisionId: cookies.get("divisionId"),
      divisionName: cookies.get("divisionName"),
      apiToken: cookies.get("apiToken"),
      // isAnonymous: cookies.get("isAnonymous"),
      fbName: cookies.get("fbName"),
      fbImageURL: cookies.get("fbImageURL"),
      fbEmail: cookies.get("fbEmail"),
      fbAccessToken: "",
      isVerified: false,
      pentaFirstName:"", 
      pentaSurName:"",
      pentaTelephone:"",
      pentaEmail:"", 
      pentaDOB: "",
      pentaImage: null,
    };

    this.lineLoginManager = new IsHealthCommon.LineLoginManager();

    this.mixInManager = new MixInManager();
    this.prxManager = new PRXManager();
    this.userManager = new UserManager();

    this.loginController = new IsHealthCommon.LoginController({
      mixInManager: this.mixInManager,
      userManager: this.userManager
    });

    this.ruleListController = new Controller.RuleListController({
      prxManager: this.prxManager
    });

    this.diagComposerController = new Studio.DiagComposerController({
      prxManager: this.prxManager
    });

  }

  componentDidMount = async () => {
    await this.checkVerified()
  };

  checkVerified = async () => {
    await this.getDivision();
    let verified = await this.isDivisionVerified(this.state.division);
    console.log("componentDidMount verified", verified);
    this.setState({ isVerified: verified });
  }

  // componentWillUpdate = () => {
  //
  //   if (this.state.apiToken || this.state.isAnonymous) {
  //     this.props.history.push({
  //       pathname: "/ruleList",
  //       state: { userId: 0 }
  //     });
  //   }
  // }

  openLine = () => {
    this.lineLoginManager.openLogin();
  }


  handleOpenSetupEmail = () => {
    this.props.history.push({
      pathname: "/emailSetup",
      state: { userId: 0 }
    });
  };

  handleEmailKeying = async email => {
    if (email) {
      this.setState({ fbEmail: email });
      this.props.cookies.set("fbEmail", email, { path: "/" });
    }

    let [
      response,
      error,
      network
    ] = await this.loginController.getRegisterByTokenFacebook({
      accessToken: this.state.fbAccessToken,
      app: "ishealth",
      email: email
    });

    this.props.cookies.set("apiToken", response.token, { path: "/" });
    this.props.history.push({
      pathname: "/ruleList",
      state: { userId: response.profile.userId }
    });
  };

  extractFullName = name => {
    var index = name.indexOf(" ");  // Gets the first index where a space occours
    var firstname  = name.substr(0, index); // Gets the first part
    var surname = name.substr(index + 1);  
    return ({firstName: firstname, surName: surname})
  }

  handleLineLoginSuccess = props => {

    console.log('handlLineLoginSuccess  props ', props)

        //     userId: profile.userId,
        //     name: verify.name,
        //     picture: verify.picture + '/large',
        //     email: verify.email,
        //     success: true,
        //     apiToken: ,

      this.setState({
        fbName: props.verify.name,
        fbEmail: props.verify.email
      });
      this.props.cookies.set("fbName", props.verify.name, { path: "/" });
      this.props.cookies.set("fbEmail", props.verify.email, { path: "/" });


    this.setState({ apiToken: props.pentaToken.token });
    this.props.cookies.set("apiToken", props.pentaToken.token , { path: "/" });


    this.props.history.push({
      pathname: "/ruleList",
    });
  }

  handleLineLoginFailed = props => {
    console.log('props', props)
    this.props.history.push({ 
      pathname: "/",
    })
  }



  handleFacebookSiteLoginSuccess = fbResponse => {
    if (fbResponse && fbResponse.name) {
      if (fbResponse)
        this.setState({
          fbName: fbResponse.name,
          fbImageURL: fbResponse.picture.data.url
        });
      this.props.cookies.set("fbName", fbResponse.name, { path: "/" });
      this.props.cookies.set("fbImageURL", fbResponse.picture.data.url, {
        path: "/"
      });

      //var index = fbResponse.name.indexOf(" ");  // Gets the first index where a space occours
      //var firstName  = fbResponse.name.substr(0, index); // Gets the first part
      //var surname = fbResponse.name.substr(index + 1);  
      let namePenta = this.extractFullName(fbResponse.name)
      //this.setState({pentaFirstName: namePenta.firstName, pentaSurName: namePenta.surName})

      if ("email" in fbResponse) {
        this.setState({ fbEmail: fbResponse.email, pentaEmail: fbResponse.email });
        this.props.cookies.set("fbEmail", fbResponse.email, { path: "/" });
      } else {
        this.setState({ fbAccessToken: fbResponse.accessToken });
      }
    }
  };

  handleAnonymousLoginSuccess = async response => {
    // this.props.cookies.set("apiToken", response.token, { path: "/" });

    // this.props.cookies.set("isAnonymous", true, { path: "/" });
    this.props.cookies.set("apiToken", "anonymous", { path: "/" });
    this.setState({ apiToken: "anonymous" });

    this.props.history.push({
      pathname: "/composer_anonymous",
      state: { userId: 0 }
    });
  };

  handleLoginSuccess = async (token, userId) => {
    console.log(' apiToken ', token)
    if(token.hasOwnProperty("token")){
      this.setState({ apiToken: token.token });
      this.props.cookies.set("apiToken", token.token, { path: "/" });
    } else {
      this.setState({ apiToken: token });
      this.props.cookies.set("apiToken", token, { path: "/" });
    }
    this.checkVerified()
    this.props.history.push({
      pathname: "/ruleList",
      state: { userId: userId }
    });

    
  };
    // state: { userId: response.profile.userId }

  handleLogout = async (normal = true) => {
    if(window.FB){
      window.FB.logout(function(response) {
        console.log(" user Logout Facebook !")
      });
    }
    this.props.cookies.remove("apiToken", { path: "/" });
    this.props.cookies.remove("divisionId", { path: "/" });
    this.props.cookies.remove("divisionName", { path: "/" });
    // this.props.cookies.remove("isAnonymous", { path: "/" });
    this.props.cookies.remove("fbName", { path: "/" });
    this.props.cookies.remove("fbImageURL", { path: "/" });
    this.props.cookies.remove("fbEmail", { path: "/" });

    this.setState({ apiToken: this.props.cookies.apiToken });
    this.setState({
      isAnonymous: false,
      fbName: "",
      fbEmail: "",
      fbImageURL: ""
    });
    if (normal) {
      this.props.history.push({ pathname: "/", state: {} });
    }
  };

  isDivisionVerified = async id => {
    //
    if (!this.props.cookies.get("apiToken")) {
      console.log(' not API Token then return not getDivision Profile')
      return;
    }
    const [response, error, network] = await this.prxManager.getDivisionProfile(
      {
        apiToken: this.props.cookies.get("apiToken"),
        division: id
      }
    );

    // console.log('response' , response)
    if (response && response.items && response.items.length > 0) {
      if (response.items[0].verified) {
        return true;
      } else {
        return false;
      }
    } else {
      return false
    }
  };

  getDivision = async () => {
    if (!this.props.cookies.get("apiToken")) {
      console.log(' apiToken not have then not getDivision')
      return;
    }
    const [response, error, network] = await this.prxManager.getDivisionHasUser(
      {
        apiToken: this.props.cookies.get("apiToken")
      }
    );

    if (response && response.items.length > 0) {
      if (response.items[0].division && "id" in response.items[0].division ) {
        this.props.cookies.set("divisionId", response.items[0].division.id, {
          path: "/"
        });
        this.setState({ divisionId: response.items[0].division.id });
      }

      if (response.items[0].division && "name" in response.items[0].division) {
        this.props.cookies.set("divisionName", response.items[0].division.name, {
          path: "/"
        });
        this.setState({ divisionName: response.items[0].division.name });
      }
    } else {
      alert(
        "ผู้ใช้งานนี้ไม่มี โรงพยาบาล (Division) ของระบบ กรุณาติดต่อ ผู้ดูแล"
      );
      this.props.history.push("/afterLogin");
    }
  };

  handleHomeIconClick = () => {
    this.props.history.push("/");
  };

  handleAlreadyLogin = () => {
    this.props.history.push({
      pathname: "/ruleList",
      state: { userId: 0 }
    });
  };

  handleGoToRegister = async => {
    // pass false variable for  not back to Landing Page
    this.handleLogout(false);
    this.props.history.push({
      pathname: "/afterLogin",
      state: { userId: 0 }
    });
  };
  handleStudioProfileSave = async props => {
    console.log('handleStudioProfileSave ', props)
    let [
      response,
      error,
      network
    ] = await this.loginController.updateUserProfile({
      apiToken: this.state.apiToken,
      firstName: props.firstName,
      lastName: props.surName,
      dob: props.birthDate,
      phone: props.telephone,
     })

     if (response) { 
       console.log('save Success')
       // Update User profile
       let dob = response.dob ? response.dob : "";
        this.setState({
          pentaFirstName: response.first_name, 
          pentaSurName: response.last_name,
          pentaTelephone: response.phone_no,
          pentaEmail: response.email,
          pentaDOB: this.AD2BE(dob),
          pentaImage: response.image_url,
        })
     } else {
       console.log('save have problem')
     }

  }

  handleAvatarClick = async props => {
    // Check if
    // /apis/PRX/division-profile/

    //  console.log(' historhy', this.props.history)
    let currentPage =
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname;
    //  console.log(' currentPAge', currentPage)

    let isEditAfterPage =
      currentPage.includes("editProfile") || currentPage.includes("afterLogin");
    // console.log('isEditAter', isEditAfterPage)
    if (isEditAfterPage) {
      return;
    }

    let isVerified = await this.isDivisionVerified(this.state.division);
    // Update State 
    this.setState({isVerified: isVerified})
    if (isVerified) {
      this.props.history.push({
        pathname: "/editProfile",
        state: { userId: 0 }
      });
    } else {
      this.props.history.push({
        pathname: "/afterLogin",
        state: { userId: 0 }
      });
    }
  };

  handleGoToEditProfile = async => {
    this.props.history.push({
      pathname: "/editProfile"
    });
  };

  registerToken = async (token) => {
    let [
      response,
      error,
      network
    ] = await this.loginController.getRegisterByTokenFacebook({
      accessToken: token,
      app: "ishealth"
    });

    if (response) {
      return response
    } else if (error && error.email && error.email == "This field is required.") {
      alert("This field is required.");
      // TODO: Continue DEV "not have email" path
      // this.handleOpenSetupEmail();
      return false
    }
  }

  checkUserProfile = async pentaToken => { 

    let [
      user_response,
      user_error,
      user_network
    ] = await this.loginController.getUserProfile({ apiToken: pentaToken })
    console.log('CheckUserProfile', user_response)
    if (user_response && user_response.first_name) {
      // already have first name 
      console.log(' already have first name ,last name in penta DB.')
      let dob = user_response.dob ? user_response.dob : "";
      this.setState({
        pentaFirstName: user_response.first_name, 
        pentaSurName: user_response.last_name,
        pentaTelephone: user_response.phone_no,
        pentaEmail: user_response.email,
        pentaDOB: this.AD2BE(dob),
        pentaImage: user_response.image_url,
      })
      return true 
    } else {
      return false
    }

  }

  /** Convert Date */
  AD2BE = (dateAD) => {
    const dateSplitted = dateAD.split('/');
    if (dateSplitted.length !== 3) {
        return dateAD;
    }

    let year = String(parseInt(dateSplitted[2], 10) + 543);
    let month = String(parseInt(dateSplitted[1], 10)).padStart(2, '0');
    let day = String(parseInt(dateSplitted[0], 10)).padStart(2, '0');
    return `${day}/${month}/${year}`;
  }

  responseFacebook = async fbResponse => {

    if (fbResponse.accessToken) {
      if (fbResponse) {
        this.handleFacebookSiteLoginSuccess(fbResponse);
      }

      let pentaResponse = await this.registerToken(fbResponse.accessToken)
      console.log('pentaResponse',pentaResponse)
      if (pentaResponse && pentaResponse.token) {
        this.handleLoginSuccess(pentaResponse.token, pentaResponse.profile.userId);

        if (!this.checkUserProfile(pentaResponse.token)) {
          // TODO: May no need
          let fbName = this.extractFullName(fbResponse.name)
          await this.loginController.patchUserProfile({ 
            apiToken: pentaResponse.token, 
            first_name: fbName.firstName, 
            last_name: fbName.surName})
          this.setState({pentaFirstName: fbName.firstName, pentaSurName: fbName.surName})
        }
      } 
    }
  };


  render() {
    // console.log("this.state.division", this.state.division);

    return (
      <CookiesProvider>
        <div className="App">
          <Menu secondary style={{ backgroundColor: "#5dbcd2", margin: 0 }}>
            <Menu.Item>
              <div style={{ display: "flex" }}>
                <h2
                  style={{ color: "white", cursor: "pointer" }}
                  onClick={this.handleHomeIconClick}
                >
                  IsHealth Studio
                </h2>
              </div>
            </Menu.Item>
            {this.state.apiToken ||
            (this.props.location &&
              this.props.location.state &&
              this.props.location.state.userId) ||
            this.state.apiToken == "anonymous" ? (
              <LogoutMenu
                onAvatarClick={this.handleAvatarClick}
                isAnonymous={this.state.apiToken == "anonymous"}
                handleLogout={this.handleLogout}
                isAnonymous={this.state.apiToken == "anonymous"}
                fbName={this.state.fbName}
                isVerified={this.state.isVerified}
              />
            ) : (
              <> </>
            )}
          </Menu>
          <Switch>
          <Route
              exact
              path="/EmailLogin/"
              render={props => {
                return <EmailLogin {...props} />;
              }}
            />
            <Route exact path="/">
              <IsHealthCommon.LandingPage
                onHaveApiToken={this.handleAlreadyLogin}
                onHaveAnonymousLogin={this.handleAlreadyLogin}
                detail={
                  <>
                    <span className="header lightgreen">Is</span>
                    <span className="header">Health Studio</span>
                    <br />
                    <div>
                      แพลตฟอร์มสร้างแชทบอทโดยไม่ต้องเขียนโปรแกรม
                      สำหรับให้ผู้ป่วยคัดกรองอาการตนเอง
                    </div>
                    <br />
                    <div>
                      โรงพยาบาลและผู้ให้บริการอื่นๆสามารถสร้างแบบฟอร์มถามตอบในรูปแบบแชทบอทจาก{" "}
                      <b>Decision Tree</b> ที่เหมาะกับเคสของผู้ป่วยแต่ละโรค
                      เพื่อให้ผู้ป่วยสามารถประเมินอาการตัวเองได้เองจากที่บ้าน
                      ลดการมาโรงพยาบาลโดยไม่จำเป็น
                      ลดความเสี่ยงในการติดเชื้อในภาวะวิกฤต
                    </div>
                    <br />

                    <div style={{width:'50%'}}> 
                    <div>
                      <Button
                        fluid
                        content="เข้าสู่ระบบ"
                        size="huge"
                        className="whiteBasicButton"
                        onClick={() => {
                          this.props.history.push("/Login")
                          // this.handleAnonymousLoginSuccess();
                        }}
                      />
                    </div>
                      <br/>
                    <div>
                      <Button
                        fluid
                        content="คลิกเพื่อทดลองใช้งาน"
                        size="huge"
                        className="whiteBasicButton"
                        onClick={() => {
                          this.handleAnonymousLoginSuccess();
                        }}
                      />
                    </div>
                     {/* <Button
                        fluid
                        content="เข้าใช้งานด้วยไลน์"
                        size="huge"
                        className="whiteBasicButton"
                        onClick={this.openLine}
                      />

                    <br />

                    <FacebookLogin
                      appId="3314489911914234"
                      fields="name,email,picture"
                      callback={this.responseFacebook}
                      textButton="เข้าใช้งานด้วยเฟชบุ๊ก"
                    />
                    <br /> */}

                    </div>

                    {/* <Button style={{backgroundColor:'#00b900', color:'white'}} onClick={ this.openLine }>  </Button>  */}
                  </>
                }
              />
            </Route>
            <Route exact path="/Login">
              {/* <SCREEN.WelcomePage/> */}
              {/* สำหรับใส่ app info */}
              <IsHealthCommon.Login
                loginLine={this.openLine}
                controller={this.loginController}
                onLoginSuccess={this.handleLoginSuccess}
                IsAnonymousLogin={true}
                OnAnonymousLoginSuccess={this.handleAnonymousLoginSuccess}
                onLoginFacebookSiteSuccess={this.handleFacebookSiteLoginSuccess}
                onHaveApiToken={this.handleAlreadyLogin}
                openSetupEmail={this.handleOpenSetupEmail}
                onHaveAnonymousLogin={this.handleAlreadyLogin}
              />
            </Route>
            {/* <Route path="/login">
              <IsHealthCommon.Login
                controller={this.loginController}
                onLoginSuccess={this.handleLoginSuccess}
                IsAnonymousLogin={true}
                OnAnonymousLoginSuccess={this.handleAnonymousLoginSuccess}
                onLoginFacebookSiteSuccess={this.handleFacebookSiteLoginSuccess}
                onHaveApiToken={this.handleAlreadyLogin}
                onHaveAnonymousLogin={this.handleAlreadyLogin}
              />
            </Route> */}
            <Route path="/afterLogin">
              <Studio.AfterLogin
                onGoToEditProfile={this.handleGoToEditProfile}
              />
            </Route>
            <Route path="/editProfile">
              <Studio.StudioProfile 
                apiToken={this.state.apiToken}
                userVerified={this.state.isVerified}
                divisionName={this.state.divisionName} 
                firstName={this.state.pentaFirstName}
                surName={this.state.pentaSurName}
                birthDate={this.state.pentaDOB}
                telephone={this.state.pentaTelephone}
                email={this.state.pentaEmail}
                image={this.state.pentaImage}
                onLoadProfile={this.checkUserProfile}
                onSave={this.handleStudioProfileSave}
              />
            </Route>
            <Route path="/emailSetup">
              <IsHealthCommon.EmailSetup handleEmail={this.handleEmailKeying} />
            </Route>
            <Route exact path="/ruleList">
              <SCREEN.RuleList
                apiToken={this.state.apiToken}
                controller={this.ruleListController}
                onSetState={state => this.setState({ ...state })}
                onGetDivision={this.getDivision}
                division={this.state.division}
              />
            </Route>
            <Route
              exact
              path="/composer/:id/"
              render={props => (
                <DiagComposer
                  {...props}
                  controller={this.diagComposerController}
                  apiToken={this.state.apiToken}
                  onGetDivision={this.getDivision}
                  division={this.state.division}
                  goToRegister={this.handleGoToRegister}
                  isVerified={this.state.isVerified}
                />
              )}
            />
            <Route
              exact
              path="/composer_anonymous/"
              render={props => (
                <SCREEN.RuleList
                  apiToken={this.state.apiToken}
                  controller={this.ruleListController}
                  onSetState={state => this.setState({ ...state })}
                  onGetDivision={this.getDivision}
                  division={"trial"}
                  isVerified={this.state.isVerified}
                />
              )}
            />
            
            <Route
            exact
            path="/callback/"> 
              <IsHealthCommon.LineCallBack 
              onLoginSuccess={this.handleLineLoginSuccess} 
              onLoginFailed={this.handleLineLoginFailed} 
               />
            </Route>

          </Switch>
        </div>
      </CookiesProvider>
    );
  }
}

export default withCookies(withRouter(MainDiag));
