import React, {useState} from 'react';
import { Input } from "semantic-ui-react";
import EmailLoginManager from "./EmailLoginManager";
import ReCAPTCHA from "react-google-recaptcha";
import { Form, Button } from "semantic-ui-react";
import { ErrorMessage } from '../../../common';
import { useHistory } from "react-router-dom";

const Register = props => {
    const history = useHistory()
    const reCAPTCHA_KEY = "6LdvmfUUAAAAAIb2DBfgd_xeN-xk4b6qq_3aR-UH"
    const manager = new EmailLoginManager();
    let [email, setEmail] = useState("");
    let [firstName, setFirstName] = useState("");
    let [lastName, setLastName] = useState("");
    let [recaptcha, setRecaptcha] = useState();
    let [message, setMessage] = useState("");
    let [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false)

    const onLogin = async () => {
        // setMessage("")
        // setErrorMessage("")
        try {
            let ret = await manager.requestLogin(email)
            // setMessage(ret.detail)
        } catch (e) {
            // setErrorMessage(JSON.stringify(e.response.data))
        }
    }

    const onCreate = async () => {
        setErrorMessage('')
        setMessage('')
        setIsLoading(true)
        try {
            let ret = await manager.register(email, recaptcha, firstName, lastName);
            setMessage(ret.detail)
            onLogin()
            setIsLoading(false)
        } catch (e) {
            let msg = JSON.stringify(e.response.data)
            setErrorMessage(msg)
            setIsLoading(false)
        }
    }

    return <div style={{ height: "100vh", padding: "3rem" }}>
        <h2>Create new account</h2>
        <ErrorMessage error={errorMessage}/>
        <ErrorMessage success={message}/>
        <Form>
            <Form.Group >
                <Form.Field width={16}>
                    <label htmlFor="inputEmail">Email address</label>
                    <Input type="email" className="form-control" id="inputEmail" required value={email} onChange={e => setEmail(e.target.value)} />
                </Form.Field>
            </Form.Group>
            <Form.Group >
                <Form.Field width={16}>
                    <label htmlFor="firstName">First name</label>
                    <Input className="form-control" id="firstName" value={firstName} required onChange={e => setFirstName(e.target.value)}/>
                </Form.Field>
            </Form.Group>
            <Form.Group >
                <Form.Field width={16}>
                    <label htmlFor="lastName">Last name</label>
                    <Input className="form-control" id="lastName" value={lastName} required onChange={e => setLastName(e.target.value)}/>
                </Form.Field>
            </Form.Group>
        </Form>
        <ReCAPTCHA
            sitekey={reCAPTCHA_KEY}
            size="normal"
            onChange={setRecaptcha}
        />
        <br/>
        <Button loading={isLoading} disabled={isLoading} primary onClick={onCreate}>Create</Button>
    </div>
}

export default Register;
