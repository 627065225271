import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `REG/CardPatientInfo.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import QmlWeb 1.0
import "../Common/Util.js" as Util

Segment{
    className: "defaultBackground"

    property alias txtBirthDate_ : txtBirthDate
    property alias cboBirthDate_ : cboBirthDate
    property alias cboSecret_ : cboSecret
    property alias cboSecretField_ : cboSecretField
    property alias leftForm : form
    property var nickname_using : false
    property var system_command : false
    property var BIRTHDATE_FULL: 0
    property var BIRTHDATE_YEAR_ONLY: 1

    CSS{
        url:'/REG/css/CardPatientInfo.css'
    }

    property RestModel restModel: RestModel
    {
        ignore: ['sequence']
        property var full_age: ""
        property var full_name: ""
        property var old_name: ""
        property var encounter_id: ""
        property var encounter_no: ""
        property var division_name: ""
        property var date_time: ""
        property var disable_nickname_reason: ""
        property var change_name_reason: ""
        property var change_name_ref_no: ""
        property var citizen_passport: ""
        property var encounter_type: ""
        property var room_no: ""

        property alias pre_name_th          : cboPrenameTh.value
        property alias first_name_th        : txtFirstName.text
        property alias middle_name_th       : txtMiddleName.text
        property alias last_name_th         : txtLastName.text

        property alias pre_name_en          : cboPrenameEn.value
        property alias first_name_en        : txtFirstNameEn.text
        property alias middle_name_en       : txtMiddleNameEn.text
        property alias last_name_en         : txtLastNameEn.text
        property alias sequence             : txtSeq.text

        property alias gender               : cboGender.value
        property var birthdate              // : txtBirthDate.text
        property bool birthdate_year_only
        // property alias deathdate         : ""
        // property alias edit_user         : ""
        property alias blood_type           : cboBlood.value
        property alias allergy              : acbAllergic.text
        property alias marriage             : cboMaritalStatus.value
        property alias couple               : txtSpouseName.text
        property alias father               : txtFatherName.text
        property alias mother               : txtMotherName.text
        // property alias relative             : txtRelativeName.text
        // property alias relative_related     : txtRelation.text
        property alias info_channel         : cboInfoChannel.value
        property alias education            : cboEducation.value
        property alias career               : cboCareer.text
        property alias position             : cboPosition.text
        property alias nationality          : cboNation.value
        property alias religion_code        : cboReligion.value
        property alias is_secret            : cboSecret.value
        property var is_newborn_unconfirmed : false
        property alias citizen_type         : cboPassport.value
        property alias citizen_no           : txtCitizenNo.text
        property alias passport_no          : txtPassportNo.text
        property alias alien_no             : txtAlienNo.text
        property alias reimburse_foreign_code   : txtReimburseForeignCode.text
        property alias non_resident         : chkNonResident.checked

        property alias birth_province       : cboProvince.value
        property alias birth_country        : cboCountry.value

        property alias nickname             : cboNickname.value
        property alias nickname_active      : chkNicknameActive.checked
        property alias nickname_permit_no   : txtNicknamePermit.text
        property alias nickname_reason      : cboNicknameReason.value
        property alias nickname_warrantor   : txtNicknameWarrantor.text
        property alias race                 : cboRace.value
        property alias belief               : cboBelief.text

        property alias ref_hn               : txtHNRama.text

        Component.onCompleted: form.setupValidate($context, $properties, restModel)
    }

    /**
    * For setting the patient data programmatically.
    * These data may obtained from SmartCard or National Database
    **/
    function setPatientData(citizen_no, birth_date, sex, th_prefix, th_firstname, th_lastname,
        en_prefix, en_firstname, en_lastname, nationality, infoChannel) {
        txtCitizenNo.text = citizen_no
        txtBirthDate.text = birth_date
        txtFirstName.text = th_firstname
        txtLastName.text = th_lastname
        txtFirstNameEn.text = en_firstname
        txtLastNameEn.text = en_lastname
        cboGender.text = sex
        cboPrenameTh.text = th_prefix
        cboPrenameEn.text = en_prefix
        cboNation.text = nationality
        cboInfoChannel.text = infoChannel
    }

    function setExtraPatientData(father_name, mother_name, middle_name_th, middle_name_en) {
        txtFatherName.text = father_name
        txtMotherName.text = mother_name
        txtMiddleName.text = middle_name_th
        txtMiddleNameEn.text = middle_name_en
    }


    // Prename ---------------------------------------------------------------------------------
    RestModel{
        id: prenameThRest
        property alias items: cboPrenameTh.items
        url: "/apis/core/prename?language=TH&disable_pagination=true"
        queryMimeType: "application/json"
    }
    RestModel{
        id: prenameEnRest
        property alias items: cboPrenameEn.items
        url: "/apis/core/prename?language=EN&disable_pagination=true"
        queryMimeType: "application/json"
    }

    // Gender ---------------------------------------------------------------------------------
    RestModel{
        id: genderRest
        property alias items: cboGender.items
        url: "/apis/core/choices/?model=core.Patient&field=gender"
        queryMimeType: "application/json"
    }

    // Country ---------------------------------------------------------------------------------
    RestModel{
        id: countryRest
        property alias items: cboCountry.items
        url: "/apis/core/country/?limit=99999"
        queryMimeType: "application/json"
    }

    // Province ---------------------------------------------------------------------------------
    RestModel{
        id: provinceRest
        property alias items: cboProvince.items
        url: "/apis/core/province/?limit=99999"
        queryMimeType: "application/json"
    }

    // Blood Type ---------------------------------------------------------------------------------
    RestModel{
        id: bloodTypeRest
        property alias items: cboBlood.items
        url: "/apis/REG/bloodtype/"
        queryMimeType: "application/json"
    }

    // Career ---------------------------------------------------------------------------------
    RestModel{
        id: careerRest
        property alias items: cboCareer.items
        url: "/apis/core/career/"
        queryMimeType: "application/json"
    }

    // Religion ---------------------------------------------------------------------------------
    RestModel{
        id: religionRest
        property alias items: cboReligion.items
        url: "/apis/core/religion/"
        queryMimeType: "application/json"
    }

    // Nationality ---------------------------------------------------------------------------------
    RestModel{
        id: nationalityRest
        property alias items: cboNation.items
        url: "/apis/core/nationality/?limit=99999"
        queryMimeType: "application/json"
    }

    // Education ---------------------------------------------------------------------------------
    RestModel{
        id: educationRest
        property alias items: cboEducation.items
        url: "/apis/core/education/"
        queryMimeType: "application/json"
    }

    // Nickname ---------------------------------------------------------------------------------
    RequestMemory{
        id: nicknameMem
        property alias items: cboNickname.items
        url: "/apis/REG/nickname/"

        onFetched:{
            setNonUsedNickname()
        }
    }

    function setNonUsedNickname(){
        var  queryset = nicknameMem.getFilter();
        queryset = queryset.eq('used', false).eq('active', true);
        cboNickname.items = nicknameMem.filter(queryset)
    }


    // Nickname reason ---------------------------------------------------------------------------------
    RestModel{
        id: nicknameReasonRest
        property alias items: cboNicknameReason.items
        url: "/apis/REG/nicknamereason/"
        queryMimeType: "application/json"
    }

    // Nickname Warrantor ---------------------------------------------------------------------------------
    // RestModel{
    //     id: nicknameWarrantorRest
    //     property alias items: cboNicknameWarrantor.items
    //     url: "/apis/core/user/"
    //     queryMimeType: "application/json"
    // }

    // Info Channel ---------------------------------------------------------------------------------
    RestModel{
        id: infoChannelRest
        property alias items: cboInfoChannel.items
        url: "/apis/REG/infochannel/"
        queryMimeType: "application/json"
    }

    // Race ---------------------------------------------------------------------------------
    RestModel{
        id: rmdRace
        property alias items: cboRace.items
        url:"/apis/core/clinicalterm/"
        queryMimeType: "application/json"
        query: {
            return {
                limit: 300,
                type: "RACE"
            }
        }
    }

    // Position ---------------------------------------------------------------------------------
    RestModel{
        id: rmdPosition
        property alias items: cboPosition.items
        url:"/apis/core/clinicalterm/"
        queryMimeType: "application/json"
        query: {
            return {
                type: "POS"
            }
        }
    }

    // Permission ----------------------------------------------------------------------------------
    Common.UserPermission {
        id: permission
        property bool rolegroup_CAN_EDIT_SECRET_DOCUMENT_CODE
    }

    // CitizenType ---------------------------------------------------------------------------------
    TextBox{
        id: txtCitizenTypeSelected
        text: "T"
        displayNone: true
    }
    Form{
        id : form
        fieldLabel: new Object({
            'birthdate': 'วันเดือนปีเกิด'
        })

        Message{
            className: "error"
        }

        Form{
            Fields{
                className: "inline"
                Field{
                    className: "three wide"
                    Text{
                        text: "ประวัติผู้ป่วย<br/>/ Patient history"
                        className: "header"
                    }
                }
                Field {
                    className: 'six wide'
                    label: 'HN ของระบบเดิม'
                    TextBox {
                        id: txtHNRama
                        inputAttr: new Object({maxlength: 50})
                        doc_label: 'HN ของระบบเดิม'
                    }
                }
                Field{
                    className:"seven wide required"
                    label:"ช่องทางให้ข้อมูล"
                    labelColor:"red"
                    ComboBox{
                        id: cboInfoChannel
                        search: true
                        fullTextSearch: true
                        allowAdditions: true
                        forceSelection: true
                        Component.onCompleted:{
                            infoChannelRest.fetch()
                        }
                    }
                }
            }
        }


        Divider{}
        
        Fields{
            displayNone: !permission.rolegroup_CAN_EDIT_SECRET_DOCUMENT_CODE
            id: cboSecretField
            Field{
                className: "six wide required"
                label: "การปกปิดข้อมูลผู้ป่วย / Concealment of patient data"
                labelColor: "red"
                ComboBox {
                    id: cboSecret
                    items: [
                        {id: "N", name: "ไม่ปกปิด"},
                        {id: "S", name: "ปกปิดประวัติ"},
                    ]
                }
            }
        }
        Fields {
            Field {
                className: "five wide required"
                label:"เอกสารระบุตัวตน<br/>/ National Identification number"
                labelColor: "red"
                ComboBox {
                    id: cboPassport
                    items:[
                        {id: 'T', name:'เลขประจำตัวประชาชน'},
                        {id: 'F', name:'เลขที่หนังสือเดินทาง'},
                        {id: 'A', name:'ใบต่างด้าว'},
                        {id: 'N', name:'ไม่มีเอกสารยืนยันตัว'},
                    ]
                    onChanged: {
                        fldCitizenNo.displayNone = true
                        fldPassportNo.displayNone = true
                        fldAlienNo.displayNone = true
                        fldReimburseForeignCode.displayNone = true
                        if(cboPassport.value == 'T') {
                            fldCitizenNo.displayNone = false
                            txtPassportNo.text = ''
                            txtAlienNo.text = ''
                        }
                        else if(cboPassport.value == 'F') {
                            fldPassportNo.displayNone = false
                            fldReimburseForeignCode.displayNone = false
                            txtCitizenNo.text = ''
                            txtAlienNo.text = ''
                        }
                        else if(cboPassport.value == 'A') {
                            fldAlienNo.displayNone = false
                            fldReimburseForeignCode.displayNone = false
                            txtCitizenNo.text = ''
                            txtPassportNo.text = ''
                        }
                        else {
                            txtCitizenNo.text = ''
                            txtPassportNo.text = ''
                            txtAlienNo.text = ''
                            txtReimburseForeignCode.text = ''
                        }
                    }
                }
            }
            Field {
                className: "five wide required"
                id: fldCitizenNo
                label:"หมายเลขบัตรประจำตัวประชาชน<br/>/ Identification number"
                labelColor: "red"
                displayNone: true
                TextBox{
                    id: txtCitizenNo
                    placeholder: "XXXXXXXXXXXXX"
                    doc_label: 'รหัสประชาชนไทย'
                    textLength: 13
                }
            }
            Field {
                className: "four wide required"
                id: fldPassportNo
                label:"หมายเลขหนังสือเดินทาง<br/>/ Passport number"
                labelColor: "red"
                displayNone: true
                TextBox{
                    id: txtPassportNo
                    doc_label: 'หนังสือเดินทาง'
                    placeholder: "XXXXXXXXXXXX"
                }
            }
            Field {
                className: "four wide required"
                id: fldAlienNo
                label:"หมายเลขใบต่างด้าว<br/>/ Alien Certificate number"
                labelColor: "red"
                displayNone: true
                TextBox{
                    id: txtAlienNo
                    doc_label: 'เลขใบต่างด้าว'
                    placeholder: "XXXXXXXXXXXX"
                }
            }
            Field {
                className: "five wide"
                id: fldReimburseForeignCode
                label:"เลขประจำตัวกรมบัญชีกลาง"
                displayNone: true
                TextBox {
                    id: txtReimburseForeignCode
                    doc_label: 'เลขประจำตัวกรมบัญชีกลาง'
                    placeholder: "XXXXXXXXXXXX"
                }
            }
            Field {
                id: fldNonResident
                CheckBox{
                    id: chkNonResident
                    text:"Non-resident"
                }
            }
        }

        Fields{
            Field{
                className:"three wide required"
                label:"คำนำหน้า / Prefix"
                labelColor: "red"
                ComboBox{
                    id: cboPrenameTh
                    search: true
                    emptyItem: " "
                    forceSelection: true
                    Component.onCompleted:{
                        prenameThRest.fetch()
                    }
                }
            }
            Field{
                className:"four wide required"
                label:"ชื่อ / First name"
                labelColor: "red"
                TextBox{
                    id: txtFirstName
                    placeholder: ""
                    // dataValidate: "ชื่อ"

                }
            }
            Field{
                className:"four wide required"
                label:"นามสกุล / Last name"
                labelColor: "red"
                TextBox{
                    id: txtLastName
                    placeholder: ""
                    // dataValidate: "นามสกุล"
                }
            }
            Field{
                className:"four wide"
                label:"ชื่อกลาง / Middle name"
                TextBox{
                    id: txtMiddleName
                    placeholder: ""
                }
            }
            Field{
                id: fldSeq
                className:"three wide"
                displayNone: txtSeq.text ? false : true
                label:"เลขลำดับ / seq"
                TextBox{
                    id: txtSeq
                    readOnly: true
                }
            }
        }

        Fields{
            Field{
                className:"three wide"
                label:""
                ComboBox{
                    id: cboPrenameEn
                    search: true
                    forceSelection: true
                    emptyItem: " "
                    Component.onCompleted:{
                        prenameEnRest.fetch()
                    }
                    doc_label: "คำนำหน้าชื่อภาษาอังกฤษ"
                }
            }
            Field{
                className:"four wide"
                label:""
                TextBox{
                    id: txtFirstNameEn
                    doc_label: "First name"
                }
            }
            Field{
                className:"four wide"
                label:""
                TextBox{
                    id: txtLastNameEn
                    doc_label: "Last name"
                }
            }
            Field{
                className:"four wide"
                label:""
                TextBox{
                    id: txtMiddleNameEn
                    doc_label: "Middle name"
                }
            }
            Field{
                id: fldSeqEn
                displayNone: txtSeq.text ? false : true
                className:"three wide"
            }
        }
        Fields{
            className:"inline"
            Field{
                className:"one wide required"
                label:"เพศ<br/>/ Gender"
                labelColor:"red"
            }
            Field{
                className:"three wide required"
                ComboBox{
                    id: cboGender
                    doc_label:"เพศ"
                    search: true
                    forceSelection: true
                    fullTextSearch: true
                    optionTextField: "value"
                    Component.onCompleted:{
                        genderRest.fetch()
                    }
                }
            }
            Field{
                className:"four wide required"
                label:"หมู่เลือด<br/>/ Blood type"
                labelColor:"red"
                ComboBox{
                    id: cboBlood
                    search: true
                    forceSelection: true
                    Component.onCompleted:{
                        bloodTypeRest.fetch()
                    }
                }
            }
            Field{
                className: "eight wide"
                label: "ข้อมูลการแพ้<br/>/ Allergic"
                AutoCompleteBox {
                    id: acbAllergic
                    RestModel{
                        id: rmdAllergic
                        url:"/apis/core/clinicalterm/"
                        property alias items: acbAllergic.options
                        property var default
                        query: {
                            return {
                                type: "C"
                            }
                        }
                    }
                    Component.onCompleted:{
                        rmdAllergic.fetch()
                    }
                }
            }
        }
        Fields{
            className:"inline"
            Field{
                className: "five wide"
                ComboBox{
                    id: cboBirthDate
                    value: BIRTHDATE_FULL
                    doc_label: "รูปแบบวันเกิด"
                    suffixClassName: "dropdown"
                    className: "red"
                    items: [
                        { id: BIRTHDATE_FULL, name: "วันเดือนปีเกิด / date of birth*" },
                        { id: BIRTHDATE_YEAR_ONLY, name: "ปีเกิด (พ.ศ.) / year of birth*" },
                    ]
                    onChanged: {
                        if(value == BIRTHDATE_FULL){
                            restModel.birthdate_year_only = false
                        }else{
                            restModel.birthdate_year_only = true
                        }
                        txtAge.recalculate()
                    }
                }
            }
            Field{
                className:"five wide required"
                displayNone: cboBirthDate.value != 0
                DateTextBox{
                    id: txtBirthDate
                    dataValidate: "birthdate"
                    doc_label: "วันเดือนปีเกิด"
                    future: false
                    onTextChanged: {
                        if(txtAge.recalculate()) {
                            txtBirthYear.text = txtBirthDate.getMomentValue().year() + 543
                        }
                    }
                }
            }
            Field{
                className:"five wide required"
                displayNone: cboBirthDate.value != 1
                TextBox{
                    id: txtBirthYear
                    dataValidate: "birthdate"
                    inputType: "number"
                    doc_label: "ปีเกิด"
                    onChanged: {
                        if(cboBirthDate.value == BIRTHDATE_YEAR_ONLY) {
                            if(txtAge.recalculate()) {
                                txtBirthDate.text = "01/01/" + txtBirthYear.text
                            } else {
                                txtBirthDate.text = ""
                            }
                        }
                    }
                }
            }
            Field{
                label:"อายุ / Age"
                className:"four wide"
                Text {
                    id: txtAge
                    doc_skip: false
                    function recalculate() {
                        // return true on update calculate success
                        var birthdate;
                        if(cboBirthDate.value == BIRTHDATE_YEAR_ONLY) {
                            // assume first day of the year
                            var birthYear = txtBirthYear.text
                            birthdate = moment([parseInt(birthYear) - 543])
                            // don't allow year in the future (or incomplete year)
                            if(birthdate.isAfter(moment()) || birthYear.length < 4) {
                                txtAge.text = ""
                                return false
                            }
                        } else if (cboBirthDate.value == BIRTHDATE_FULL) {
                            birthdate = txtBirthDate.getMomentValue().startOf('day')
                        }
                        txtAge.text = ""
                        if(birthdate.isValid()) {
                            txtAge.text = Util.getAge(birthdate)
                            return true
                        }
                        return false
                    }
                }
            }
            Field{
                label:"ศาสนา / Religion"
                className:"six wide"
                ComboBox{
                    id: cboReligion
                    search: true
                    forceSelection: true
                    emptyItem: '-'
                    fullTextSearch: true
                    optionTextField: "full_name"
                    Component.onCompleted:{
                        religionRest.fetch()
                    }
                }
            }
        }
        Fields{
            className:"inline"
            Field{
                label:"ประเทศที่เกิด<br/>/ Birth Country"
                className:"six wide"
                ComboBox{
                    id: cboCountry
                    search: true
                    forceSelection: true
                    emptyItem: '-'
                    fullTextSearch: "exact"
                    optionTextField: "full_name"
                    // match: "value"
                    Component.onCompleted:{
                        countryRest.fetch()
                    }
                }
            }
            Field{
                label:"จังหวัดที่เกิด<br/>/ Birth place"
                className:"five wide"
                ComboBox{
                    id: cboProvince
                    search: true
                    forceSelection: true
                    emptyItem: '-'
                    Component.onCompleted:{
                        provinceRest.fetch()
                    }
                }
            }
            Field{
                label:"สัญชาติ<br/>/ Nationality"
                className:"five wide required"
                labelColor:"red"
                ComboBox{
                    id: cboNation
                    search: true
                    forceSelection: true
                    // fullTextSearch: true
                    optionTextField: "full_name"

                    Component.onCompleted:{
                        nationalityRest.fetch()
                    }
                }
            }
        }
        Fields{
            className:"inline"

            Field{
                className:"five wide"
                label:"เชื้อชาติ<br/>/ Ethnicity"
                ComboBox{
                    id: cboRace
                    doc_label: 'เชื้อชาติ'
                    search: true
                    forceSelection: true
                    emptyItem: '-'
                    Component.onCompleted:{
                        rmdRace.fetch()
                    }
                }
            }

            Field{
                className:"eleven wide"
                label:"ความเชื่อ<br/>/ Belief"
                ComboBox {
                    id: cboBelief
                    doc_label: 'ความเชื่อ'
                    search: true
                    fullTextSearch: true
                    multipleSelection: true
                    allowAdditions: true
                    forceSelection: true
                    RestModel{
                        id: rmdBelief
                        url:"/apis/core/clinicalterm/"
                        property alias items: cboBelief.items
                        property var default
                        query: {
                            return {
                                type: "SA"
                            }
                        }
                    }
                    Component.onCompleted:{
                        rmdBelief.fetch()
                    }
                }
            }
        }
        Fields{
            className:"inline"

            Field{
                className:"six wide"
                label:"อาชีพ<br/>/ Occupation"
                ComboBox{
                    id: cboCareer
                    search: true
                    emptyItem: '-'
                    forceSelection: true
                    fullTextSearch: true
                    allowAdditions: true
                    Component.onCompleted:{
                        careerRest.fetch()
                    }
                }
            }

            Field{
                className:"seven wide"
                label:"ตำแหน่งงาน<br/>/ Position"
                ComboBox{
                    id: cboPosition
                    search: true
                    emptyItem: '-'
                    forceSelection: true
                    fullTextSearch: true
                    allowAdditions: true
                    Component.onCompleted: {
                        rmdPosition.fetch()
                    }
                }
            }
            Field{
                className:"eight wide"
                label:"ระดับการศึกษา<br/>/ Level of education"
                ComboBox{
                    id: cboEducation
                    search: true
                    emptyItem: '-'
                    forceSelection: true
                    Component.onCompleted:{
                        educationRest.fetch()
                    }
                }
            }
        }
        Fields{
            className:"inline"
            Field{
                className:"eight wide"
                label:"สถานภาพสมรส<br/>/ Marital status"
                ComboBox{
                    id: cboMaritalStatus
                    // value:"S"
                    search: true
                    emptyItem: '-'
                    forceSelection: true
                    // dataValidate: "สถานภาพสมรส"
                    items:[
                        {
                            id: "S",
                            text: "โสด"
                        },
                        {
                            id: "M",
                            text: "สมรส"
                        },
                        {
                            id: "E",
                            text: "หมั้น"
                        },
                        {
                            id: "D",
                            text: "หย่า"
                        },
                        {
                            id: "W",
                            text: "หม้าย"
                        },
                        {
                            id: "A",
                            text: "แยกกันอยู่"
                        }
                    ]
                }
            }
            Field{
                className:"eight wide"
                label:"ชื่อคู่สมรส<br/>/ Spouse's Name"
                TextBox{
                    id: txtSpouseName
                }
            }
        }
        Fields{
            className:"inline"
            Field{
                className:"eight wide required"
                label:"ชื่อบิดา<br/>/ Father's Name"
                labelColor:"red"
                TextBox{
                    id: txtFatherName
                }
            }
            Field{
                label:"ชื่อมารดา<br/>/ Mother's Name"
                labelColor:"red"
                className:"eight wide required"
                TextBox{
                    id: txtMotherName
                }
            }
        }

        Br{}

        Field{
            label:"นามแฝง"
            className: "inline"
            CheckBox{
                id: chkNicknameActive
                doc_label:"ใช้นามแฝง"
                type: "toggle"
                text:"&nbsp;"
                onChanged:{
                    restModel.disable_nickname_reason = ""

                    // Non nickname -> turn on
                    if(!system_command && !nickname_using && checked){
                        setNonUsedNickname();
                        nickNameContainer.transition("slide down");
                    }
                    // Non nickname -> turn off
                    else if(!system_command && !nickname_using && !checked){
                        nickNameContainer.transition("fade down");
                    }
                    // Nickname using -> turn on
                    else if(!system_command && nickname_using && checked){
                        nickNameContainer.transition("fade down");
                    }
                    // Nickname using -> turn off
                    else if(nickname_using && !checked){
                        crdDisableNickname.displayNone = false
                        crdDisableNickname.clear()
                        modDisableNickname.show()
                    }
                    system_command = false
                }
            }
            Modal{
                id: modDisableNickname
                doc_label: "หน้าจอยกเลิกนามแฝง"
                className: "tiny"
                CardDisableNickname {
                    id: crdDisableNickname
                    hideCallback: function(){
                        modDisableNickname.hide()
                    }
                    onConfirmDisable:{
                        if(isConfirm){
                            system_command = true
                            chkNicknameActive.checked = false
                            nickNameContainer.transition("fade down");
                            restModel.disable_nickname_reason = reason
                            modDisableNickname.hide()
                        }
                        else{
                            system_command = true
                            chkNicknameActive.checked = true
                            modDisableNickname.hide()
                        }
                    }
                }
                onHidden:{
                    if (!system_command){
                        if (chkNicknameActive.checked != nickname_using){
                            system_command = true
                            chkNicknameActive.checked = nickname_using
                        }
                    }
                    system_command = false
                }
            }
        }

        Divider{}
        Container{
            displayNone: true
            id:nickNameContainer
            Fields{
                className:"inline"
                Field{
                    label:"ชื่อนามแฝง"
                    className:"eight wide"
                    ComboBox{
                        id: cboNickname
                        search: true
                        emptyItem: '-'
                        forceSelection: true
                        fullTextSearch: true
                        optionTextField: "full_name"
                    }
                }
                Field{
                    label:"เลขที่อนุมัตินามแฝง"
                    className:"eight wide"
                    TextBox{
                        id: txtNicknamePermit
                    }
                }
            }
            Fields{
                className:"inline"
                Field{
                    label:"เหตุผล"
                    className:"eight wide"
                    ComboBox{
                        id: cboNicknameReason
                        search: true
                        emptyItem: '-'
                        forceSelection: true
                        fullTextSearch: true
                        allowAdditions: true
                        useTextValue: true
                        Component.onCompleted:{
                            nicknameReasonRest.fetch()
                        }
                    }
                }
                Field{
                    label:"ผู้อนุมัตินามแฝง"
                    className:"eight wide"
                    TextBox{
                        id: txtNicknameWarrantor
                        // search: true
                        // fullTextSearch: true
                        // optionTextField: "full_name"
                        // Component.onCompleted:{
                        //     nicknameWarrantorRest.fetch()
                        // }
                    }
                }
            }

        }

    }

    function lockNicknameFields(locked){
        locked = !locked
        cboNickname.enabled = locked
        txtNicknamePermit.enabled = locked
        cboNicknameReason.enabled = locked
        txtNicknameWarrantor.enabled = locked
    }

    function getCurrentNickname(id){
        cboNickname.items = nicknameMem.getItem(id)
    }

    function clearErrorMessage(){
        form.clear()
    }

    function clear(){
        crdDisableNickname.clear()
        restModel.disable_nickname_reason = ""
        nickname_using = false
        system_command = false
        form.clear()
        cboPrenameTh.clear()
        cboPrenameEn.clear()
        txtFirstName.text = ""
        txtMiddleName.text = ""
        txtLastName.text = ""
        txtFirstNameEn.text = ""
        txtMiddleNameEn.text = ""
        txtLastNameEn.text = ""
        txtSeq.text = ""
        txtCitizenNo.text = ""
        txtBirthDate.text = ""
        txtBirthYear.text = ""
        acbAllergic.text = ""
        txtAge.text = ""
        chkNonResident.checked = false
        cboBlood.clear()
        cboNation.clear()
        cboProvince.clear()
        cboReligion.clear()
        cboNation.clear()
        cboEducation.clear()
        // radioGender.value = "M"
        cboGender.clear()
        cboBlood.clear()
        txtSpouseName.text = ""
        txtFatherName.text = ""
        txtMotherName.text = ""
        cboCareer.clear()
        cboPosition.text = ""
        cboMaritalStatus.value = ''
        cboCountry.clear()
        cboSecret.value = "N"
        cboPassport.value = "T"
        txtCitizenNo.text = ""
        txtPassportNo.text = ""
        txtAlienNo.text = ""
        txtReimburseForeignCode.text = ""
        // txtRelativeName.text = ""
        // txtRelation.text = ""
        cboInfoChannel.clear()
        chkNicknameActive.checked = false
        cboNickname.clear()
        cboNicknameReason.clear()
        txtNicknameWarrantor.text = ""
        txtNicknamePermit.text = ""
        cboBirthDate_.value = patientHistory.BIRTHDATE_FULL
        txtHNRama.text = ''
        cboRace.clear()
        cboBelief.clear()
        cboNickname.items = nicknameMem.fetch()
        lockNicknameFields(false)
    }
}

        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/REG/CardPatientInfo.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}