import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `REG/SubAddress.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import QmlWeb 1.0
Dom{
    id:formAddress
    signal copyHomeAddress(var source)
    signal provinceChange(var source)
    signal districtChange(var source)
    property alias cboType_ : cboType
    property alias cboCity_ : cboCity
    property alias cboDistrict_ : cboDistrict
    property alias cboProvince_ : cboProvince

    property var headName
    property var showRelativeFields: false
    property var updateFromServer: false
    property bool show_copy_home_address_checkbox: true
    property bool show_copy_present_address_checkbox: true
    property bool show_address: true
    property bool show_contact: true
    property RestModel restModel: RestModel{
        property alias owner_name           : txtRelativeName.text
        property alias relative             : cboRelation.text
        property alias no                   : txtAddress.text
        property alias name                 : txtHome.text
        property alias town                 : txtTown.text
        property alias street               : txtStreet.text
        property alias road                 : txtRoad.text
        property var   edit_user            : ""
        property alias type                 : cboType.value
        property alias city                 : cboCity.value
        property alias district             : cboDistrict.value
        property alias province             : cboProvince.value
        property var  country               : ""
        property alias zipcode              : txtZipcode.text
        property var channnel               : ""
        property alias tel_home             : txtPhone.text
        property alias tel_home_suffix      : txtPhoneSuffix.text
        property alias tel_office           : txtOfficePhone.text
        property alias tel_office_suffix    : txtOfficePhoneSuffix.text
        property alias tel_mobile           : txtMobile.text
        property alias email                : txtEmail.text
        property alias channel              : cboChannel.value
        property alias note                 : txtNote.text
    }
    function clear(){
        chkHomeAddressCopy.checked = false
        chkPresentAddressCopy.checked = false

        txtRelativeName.text = ""
        txtHome.text = ""
        txtTown.text = ""
        txtStreet.text = ""
        cboRelation.clear()
        
        txtAddress.text = ""
        cboType.clear()
        cboCity.clear()
        cboDistrict.clear()
        cboProvince.clear()
        txtRoad.text = ""
        txtPhone.text = ""
        txtPhoneSuffix.text = ""
        txtOfficePhone.text = ""
        txtOfficePhoneSuffix.text = ""
        txtMobile.text = ""
        txtEmail.text = ""
        txtNote.text = ""
        cboChannel.clear()

        updateFromServer = true
        txtZipcode.text = ""
        updateFromServer = false

        // We require user to select Province first! (then they can select district and city)
        cboDistrict.items = [];
        cboCity.items = [];
    }
    
    // Home Type ---------------------------------------------------------------------------------
    RestModel{
        id: relationRest
        url: "/apis/core/clinicalterm/?type=F"
        queryMimeType: "application/json"
        property alias items : cboRelation.items
    }

    // Home Type ---------------------------------------------------------------------------------
    RestModel{
        id: homeTypeRest
        url: "/apis/core/hometype"
        queryMimeType: "application/json"
        property alias items : cboType.items
    }

    // Contact Channnel ---------------------------------------------------------------------------------
    RestModel{
        id: receiveRest
        property alias items: cboChannel.items
        url: "/apis/core/contactchannel"
        queryMimeType: "application/json"
    }

    /**
    * For setting the patient address programmatically.
    * These address data may obtained from SmartCard or National Database
    **/
    function setAddress(house_no, moo, soi, road, tambol, amphoe, province) {
        txtAddress.text = house_no
        txtTown.text = moo
        txtStreet.text = soi
        txtRoad.text = road
        cboProvince.text = province
        cboDistrict.text = amphoe
        cboCity.text = tambol
    }

    function setExtraAddress(house_type, village) {
        cboType.text = house_type
        txtHome.text = village
    }
    
    Grid{
        Row {
            Column {
                className: "five wide"
                Text{
                    text: headName
                    className: "header"
                }
            }  
            Column{
                className: "five wide"
                visible: show_copy_present_address_checkbox
                Form{
                    Fields{
                        className: "inline"
                        Field{
                            className: "inline"
                            CheckBox{
                                id: chkPresentAddressCopy
                                type: "toggle"
                                text:"เหมือนที่อยู่ปัจจุบัน"
                                onChanged:{
                                    if (checked)
                                    {
                                        chkHomeAddressCopy.checked = false
                                        formAddress.copyHomeAddress("present copied");

                                        txtHome.enabled = false;
                                        txtTown.enabled = false;
                                        txtStreet.enabled = false;
                                        txtAddress.enabled = false;
                                        txtRoad.enabled = false;
                                        txtZipcode.enabled = false;
                                        cboType.enabled = false;
                                        cboCity.enabled = false;
                                        cboDistrict.enabled = false;
                                        cboProvince.enabled = false;
                                        // cboCountry.enabled = false;
                                    }
                                    else
                                    {
                                        txtHome.enabled = true;
                                        txtTown.enabled = true;
                                        txtStreet.enabled = true;
                                        txtAddress.enabled = true;
                                        txtRoad.enabled = true;
                                        txtZipcode.enabled = true;
                                        cboType.enabled = true;
                                        cboCity.enabled = true;
                                        cboDistrict.enabled = true;
                                        cboProvince.enabled = true;
                                        // cboCountry.enabled = true;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            Column{
                className: "six wide"
                visible: show_copy_home_address_checkbox
                Form{
                    Fields{
                        Field{
                            className: "inline"
                            CheckBox{
                                id: chkHomeAddressCopy
                                type: "toggle"
                                text:"เหมือนที่อยู่ตามทะเบียนบ้าน"
                                onChanged:{
                                    if (checked)
                                    {
                                        chkPresentAddressCopy.checked = false
                                        formAddress.copyHomeAddress("home copied");

                                        txtHome.enabled = false;
                                        txtTown.enabled = false;
                                        txtStreet.enabled = false;
                                        txtAddress.enabled = false;
                                        txtRoad.enabled = false;
                                        txtZipcode.enabled = false;
                                        cboType.enabled = false;
                                        cboCity.enabled = false;
                                        cboDistrict.enabled = false;
                                        cboProvince.enabled = false;
                                        // cboCountry.enabled = false;
                                    }
                                    else
                                    {
                                        txtHome.enabled = true;
                                        txtTown.enabled = true;
                                        txtStreet.enabled = true;
                                        txtAddress.enabled = true;
                                        txtRoad.enabled = true;
                                        txtZipcode.enabled = true;
                                        cboType.enabled = true;
                                        cboCity.enabled = true;
                                        cboDistrict.enabled = true;
                                        cboProvince.enabled = true;
                                        // cboCountry.enabled = true;
                                    }
                                }
                            }
                        }
                    }
                }
            }         
        }
    }
    Divider{}

    Form{
        displayNone: !showRelativeFields
        Fields{
            className:"inline"
            Field{
                className:"eight wide"
                label:"ชื่อผู้ติดต่อ<br/>/ Contact person"
                TextBox{
                    id: txtRelativeName
                }
            }
            Field{
                label:"ความสัมพันธ์<br/>/ Relations"
                className:"eight wide"
                ComboBox{
                    id: cboRelation
                    search: true
                    fullTextSearch: true
                    forceSelection: true
                    emptyItem: '-'
                    Component.onCompleted:{
                        relationRest.fetch()
                    }
                }
            }
        }
    }

    Container{
        id: addressContainer
        Fields{
            className:"inline"
            Field{
                label:"บ้านเลขที่<br/>/ Current address"
                className:"four wide"
                TextBox{
                    id: txtAddress
                }
            }
            Field{
                label:"ชื่อหมู่บ้าน/อาคาร<br/>/ Village name"
                className:"six wide"
                TextBox{
                    id: txtHome
                }
            }
            Field{
                label:"ประเภทที่พักอาศัย<br/>/ Village type"
                className:"six wide"
                ComboBox{
                    id: cboType
                    search: true
                    fullTextSearch: true
                    forceSelection: true
                    emptyItem: '-'
                    Component.onCompleted:{
                        homeTypeRest.fetch()
                    }
                }
            }
        }
        Fields{
            className:"inline"
            Field{
                label:"หมู่ที่<br/>/ Village number"
                className:"four wide"
                TextBox{
                    id: txtTown
                }
            }
            Field{
                label:"ซอย<br/>/ Soi"
                className:"six wide"
                TextBox{
                    id: txtStreet
                }
            }
            Field{
                label:"ถนน<br/>/ Street"
                className:"six wide"
                TextBox{
                    id: txtRoad
                }
            }
        }
        Fields{
            className:"inline"
            Field{
                label:"จังหวัด<br/>/ Province"
                className:"four wide"
                ComboBox{
                    id: cboProvince
                    search: true
                    forceSelection: true
                    emptyItem: '-'
                    onChanged:{
                        if (cboProvince.text == "กรุงเทพมหานคร"){
                            fidDistrict.label = "เขต"
                            fidCity.label = "แขวง"
                        }
                        else{
                            fidDistrict.label = "อำเภอ"
                            fidCity.label = "ตำบล"   
                        }

                        if (chkHomeAddressCopy.checked)
                            formAddress.provinceChange("home copied")
                        else if (chkPresentAddressCopy.checked)
                            formAddress.provinceChange("present copied")
                        var  queryset = districtMem.getFilter()
                        queryset = queryset.eq('area', parseInt(cboProvince.getValue()))
                        cboDistrict.items = districtMem.filter(queryset)
                    }
                }
            }
            Field{
                id: fidDistrict
                label:"อำเภอ<br/>/ District"
                className:"four wide"
                ComboBox{
                    id:cboDistrict
                    search: true
                    fullTextSearch: true
                    forceSelection: true
                    emptyItem: '-'
                    onChanged:{
                        if (chkHomeAddressCopy.checked)
                            formAddress.districtChange("home copied")
                        else if (chkPresentAddressCopy.checked)
                            formAddress.districtChange("present copied")
                        var  queryset = cityMem.getFilter()
                        queryset = queryset.eq('area', parseInt(cboDistrict.getValue()))
                        cboCity.items = cityMem.filter(queryset)
                    }
                }
            }
            Field{
                id: fidCity
                label:"ตำบล<br/>/ Sub-district"
                className:"four wide"
                ComboBox{
                    id: cboCity
                    search: true
                    fullTextSearch: true
                    forceSelection: true
                    emptyItem: '-'
                    onChanged:{
                        if(cboCity.value)
                            txtZipcode.text = cityMem.getItem(cboCity.value)[0].zipcode
                        else
                            txtZipcode.text = ""
                    }
                }
            }
            Field{
                label:"รหัสไปรษณีย์<br/>/ Zip code"
                className:"four wide"
                TextBox{
                    id: txtZipcode
                }
            }
        }
    }

    Container{
        style: show_contact ? "" : "display: none"
        Fields{
            className:"inline"
            Field{
                style: "padding-right: 0px"
                label:"โทรศัพท์บ้าน<br/>/ Landline"
                className:"nine wide"
                TextBox{
                    id: txtPhone
                }
            }
            Field{
                className:"two wide"
                TextBox{
                    id: txtPhoneSuffix
                    doc_label: "เบอร์ต่อโทรศัพท์บ้าน"
                    textLength:"5"
                }
            }
            Field{
                style: "padding-right: 0px"
                label:"โทรศัพท์ที่ทำงาน<br/>/ Work phone"
                className:"ten wide"
                TextBox{
                    id: txtOfficePhone
                }
            }
            Field{
                className:"two wide"
                TextBox{
                    id: txtOfficePhoneSuffix
                    doc_label: "เบอร์ต่อโทรศัพท์ที่ทำงาน"
                    textLength:"5"
                }
            }
            Field{
                label:"โทรศัพท์มือถือ<br/>/ Mobile phone"
                className:"nine wide"
                TextBox{
                    id: txtMobile
                }
            }
        }
        Fields{
            className:"inline"
            Field{
                label:"E-mail"
                className:"eight wide"
                TextBox{
                    id: txtEmail
                }   
            }
            Field{
                label:"ช่องทางการส่งข้อมูล<br/>/ Information Channel"
                className:"eight wide"
                ComboBox{
                    id: cboChannel
                    search: true
                    forceSelection: true
                    emptyItem: '-'
                    fullTextSearch: true
                    allowAdditions: true
                    Component.onCompleted:{
                        receiveRest.fetch()
                    }
                }
            }
            Field{
                label:"หมายเหตุ<br/>/ Note"
                className:"six wide"
                TextBox{
                    id: txtNote
                }
            }
        }
    }

    function copy(){
        cboProvince.changed()
        cboDistrict.changed()
        cboCity.changed()
    } 
}
        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/REG/SubAddress.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}