import React from "react";
import PropTypes from "prop-types";
import { ModConfirm, ErrorMessage } from "../common";
import { Form, Input } from "semantic-ui-react";

const ModAuthen = React.forwardRef((props, ref) => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  React.useImperativeHandle(ref, () => ({
    clear: () => {
      setUsername("");
      setPassword("");
    }
  }));

  return (
    <ModConfirm
      titleColor={props.titleColor}
      backgroundColor={props.backgroundColor}
      openModal={props.open}
      titleName={props.titleName}
      onDeny={props.onDeny}
      onCloseWithDimmerClick={props.onCloseWithDimmerClick}
      onApprove={() => props.onApprove({ username, password })}
      loading={props.loading}
      approveButtonText={props.approveButtonText}
      denyButtonText={props.denyButtonText}
      content={
        <Form error={props.error ? true : false}>
          <ErrorMessage error={props.error} />
          <Form.Field label="Username" error={props.error ? true : false} />
          <Form.Field error={props.error ? true : false}>
            <Input
              placeholder="Username"
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
          </Form.Field>
          <Form.Field label="Password" error={props.error ? true : false} />
          <Form.Field error={props.error ? true : false}>
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </Form.Field>
          {props.children}
        </Form>
      }
    />
  );
});

ModAuthen.defaultProps = {
  open: false,
  titleName: "กรุณา Log in อีกครั้ง",
  onDeny: () => {},
  error: null,
  onApprove: () => {},
  loading: false
};

ModAuthen.propTypes = {
  open: PropTypes.bool,
  titleName: PropTypes.string,
  onDeny: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onApprove: PropTypes.func,
  headerColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  loading: PropTypes.bool
};

export default React.memo(ModAuthen);
