import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import moment from 'moment';

const SearchInput = (props) => {
  const [timeList, setTimeList] = useState([]);

  useEffect(() => {
    generateTimeOptions();
  }, [props]);

  const generateTimeOptions = () => {
   
    var min = ":00";
    const date = moment().format("MM/DD/YYYY");

    let timeList = [];
    let start = moment(date + " " + ("0" + props.minHour).slice(-2) + min, "MM/DD/YYYY HH:mm");

    let end = moment(date + " " + ("0" + props.maxHour).slice(-2) + min, "MM/DD/YYYY HH:mm");
    if (props.maxHour == 23) {
      end = moment(date + " " + ("0" + props.maxHour).slice(-2) + ":59", "MM/DD/YYYY HH:mm");
    }

    while (start <= end) {
      const time = moment(start).format("HH:mm");
      timeList.push(time);
      start = moment(start).add(props.interval, "minutes");
    }
    setTimeList(timeList)
  }

  return (
    <div style={{ ...props.style }}>
      <Input
        list='time_datalist_id'
        placeholder={props.placeholder}
        onChange={(e) => {
          props.onTextChange(e.target.value)
        }}
        icon={props.icon}
      />
      <datalist id='time_datalist_id' icon='clock'>
        {timeList.map((time, index) => {
          return (<option key={index} value={time} />)
        }
        )}
      </datalist>
    </div>
  )
}

SearchInput.defaultProps = {
  style: {},
  children: () => {},
  placeholder: 'Choose Time',
  icon: 'clock',
  onTextChange: () => {},
  minHour: 0,
  maxHour: 23,
  interval: 30
};

SearchInput.propTypes = {
  style: PropTypes.object,
  interval: PropTypes.number,
  minHour: PropTypes.number,
  maxHour: PropTypes.number,
  onTextChange: PropTypes.func,
};

export default SearchInput;