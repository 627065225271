export const UIReducer = (state, action) => {
  switch (action.type) {
    // case 'SET_INITIAL_STATE':
    //   return
    case 'CLEAR':
      return action.initialState;
    case 'CHANGE_STATE':
      let newState = { ...state }
      action.actionList.forEach((item) => {
        newState = { ...newState, [item.field]: item.value }
      })
      return newState;
    default:
      return state;
  }
}

