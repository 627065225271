import React, { useState } from "react";
import { Dimmer, Loader, Button, Grid } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import FacebookLogin from "react-facebook-login";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Register from "./EmailRegister";
import Login from "./EmailLogin";

const EmailLogin = props => {

  return (
    <Grid columns={2} style={{ width: "100%", padding: "3rem" }}>
      <Grid.Column className="col-md-6">
        <Register />
      </Grid.Column>
      <Grid.Column className="col-md-6">
        <Login />
      </Grid.Column>
    </Grid>
  );
};

export default EmailLogin;
