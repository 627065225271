// import React, { useState, useRef } from "react";

// const FBLoginButton = props => {
//   return (
//     <div
//       className="fb-login-button"
//       data-size="large"
//       data-button-type="login_with"
//       data-layout="default"
//       data-auto-logout-link="false"
//       data-use-continue-as="true"
//       data-width=""
//       width={props.width}
//     />
//   );
// };

// export default FBLoginButton
import React from "react";
import "./LoginButton.css";
import PropTypes from "prop-types";

const FacebookLoginButton = React.forwardRef((props, ref) => {
  return (
    <div
      className="facebookLoginButton"
      onClick={props.onClick}
      ref={ref}
      style={{ ...props.style }}
    >
      <img style={props.logoStyle} alt="" />
      <span style={props.textStyle}>{props.fbLoginText}</span>
    </div>
  );
});

FacebookLoginButton.defaultProps = {
  onClick: () => {},
  logoStyle: {},
  textStyle: {},
  fbLoginText: "เข้าสู่ระบบด้วย Facebook"
};

FacebookLoginButton.propTypes = {
  onClick: PropTypes.func,
  logoStyle: PropTypes.object,
  textStyle: PropTypes.object,
  lineLoginText: PropTypes.string
};

export default FacebookLoginButton;
