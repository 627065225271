import React, { useRef, useState } from "react";
import {
  Button,
  Dimmer,
  Form,
  Grid,
  Icon,
  Image,
  Label,
  Loader,
  Modal,
  Segment,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import RightLabel from "./RightLabel";
import defaultImage from "../common/person.png";
import CardPatientUD from "../DPO/CardPatientUD";
import ModalADRInfo from "../PHV/ModalADRInfo";
import { formatToBEDatetime } from "../../utils";
import { useEffect } from "react";

import { ProblemListList } from "../../apis/master/apps/DPO/ProblemList";

const styles = {
  grayBg: {
    backgroundColor: "#f3f3f3",
  },
  hnStyle: {
    color: "black",
    backgroundColor: "white",
    fontWeight: "normal",
  },
  blueFont: {
    color: "#3180ed",
  },
  noPadding: {
    padding: "0px",
  },
};

const PatientPanel = (props) => {
  const isMounted = useRef(true);

  const [underlyingDisease, setUnderlyingDisease] = useState({});
  const [underlyingDiseaseText, setUnderlyingDiseaseText] = useState("-");
  const [openModADR, setOpenModADR] = useState(false);
  const [openModPatientUD, setOpenModPatientUD] = useState(false);

  useEffect(() => {
    if (props.showUnderlyingDisease && props.patientInfo && props.patientInfo.id) {
      getProblemList();
    }
  }, [props.showUnderlyingDisease, props.patientInfo]);

  const getProblemList = async () => {
    const [result, error, network] = await ProblemListList.list({
      params: {
        patient: props.patientInfo.id,
        is_active: true,
      },
    });
    if (isMounted.current) {
      if (error) {
        toast.error(error);
      } else {
        let data = [];
        result.items.map((d) => {
          data.push(d.message);
        });
        setUnderlyingDisease(result.items);
        const udText = data.join(", ")
        if (udText !== "") {
          setUnderlyingDiseaseText(udText);
        } else if (udText === ""){
          setUnderlyingDiseaseText("-")
        }
      }
    }
  };

  const handleOnClickADR = () => {
    setOpenModADR(!openModADR);
  };

  const handleOpenPatientUDDialog = () => {
    setOpenModPatientUD(true);
  };

  const handleClosePatientUDDialog = () => {
    setOpenModPatientUD(false);
  };

  const {
    hn,
    profile_image,
    full_name_th,
    birthdate,
    full_age,
    religion,
    gender_name,
    coverage,
    underlying_disease,
    is_new_patient,
  } = props.patientInfo ? props.patientInfo : props; // To prevent bugs when there is no props.patientInfo

  const { number, payer_name } = props.encounterInfo
    ? props.encounterInfo
    : props;

  const { tobacco, alcohol, triage_level, precaution } = props.drugInfo
    ? props.drugInfo
    : props;

  const { weight, weight_unit } = props.weightInfo ? props.weightInfo : props;

  const { height, height_unit } = props.heightInfo ? props.heightInfo : props;

  const {
    checkin_time,
    checkout_time,
    episode_start,
    episode_name,
    isLoading,
  } = props.episodeInfo ? props.episodeInfo : props;

  let image = profile_image ? profile_image.image : defaultImage;

  let patientLabel = props.adrReaction
    ? props.adrReaction.length > 0
      ? {
          as: "a",
          color: "red",
          content: "ADR",
          ribbon: "right",
          size: "tiny",
          onClick: () => handleOnClickADR(),
        }
      : {
          as: "a",
          content: "NKA",
          ribbon: "right",
          size: "tiny",
        }
    : null;

  let checkInTime =
    props.encounterInfo && props.encounterInfo.started
      ? formatToBEDatetime({ date: props.encounterInfo.started })
      : "";
  let checkOutTime =
    props.encounterInfo && props.encounterInfo.ended
      ? formatToBEDatetime({ date: props.encounterInfo.ended })
      : "";

  if (props.episodeInfo && props.episodeInfo.checkin_time) {
    checkInTime = props.episodeInfo.checkin_time;
  }
  if (props.episodeInfo && props.episodeInfo.checkout_time) {
    checkOutTime = props.episodeInfo.checkout_time;
  }

  return (
    <Segment raised style={styles.grayBg}>
      <Dimmer active={isLoading} inverted>
        <Loader />
      </Dimmer>
      <ModalADRInfo
        open={openModADR}
        onClose={handleOnClickADR}
        data={props.adrReaction}
      />

      <Grid columns={3}>
        <Grid.Column width={2}>
          <Image
            fluid
            src={image}
            onError={(i) => (i.target.src = defaultImage)}
            label={patientLabel}
          />
          {is_new_patient && (
            <Label style={patientLabel} className="fluid" color="teal">
              <Icon name="star" />
              New Patient
            </Label>
          )}
        </Grid.Column>

        <Grid.Column width={8}>
          <Form>
            <Form.Group>
              <Form.Field width={2}>
                <RightLabel style={styles.blueFont}>HN</RightLabel>
              </Form.Field>
              <Form.Field width={3}>
                <Label className="fluid" style={styles.hnStyle}>
                  {hn || "-"}
                </Label>
              </Form.Field>
              <Form.Field width={1}>
                <RightLabel style={styles.blueFont}>ชื่อ</RightLabel>
              </Form.Field>
              <Form.Field width={4}>
                <Label className="fluid" style={styles.blueFont}>
                  {full_name_th || "-"}
                </Label>
              </Form.Field>
              <Form.Field width={3}>
                <RightLabel>สูบบุหรี่</RightLabel>
              </Form.Field>
              <Form.Field width={1}>
                <Label className="fluid">{tobacco || "-"}</Label>
              </Form.Field>
              <Form.Field width={1} style={styles.noPadding}>
                <RightLabel>ดื่มสุรา</RightLabel>
              </Form.Field>
              <Form.Field width={1}>
                <Label className="fluid">{alcohol || "-"}</Label>
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field width={2}>
                <RightLabel style={styles.blueFont}>วันเกิด</RightLabel>
              </Form.Field>
              <Form.Field width={3}>
                <Label className="fluid" style={styles.blueFont}>
                  {birthdate || "-"}
                </Label>
              </Form.Field>
              <Form.Field width={1}>
                <RightLabel>อายุ</RightLabel>
              </Form.Field>
              <Form.Field width={4}>
                <Label className="fluid">{full_age || "-"}</Label>
              </Form.Field>
              <Form.Field width={3}>
                <RightLabel>ศาสนา/ความเชื่อ</RightLabel>
              </Form.Field>
              <Form.Field width={3}>
                <Label className="fluid">{religion || "-"}</Label>
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field width={2}>
                <RightLabel>เพศ</RightLabel>
              </Form.Field>
              <Form.Field width={3}>
                <Label className="fluid">{gender_name || "-"}</Label>
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field width={2}>
                <RightLabel>สิทธิ</RightLabel>
              </Form.Field>
              <Form.Field width={3}>
                <Label className="fluid">{coverage || "-"}</Label>
              </Form.Field>
              <Form.Field width={1}>
                <RightLabel>Payer</RightLabel>
              </Form.Field>
              <Form.Field width={4}>
                <Label className="fluid">{payer_name || "-"}</Label>
              </Form.Field>
              <Form.Field width={3}>
                <RightLabel>Precaution</RightLabel>
              </Form.Field>
              <Form.Field width={3}>
                <Label className="fluid">{precaution || "-"}</Label>
              </Form.Field>
            </Form.Group>
          </Form>
        </Grid.Column>

        <Grid.Column width={6}>
          <Form>
            <Form.Group>
              <Form.Field width={4}>
                <RightLabel>Triage Level</RightLabel>
              </Form.Field>
              <Form.Field width={4}>
                <Label className="fluid"> {triage_level || "-"}</Label>
              </Form.Field>
              <Form.Field width={3}>
                <RightLabel style={styles.blueFont}>Encounter</RightLabel>
              </Form.Field>
              <Form.Field width={5}>
                <Label className="fluid" style={styles.blueFont}>
                  {number || "-"}
                </Label>
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field width={4}>
                <RightLabel>Weight</RightLabel>
              </Form.Field>
              <Form.Field width={4}>
                <Label className="fluid">
                  {`${weight || "-"} ${weight_unit || ""}`}
                </Label>
              </Form.Field>
              <Form.Field width={3}>
                <RightLabel>Check-in Time</RightLabel>
              </Form.Field>
              <Form.Field width={5}>
                <Label className="fluid">{checkInTime || "-"}</Label>
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field width={4}>
                <RightLabel>Height</RightLabel>
              </Form.Field>
              <Form.Field width={4}>
                <Label className="fluid">
                  {`${height || "-"} ${height_unit || ""}`}
                </Label>
              </Form.Field>
              <Form.Field width={3}>
                <RightLabel>Checkout Time</RightLabel>
              </Form.Field>
              <Form.Field width={5}>
                <Label className="fluid">{checkOutTime || "-"}</Label>
              </Form.Field>
            </Form.Group>

            {props.showUnderlyingDisease && (
              <Form.Group>
                <Form.Field width={4}>
                  <RightLabel>Underlying Disease</RightLabel>
                </Form.Field>
                <Form.Field width={4}>
                  <Label className="fluid">{underlyingDiseaseText}</Label>
                </Form.Field>
                <Form.Field width={1}>
                  <Button
                    circular
                    icon="search"
                    color="green"
                    size="mini"
                    onClick={handleOpenPatientUDDialog}
                  />
                </Form.Field>
              </Form.Group>
            )}
          </Form>
        </Grid.Column>
      </Grid>

      <Modal
        size="small"
        centered={true}
        open={openModPatientUD}
        closeOnDimmerClick={true}
        onClose={handleClosePatientUDDialog}
      >
        <CardPatientUD
          onClose={handleClosePatientUDDialog}
          refreshCallback={getProblemList}
          hideCallback={handleClosePatientUDDialog}
          patientId={props.patientInfo.id}
          canEdit={true}
          underlyingDisease={underlyingDisease}
          underlyingDiseaseText={underlyingDiseaseText}
        />
      </Modal>
    </Segment>
  );
};

PatientPanel.defaultProps = {
  image: "https://www.flynz.co.nz/wp-content/uploads/profile-placeholder.png",

  // Disable UI
  disableEpisode: false,
  showUnderlyingDisease: false,
};

PatientPanel.propTypes = {
  image: PropTypes.string,
  hn: PropTypes.string,
  full_name_th: PropTypes.string,
  tobacco: PropTypes.string,
  alcohol: PropTypes.string,
  triage_level: PropTypes.string,
  encounter_no: PropTypes.string,
  birthdate: PropTypes.string,
  full_age: PropTypes.string,
  religion: PropTypes.string,
  gender_name: PropTypes.string,
  height: PropTypes.string,
  height_unit: PropTypes.string,
  weight: PropTypes.string,
  weight_unit: PropTypes.string,
  encounter_coverage: PropTypes.string,
  encounter_payer_name: PropTypes.string,
  precaution: PropTypes.string,
  underlying_disease: PropTypes.string,
  checkin_time: PropTypes.string,
  checkout_time: PropTypes.string,
  encounter_checkin_time: PropTypes.string,
  encounter_checkout_time: PropTypes.string,
  episode_start: PropTypes.string,
  episode_name: PropTypes.string,
  patientInfo: PropTypes.object,
  encounterInfo: PropTypes.object,
  weightInfo: PropTypes.object,
  drugInfo: PropTypes.object,
  heightInfo: PropTypes.object,
  episodeInfo: PropTypes.object,

  // Disable UI
  disableEpisode: PropTypes.bool,
  showUnderlyingDisease: PropTypes.bool,
};

export default React.memo(PatientPanel);
