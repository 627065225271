import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `Common/SubBarcodeBox.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import QmlWeb 1.0
import 'Util.js' as Util

Item {
    id: subMultiFunction
    // an array of model name which will be shown in combobox.
    property var limitedSelection
    property var width: 400

    // icon of button on textbox
    property alias icon: atbCode.icon
    // text of button on textbox
    property alias buttonText: atbCode.buttonText
    // focus on textbox
    property alias focused: atbCode.focused

    signal customFetched(var type, var pk, var patientId, var encounterId)
    signal patientChanged(var pk)
    signal encounterChanged(var pk)

    /**
    * Pass a function as overrideBarcode parameter. It'll be called to override barcode construction process.
    * For example, you want to modify the barcode before sending out to backend.
    * The function accept 2 parameter prefix, and barcode respectively and must return a string
    * e.g. overrideBarcode: function(prefix, barcode) { ....... return 'New Barcode' }
    * NOTE: prefix will be '' (empty string) when AUTO select.
    **/
    property var overrideBarcode: null

    style: new Object({
        padding: '0.4em',
        width: subMultiFunction.width + 'px'
    })

    onWidthChanged: {
        var style = { padding: '0.4em' }
        if (typeof subMultiFunction.width === 'number') {
            style.width = subMultiFunction.width + 'px'
        }
        else if (typeof subMultiFunction.width === 'string') {
            style.width = subMultiFunction.width
        }
        subMultiFunction.style = style
    }

    function selectBarcode() {
        atbCode.setSelect()
    }

    function clear() {
        atbCode.text = ''
    }

    RestModel {
        id: rmdBarcode
        property var pk
        property string type
        property var patient
        property var encounter

        function fetchBarcode() {
            var prefix = cboType.getSelected().id
            var barcode = atbCode.text.trim()

            prefix = (prefix == 'AUTO') ? '' : prefix;

            if(overrideBarcode != null && typeof overrideBarcode === 'function') {
                default_barcode = overrideBarcode(prefix, barcode)
                rmdBarcode.url =  '/apis/core/default-barcode/' + default_barcode + '/'
            }
            else {
                rmdBarcode.url =  '/apis/core/default-barcode/' + prefix + barcode + '/'
            }
            rmdBarcode.pk = ''
            rmdBarcode.type = ''
            rmdBarcode.patient = ''
            rmdBarcode.encounter = ''
            rmdBarcode.fetch()
        }

        onFetched: {
            subMultiFunction.customFetched(type, pk, patient, encounter)
            switch (type) {
                case 'Patient':
                    subMultiFunction.patientChanged(pk)
                    break
                case 'Encounter':
                    subMultiFunction.encounterChanged(pk)
                    break
                default:
                    break
            }
        }
        onFailed:{
            if(xhr.status == 404){
                Util.alert('ไม่พบข้อมูล', 'red', 'ปิด')
            }
            else if(xhr.status == 400){
                Util.alert('รหัสไม่ถูกต้อง', 'red', 'ปิด')
            }
            else if(xhr.status == 403){
                Util.alert('ไม่มีสิทธิเข้าถึงข้อมูล', 'red', 'ปิด')
            }
            subMultiFunction.clear()
        }
    }

    Form {  // Scan and input
        style: 'width: 100%'
        Fields {
            className: 'inline'
            style: 'margin: 0em'
            Field {
                className: 'ten wide'
                label: cboType.value == 'AUTO' ? 'สแกนรหัส' : 'กรอกรหัส'
                ActionTextBox {
                    id: atbCode
                    placeholder: cboType.text
                    buttonColor: 'green'
                    inputType: 'text'
                    onEntered: {
                        atbCode.buttonText = ''
                        rmdBarcode.fetchBarcode()
                    }
                    onButtonClicked: {
                        rmdBarcode.fetchBarcode()
                    }
                }
            }
            Field {
                className: 'six wide'
                ComboBox {
                    id: cboType
                    value: 'AUTO'
                    className: 'wide-item'
                }
                RestModel{
                    id: rmdType
                    property var items
                    url: "/apis/core/barcode-prefix/"
                    onFetched: {
                        var filteredItems;

                        if (limitedSelection) {
                            items = _.keyBy(items, 'name');
                            filteredItems = _.filter(_.map(limitedSelection, function(name){return items[name]}))
                        } else {
                            filteredItems = items
                        }

                        filteredItems.unshift({ id:'AUTO', name: 'Auto' })
                        cboType.items = filteredItems
                    }
                }
            }
        }
    }
    Component.onCompleted:{
        rmdType.fetch()
    }
}

        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/Common/SubBarcodeBox.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}