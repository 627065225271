import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `Common/CardRoomSearch.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import QmlWeb 1.0
import DGrid 1.0
import '../Common/Util.js' as Util

CardLayout {
    id: __root
    title: 'ค้นหาห้อง'
    headerColor: 'blue'
    toggleCard: false

    property alias keyword: txtRoomno.text

    signal selected(int roomID, var roomData)

    function grdSelected() {
        if(Object.keys(grdRoom.selectedRow).length !== 0){
            __root.selected(grdRoom.selectedRow.id, grdRoom.selectedRow)
        }
    }

    function clearAll() {
        txtRoomno.text = ''
        cboWardType.value = ''
        grdRoom.model = []
    }

    function filterSearch() {
        var query = {}
        query.can_reserve = 'true'

        if(txtRoomno.text)
            query.room_no__icontains = txtRoomno.text

        if(cboWardType.value !== 'NONE')
            query.ward_type = cboWardType.value

        if(cboRoomType.value !== 'NONE')
            query.room_type = cboRoomType.value

        if(cboRoomStatus.value !== 'NONE')
            query.status_name = cboRoomStatus.value

        rmdRoom.query = query
        rmdRoom.fetch()
    }

    RestModel {
        id: rmdRoom
        url: '/apis/ADM/room/'
        property alias items: grdRoom.model
    }

    content: [
        Form{
            inline: true
            className: 'small'
            Fields{
                Field{
                    className: 'two wide'
                    label: 'เลขห้อง/เตียง'
                    labelAlign:'right'
                }
                Field {
                    className: 'five wide'
                    TextBox {
                        id: txtRoomno
                        onEntered: {
                            if(text) {
                                filterSearch()
                            }
                        }
                    }
                }
                Field{
                    className: 'two wide'
                    label: 'ประเภทหอผู้ป่วย'
                    labelAlign:'right'
                }
                Field {
                    className:'five wide'
                    ComboBox{
                        id: cboWardType
                        search: true
                        fullTextSearch: true
                        optionTextField: 'name'
                        emptyItem: ' '
                        Component.onCompleted: {
                            rmdWardType.fetch()
                        }
                        RestModel {
                            id: rmdWardType
                            url: '/apis/ADM/ward-type/'
                            property alias items: cboWardType.items
                        }
                    }
                }
                Field {
                    className:'two wide'
                    Button {
                        text:'ค้นหา'
                        backgroundColor: 'blue'
                        className:'fluid'
                        onClicked: {
                            filterSearch()
                        }
                    }
                }
            }

            Fields {
                Field{
                    className: 'two wide'
                    label: 'ประเภทห้อง'
                    labelAlign:'right'
                }
                Field {
                    className :'five wide'
                    ComboBox {
                        id: cboRoomType
                        search: true
                        fullTextSearch: true
                        emptyItem: ' '
                        Component.onCompleted: {
                            rmdRoomType.fetch()
                        }
                        RestModel{
                            id: rmdRoomType
                            url:'/apis/ADM/room-type/'
                            property alias items: cboRoomType.items
                        }
                    }
                }
                Field {
                    className: 'two wide'
                    label: 'สถานะห้อง'
                    labelAlign: 'right'
                }
                Field {
                    className: 'five wide'
                    ComboBox {
                        id: cboRoomStatus
                        search: true
                        fullTextSearch: true
                        optionTextField: 'label'
                        emptyItem: ' '
                        Component.onCompleted: {
                            rmdRoomStatus.fetch()
                        }
                        RestModel {
                            id: rmdRoomStatus
                            url: '/apis/ADM/room/summary-status/'
                            property alias items: cboRoomStatus.items
                        }
                    }
                }
                Field {
                    className:'two wide'
                    Button {
                        text:'clear'
                        backgroundColor: 'teal'
                        className:'fluid'
                        onClicked: {
                            clearAll()
                        }
                    }
                }
            }
        },

        Br {},

        DGrid {
            id: grdRoom

            columns:[
                DColumn {label:'เลขห้อง/เตียง'   ;field:'room_no'     ;width:150},
                DColumn {label:'ประเภทหอผู้ป่วย' ;field:'ward_name'   ;width:150},
                DColumn {label:'ประเภทห้อง'      ;field:'room_name'   ;width:300},
                DColumn {label:'สถานะห้อง'       ;field:'room_status' ;width:100},
            ]
            onEntered: {
                grdSelected()
            }
        },

        Form {
            Fields {
                className:'inline'
                Field {
                    className: 'fourteen wide'
                }
                Field {
                    className: 'two wide'
                    Button {
                        className: 'green fluid'
                        text: 'เลือก'
                        onClicked: {
                            grdSelected()
                        }
                    }
                }
            }
        }
    ]
}

        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/Common/CardRoomSearch.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}