import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `Common/ModScanner.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import QmlWeb 1.0
import DGrid 1.0
import '../Common/Util.js' as Util
import '../Common/CAgent.js' as CAgent

Modal {
    id: modScanner
    className: 'large'
    closable: false
    // TODO: For dev only, comment this before commit
    // property int patientId: null || 1
    property int patientId: null
    property int encounterId: null
    property var scannedIdList: []
    property var scannedPicList: []
    property var division: 'current'            // division id to filter DocumentType or 'current' to filter by current device
    signal scanCompleted(string base64image)
    signal saveCompleted(var scannedIdList, var scannedPicList)

    // TODO: For dev only, comment this before commit
    // Component.onCompleted: {
    //     setTimeout(function () {
    //         modScanner.show()
    //     })
    // }

    function setData(data){
        crdLayout.setData(data)
    }

    onShowed: {
        if (!patientId && !encounterId) {
            Util.alert('กรุณาระบุ patientId หรือ encounterId', 'red', 'ปิด', null, function(){
                modScanner.hide()
            })
        } else {
            crdLayout.reload(patientId, encounterId)
        }
    }

    onHidden: {
        crdLayout.clear()
    }

    CardLayout {
        id: crdLayout
        title: 'สแกนเอกสาร'
        headerColor: 'yellow'
        toggleCard: false
        allowClose: closeChecking
        function reload(patientId, encounterId) {
            if (patientId) {
                subPatientIdentification.reload(patientId)
            } else if (encounterId) {
                subPatientIdentification.reloadFromEncounter(encounterId)
            }
        }
        function setData(data){
            subScannerForm.setData(data)
        }
        function closeChecking() {
            if (!grdScanResult.model.length) {
                modScanner.hide()
                return false
            }
            modConfirm.titleColor = 'red'
            modConfirm.text = 'มีเอกสารที่สแกนอยู่ ปิดหน้าจอหรือไม่'
            modConfirm.ask(function() {
                modScanner.hide()
            })
            return false
        }

        function scan() {
            CAgent.scan(function(base64image) {
                var prefix = 'data:text/xml;base64,'
                if(base64image.startsWith(prefix)) {
                    base64image = base64image.slice(prefix.length)
                }
                addList(base64image, -1)
                showPreview(base64image)
                scanCompleted(base64image)
                dimLoading.active = false
                subScannerForm.clearForm()
            }, function(error) {
                dimLoading.active = false
                Util.danger(error)
            })
        }

        function scanList() {
            // Single image
            // CAgent.scan(function(base64imageList) {   
            
            // List image
            CAgent.scanList(function(base64imageList) { 

                for(var i = 0; i < base64imageList.length; i++){
                    base64image = base64imageList[i];
                    var prefix = 'data:image/jpeg;base64,'
                    if(base64image.startsWith(prefix)) {
                        base64image = base64image.slice(prefix.length)
                    }
                    addList(base64image, i+1)
                    showPreview(base64image)
                    scanCompleted(base64image)
                }
                dimLoading.active = false
                subScannerForm.clearForm()
            }, function(error) {
                dimLoading.active = false
                Util.danger(error)
            })
        }

        function addList(base64image, i) {
            var row = {}
            subScannerForm.fillRow(row)
            row.img = base64image
            row.code = ''
            if (i !== -1) {
               row.page_number = i
            }
            grdScanResult.model.push(row)
            grdScanResult.refreshModel()
        }

        function showPreview(base64image) {
            imgPreview.displayNone = !base64image
            imgPreview.htmlAttr = {
                src: 'data:image/jpeg;base64,' + base64image
            }
        }

        function save() {
            if (!grdScanResult.model.length) {
                Util.alert('ไม่มีเอกสารที่สแกน', 'red', 'ปิด', null)
                return
            }
            modConfirm.titleColor = 'red'
            modConfirm.text = 'ไม่ต้องการสแกนเอกสารเพิ่มแล้ว ใช่หรือไม่'
            modConfirm.ask(function() {
                modScanner.scannedIdList = []
                modScanner.scannedPicList = []
                saveEach(0)  // Initial index of model in DGrid
            })
        }

        function saveEach(index) {
            var row = grdScanResult.model[index]
            rmdScanDoc.document_type = row.document_type
            rmdScanDoc.document_image = row.img
            rmdScanDoc.document_code = row.code
            rmdScanDoc.patient = modScanner.patientId
            rmdScanDoc.encounter = modScanner.encounterId
            rmdScanDoc.page = row.page_number

            rmdScanDoc.document_no = row.document_no
            rmdScanDoc.version = row.version
            rmdScanDoc.owner_division = row.owner_division
            rmdScanDoc.document_date = row.document_date
            rmdScanDoc.expiry_date = row.expiry_date
            rmdScanDoc.remark = row.remark

            rmdScanDoc.create().then(function(xhr) {
                var response = JSON.parse(xhr.response)
                modScanner.scannedIdList.push(response.id)
                modScanner.scannedPicList.push(response.document_image)

                if ((index + 1) < grdScanResult.model.length) {
                    saveEach(index + 1)
                }
                else {
                    Util.alert('บันทึกสำเร็จ', 'green', 'ปิด', null, function(){
                        saveCompleted(modScanner.scannedIdList, modScanner.scannedPicList)
                        modScanner.hide()
                    })
                }
            })
            .catch(function(e){
                var error_text = ""
                if(JSON.parse(e.response)["owner_division"]!=null){
                    error_text = "กรุณาระบุ 'แผนกเจ้าของเอกสาร'"
                }
                else{
                    error_text = "กรุณาตรวจสอบข้อมูลเอกสาร"
                }
                Util.alert('ไม่สามารถบันทึกเอกสารได้', 'red', 'ปิด', null, function(){}, error_text)
            })
        }

        function clear() {
            modScanner.scannedIdList = []
            modScanner.scannedPicList = []
            grdScanResult.model = []
            subScannerForm.clearForm()
            showPreview('')
            subPatientIdentification.clear()
        }

        RestModel {
            id: rmdScanDoc
            url: '/apis/core/scanned-document/'
            property int document_type
            property string document_image
            property string document_code
            property int patient
            property int encounter
            property int page

            property string document_no
            property int version
            property int owner_division
            property string document_date
            property string expiry_date
            property string remark
        }

        Zoom {
            id: zoom
        }

        ChromePrint {
            id: chromePrint
        }

        content: [
            Dimmer {
                id: dimLoading
                className: 'inverted'
                Loading {
                    className: 'big text'
                    text: 'กำลังสแกน'
                }
            },
            PatientIdentification {
                id: subPatientIdentification
                style: 'margin-top: -10px;'
                compact: true
            },
            Grid {
                Column {
                    className: 'eight wide'
                    Segment {
                        id: domPreview
                        className: 'grey inverted'
                        style: new Object({
                            'text-align': 'center',
                            'height': '100%',
                            'cursor': 'zoom-in',
                        })
                        Dom {
                            id: imgPreview
                            tagName: 'img'
                            style: 'display: inline-block'
                            htmlAttr: new Object({
                                width: '400px'
                            })
                            Component.onCompleted: {
                                imgPreview.dom.addEventListener('click', function(event) {
                                    if (imgPreview.dom.src) {
                                        zoom.show(imgPreview.dom.src, event)
                                    }
                                })
                            }
                        }
                    }
                }
                Column {
                    className: 'eight wide'
                    SubScannerForm {
                        id: subScannerForm
                        division: modScanner.division

                        onCancelEditClicked: {
                            grdScanResult.clearSelection()
                            subScannerForm.clearForm()
                        }

                        onScanClicked: {
                            dimLoading.active = true
                            
                            // TODO: For dev only, comment this before commit
                            // CAgent.setMockupScannedImage()
                            CAgent.checkCAgentScanListAvailable(crdLayout.scanList, crdLayout.scan)
                        }

                        onEditClicked: {
                            subScannerForm.fillRow(grdScanResult.selectedRow)
                            subScannerForm.clearForm()
                            grdScanResult.refreshModel()
                            crdLayout.showPreview('')
                        }
                    }

                    DGrid {
                        id: grdScanResult
                        doc_label: 'เอกสารที่สแกนแล้ว'
                        height: 350
                        model: []
                        onDeselected: showPreview('')
                        onSelected: {
                            subScannerForm.fillFormEdit(grdScanResult.selectedRow)
                            crdLayout.showPreview(grdScanResult.selectedRow.img)
                        }

                        function refreshModel() {
                            grdScanResult.model = grdScanResult.model
                        }

                        columns: [
                            DColumn {
                                width: 20
                                label: 'NO.'
                                field: 'number'
                                formatter: function(value, object) {
                                    return object.storekey + 1
                                }
                            },
                            DColumn {
                                width: 45
                                label: 'ชื่อเอกสาร'
                                field: 'document_type_name'
                            },
                            DColumn {
                                width: 30
                                label: 'เลขที่เอกสาร'
                                field: 'document_no'
                            },
                            DCustomColumn{
                                field:'_print'
                                label: 'พิมพ์'
                                width: 13
                                Button {
                                    text: 'พิมพ์'
                                    backgroundColor: 'teal'
                                    onClicked: {
                                        var prefix = 'data:image/jpeg;base64,'
                                        chromePrint.print(prefix + grdScanResult.selectedRow.img)
                                    }
                                }
                            },
                            DCustomColumn {
                                width: 10
                                label: 'ลบ'
                                Button {
                                    icon: 'remove'
                                    backgroundColor: 'red'
                                    onClicked: {
                                        modConfirm.titleColor = 'red'
                                        modConfirm.text = 'ต้องการลบ <b>'+ grdScanResult.selectedRow.document_type_name + '</b>'
                                        modConfirm.text += ' หมายเลข <b>' + (grdScanResult.selectedRow.storekey + 1)
                                        modConfirm.text += '</b><br>ใช่หรือไม่'
                                        modConfirm.ask(function() {
                                            var keep = []
                                            grdScanResult.model.forEach (function (item) {
                                                if (item.storekey !== grdScanResult.selectedRow.storekey) {
                                                    keep.push(item)
                                                }
                                            })
                                            grdScanResult.model = keep
                                        })
                                    }
                                }
                            }
                        ]
                    }
                    Br {}
                    Form {
                        Fields {
                            className: 'inline'
                            Field {
                                className: 'ten wide'
                            }
                            Field {
                                className: 'three wide'
                                Button {
                                    className: 'fluid'
                                    backgroundColor: 'red'
                                    text: 'ยกเลิก'
                                    onClicked: {
                                        closeChecking()
                                    }
                                }
                            }
                            Field {
                                className: 'three wide'
                                Button {
                                    className: 'fluid'
                                    backgroundColor: 'green'
                                    text: 'บันทึก'
                                    onClicked: {
                                        save()
                                    }
                                }
                            }
                        }
                    }
                }
            }
        ]
    }

    ModConfirm {
        id: modConfirm
        titleName: 'แจ้งเตือน'
        denyButtonColor: 'red'

        property var text

        content: [
            Text {
                text: modConfirm.text
            }
        ]
        onApprove: {
            hide()
        }
        onDeny: {
            hide()
        }
    }
}

        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/Common/ModScanner.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}