import React, { useEffect, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { Input, Button, Icon } from "semantic-ui-react";
import { formatToBEDatetime } from "../../utils";
import moment from "moment";
import TimePicker from "react-time-picker";

const TimeFreeTextBox24 = React.forwardRef((props, ref) => {

  const [time, setTime] = React.useState("00:00");
  useEffect(() => {
    setTime(props.value);
  }, [props.value]);

  useImperativeHandle(ref, () => ({
    getTime: () => {
      return time;
    }
  }));





  const timeChange = value => {
    setTime(value);
    props.onChange(value);
  };

  const setCurrentTime = () => {
    if (props.disableIconClick) {
      return
    }
    if (!props.disabledClick) {
      let dateTime = formatToBEDatetime({ date: moment() });
      let time = dateTime.split(" ")[1];
      console.log("time1", time);
      setTime(time);
      props.onChange(time);
    } else {
      if (!time) {
        let dateTime = formatToBEDatetime({ date: moment() });
        let time = dateTime.split(" ")[1];
        setTime(time);
        props.onChange(time);
      }
    }
  };

  return (
    <div
      style={props.style ? props.style : {
        display: "flex",
        alignItems: "center",
      }}
    >
      <TimePicker
        style={{ border: "0px" }}
        className={props.className}
        onChange={timeChange}
        value={time}
        locale="sv-sv"
        clearIcon={null}
        format={"HH:mm"}
        disableClock
        autoFocus={props.autoFocus}
        onClick={props.onClick}
        disabled={props.disabled}
      />

      <div onClick={setCurrentTime} style={{ paddingLeft: "4px", margin: "auto" }}>
        <Icon name="clock" color="grey" disabled={props.disabled} />
      </div>
    </div>
  );
});

TimeFreeTextBox24.defaultProps = {
  disabled: false,
  onChange: () => { },
  value: "",
  className: "",
  disabledClick: false,
  onClick: () => { },
  autoFocus: true,
  disableIconClick: false,
};

TimeFreeTextBox24.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
  disabledClick: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disableIconClick: PropTypes.bool,
};

export default React.memo(TimeFreeTextBox24);

/**
 *
 *
 * <Input
      disabled={props.disabled ? true : false}
      type='time'
      name="time"
      value={time}
      onChange={timeChange}
      icon='clock'
      className={props.className}
      onClick={setCurrentTime }
    />
 *
 */
