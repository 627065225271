import React from 'react';
import PropTypes from 'prop-types';

const FrameChrome = ({ file, visible, height }) => {

  const iframeRef = React.createRef()

  if (file && visible) {
    return (
      <div
      style={{ width: "100%", height: "100%" }}>
      <iframe ref={iframeRef} id='ifrm'
        src={file}
        style={{ width: '100%', height: '100%' }}
        onLoad={() => {
          if(iframeRef.current.contentDocument){
            var image = iframeRef.current.contentDocument.getElementsByTagName('img')[0];
            image.style.width = '100%';
            image.style.height = height ? height : 'auto';
          }
        }}
      >
      </iframe>
    </div>
    )
  } else {
    return <div></div>;
  }
};

FrameChrome.propTypes = {
  file: PropTypes.string,
  visible: PropTypes.bool,
};

FrameChrome.defaultProps = {
  file: '',
  visible: false,
};


export default React.memo(FrameChrome)