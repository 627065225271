export default class RuleListController {
  constructor(props) {
    this.prxManager = props.prxManager;
  }

  getDiagRule = async ({ apiToken, division, active } = {}) => {
    // 
    const [response, error, network] = await this.prxManager.getDiagRule({ apiToken, division, active});
    // 
    return [response, error, network];
  };

  postDiagRule = async ({ apiToken, data } = {}) => {
    const [response, error, network] = await this.prxManager.postDiagRule({ apiToken, data});
    return [response, error, network];
  };

  patchDiagRulePublish = async ({ apiToken, published, id } = {}) => {
    const [
      response,
      error,
      network
    ] = await this.prxManager.patchDiagRulePublish({ apiToken, published, id });
    return [response, error, network];
  };

  patchDiagRuleActive = async ({ apiToken, active, id, published } = {}) => {
    const [
      response,
      error,
      network
    ] = await this.prxManager.patchDiagRuleActive({ apiToken, active, id, published});
    return [response, error, network];
  };
}
