import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

/**
 * PureReactTable Component Custom pageSizeOption
 */
export default class CustomPureReactTable extends React.PureComponent {
    static propTypes = {
      selectedRow: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
      loading: PropTypes.bool,
      data: PropTypes.arrayOf(PropTypes.object),
      columns: PropTypes.arrayOf(PropTypes.object),
      page: PropTypes.number,
      pages: PropTypes.number,
      pageSize: PropTypes.number,
      onPageSizeChange: PropTypes.func,
      onPageChange: PropTypes.func,
    };

    static defaultProps = {
      loading: false,
      data: [],
      columns: [],
      selectedRow: null,
      pageSizeOptions: [10, 15, 20],
    };

    render() {
      return (
        <ReactTable
          className='-striped -highlight'
          {...this.props}
        />
      );
    }
}