export default class LoginController {
  constructor(props) {
    this.mixInManager = props.mixInManager;
    this.userManager = props.userManager;
  }

  getRegisterByTokenFacebook = async ({ accessToken, subscription, device_id, app, email }) => {
    
    let [
      response,
      error,
      network
    ] = await this.mixInManager.getRegisterByTokenFacebook({
      accessToken,
      subscription,
      device_id,
      app,
      email
    });
    return [response, error, network];
  };

  getUserProfile = async ({ apiToken}) => {
    let [
      response,
      error,
      network
    ] = await this.userManager.getUserProfile({
      apiToken
    });
    return [response, error, network];
  }

  updateUserProfile = async ({apiToken, firstName, lastName, dob, phone}) => {
    let [
      response,
      error,
      network
    ] = await this.userManager.updateUserProfile({
      apiToken,
      firstName,
      lastName,
      dob,
      phone,
    });
    return [response, error, network];
  }
}