import React from "react";
import {
  Grid,
  Form,
  Image,
  Segment,
  Label,
  Divider,
  Input,
  Icon,
  Modal,
  Popup,
  Dimmer,
  Loader
} from "semantic-ui-react";
import * as Common from "../../../_qmljs/Common";
import * as REG from "../../../_qmljs/REG";
import REGService from "../../apis/services/REGService";
import CoreService from "../../apis/services/CoreService";
import APPService from "../../apis/services/APPService";
import { to, setStateAsync } from "../../utils";
import { toast } from "react-toastify";
import _ from "../../compat/lodashplus";
import { TRIAGE_LEVEL } from "../../utils/constant";

const styles = {
  labelStyle: {
    background: "none",
    color: "black",
    fontSize: "0.9em",
    paddingLeft: 0,
    paddingRight: 0
  },
  searchIconStyle: {
    background: "#b7b7b7",
    color: "black"
  }
};

function DetailRow(props) {
  // choose one between props detail or using children

  // if display not set, show
  //    display true   , show
  //    display false  , not show

  if (props.display == false) {
    return null;
  }

  // if popup set , show popup
  //    popup not set , not show popup

  var content;
  if (props.popup) {
    content = (
      <Popup
        content={props.value}
        trigger={<Label className="fluid">{props.value || "-"}</Label>}
      />
    );
  } else if (props.openCard) {
    content = (
      <Popup
        content={props.content}
        trigger={
          <Label as="a" className="fluid" onClick={props.openCard}>
            {" "}
            {props.value || "-"}{" "}
          </Label>
        }
      />
    );
  } else {
    content = <Label className="fluid">{props.value || "-"}</Label>;
  }

  let line = (
    <Grid.Row style={{ padding: 1.5 }}>
      <Grid.Column textAlign="right" width={5}>
        <Label style={styles.labelStyle}>{props.label}</Label>
      </Grid.Column>
      <Grid.Column width={11} style={{ alignSelf: "center" }}>
        {props.children ? props.children : content}
      </Grid.Column>
    </Grid.Row>
  );

  return line;
}

const INITIAL_STATE = {
  inputHN: "",
  modalPatientSearch: false,
  status: "",
  patientId: null,
  checkoutCause: "",
  division: "",
  doctor: "",
  medicalRecord: "",
  underlyingDisease: "",
  remark: "",
  note: "",
  created: "",
  generalType: "",
  examinationType: null,
  allowedActions: null,
  hn: "",
  encounterId: null,
  encounterNo: "",
  fullName: "",
  birthdate: "",
  fullAge: "",
  citizenNo: "",
  genderName: "",
  coverage: "",
  payerName: "",
  coverageCode: "",
  welfareCategory: "",
  profileImage: null,
  oldName: "",
  deathdate: "",
  religion: "",
  belief: "",
  nextAppointment: "",
  patient: {},
  isLoading: false
};

class PatientPanel extends React.PureComponent {
  static defaultProps = {
    fetchRecentEncounterByHN: false,
    allowEditHN: true,
    showNewPatient: false,
    showEncounter: false,
    showTriageLevel: false,
    showAge: true,
    showDateOfBirth: true,
    showCoverage: true,
    showCheckout: true,
    showRemark: false,
    showNote: false,
    showAppoint: true,
    showAdmitDate: false,
    showDivider: true,
    triageLevel: "",
    showErrEncounterFetchFail: true,
    onEncounterChanged: () => {}
  };
  constructor(props) {
    super(props);
    // console.log("PatientPanel constructor")
    this.regService = new REGService();
    this.coreService = new CoreService();
    this.appService = new APPService();
    this.setStateAsync = setStateAsync.bind(this);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    // console.log("PatientPanel CDM ")
    if ( this.props.encounterId) {
      // this.setState({isLoading: true})
      this.fetchByEncounter(this.props.encounterId)
      // this.setState({isLoading: false})
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if ( this.props.encounterId != prevProps.encounterId) {
      this.fetchByEncounter(this.props.encounterId)
    }
  }

  handleOnEnterHN = event => {
    if (event.key === "Enter") {
      const hn = this.state.inputHN.toUpperCase().trim();
      this.fetchHN(hn);
      // patientPanel.fetchHN(hnInput.text);
      // patientPanel.hnTextChanged(hnInput.text);
    }
  };

  clear = () => {};

  setStateFromData = data => {
    let newData = {};
    for (let key in data) {
      const camelCaseKey = _.camelCase(key);
      if (this.state.hasOwnProperty(camelCaseKey)) {
        newData[camelCaseKey] = data[key];
      }
    }
    return this.setStateAsync(newData);
  };

  doActionAfterPatientFetched = patientId => {
    const { fetchRecentEncounterByHN, showAppoint } = this.props;
    if(this.subADRlbt && this.subADRlbt.qml){
      this.subADRlbt.qml.refreshApproved(patientId);
    }
    if (fetchRecentEncounterByHN) {
      this.fetchLastPatientEncounter(patientId);
    }
    if (showAppoint) {
      this.fetchAppiontment(patientId);
    }
  };

  fetchAppiontment = async patientId => {
    const params = {
      patient: patientId
    };
    const [error, data] = await to(this.appService.getAllAppointment(params));
    if (error) {
      toast.error("เกิดความผิดพลาด");
      return;
    }
    const items = data.items;
    let nextAppointment = "-";
    if (items && items.length > 0 && items[0].next_appoint) {
      nextAppointment = items[0].next_appoint;
    }
    this.setState({ nextAppointment: nextAppointment });
  };

  fetchHN = async hn => {
    const [error, data] = await to(this.regService.getPatientByHN(hn));
    if (error) {
      toast.error("เกิดความผิดพลาด");
      return;
    }
    await this.setStateFromData(data);
    this.props.onHnChanged(this.state.hn);
    this.doActionAfterPatientFetched(this.state.patientId);
    return Promise.resolve(null);
  };

  fetchByPatient = async (id, preventClear, ignoreEncounter = false) => {
    if (!preventClear) {
      this.clear();
    }
    const [error, data] = await to(this.regService.getPatientById(id));
    if (error) {
      toast.error("เกิดความผิดพลาด");
      return;
    }
    if (ignoreEncounter) {
      delete data["encounter_id"];
      delete data["encounter_no"];
      delete data["encounter_type"];
    }
    let newData = {};
    for (let key in data) {
      const camelCaseKey = _.camelCase(key);
      if (this.state.hasOwnProperty(camelCaseKey)) {
        newData[camelCaseKey] = data[key];
      }
    }
    this.setState({ patient: newData });
    // await this.setStateFromData(data);
    this.doActionAfterPatientFetched(this.state.patientId);
    return Promise.resolve(null);
  };

  fetchByEncounter = async encounterId => {
    this.clear();
    this.setState({ isLoading: true})
    const [error, data] = await to(this.coreService.getEncounter(encounterId));
    if (this.props.showErrEncounterFetchFail) {
      if (error) {
        toast.error("เกิดความผิดพลาด");
        this.setState({ isLoading: false})
        return Promise.reject(error);
      }
    }
    if (data) {
      data["encounterId"] = data.id;
      data["encounterNo"] = data.number;
      await this.setStateFromData(data);
      const patient = data.patient;
      await this.fetchByPatient(patient, true, true);
      this.emitEncounterChanged();
      this.setState({ isLoading: false})
    }
    return Promise.resolve(null);
  };

  fetchLastPatientEncounter = async patientId => {
    const [error, data] = await to(
      this.coreService.getLastEncounterByPatient(patientId)
    );
    if (this.props.showErrEncounterFetchFail) {
      if (error) {
        toast.error("เกิดความผิดพลาด");
        return;
      }
    }
    await this.setStateFromData(data);
    this.emitEncounterChanged();
    return Promise.resolve(null);
  };

  handleOnHNIconClick = () => {
    this.showModalPatientSearch();
  };

  handleOnHNChange = (event, data) => {
    this.setState({ inputHN: data.value });
  };

  showModalPatientSearch = () => {
    this.setState({ modalPatientSearch: true });
  };

  handleOnCloseModalPatientSearch = () => {
    this.setState({ modalPatientSearch: false });
  };

  handleOnPatientSelect = async (patientId, patientData) => {
    const hn = patientData.hn;
    await this.setStateAsync({
      modalPatientSearch: false,
      inputHN: hn
    });
    this.fetchByPatient(patientId);
    // patientPanel.hnTextChanged(hnInput.text);
  };
  handleOnConfirmCoverage = () => {
    this.modConfirmCoverage.show();
    // TODO: implement for complete.
  };

  // handleOnConfirmCoverage = async (patientId, patientData) => {
  //   const hn = patientData.hn;
  //   await this.setStateAsync({
  //     'modalPatientSearch': false,
  //     'inputHN': hn
  //   })
  //   this.fetchByPatient(patientId);
  //   // patientPanel.hnTextChanged(hnInput.text);
  // }

  emitEncounterChanged = () => {
    this.props.onEncounterChanged(this.state);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.patientId != this.state.patientId) {
      this.props.onPatientChanged(this.state.patientId, this.state.hn);
    }
  }

  getPatientName() {
    return this.state.fullName;
  }

  render() {
    const {
      fetchRecentEncounterByHN,
      allowEditHN,
      showNewPatient,
      showEncounter,
      showTriageLevel,
      showAge,
      showDateOfBirth,
      showCoverage,
      showCheckout,
      showRemark,
      showNote,
      showAppoint,
      showAdmitDate,
      showDivider,
      children,
      triageLevel
    } = this.props;

    const {
      inputHN,
      modalPatientSearch,
      status,
      patientId,
      checkoutCause,
      division,
      doctor,
      medicalRecord,
      underlyingDisease,
      remark,
      note,
      created,
      generalType,
      examinationType,
      hn,
      encounterNo,
      fullName,
      birthdate,
      fullAge,
      citizenNo,
      genderName,
      coverage,
      payerName,
      coverageCode,
      welfareCategory,
      profileImage,
      oldName,
      deathdate,
      religion,
      belief,
      nextAppointment
    } = this.state;

    const srcImage =
      (profileImage && profileImage.image) ||
      "../../../static/images/person.png";
    return (
      <Segment className="raised defaultBackground">
        <Modal
          open={modalPatientSearch}
          size="large"
          onClose={this.handleOnCloseModalPatientSearch}
          // style={{marginTop:0}} //dont push
        >
          <Common.CardPatientSearch
            hideCallback={this.handleOnCloseModalPatientSearch}
            onSelected={this.handleOnPatientSelect}
          />
        </Modal>

        <REG.ModConfirmCoverage
          ref={modConfirmCoverage => {
            if (modConfirmCoverage)
              this.modConfirmCoverage = modConfirmCoverage.qml;
          }}
          patient_id={patientId}
          readOnly={true}
        />
        <Dimmer.Dimmable>
          <Dimmer inverted active={this.props.isLoading || this.state.isLoading}>
            <Loader inverted />
          </Dimmer>
          <Grid className="center aligned padded">
            <Grid.Row>
              <Form>
                <Form.Field inline>
                  <Image className="small rounded" src={srcImage} />
                  <Common.SubADRLabelTag
                    className="floating"
                    ref={subADRlbt => {
                      if (subADRlbt) this.subADRlbt = subADRlbt;
                    }}
                  />
                </Form.Field>
              </Form>
            </Grid.Row>
            <Grid.Row>
              {showNewPatient ? (
                <Label color="teal">
                  <Icon name="star" />
                  New Patient
                </Label>
              ) : null}
            </Grid.Row>
          </Grid>
          <Grid>
            <DetailRow label="HN">
              {allowEditHN ? (
                <Input
                  value={inputHN}
                  className="fluid"
                  icon={
                    <Icon
                      link
                      style={styles.searchIconStyle}
                      name="search"
                      onClick={this.handleOnHNIconClick}
                    />
                  }
                  onChange={this.handleOnHNChange}
                  onKeyPress={this.handleOnEnterHN}
                />
              ) : (
                <Label className="fluid">{hn || "-"}</Label>
              )}
            </DetailRow>
            <DetailRow
              display={showEncounter}
              label="Encounter"
              value={encounterNo}
            />
            <DetailRow
              display={showTriageLevel}
              label="Level"
              value={TRIAGE_LEVEL[triageLevel] ? TRIAGE_LEVEL[triageLevel] : "-"}
            />
            <DetailRow
              label="ชื่อ"
              value={fullName || this.state.patient.fullName}
            ></DetailRow>
            <DetailRow
              label="เพศ"
              value={genderName || this.state.patient.genderName}
            />
            <DetailRow
              display={showAge}
              label="อายุ"
              value={fullAge || this.state.patient.fullAge}
            />
            <DetailRow
              display={showDateOfBirth}
              label="วันเกิด"
              value={birthdate || this.state.patient.birthdate}
            />
            <DetailRow
              display={showCoverage}
              openCard={this.handleOnConfirmCoverage}
              content="กดเพื่อดูสิทธิ์"
              label="สิทธิ์"
              value={coverage || this.state.patient.coverage}
            />
            <DetailRow
              display={showCoverage}
              label="Payer"
              value={payerName || this.state.patient.payerName}
            />
            <DetailRow
              popup
              label="ศาสนา/ความเชื่อ"
              value={religion || this.state.patient.religion}
            />
            <DetailRow
              display={showAdmitDate}
              label="วันที่ Admit"
              value={created || this.state.patient.created}
            />
            <DetailRow
              display={showCheckout}
              label="Checkout"
              value={checkoutCause || this.state.patient.checkoutCause}
            />
            <DetailRow
              display={showRemark}
              label="จุดสังเกต"
              value={remark || this.state.patient.remark}
            />
            <DetailRow
              display={showNote}
              label="หมายเหตุ"
              value={note || this.state.patient.note}
            />
            <DetailRow
              display={showAppoint}
              label="นัดหมาย"
              value={nextAppointment}
            />
          </Grid>
          {showDivider ? <Divider /> : null}
          {children}
        </Dimmer.Dimmable>
      </Segment>
    );
  }
}

PatientPanel.defaultProps = {
  onPatientChanged: () => {},
  onEncounterChanged: () => {},
  isLoading: false
};

export default React.memo(PatientPanel);
