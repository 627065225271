import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `REG/CardSmartCardInfo.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import DGrid 1.0
import QmlWeb 1.0
import '../Common/Util.js' as Util

Common.CardLayout {
    id: root
    
    className: "large"
    signal confirm()

    property string username: ""
    property var jsonData: null

    function clear() {
        jsonData = null
        txtCitizenNo.text = ""
        txtFirstNameTH.text = ""
        txtLastNameTH.text = ""
        txtFirstNameEN.text = ""
        txtLastNameEN.text = ""
        txtSex.text = ""
        txtNationality.text = ""
        txtReligion.text = ""
        txtAge.text = ""
        txtBirthDate.text = ""
        txtFatherName.text = ""
        txtMotherName.text = ""

        txtAddress.text = ""
        txtHomeType.text = ""
        txtVillage.text = ""
        txtAvenue.text = ""
        txtRoad.text = ""
        txtCity.text = ""
        txtDistrict.text = ""
        txtProvince.text = ""

        imgProfile.source = ""
    }

    function mapData() {
        if (!jsonData){
            return
        }

        txtCitizenNo.text = jsonData.personalID || ""
        txtFirstNameTH.text = (jsonData.titleDesc || "") + " " + (jsonData.firstName || "")
        txtLastNameTH.text = (jsonData.middleName || "") + " " + (jsonData.lastName || "")
        txtFirstNameEN.text = (jsonData.englishTitleDesc || "") + " " + (jsonData.englishFirstName || "")
        txtLastNameEN.text = (jsonData.englishMiddleName || "") + " " + (jsonData.englishLastName || "")
        txtSex.text = jsonData.genderDesc || ""
        txtNationality.text = jsonData.nationalityDesc || ""
        txtReligion.text = jsonData.religionDesc || ""
        
        if (jsonData.dateOfBirth) {
            // var birth_date = jsonData.dateOfBirth.toString().match(/(?<year>[0-9]{4})(?<month>[0-9]{2})(?<day>[0-9]{2})/).groups;
            var birth_date = jsonData.dateOfBirth.toString()
            txtBirthDate.text = birth_date.slice(6) + "/" + birth_date.slice(4, 6) + "/" + birth_date.slice(0, 4)
        } else {
            txtBirthDate.text = ""
        }

        txtAge.text = jsonData.age || ""
        txtFatherName.text = jsonData.fatherName || ""
        txtMotherName.text = jsonData.motherName || ""

        txtAddress.text = jsonData.houseNo || ""
        txtHomeType.text = jsonData.houseTypeDesc || ""
        txtVillage.text = jsonData.villageDesc || ""
        txtAvenue.text = jsonData.alleyDesc || ""
        txtRoad.text = jsonData.roadDesc || ""
        txtCity.text = jsonData.subdistrictDesc || ""
        txtDistrict.text = jsonData.districtDesc || ""
        txtProvince.text = jsonData.provinceDesc || ""

        imgProfile.source = jsonData.mineType + "," + jsonData.image || ""
    }

    function getData(citizen_no) {
        frmInput.loading(true)
        
        CAgent.newSmartcard(
            citizen_no,
            function(result){
                root.jsonData = result
                root.mapData()
                frmInput.loading(false)
            },
            function(error){
                Util.danger(error)
                frmInput.loading(false)
            }
        )
    }

    title: "เชื่อมต่อข้อมูลจากระบบทะเบียนราษฎรออนไลน์"

    content: [
        Form{
            className: "inline"
            Fields {
                Field {
                    className: "sixteen wide"
                    label: "โดย " + root.username
                    labelAlign: "right"
                }
            }
        },
        Form {
            className: "inline"
            id: frmInput

            function loading(load){
                if (load){
                    frmInput.className = "loading inline"
                } else {
                    frmInput.className = "inline"
                }
            }
            
            Fields {
                Field {
                    className: "twelve wide"

                    Form {
                        className: "inline"
                        Fields {
                            Field {
                                className: "sixteen wide"
                                label: "เลขประจำตัวประชาชน :"
                            }
                        }
                        Fields {
                            Field {
                                className: "one wide"
                            }
                            Field {
                                className: "nine wide"
                                TextBox{
                                    id: txtCitizenNo
                                }
                            }
                            Field {
                                className: "six wide"
                                Button {
                                    className: "fluid basic green"
                                    id: btnCheck
                                    text: "ตรวจสอบข้อมูล"

                                    onClicked: {
                                        getData(txtCitizenNo.text)
                                    }
                                }
                            }
                        }
                        Fields {
                            Field {
                                className: "six wide"
                                Button {
                                    className: "fluid basic blue"
                                    id: btnCard
                                    text: "บัตรประชาชน"

                                    onClicked: {
                                        getData(null)
                                    }
                                }
                            }
                            Field {
                                className: "six wide"
                                Button {
                                    className: "fluid green"
                                    id: btnPrint
                                    text: "พิมพ์เอกสาร"

                                    onClicked: {
                                        root.loading = true
                                        rmdSmartCardPatientInfoPrint.create()
                                    }
                                }
                                RestModel{
                                    id: rmdSmartCardPatientInfoPrint
                                    url: "/apis/REG/smart-card-patient-info/print/"
                                    property alias data: root.jsonData
                                    property var pdf: true
                                    property bool success
                                    property string pdf_b64data
                                    property var error

                                    onSaved: {
                                        if (success) {
                                            if(pdf_b64data.length > 0){
                                                Util.displayPDF(pdf_b64data)
                                            }
                                            else{
                                                Util.alert('พิมพ์สำเร็จ', 'green')
                                            }
                                        }
                                        else{
                                            Util.alert(error.message, 'red')
                                        }
                                        root.loading = false
                                    }

                                    onFailed: {
                                        Util.alert(_('เกิดข้อผิดพลาด'), 'red', null, null, null, error)
                                        root.loading = false
                                    }
                                }
                            }
                            Field {
                                className: "six wide"
                                Button {
                                    className: "fluid green"
                                    id: btnConfirm
                                    text: "ส่งข้อมูลเข้า HIS"

                                    onClicked: {
                                        if (!root.jsonData) {
                                            Util.alert("ไม่พบข้อมูลผู้ป่วย", "red")
                                        } else {
                                            confirm()
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                Field{
                    className: "four wide"
                    Dom {
                        id: imgProfile
                        property string source
                        tagName: 'img'
                        style: 'cursor: pointer; height: 170px !important'
                        htmlAttr: new Object({
                            width: 170,
                        })
                        onSourceChanged: {
                            imgProfile.dom.src = imgProfile.source
                        }
                    }
                }
            }
        },
        Form{
            className: "inline"
            Fields {
                Field {
                    className: "sixteen wide header"
                    label: "รายละเอียดผู้ป่วย"
                }
            }
            Fields {
                Field {
                    className: "one wide"
                }
                Field {
                    className: "two wide"
                    label: "ชื่อ:"
                }
                Field {
                    className: "inline thirteen wide"
                    Text {
                        id: txtFirstNameTH
                    } 
                }
            }
            Fields {
                Field {
                    className: "one wide"
                }
                Field {
                    className: "two wide"
                    label: "นามสกุล:"
                }
                Field {
                    className: "inline thirteen wide"
                    Text {
                        id: txtLastNameTH
                    } 
                }
            }
            Fields {
                Field {
                    className: "one wide"
                }
                Field {
                    className: "two wide"
                    label: "First Name:"
                }
                Field {
                    className: "inline thirteen wide"
                    Text {
                        id: txtFirstNameEN
                    } 
                }
            }
            Fields {
                Field {
                    className: "one wide"
                }
                Field {
                    className: "two wide"
                    label: "Last Name:"
                }
                Field {
                    className: "inline thirteen wide"
                    Text {
                        id: txtLastNameEN
                    } 
                }
            }
            Fields {
                Field {
                    className: "one wide"
                }
                Field {
                    className: "two wide"
                    label: "เพศ:"
                }
                Field {
                    className: "inline five wide"
                    Text {
                        id: txtSex
                    }
                }
                Field {
                    className: "two wide"
                    label: "สัญชาติ"
                }
                Field {
                    className: "inline five wide"
                    Text {
                        id: txtNationality
                    }
                }
            }
            Fields {
                Field {
                    className: "one wide"
                }
                Field {
                    className: "two wide"
                    label: "ศาสนา:"
                }
                Field {
                    className: "inline thirteen wide"
                    Text {
                        id: txtReligion
                    }
                }
            }
            Fields {
                Field {
                    className: "one wide"
                }
                Field {
                    className: "four wide"
                    label: "วันเดือนปีพ.ศ.เกิด:"
                }
                Field {
                    className: "inline three wide"
                    Text {
                        id: txtBirthDate
                    }
                }
                Field {
                    className: "two wide"
                    label: "อายุ"
                }
                Field {
                    className: "inline five wide"
                    Text {
                        id: txtAge
                    }
                }
            }
            Fields {
                Field {
                    className: "one wide"
                }
                Field {
                    className: "two wide"
                    label: "ชื่อบิดา:"
                }
                Field {
                    className: "inline five wide"
                    Text {
                        id: txtFatherName
                    }
                }
                Field {
                    className: "two wide"
                    label: "ชื่อมารดา"
                }
                Field {
                    className: "inline five wide"
                    Text {
                        id: txtMotherName
                    }
                }
            }
            Fields {
                Field {
                    className: "sixteen wide"
                    label: "ที่อยู่ตามทะเบียนบ้าน"
                }
            }
            Fields {
                Field {
                    className: "one wide"
                }
                Field {
                    className: "two wide"
                    label: "บ้านเลขที่:"
                }
                Field {
                    className: "inline five wide"
                    Text {
                        id: txtAddress
                    }
                }
                Field {
                    className: "two wide"
                    label: "ประเภทบ้าน"
                }
                Field {
                    className: "inline five wide"
                    Text {
                        id: txtHomeType
                    }
                }
            }
            Fields {
                Field {
                    className: "one wide"
                }
                Field {
                    className: "two wide"
                    label: "หมู่ที่:"
                }
                Field {
                    className: "inline five wide"
                    Text {
                        id: txtVillage
                    }
                }
                Field {
                    className: "two wide"
                    label: "ซอย"
                }
                Field {
                    className: "inline five wide"
                    Text {
                        id: txtAvenue
                    }
                }
            }
            Fields {
                Field {
                    className: "one wide"
                }
                Field {
                    className: "two wide"
                    label: "ถนน:"
                }
                Field {
                    className: "inline five wide"
                    Text {
                        id: txtRoad
                    }
                }
                Field {
                    className: "two wide"
                    label: "ตำบล"
                }
                Field {
                    className: "inline five wide"
                    Text {
                        id: txtCity
                    }
                }
            }
            Fields {
                Field {
                    className: "one wide"
                }
                Field {
                    className: "two wide"
                    label: "อำเภอ:"
                }
                Field {
                    className: "inline five wide"
                    Text {
                        id: txtDistrict
                    }
                }
                Field {
                    className: "two wide"
                    label: "จังหวัด"
                }
                Field {
                    className: "inline five wide"
                    Text {
                        id: txtProvince
                    }
                }
            }
        }
    ]
}
        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/REG/CardSmartCardInfo.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}