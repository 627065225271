import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `Common/MenuBar.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import QmlWeb 1.0
import "Util.js" as Util

Menu {
    id: _menubar
    className: "small"
    doc_skip: true

    signal goBack()

    property bool showHomeButton: true
    property bool showAccount: true
    property bool showSwitchUser: false
    property bool showBackButton: false

    property alias userImage: _userImage.source
    property alias username: _username.text
    property alias customItem: _customItem.data

    function handleUsersMessage(msg) {
        if(msg.event === 'inactivity_timeout') {
            rmdSessionInfo.fetch()
        }
        else if(msg.event == 'logged_out') {
            // we still need to fetch to check if our session_expired (User can login multiple sessions)
            // our session might not expired
            rmdSessionInfo.fetch()
        }
    }

    function _createLoginUrl() {
        loginUrl = '/users/login/?next=' + encodeURIComponent(location.pathname + location.search)
        return loginUrl + '&comname=' + DJANGO.device.computer_name
    }

    function displayLoggedOutMessage() {
        Util.alert('You have been logged out. Please re-login', 'red', 'close', 10000, function(){
            location.href = _createLoginUrl()
        })
    }

    Component.onCompleted: {
        // Handle the websocket connection
        _wsTag.icon = "big blue loading refresh";
        _wsStatus.text = 'connecting...';

        vcMessenger.onConnectionOpen( function() {
            _wsTag.icon = "big green check circle";
            _wsStatus.text = 'connected'
        });

        vcMessenger.onConnectionClose( function() {
            _wsTag.icon = "big red remove circle";
            _wsStatus.text = 'disconnected'
        });

        vcMessenger.onMessage(function(msg) {
            if(msg.source === 'users') {
                _menubar.handleUsersMessage(msg)
            }
            else if(msg.event === 'alert') {
                Util.alert(msg.message, 'yellow', _('รับทราบ'))
            }
        })

        if( vcMessenger.isSupported ) {
            // Connect to websocket
            if(!vcMessenger.isConnected()) {  // Prevent duplicate connection
                vcMessenger.connect(DJANGO.division.code);
            }
        }
        else {
            // Websocket not supported
            _wsTag.text = "Not supported";
            _wsTag.backgroundColor = "grey";
        }
    }

    RestModel {
        id: rmdSessionInfo
        property bool inactivity_timeout
        property bool session_expired
        url: '/users/apis/session-info/'
        onFetched: {
            if(inactivity_timeout) {
                login.show()
            }
            if(session_expired) {
                displayLoggedOutMessage()
            }
            // do nothing (not inactivity_timeout or session_expired)
        }
        onFailed: {
            if(xhr.status == 403) {
                displayLoggedOutMessage()
            }
        }
    }

    Item {
        displayNone: !showAccount || !showSwitchUser
        style: "padding: 5px;"
        Dom {
            Button {
                id: _btnSwitchUser
                icon: "random"
                circular: true
                backgroundColor: "transparent"
                className: "huge fluid"
                Popup{
                    position:"bottom left"
                    Text{
                        text: 'Switch User'
                    }
                }
            }
        }
    }

    Item {
        displayNone: !showHomeButton
        style: 'padding: 0px;'
        Dom{
            tagName: 'a'
            htmlAttr: { href: '/' }

            Button {
                id: _btnHome
                icon: "home"
                circular: true
                backgroundColor: "transparent"
                className: "massive fluid"
            }
        }
    }

    Item {
        displayNone: !showAccount
        className: "header"
        style: "padding: 0px; "
    }

    Item {
        displayNone: !showAccount
        Image {
            id: _userImage
            className: "avatar"
            source: "../../static/images/person.png"
        }
    }

    Item {
        id: _username
        displayNone: !showAccount
        style: 'padding: 0.4em'
        Form{
            Fields{
                className: "inline"
                Field{
                    Text{
                        className: "header"
                        text: DJANGO.user.fullname
                    }
                }
                Field{
                    style: "padding-right: 0px"
                    Icon {
                        id: _wsTag
                        Popup{
                            position:"bottom left"
                            Text{
                                id:_wsStatus
                            }
                        }
                    }
                }
            }
        }
    }

    Item {
        displayNone: !showAccount
        className: "header"
        text: DJANGO.division.code
    }

    Item {
        displayNone: !showBackButton
        style: "padding: 5px;"
        Dom{
            Button {
                id: _btnBack
                icon: "caret left"
                text: "Back"
                circular: true
                backgroundColor: "transparent"
                className: "large fluid"
                onClicked:{
                    _menubar.goBack()
                }
            }
        }
    }

    Container {
        className:"fluid"
        style: {
            return{
                margin:"0px !important",
                alignItems:"center"
            }
        }
        id: _customItem
    }

    InnerMenu {
        displayNone: !showAccount
        className: "right"

        Item {
            style: "margin-right: 25px;"
            Dom {
                Button {
                    className: "red"
                    text: "Log out"
                    onClicked: {
                        var smartCardAuthen = Util.getCookie('smartCardAuthen')
                        if (smartCardAuthen){
                            Util.setCookie('smartCardAuthen', false)
                        }
                        vcMessenger.dispose();
                        loginUrl = _createLoginUrl()
                        loginUrl = encodeURIComponent(loginUrl)
                        location.href = '/users/logout/?next=' + loginUrl
                    }
                }
            }
        }
    }

    Login {
        id: login
        relogin: true
        showRelogin: true
        displayNone: !showAccount
    }
}

        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/Common/MenuBar.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}