import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `REG/ModOpenEncounter.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import QmlWeb 1.0
import '../Common/Util.js' as Util

Modal{
    id: rootOpenVNModal
    property var patientDisplayName
    property var patient_id
    property var hideChiefComplaint
    property var showModal: rootOpenVNModal._showModal
    property bool newEncounterOnly : false

    function _showModal() {
        rootOpenVNModal.show()
    }

    onShowed:{
        checkRevisit.fetch()
        crdOpenVN.refresh()
        crdAppointmentList.clear()
        crdAppointmentList.refresh()
    }

    function openEmergencyEncounter() {
        crdOpenVN.openEmergencyEncounter()
        checkRevisit.show_alert = false
    }

    RestModel{
        id: rmdPatientCoverage
        url: '/apis/core/patientcoverage/?active=true&unexpired=true&patient=' + patient_id
        property var items
        property int total

        coverage_info: ''

        onFetched:{
            var coverage_info = ''
            if(rmdPatientCoverage.total > 0){
                coverage_info = 'ผู้ป่วยมีสิทธิ์การรักษา :<br>'
                rmdPatientCoverage.items.forEach(function(coverage) {
                    if (coverage['stop_date'] != null) {
                        coverage_info += coverage['coverage_name'] + ' หมดอายุ ' + coverage['stop_date']
                    } else {
                        coverage_info += coverage['coverage_name'] + ' หมดอายุ ' + '-'
                    }
                    coverage_info += '<br>'
                })
                coverage_info += '<br><br>'
            }
            rmdPatientCoverage.coverage_info = coverage_info
        }
    }

    Segment{
        className: 'defaultBackground'
        RestModel{
            id: checkRevisit
            property bool show_alert: true
            url:'/apis/core/encounter/'
            query: {
                return{
                    action: 'isRevisit',
                    patient: patient_id,
                }
            }
            property var items
            onFetched:{
                if(items.length !== 0 && checkRevisit.show_alert){
                    revisitAlert.message = 'ผู้ป่วยมี Encounter อยู่แล้ว จะทำการ re-visit'
                    revisitAlert.show()
                }
                checkRevisit.show_alert = true  // set back to normal state
            }
        }

        AlertDialog{
            id: revisitAlert
        }
        Link{
            href: 'javascript:void(0)'
            text: '&#10006'
            doc_label:'CloseOpenVN'
            style: {
                return{
                    position:'absolute',
                    right:'5px',
                    top:'5px',
                    color:'red',
                    fontSize:'x-large',
                    zIndex:'2'
                }
            }
            onClicked:{
                rootOpenVNModal.hide()
            }
        }
        LabelTag {
            className: 'ribbon large title'
            text: patientDisplayName
            backgroundColor: 'blue'
        }
        Tab{
            id: tabContainer
            headerStyle: 'line'
            TabContent{
                id: tctMain
                style: 'padding: 0px; margin: 0px;'
                text:'ข้อมูลหลัก'
                
                onSelected: {
                    tabContainer.selectedTab = 'MAIN'
                }

                CardOpenEncounter{
                    id: crdOpenVN
                    readOnly: newEncounterOnly
                    patientId: rootOpenVNModal.patient_id
                    hideChiefComplaint_:hideChiefComplaint
                    hideTitle: true
                    toggle: false
                    hideHeaderIcon: true
                }
            }
            TabContent{
                id: tctAppointment
                style: 'padding: 0px; margin: 0px;'
                text:'ข้อมูลนัดหมาย'

                onSelected: {
                    tabContainer.selectedTab = 'APPOINTMENT'
                }

                CardAppointmentList{
                    id: crdAppointmentList
                    patient_id: rootOpenVNModal.patient_id
                    appointment_list: null
                    total: 0

                    onHasAppointment:{
                        var appointment_info = ''
                        if (tabContainer.selectedTab !== 'APPOINTMENT') {
                            appointment_list.forEach(function(appointmenr) {
                                appointment_info += 'ผู้ป่วยมีนัด '
                                appointment_info += appointmenr['type_name']
                                appointment_info += ' '
                                appointment_info += appointmenr['doctor_name']
                                appointment_info += '<br>วัน '
                                appointment_info += appointmenr['date']
                                appointment_info += ' เวลา '
                                appointment_info += appointmenr['start_time']
                                appointment_info += '<br>แผนก '
                                appointment_info += appointmenr['division_name']
                                appointment_info += '<br><br>'
                            })
                            tctAppointment.select()
                        }
                        rmdPatientCoverage.fetch().then(function() {
                            if(rmdPatientCoverage.coverage_info != '' || appointment_info != ''){
                                Util.alert('ข้อมูลเพิ่มเติม', 
                                    'blue',
                                    'ตกลง', 
                                    null, 
                                    null,
                                    rmdPatientCoverage.coverage_info + appointment_info
                                )
                            }
                        })
                    }

                    onEncounterCreated: {
                        // Switch tab to crdOpenVN and Refresh for new encounter
                        tctMain.select()
                        crdOpenVN.refresh()
                        encounter_list.forEach(function(encounter_id) {
                            crdOpenVN.setEncounterEdit(rootOpenVNModal.patient_id, encounter_id)
                        })
                    }
                }
            }
        }
    }
}

        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/REG/ModOpenEncounter.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}