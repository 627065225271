import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import DateTextBox from '../common/DateTextBox';
import { Form } from 'semantic-ui-react';
import { parseDate, formatDate } from '../../utils/dateUtils';
import moment from 'moment';
import PropTypes from 'prop-types';

const styles = {
  label: {
    marginLeft: "1em"
  }
};

const DateFormTo = forwardRef((props, ref) => {

  let fromTxt = props.fromText || "ตั้งแต่"
  let toTxt = props.toText || "วันที่สิ้นสุด"
  
  const [startDate, setStartDate] = useState(props.defaultStartDate);
  const [endDate, setEndDate] = useState(props.defaultEndDate)


  useEffect( () => {
    let end = parseDate(endDate)
    let start = parseDate(startDate)
    if (start > end) {
      setStartDate(endDate)
    }
  },[endDate, startDate])

  useImperativeHandle(ref, () => ({

    getStartDate: () => {
      return startDate
    },

    getEndDate: () => {
      return endDate
    }
  }));

  const handleDateStartChange = (date) => {
    console.log(' handleDateStartChange date:', date)
    setStartDate(date)
    props.onStartDateChange(date)
  }

  const handleDateEndChange = (date) => {
    console.log(' handleDateStartChange date:', date)
    setEndDate(date)
    props.onEndDateChange(date)
  }

  return (
    <Form.Group inline style={props.style} className={props.className}>
      {fromTxt}
      <Form.Field style={styles.label}>
        <DateTextBox
          maxDate={endDate}
          size={props.dateTextBoxSize}
          value={startDate}
          onChange={handleDateStartChange}
        />
      </Form.Field>
      {toTxt}
      <Form.Field style={styles.label}>
        <DateTextBox
          maxDate={props.defaultMaxDate}
          size={props.dateTextBoxSize}
          value={endDate}
          onChange={handleDateEndChange}
        />
      </Form.Field>
    </Form.Group>
  )
})

DateFormTo.defaultProps = {
  fromText: 'From',
  toText: 'To',
  onStartDateChange: () => {},
  onEndDateChange: () => {},

  dateTextBoxSize: 'small',

  defaultStartDate: formatDate(moment().add(-7, 'days')),
  defaultEndDate: formatDate(moment()),
  className: "",
  defaultMaxDate: formatDate(moment()),
};

DateFormTo.propTypes = {
  fromText: PropTypes.string,
  toText: PropTypes.string,

  onStartDateChange: PropTypes.func, 
  onEndDateChange: PropTypes.func,

  dateTextBoxSize: PropTypes.string,

  defaultStartDate: PropTypes.string,
  defaultEndDate: PropTypes.string,

  className: PropTypes.string,
  defaultMaxDate: PropTypes.string,
}


export default React.memo(DateFormTo);
