import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { Button, Dropdown, Form, Icon, Input } from "semantic-ui-react";
import PropTypes from "prop-types";
import { useFormatDropdown } from "../../../react-lib/utils/hooksUtils";

const style = {
  zeroPadding: { paddingTop: "0px" , marginBottom: "0px"}
}

const BarcodeBox = forwardRef((props, ref) => {
  const [barcodePrefix, setBarcodePrefix] = React.useState({});
  const [placeholder, setPlaceholder] = React.useState("Auto");

  const barcodeRef = useRef();
  const prefixRef = useRef();

  useImperativeHandle(ref, () => ({
    clear: () => {
      barcodeRef.current.inputRef.current.value = "";

      // WORK
      //prefixRef.current.setValue(""); 
      //prefixRef.current.setValue("auto"); 

      // BUG
      // prefixRef.current.state.value = "";
    },
    barcodeValue: () => {
      console.log(' Return barc V:' , barcodeRef.current.inputRef.current.value )
      return barcodeRef.current.inputRef.current.value;
    },
    prefixValue: () => {
      let prefix = prefixRef.current.state.value;
      if (prefix === 'auto') {
        return "";
      }
      else {
        return prefix;
      }
    }
  }));

  useEffect(() => {
    getBarcodePrefix();
  }, []);

  const barcodePrefixList = useFormatDropdown(barcodePrefix ? barcodePrefix.items : [], "name", "id");

  const getBarcodePrefix = async () => {
    const choice = await props.onGetBarcodeChoice();
    if(choice && choice.hasOwnProperty('items')){
      choice["items"].unshift({ id: "auto", name: "AUTO" });
    }
    setBarcodePrefix(choice);
  }
  
  return (
    <React.Fragment>
      <Form>
        <Form.Group inline style={style.zeroPadding}>
          <Form.Field>{props.label}</Form.Field>
          <Form.Field>
            <Input
              size={"mini"}
              ref={barcodeRef}
              type="text"
              placeholder={placeholder}
              action={{ icon: 'search', color: 'green', onClick: () => props.onGetBarcode() }}
            >
            </Input>
          </Form.Field>
          <Form.Field>
            <Dropdown
              ref={prefixRef}
              style={{ minWidth: "150px" }}
              selection
              defaultValue={"auto"}
              options={barcodePrefixList}
              onChange={(e, data) => {
                let obj = data.options.find((item) => item.value === data.value)
                props.onPrefixChange({ text: obj.text })
                setPlaceholder(obj.text)
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </React.Fragment>
  )
});

BarcodeBox.defaultProps = {
  barcodePrefix: {},
  onGetBarcodeChoice: () => {},
  onGetBarcode: () => {},
  label: "สแกนรหัส",
  onPrefixChange: () => {},
}

BarcodeBox.propTypes = {
  barcodePrefix: PropTypes.object,
  onGetBarcodeChoice: PropTypes.func,
  onGetBarcode: PropTypes.func,
  onPrefixChange: PropTypes.func,
  label: PropTypes.string,
}

export default BarcodeBox;