
import PRXService from "../services/PRXService";
import PRXserviceLocal from "../services/PRXServiceLocal";
import { to } from "../../utils";
import { formatDateToYYYYMMDD } from "../../utils/dateUtils";
import { identifier } from "@babel/types";
export default class PRXManager {
  // anonymous
  newPRXService = apiToken => {
    //
    if (apiToken === "anonymous") {
      return new PRXserviceLocal();
    } else {
      return new PRXService();
    }
  };

  getDiagRule = async ({ apiToken, division, active } = {}) => {
    let params = {};
    if (division) {
      params.division = division;
    }
    params.active = active ? true : false;
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getDiagRule({
        params,
        apiToken
      })
    );
    //
    return [response, error, network];
  };
  getDiagForm = async ({ apiToken, patient, limit, offset, division } = {}) => {
    let params = {};
    if (patient) {
      params.patient = patient;
    }
    if (limit) {
      params.limit = limit;
    }
    if (offset) {
      params.offset = offset;
    }
    if (division) {
      params.division = division;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getDiagForm({
        params,
        apiToken
      })
    );
    //
    return [response, error, network];
  };
  getDivisionHasUser = async ({ apiToken } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getDivisionHasUser({
        params,
        apiToken
      })
    );
    return [response, error, network];
  };
  getDivisionProfile = async ({ apiToken, division, divisionCode } = {}) => {
    let params = {};
    if (division) {
      params.division = division;
    }
    if(divisionCode){
      params.code = divisionCode
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getDivisionProfile({
        params,
        apiToken
      })
    );
    return [response, error, network];
  };
  createDivisionHasUser = async ({ apiToken, data } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.createDivisionHasUser({
        params,
        apiToken,
        data
      })
    );
    return [response, error, network];
  };
  getDiagRuleDetail = async ({ apiToken, id } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getDiagRuleDetail({
        params,
        apiToken,
        id
      })
    );
    return [response, error, network];
  };
  postDiagRule = async ({ apiToken, data } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.postDiagRule({
        params,
        apiToken,
        data
      })
    );
    return [response, error, network];
  };
  patchDiagRuleDetail = async ({ apiToken, id, content } = {}) => {
    let params = {};
    let data = {};
    if (content) {
      data.content = content;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.patchDiagRuleDetail({
        params,
        apiToken,
        data,
        id
      })
    );
    return [response, error, network];
  };
  patchDiagRuleName = async ({ apiToken, id, name } = {}) => {
    let params = {};
    let data = {};
    if (name) {
      data.name = name;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.patchDiagRuleDetail({
        params,
        apiToken,
        data,
        id
      })
    );
    return [response, error, network];
  };
  patchDiagRulePublish = async ({ apiToken, id, published } = {}) => {
    let params = {};
    let data = {
      published: published
    };
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.patchDiagRuleDetail({
        params,
        apiToken,
        data,
        id
      })
    );
    return [response, error, network];
  };
  patchDiagRuleActive = async ({ apiToken, id, active, published } = {}) => {
    let params = {};
    let data = {
      active: active
    };
    if (published !== undefined && published !== null) {
      data.published = published;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.patchDiagRuleDetail({
        params,
        apiToken,
        data,
        id
      })
    );
    return [response, error, network];
  };
  getPublishedDiagRule = async ({ params, apiToken, diagRuleId }) => {
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getPublishedDiagRule({
        params,
        apiToken,
        diagRuleId
      })
    );
    return [response, error, network];
  };
  postPublishedDiagRule = async ({ apiToken, content, diagRule } = {}) => {
    let params = {};
    let data = {};
    if (content) {
      data.content = content;
    }
    if (diagRule) {
      data.diag_rule = diagRule;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.postPublishedDiagRule({
        params,
        apiToken,
        data
      })
    );
    return [response, error, network];
  };
  patchPublishedDiagRule = async ({ apiToken, published, id } = {}) => {
    let params = {};
    let data = {};
    if (published || published === false) {
      data.published = published;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.patchPublishedDiagRule({
        params,
        apiToken,
        data,
        id
      })
    );
    return [response, error, network];
  };
  getTriageLevelClassify = async ({ apiToken, divisionId } = {}) => {
    let params = {};
    let data = {};
    if (divisionId) {
      params.division = divisionId;
    }
    const prxService = this.newPRXService(apiToken);
    let [error, response, network] = await to(
      prxService.getTriageLevelClassify({
        params,
        apiToken
      })
    );
    if (error) {
      [error, response, network] = await to(
        prxService.getMasterTriageLevelClassify({
          params,
          apiToken
        })
      );
    }
    return [response, error, network];
  };
  getClassifyUser = async ({ apiToken, divisionId } = {}) => {
    let params = {};
    let data = {};
    if (divisionId) {
      params.division = divisionId;
    }
    const prxService = this.newPRXService(apiToken);
    let [error, response, network] = await to(
      prxService.getClassifyUser({
        params,
        apiToken
      })
    );
    if (error) {
      [error, response, network] = await to(
        prxService.getClassifyUser({
          params,
          apiToken
        })
      );
    }
    return [response, error, network];
  };
  getMasterTriageLevelClassify = async ({ apiToken } = {}) => {
    let params = {};
    let data = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getMasterTriageLevelClassify({
        params,
        apiToken
      })
    );
    return [response, error, network];
  };
  getLatestChatChannel = async ({ apiToken, userId } = {}) => {
    let params = {};
    if (userId) {
      params.user_id = userId;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getLatestChatChannel({
        params,
        apiToken
      })
    );
    return [response, error, network];
  };
  getEncounterTriage = async ({
    apiToken,
    filter,
    patientId,
    divisionIds,
    hasPatientApp,
    orderBy,
    date
  } = {}) => {
    let params = {};
    if (filter) {
      params.triage_level = filter;
    }
    if (patientId) {
      params.patients = patientId;
    }
    if (divisionIds) {
      params.divisions = divisionIds;
    }
    if (hasPatientApp || hasPatientApp === false) {
      params.has_patient_appointment = hasPatientApp;
    }
    if (orderBy) {
      params.order_by = orderBy;
    }
    if (date) {
      params.date = date;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getEncounterTriage({
        params,
        apiToken
      })
    );
    return [response, error, network];
  };
  getEncounter = async ({ apiToken, divisionId } = {}) => {
    let params = {};
    if (divisionId) {
      params.division = divisionId;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getEncounter({
        params,
        apiToken
      })
    );
    return [response, error, network];
  };
  getChatChannelMessageList = async ({
    apiToken,
    chatChannelId,
    last
  } = {}) => {
    let params = {};
    if (last) {
      params.last = last;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getChatChannelMessageList({
        params,
        apiToken,
        chatChannelId
      })
    );
    return [response, error, network];
  };
  getMessageFromURL = async ({ apiToken, url } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getMessageFromURL({
        params,
        apiToken,
        url
      })
    );
    return [response, error, network];
  };
  postChatChannelMessage = async ({
    apiToken,
    content,
    contentType,
    chatChannelId,
    contentFile
  } = {}) => {
    let params = {};
    let data = {};
    if (content && contentType !== "image" && contentType !== "file") {
      data.content = content;
    }
    if (contentType) {
      data.content_type = contentType;
    }
    if (contentFile) {
      data.content_file = contentFile;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.postChatChannelMessage({
        params,
        apiToken,
        data,
        chatChannelId
      })
    );
    return [response, error, network];
  };
  geNurseNoteList = async ({ apiToken, encounterId } = {}) => {
    let params = {};
    if (encounterId) {
      params.encounter = encounterId;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.geNurseNoteList({
        params,
        apiToken
      })
    );
    return [response, error, network];
  };
  geProgressNoteList = async ({ apiToken, encounterId } = {}) => {
    let params = {};
    if (encounterId) {
      params.encounter = encounterId;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.geProgressNoteList({
        params,
        apiToken
      })
    );
    return [response, error, network];
  };
  getChannelDetail = async ({ apiToken, chatChannelId } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getChannelDetail({
        chatChannelId,
        params,
        apiToken
      })
    );
    return [response, error, network];
  };
  geNurseNoteEncounter = async ({ apiToken, encounterId } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.geNurseNoteEncounter({
        encounterId,
        params,
        apiToken
      })
    );
    return [response, error, network];
  };
  getProxyPatient = async ({ apiToken, patientId } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getProxyPatient({
        apiToken,
        params,
        patientId
      })
    );
    return [response, error, network];
  };

  getProxyPatientList = async ({ apiToken, division, search } = {}) => {
    let params = {};
    if(search){
      params.search = search
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getProxyPatientList({
        apiToken,
        params,
        division
      })
    );
    return [response, error, network];
  };

  patchProxyPatientHasDivision = async ({
    apiToken,
    hn,
    id
  } = {}) => {
    let params = {}
    let data = {}
    if(hn){
      data.hn = hn
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.patchProxyPatientHasDivision({
        apiToken,
        params,
        data,
        id
      })
    );
    return [response, error, network];
  }

  patchProxyPatientHasDivisionAdmin = async ({
    apiToken,
    hn,
    id
  } = {}) => {
    let params = {}
    let data = {}
    if(hn){
      data.hn = hn
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.patchProxyPatientHasDivisionAdmin({
        apiToken,
        params,
        data,
        id
      })
    );
    return [response, error, network];
  }

  patchEncounterReclassify = async ({
    apiToken,
    encounterId,
    triageLevel
  } = {}) => {
    let params = {};
    let data = {};
    data.triage_level = triageLevel;
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.patchEncounterReclassify({
        apiToken,
        params,
        data,
        encounterId
      })
    );
    return [response, error, network];
  };
  getChatChannel = async ({ apiToken, userId, isPatient } = {}) => {
    let params = {};
    if (userId) {
      params.user = userId;
      params.user_type = isPatient ? "PATIENT" : "STAFF";
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getChatChannel({
        params,
        apiToken
      })
    );
    return [response, error, network];
  };
  getProxyPatientHasDivision = async ({ apiToken, params }) => {
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getProxyPatientHasDivision({
        params,
        apiToken
      })
    );
    return [response, error, network];
  };

  getPatient = async ({ apiToken }) => {
    const prxService = this.newPRXService(apiToken);
    const [error, response] = await to(
      prxService.getPatient({
        apiToken
      })
    );
    return [response, error];
  };
  postEncounterPatientAppointment = async ({ encounterId, apiToken } = {}) => {
    let params = {};
    let data = {};
    if (encounterId) {
      data.encounter = encounterId;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response] = await to(
      prxService.postEncounterPatientAppointment({
        apiToken,
        data,
        params
      })
    );
    return [response, error];
  };
  getDoctorWorkSchedule = async ({
    apiToken,
    providerId,
    startDate,
    endDate,
    divisionId
  } = {}) => {
    let params = {};
    if (providerId) {
      params.provider = providerId;
    }
    if (startDate) {
      params.start_date = startDate;
    }
    if (endDate) {
      params.end_date = endDate;
    }
    if (divisionId) {
      params.division = divisionId;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response] = await to(
      prxService.getDoctorWorkSchedule({
        apiToken,
        params
      })
    );
    return [response, error];
  };
  getAvailableDivisionServiceBlock = async ({
    apiToken,
    division,
    employee,
    date,
    timeRange
  } = {}) => {
    let params = {};
    if (division) {
      params.division = division;
    }
    if (employee) {
      params.employee = employee;
    }
    if (date) {
      params.date = date;
    }
    if (timeRange) {
      params.time_range = timeRange;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response] = await to(
      prxService.getAvailableDivisionServiceBlock({
        apiToken,
        params
      })
    );
    return [response, error];
  };
  postSelectDivisionServiceBlock = async ({
    apiToken,
    patientAppointmentId,
    division,
    timeRange,
    employee
  } = {}) => {
    let params = {};
    let data = {};
    if (patientAppointmentId) {
      data.patient_appointment = patientAppointmentId;
    }
    if (division) {
      data.division = division;
    }
    if (employee) {
      data.employee = employee;
    }
    if (timeRange) {
      data.time_range = timeRange;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.postSelectDivisionServiceBlock({
        apiToken,
        data,
        params
      })
    );
    return [response, error, network];
  };
  postRequestChangeDivisionServiceBlock = async ({
    apiToken,
    remark,
    division,
    patientAppointment
  } = {}) => {
    let params = {};
    let data = {};
    if (patientAppointment) {
      data.patient_appointment = patientAppointment;
    }
    if (division) {
      data.division = division;
    }
    if (remark) {
      data.remark = remark;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.postRequestChangeDivisionServiceBlock({
        apiToken,
        data,
        params
      })
    );
    return [response, error, network];
  };
  getEncounterTriageDetail = async ({ apiToken, encounterId } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getEncounterTriageDetail({
        apiToken,
        encounterId,
        params
      })
    );
    return [response, error, network];
  };

  getPatientQueue = async ({
    apiToken,
    divisionIds,
    divisionGroupCode,
    unsignedOnly
  } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    if (divisionIds) {
      params.division = divisionIds;
    }
    if (divisionGroupCode) {
      params.division_group_code = divisionGroupCode;
    }
    if (unsignedOnly) {
      params.unsigned_only = unsignedOnly;
    }
    const [error, response, network] = await to(
      prxService.getPatientQueue({
        apiToken,
        params
      })
    );
    return [response, error, network];
  };

  getAppointedPatientQueue = async ({
    apiToken,
    divisionIds,
    date
  } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    if (divisionIds) {
      params.division = divisionIds;
    }
    if (date) {
      params.date = date;
    }
    const [error, response, network] = await to(
      prxService.getAppointedPatientQueue({
        apiToken,
        params
      })
    );
    return [response, error, network];
  }

  getShippingAddress = async ({ apiToken, patientId, encounterId } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    if (patientId) {
      params.patient = patientId;
    }
    if (encounterId) {
      params.encounter = encounterId;
    }
    const [error, response, network] = await to(
      prxService.getShippingAddress({
        apiToken,
        params
      })
    );
    return [response, error, network];
  };
  postShippingAddress = async ({
    apiToken,
    patientAddressId,
    encounterId,
    selfPickup,
    receiverName,
    receiverTel
  } = {}) => {
    let params = {};
    let data = {};
    const prxService = this.newPRXService(apiToken);
    if (patientAddressId) {
      data.patient_address = patientAddressId;
    }
    if (encounterId) {
      data.encounter = encounterId;
    }
    if (selfPickup || selfPickup === false) {
      data.self_pickup = selfPickup;
    }
    if (receiverName) {
      data.receiver_name = receiverName;
    }
    if (receiverTel) {
      data.receiver_tel = receiverTel;
    }
    const [error, response, network] = await to(
      prxService.postShippingAddress({
        apiToken,
        params,
        data
      })
    );
    return [response, error, network];
  };
  getInitPatientAddress = async ({ apiToken, patientId } = {}) => {
    let params = {};
    const prxService = this.newPRXService();
    const [error, response, network] = await to(
      prxService.getInitPatientAddress({
        params,
        apiToken,
        patientId
      })
    );
    return [response, error, network];
  };
  getDashboard = async ({
    apiToken,
    search,
    date,
    triageLevels,
    division,
    paymentStatus
  } = {}) => {
    let params = {};
    if (search) {
      params.search = search;
    }
    if (date) {
      params.date = date;
    }
    if (triageLevels) {
      params.triage_levels = triageLevels;
    }
    if (division) {
      params.division = division;
    }
    if (paymentStatus) {
      params.payment_status = paymentStatus;
    }
    const prxService = this.newPRXService();
    const [error, response, network] = await to(
      prxService.getDashboard({
        params,
        apiToken
      })
    );
    return [response, error, network];
  };
  getDashboardAppointment = async ({
    apiToken,
    hn,
    patient_name,
    division,
    doctor_name,
    start_date,
    end_date,
    patient_dob,
    cancelToken
  }) => {
    const prxService = this.newPRXService();
    let params = {};
    if (hn) {
      params.hn = hn;
    }
    if (patient_name) {
      params.patient_name = patient_name;
    }
    if (division) {
      params.division = division;
    }
    if (doctor_name) {
      params.doctor_name = doctor_name;
    }
    if (start_date) {
      params.start_date = formatDateToYYYYMMDD(start_date);
    }
    if (end_date) {
      params.end_date = formatDateToYYYYMMDD(end_date);
    }
    if (patient_dob) {
      params.patient_dob = formatDateToYYYYMMDD(patient_dob);
    }
    const [error, response, network] = await to(
      prxService.getDashboardAppointment({ apiToken, params, cancelToken })
    );
    return [response, error, network];
  };

  getResolveChatChannel = async ({
    apiToken,
    divisionId,
    hn
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {}
    if(hn){
      params.hn = hn
    }
    const [error, response, network] = await to(
      prxService.getResolveChatChannel({ apiToken, params, divisionId })
    );
    return [response, error, network];
  }
}
