import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `Common/CardEpisode.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import QmlWeb 1.0
import DGrid 1.0
import '../Common' as Common
import '../Common/Util.js' as Util

Common.CardLayout {
    id: root
    title: 'Episode of Care'
    headerColor: 'purple'
    hideCloseButton: true

    property var patientID : null
    property var EPISODE_URL : "/apis/core/episode/"
    property var PATIENT_URL : "/apis/REG/patient/"

    RestModel {
        id: rmdEpisode
        property var patient
        property var name
        property var description
        property var start_date
        property var end_date
        property var active
    }

    RestModel {
        id: rmdEpisodeForGrid
        property alias items: grdEpisode.model
    }

    RestModel {
        id: rmdPatient
        property var full_name
        property var hn
    }

    function setPatient(patientID) {
        clearAll();
        root.patientID = patientID
        rmdPatient.url = PATIENT_URL + patientID + "/"
        rmdPatient.fetch().then(function(){
            psbHN.text = rmdPatient.hn
            txtPatientName.text = rmdPatient.full_name
            refreshGrid()
        })
    }

    function refreshGrid() {
        grdEpisode.model = []
        if (patientID != null) {
            rmdEpisodeForGrid.url = EPISODE_URL
            rmdEpisodeForGrid.query = {"patient":patientID}
            rmdEpisodeForGrid.fetch().then(clearEpisode)
        }
    }

    function save(){
        if (patientID == null) {
            Util.danger("กรุณาระบุ Patient")
        }
        rmdEpisode.patient = patientID
        rmdEpisode.name = txtEpisodeName.text
        rmdEpisode.description = txtEpisodeDescription.text
        rmdEpisode.start_date = dtbStartDate.text
        rmdEpisode.end_date = dtbEndDate.text
        rmdEpisode.active = true
        if(txtEpisodeId.text != "") {
            rmdEpisode.url = EPISODE_URL + txtEpisodeId.text + "/"
            rmdEpisode.save().then(refreshGrid)
        } else {
            rmdEpisode.url = EPISODE_URL
            rmdEpisode.create().then(refreshGrid)
        }
    }

    function cancel() {
        if(txtEpisodeId.text != "") {
            rmdEpisode.patient = patientID
            rmdEpisode.name = txtEpisodeName.text
            rmdEpisode.description = txtEpisodeDescription.text
            rmdEpisode.start_date = dtbStartDate.text
            rmdEpisode.end_date = dtbEndDate.text
            rmdEpisode.active = false
            rmdEpisode.url = EPISODE_URL + txtEpisodeId.text + "/"
            rmdEpisode.save().then(refreshGrid)
        }
    }

    function clearEpisode() {
        txtEpisodeId.text = ""
        txtEpisodeName.text = ""
        txtEpisodeDescription.text = ""
        dtbStartDate.text = ""
        dtbEndDate.text = ""
    }

    function clearAll() {
        grdEpisode.model = []
        txtPatientName.text = ""
        root.patientID = null
        clearEpisode()
    }


    content: [
        Form{
            // Patient Section.
            Fields {
                className:"inline"
                Field {
                    className: "two wide"
                    label: "HN:"
                    labelAlign: "right"
                }
                Field {
                    className: "three wide"
                    Common.PatientSearchBox {
                        id: psbHN
                        onSearched: {
                            txtPatientName.text = patientData['full_name']
                            root.patientID = patientID
                            refreshGrid()
                        }
                    }
                }
                Field {
                    className: "six wide"
                    TextBox{
                        id: txtPatientName
                        readOnly: true
                    }
                }
            }
            // Grid Episode Section
            Fields {
                Field {
                    className: "two wide"
                }
                Field {
                    className: "twelve wide"
                    Dom {
                        DGrid {
                            id: grdEpisode
                            height: 150
                            columns:[
                                DColumn {label: 'Name'; field: 'name'; width:40},
                                DColumn {label: 'Start Date'; field: 'start_date'; width:10},
                                DColumn {label: 'End Date'; field: 'end_date'; width:10},
                                DCustomColumn {
                                    label: 'Encounter ที่เกี่ยวข้อง'; 
                                    field: 'related_encounter'; 
                                    width:20
                                    Dom {
                                        Button {
                                            id: btn
                                            className: "purple"
                                            style: 'display: block; margin-left: auto; margin-right: auto;'
                                            icon: 'list layout'
                                            text: 'View'
                                            onClicked: {
                                                // console.log(modelData.id)
                                                modEpisodeEncounter.show()
                                                crdEpisodeEncounter.fetchEncounter(modelData.id)
                                                
                                            }
                                        }
                                    }
                                }
                            ]
                            onSelected: {
                                if (Object.keys(grdEpisode.selectedRow).length !== 0){
                                    txtEpisodeId.text = grdEpisode.selectedRow.id
                                    txtEpisodeName.text = grdEpisode.selectedRow.name
                                    txtEpisodeDescription.text = grdEpisode.selectedRow.description
                                    dtbStartDate.text = grdEpisode.selectedRow.start_date
                                    dtbEndDate.text = grdEpisode.selectedRow.end_date
                                }
                            }
                        }
                    }
                }
            }
            // Episode Detail Section
            Br{}
            Fields {
                className: "inline"
                displayNone: true
                Field {
                    className: "two wide"
                    label: "id:"
                    labelAlign: "right"
                }
                Field {
                    className: "one wide"
                    TextBox{
                        id: txtEpisodeId
                        readOnly: true
                    }
                }
            }
            Fields {
                className: "inline"
                Field {
                    className: "two wide"
                    label: "Name:"
                    labelAlign: "right"
                }
                Field {
                    className: "twelve wide"
                    TextBox{
                        id: txtEpisodeName
                    }
                }
            }
            Fields {
                className: "inline"
                Field {
                    className: "two wide"
                    label: "Description:"
                    labelAlign: "right"
                }
                Field {
                    className: "twelve wide"
                    TextArea{
                        id: txtEpisodeDescription
                    }
                }
            }
            Fields {
                className: "inline"
                Field {
                    className: "two wide"
                    label: "Start Date:"
                    labelAlign: "right"
                }
                Field {
                    className: 'three wide'
                    DateTextBox {
                        id:  dtbStartDate
                        text: Util.dateNow()
                    }
                }
                Field {
                    className: "two wide"
                    label: "End Date:"
                    labelAlign: "right"
                }
                Field {
                    className: 'three wide'
                    DateTextBox {
                        id:  dtbEndDate
                    }
                }
            }
            // Button Section
            Br {}
            Grid {
                Column{
                    className:'left floated left aligned two wide'
                    Button {
                        text:'Cancel'
                        className:'red'
                        fluid:true
                        onClicked: {
                            cancel()
                        }
                    }
                }
                Column {
                    className:'right floated right aligned ten wide'
                }
                Column {
                    className:'right floated right aligned two wide'
                    Button {
                        text:'New'
                        className:'blue'
                        fluid:true
                        onClicked: {
                            clearEpisode()
                            refreshGrid()
                        }
                    }
                }
                Column {
                    className:'right floated right aligned two wide'
                    Button {
                        text:'Save'
                        className:'green'
                        fluid:true
                        onClicked: {
                            root.save()
                        }
                    }
                }

            }
        },
        Modal {
            id: modEpisodeEncounter
            CardEpisodeEncounter {
                id: crdEpisodeEncounter
            }
        }
    ]
}
        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/Common/CardEpisode.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}